// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.admin.portal.apis.proto (package geneo.lms.admin.portal.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ModuleContentLock } from "@protos/learning_management/lms.common.apis_pb";
import { SchoolContentModuleNodeLock } from "@protos/school_management/school.db_pb";
import { ContentApprovalStatusEnum, ContentDeletionStatusEnum, Module_ModuleCategoryEnum, Remark } from "@protos/content_management/content.db_pb";
import { TeachClassSubjects } from "@protos/user_management/ums.login.apis_pb";
import { APIResponse_CodeEnum, APIResponse_StatusEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";

/**
 * @generated from enum geneo.lms.admin.portal.apis.SchoolTeacherContentApprovalStatus
 */
export enum SchoolTeacherContentApprovalStatus {
  /**
   * @generated from enum value: APPROVAL_STATUS_UNDEFINED = 0;
   */
  APPROVAL_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: APPROVAL_STATUS_PENDING = 1;
   */
  APPROVAL_STATUS_PENDING = 1,

  /**
   * @generated from enum value: APPROVAL_STATUS_COMPLETED = 2;
   */
  APPROVAL_STATUS_COMPLETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SchoolTeacherContentApprovalStatus)
proto3.util.setEnumType(SchoolTeacherContentApprovalStatus, "geneo.lms.admin.portal.apis.SchoolTeacherContentApprovalStatus", [
  { no: 0, name: "APPROVAL_STATUS_UNDEFINED" },
  { no: 1, name: "APPROVAL_STATUS_PENDING" },
  { no: 2, name: "APPROVAL_STATUS_COMPLETED" },
]);

/**
 * @generated from enum geneo.lms.admin.portal.apis.ActionTypeEnum
 */
export enum ActionTypeEnum {
  /**
   * @generated from enum value: ACTION_TYPE_UNDEFINED = 0;
   */
  ACTION_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: ACTION_TYPE_DELETE = 1;
   */
  ACTION_TYPE_DELETE = 1,

  /**
   * @generated from enum value: ACTION_TYPE_UPLOAD = 2;
   */
  ACTION_TYPE_UPLOAD = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionTypeEnum)
proto3.util.setEnumType(ActionTypeEnum, "geneo.lms.admin.portal.apis.ActionTypeEnum", [
  { no: 0, name: "ACTION_TYPE_UNDEFINED" },
  { no: 1, name: "ACTION_TYPE_DELETE" },
  { no: 2, name: "ACTION_TYPE_UPLOAD" },
]);

/**
 * @generated from enum geneo.lms.admin.portal.apis.ReportTypeEnum
 */
export enum ReportTypeEnum {
  /**
   * @generated from enum value: REPORT_TYPE_UNDEFINED = 0;
   */
  REPORT_TYPE_UNDEFINED = 0,

  /**
   * Knowledge Diagnostic Test
   *
   * @generated from enum value: REPORT_TYPE_KDT = 1;
   */
  REPORT_TYPE_KDT = 1,

  /**
   * @generated from enum value: REPORT_TYPE_HOMEWORK = 2;
   */
  REPORT_TYPE_HOMEWORK = 2,

  /**
   * @generated from enum value: REPORT_TYPE_FORMATIVE_ASSESSMENT = 3;
   */
  REPORT_TYPE_FORMATIVE_ASSESSMENT = 3,

  /**
   * @generated from enum value: REPORT_TYPE_CONNECTED_CLASSROOM = 4;
   */
  REPORT_TYPE_CONNECTED_CLASSROOM = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportTypeEnum)
proto3.util.setEnumType(ReportTypeEnum, "geneo.lms.admin.portal.apis.ReportTypeEnum", [
  { no: 0, name: "REPORT_TYPE_UNDEFINED" },
  { no: 1, name: "REPORT_TYPE_KDT" },
  { no: 2, name: "REPORT_TYPE_HOMEWORK" },
  { no: 3, name: "REPORT_TYPE_FORMATIVE_ASSESSMENT" },
  { no: 4, name: "REPORT_TYPE_CONNECTED_CLASSROOM" },
]);

/**
 * @generated from enum geneo.lms.admin.portal.apis.ReportRoleTypeEnum
 */
export enum ReportRoleTypeEnum {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: TEACHER = 2;
   */
  TEACHER = 2,

  /**
   * @generated from enum value: STUDENT = 3;
   */
  STUDENT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportRoleTypeEnum)
proto3.util.setEnumType(ReportRoleTypeEnum, "geneo.lms.admin.portal.apis.ReportRoleTypeEnum", [
  { no: 0, name: "UNDEFINED" },
  { no: 2, name: "TEACHER" },
  { no: 3, name: "STUDENT" },
]);

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminContentLockUpdateRequest
 */
export class AdminContentLockUpdateRequest extends Message<AdminContentLockUpdateRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.lms.common.apis.ModuleContentLock moduleInfo = 5;
   */
  moduleInfo?: ModuleContentLock;

  /**
   * @generated from field: optional bool is_update_required_for_all_sections = 6;
   */
  isUpdateRequiredForAllSections?: boolean;

  constructor(data?: PartialMessage<AdminContentLockUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminContentLockUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "moduleInfo", kind: "message", T: ModuleContentLock },
    { no: 6, name: "is_update_required_for_all_sections", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminContentLockUpdateRequest {
    return new AdminContentLockUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminContentLockUpdateRequest | PlainMessage<AdminContentLockUpdateRequest> | undefined, b: AdminContentLockUpdateRequest | PlainMessage<AdminContentLockUpdateRequest> | undefined): boolean {
    return proto3.util.equals(AdminContentLockUpdateRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminContentLockUpdateResponse
 */
export class AdminContentLockUpdateResponse extends Message<AdminContentLockUpdateResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<AdminContentLockUpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminContentLockUpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminContentLockUpdateResponse {
    return new AdminContentLockUpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminContentLockUpdateResponse | PlainMessage<AdminContentLockUpdateResponse> | undefined, b: AdminContentLockUpdateResponse | PlainMessage<AdminContentLockUpdateResponse> | undefined): boolean {
    return proto3.util.equals(AdminContentLockUpdateResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentLockModuleData
 */
export class ContentLockModuleData extends Message<ContentLockModuleData> {
  /**
   * @generated from field: geneo.school.db.SchoolContentModuleNodeLock module_updated_lock_info = 1;
   */
  moduleUpdatedLockInfo?: SchoolContentModuleNodeLock;

  constructor(data?: PartialMessage<ContentLockModuleData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentLockModuleData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_updated_lock_info", kind: "message", T: SchoolContentModuleNodeLock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJsonString(jsonString, options);
  }

  static equals(a: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined, b: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined): boolean {
    return proto3.util.equals(ContentLockModuleData, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchRequest
 */
export class AdminModuleLockInfoFetchRequest extends Message<AdminModuleLockInfoFetchRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 module_id = 5;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum category = 6;
   */
  category?: Module_ModuleCategoryEnum;

  constructor(data?: PartialMessage<AdminModuleLockInfoFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchRequest {
    return new AdminModuleLockInfoFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminModuleLockInfoFetchRequest | PlainMessage<AdminModuleLockInfoFetchRequest> | undefined, b: AdminModuleLockInfoFetchRequest | PlainMessage<AdminModuleLockInfoFetchRequest> | undefined): boolean {
    return proto3.util.equals(AdminModuleLockInfoFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchResponse
 */
export class AdminModuleLockInfoFetchResponse extends Message<AdminModuleLockInfoFetchResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<AdminModuleLockInfoFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminModuleLockInfoFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminModuleLockInfoFetchResponse {
    return new AdminModuleLockInfoFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminModuleLockInfoFetchResponse | PlainMessage<AdminModuleLockInfoFetchResponse> | undefined, b: AdminModuleLockInfoFetchResponse | PlainMessage<AdminModuleLockInfoFetchResponse> | undefined): boolean {
    return proto3.util.equals(AdminModuleLockInfoFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoRequest
 */
export class FetchAdminSchoolsInfoRequest extends Message<FetchAdminSchoolsInfoRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchAdminSchoolsInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoRequest {
    return new FetchAdminSchoolsInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdminSchoolsInfoRequest | PlainMessage<FetchAdminSchoolsInfoRequest> | undefined, b: FetchAdminSchoolsInfoRequest | PlainMessage<FetchAdminSchoolsInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdminSchoolsInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoResponse
 */
export class FetchAdminSchoolsInfoResponse extends Message<FetchAdminSchoolsInfoResponse> {
  /**
   * Status code
   *
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * Error code
   *
   * @generated from field: int32 code = 2;
   */
  code = 0;

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.SchoolDetails data = 4;
   */
  data: SchoolDetails[] = [];

  constructor(data?: PartialMessage<FetchAdminSchoolsInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAdminSchoolsInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: SchoolDetails, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdminSchoolsInfoResponse {
    return new FetchAdminSchoolsInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdminSchoolsInfoResponse | PlainMessage<FetchAdminSchoolsInfoResponse> | undefined, b: FetchAdminSchoolsInfoResponse | PlainMessage<FetchAdminSchoolsInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdminSchoolsInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.SchoolDetails
 */
export class SchoolDetails extends Message<SchoolDetails> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: optional string school_code = 3;
   */
  schoolCode?: string;

  /**
   * @generated from field: string school_logo_image_url = 4;
   */
  schoolLogoImageUrl = "";

  constructor(data?: PartialMessage<SchoolDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.SchoolDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "school_logo_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolDetails {
    return new SchoolDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolDetails {
    return new SchoolDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolDetails {
    return new SchoolDetails().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolDetails | PlainMessage<SchoolDetails> | undefined, b: SchoolDetails | PlainMessage<SchoolDetails> | undefined): boolean {
    return proto3.util.equals(SchoolDetails, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResquest
 */
export class FetchSchoolClassSectionCoursesInfoResquest extends Message<FetchSchoolClassSectionCoursesInfoResquest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<FetchSchoolClassSectionCoursesInfoResquest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResquest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResquest {
    return new FetchSchoolClassSectionCoursesInfoResquest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolClassSectionCoursesInfoResquest | PlainMessage<FetchSchoolClassSectionCoursesInfoResquest> | undefined, b: FetchSchoolClassSectionCoursesInfoResquest | PlainMessage<FetchSchoolClassSectionCoursesInfoResquest> | undefined): boolean {
    return proto3.util.equals(FetchSchoolClassSectionCoursesInfoResquest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResponse
 */
export class FetchSchoolClassSectionCoursesInfoResponse extends Message<FetchSchoolClassSectionCoursesInfoResponse> {
  /**
   * Status code
   *
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * Error code
   *
   * @generated from field: int32 code = 2;
   */
  code = 0;

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.TeachClassSubjects data = 4;
   */
  data: TeachClassSubjects[] = [];

  constructor(data?: PartialMessage<FetchSchoolClassSectionCoursesInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolClassSectionCoursesInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeachClassSubjects, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolClassSectionCoursesInfoResponse {
    return new FetchSchoolClassSectionCoursesInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolClassSectionCoursesInfoResponse | PlainMessage<FetchSchoolClassSectionCoursesInfoResponse> | undefined, b: FetchSchoolClassSectionCoursesInfoResponse | PlainMessage<FetchSchoolClassSectionCoursesInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchSchoolClassSectionCoursesInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListRequest
 */
export class FetchSchoolContentApprovalRequestListRequest extends Message<FetchSchoolContentApprovalRequestListRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: optional int32 school_id = 2;
   */
  schoolId?: number;

  /**
   * @generated from field: optional geneo.lms.admin.portal.apis.ApprovalRequestType approval_request_type = 3;
   */
  approvalRequestType?: ApprovalRequestType;

  /**
   * @generated from field: optional int32 page_number = 4;
   */
  pageNumber?: number;

  constructor(data?: PartialMessage<FetchSchoolContentApprovalRequestListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "approval_request_type", kind: "message", T: ApprovalRequestType, opt: true },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolContentApprovalRequestListRequest {
    return new FetchSchoolContentApprovalRequestListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListRequest {
    return new FetchSchoolContentApprovalRequestListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListRequest {
    return new FetchSchoolContentApprovalRequestListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolContentApprovalRequestListRequest | PlainMessage<FetchSchoolContentApprovalRequestListRequest> | undefined, b: FetchSchoolContentApprovalRequestListRequest | PlainMessage<FetchSchoolContentApprovalRequestListRequest> | undefined): boolean {
    return proto3.util.equals(FetchSchoolContentApprovalRequestListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ApprovalRequestType
 */
export class ApprovalRequestType extends Message<ApprovalRequestType> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ActionTypeEnum action_type = 1;
   */
  actionType = ActionTypeEnum.ACTION_TYPE_UNDEFINED;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.SchoolTeacherContentApprovalStatus status = 2;
   */
  status = SchoolTeacherContentApprovalStatus.APPROVAL_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ApprovalRequestType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ApprovalRequestType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "action_type", kind: "enum", T: proto3.getEnumType(ActionTypeEnum) },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(SchoolTeacherContentApprovalStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApprovalRequestType {
    return new ApprovalRequestType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApprovalRequestType {
    return new ApprovalRequestType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApprovalRequestType {
    return new ApprovalRequestType().fromJsonString(jsonString, options);
  }

  static equals(a: ApprovalRequestType | PlainMessage<ApprovalRequestType> | undefined, b: ApprovalRequestType | PlainMessage<ApprovalRequestType> | undefined): boolean {
    return proto3.util.equals(ApprovalRequestType, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListResponse
 */
export class FetchSchoolContentApprovalRequestListResponse extends Message<FetchSchoolContentApprovalRequestListResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.AdminApprovalRequests data = 4;
   */
  data?: AdminApprovalRequests;

  constructor(data?: PartialMessage<FetchSchoolContentApprovalRequestListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchSchoolContentApprovalRequestListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: AdminApprovalRequests },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolContentApprovalRequestListResponse {
    return new FetchSchoolContentApprovalRequestListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListResponse {
    return new FetchSchoolContentApprovalRequestListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolContentApprovalRequestListResponse {
    return new FetchSchoolContentApprovalRequestListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolContentApprovalRequestListResponse | PlainMessage<FetchSchoolContentApprovalRequestListResponse> | undefined, b: FetchSchoolContentApprovalRequestListResponse | PlainMessage<FetchSchoolContentApprovalRequestListResponse> | undefined): boolean {
    return proto3.util.equals(FetchSchoolContentApprovalRequestListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.AdminApprovalRequests
 */
export class AdminApprovalRequests extends Message<AdminApprovalRequests> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentUploadRequestInfo upload_requests = 1;
   */
  uploadRequests?: ContentUploadRequestInfo;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ContentDeletionRequestInfo delete_requests = 2;
   */
  deleteRequests?: ContentDeletionRequestInfo;

  constructor(data?: PartialMessage<AdminApprovalRequests>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.AdminApprovalRequests";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_requests", kind: "message", T: ContentUploadRequestInfo },
    { no: 2, name: "delete_requests", kind: "message", T: ContentDeletionRequestInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminApprovalRequests {
    return new AdminApprovalRequests().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminApprovalRequests {
    return new AdminApprovalRequests().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminApprovalRequests {
    return new AdminApprovalRequests().fromJsonString(jsonString, options);
  }

  static equals(a: AdminApprovalRequests | PlainMessage<AdminApprovalRequests> | undefined, b: AdminApprovalRequests | PlainMessage<AdminApprovalRequests> | undefined): boolean {
    return proto3.util.equals(AdminApprovalRequests, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentDeletionRequestInfo
 */
export class ContentDeletionRequestInfo extends Message<ContentDeletionRequestInfo> {
  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest pending_requests = 1;
   */
  pendingRequests: ContentDeletionApprovalRequest[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest completed_requests = 2;
   */
  completedRequests: ContentDeletionApprovalRequest[] = [];

  constructor(data?: PartialMessage<ContentDeletionRequestInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentDeletionRequestInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pending_requests", kind: "message", T: ContentDeletionApprovalRequest, repeated: true },
    { no: 2, name: "completed_requests", kind: "message", T: ContentDeletionApprovalRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentDeletionRequestInfo {
    return new ContentDeletionRequestInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentDeletionRequestInfo {
    return new ContentDeletionRequestInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentDeletionRequestInfo {
    return new ContentDeletionRequestInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ContentDeletionRequestInfo | PlainMessage<ContentDeletionRequestInfo> | undefined, b: ContentDeletionRequestInfo | PlainMessage<ContentDeletionRequestInfo> | undefined): boolean {
    return proto3.util.equals(ContentDeletionRequestInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentUploadRequestInfo
 */
export class ContentUploadRequestInfo extends Message<ContentUploadRequestInfo> {
  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentUploadApprovalRequest pending_requests = 1;
   */
  pendingRequests: ContentUploadApprovalRequest[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.ContentUploadApprovalRequest completed_requests = 2;
   */
  completedRequests: ContentUploadApprovalRequest[] = [];

  /**
   * @generated from field: int32 total_pending_requests_count = 3;
   */
  totalPendingRequestsCount = 0;

  /**
   * @generated from field: int32 total_completed_requests_count = 4;
   */
  totalCompletedRequestsCount = 0;

  constructor(data?: PartialMessage<ContentUploadRequestInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentUploadRequestInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pending_requests", kind: "message", T: ContentUploadApprovalRequest, repeated: true },
    { no: 2, name: "completed_requests", kind: "message", T: ContentUploadApprovalRequest, repeated: true },
    { no: 3, name: "total_pending_requests_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_completed_requests_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentUploadRequestInfo {
    return new ContentUploadRequestInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentUploadRequestInfo {
    return new ContentUploadRequestInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentUploadRequestInfo {
    return new ContentUploadRequestInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ContentUploadRequestInfo | PlainMessage<ContentUploadRequestInfo> | undefined, b: ContentUploadRequestInfo | PlainMessage<ContentUploadRequestInfo> | undefined): boolean {
    return proto3.util.equals(ContentUploadRequestInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentUploadApprovalRequest
 */
export class ContentUploadApprovalRequest extends Message<ContentUploadApprovalRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string teacher_name = 3;
   */
  teacherName = "";

  /**
   * @generated from field: int32 class_id = 4;
   */
  classId = 0;

  /**
   * @generated from field: string class = 5;
   */
  class = "";

  /**
   * @generated from field: int32 section_id = 6;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 7;
   */
  sectionName = "";

  /**
   * @generated from field: int32 subject_id = 8;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_name = 9;
   */
  subjectName = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject_enum = 10;
   */
  subjectEnum = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp submitted_date = 11;
   */
  submittedDate?: Timestamp;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 12;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string chapter_name = 13;
   */
  chapterName = "";

  /**
   * @generated from field: optional string topic_name = 14;
   */
  topicName?: string;

  /**
   * @generated from field: string resource_id = 15;
   */
  resourceId = "";

  /**
   * @generated from field: string title = 16;
   */
  title = "";

  /**
   * @generated from field: geneo.content.db.ContentApprovalStatusEnum status = 17;
   */
  status = ContentApprovalStatusEnum.CONTENT_APPROVAL_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ContentUploadApprovalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentUploadApprovalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "subject_enum", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 11, name: "submitted_date", kind: "message", T: Timestamp },
    { no: 12, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 13, name: "chapter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "topic_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "status", kind: "enum", T: proto3.getEnumType(ContentApprovalStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentUploadApprovalRequest {
    return new ContentUploadApprovalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentUploadApprovalRequest {
    return new ContentUploadApprovalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentUploadApprovalRequest {
    return new ContentUploadApprovalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContentUploadApprovalRequest | PlainMessage<ContentUploadApprovalRequest> | undefined, b: ContentUploadApprovalRequest | PlainMessage<ContentUploadApprovalRequest> | undefined): boolean {
    return proto3.util.equals(ContentUploadApprovalRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest
 */
export class ContentDeletionApprovalRequest extends Message<ContentDeletionApprovalRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string teacher_name = 3;
   */
  teacherName = "";

  /**
   * @generated from field: int32 class_id = 4;
   */
  classId = 0;

  /**
   * @generated from field: string class = 5;
   */
  class = "";

  /**
   * @generated from field: int32 section_id = 6;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 7;
   */
  sectionName = "";

  /**
   * @generated from field: int32 subject_id = 8;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_name = 9;
   */
  subjectName = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject_enum = 10;
   */
  subjectEnum = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp submitted_date = 11;
   */
  submittedDate?: Timestamp;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 12;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string resource_id = 13;
   */
  resourceId = "";

  /**
   * @generated from field: string title = 14;
   */
  title = "";

  /**
   * @generated from field: geneo.content.db.ContentDeletionStatusEnum status = 15;
   */
  status = ContentDeletionStatusEnum.CONTENT_DELETION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ContentDeletionApprovalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ContentDeletionApprovalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "subject_enum", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 11, name: "submitted_date", kind: "message", T: Timestamp },
    { no: 12, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 13, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "status", kind: "enum", T: proto3.getEnumType(ContentDeletionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentDeletionApprovalRequest {
    return new ContentDeletionApprovalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentDeletionApprovalRequest {
    return new ContentDeletionApprovalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentDeletionApprovalRequest {
    return new ContentDeletionApprovalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContentDeletionApprovalRequest | PlainMessage<ContentDeletionApprovalRequest> | undefined, b: ContentDeletionApprovalRequest | PlainMessage<ContentDeletionApprovalRequest> | undefined): boolean {
    return proto3.util.equals(ContentDeletionApprovalRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminRequest
 */
export class UpdateContentApprovalByAdminRequest extends Message<UpdateContentApprovalByAdminRequest> {
  /**
   * @generated from field: int64 admin_id = 1;
   */
  adminId = protoInt64.zero;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ActionStatus action_status = 3;
   */
  actionStatus?: ActionStatus;

  /**
   * @generated from field: optional google.protobuf.Timestamp action_date = 4;
   */
  actionDate?: Timestamp;

  /**
   * @generated from field: optional geneo.content.db.Remark remark = 5;
   */
  remark?: Remark;

  constructor(data?: PartialMessage<UpdateContentApprovalByAdminRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "action_status", kind: "message", T: ActionStatus },
    { no: 4, name: "action_date", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "remark", kind: "message", T: Remark, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateContentApprovalByAdminRequest {
    return new UpdateContentApprovalByAdminRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminRequest {
    return new UpdateContentApprovalByAdminRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminRequest {
    return new UpdateContentApprovalByAdminRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateContentApprovalByAdminRequest | PlainMessage<UpdateContentApprovalByAdminRequest> | undefined, b: UpdateContentApprovalByAdminRequest | PlainMessage<UpdateContentApprovalByAdminRequest> | undefined): boolean {
    return proto3.util.equals(UpdateContentApprovalByAdminRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ActionStatus
 */
export class ActionStatus extends Message<ActionStatus> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ActionTypeEnum action_type = 1;
   */
  actionType = ActionTypeEnum.ACTION_TYPE_UNDEFINED;

  /**
   * @generated from field: bool is_approve = 2;
   */
  isApprove = false;

  /**
   * @generated from field: bool is_approve_for_all_section = 3;
   */
  isApproveForAllSection = false;

  /**
   * @generated from field: optional int32 approve_for_section_id = 4;
   */
  approveForSectionId?: number;

  constructor(data?: PartialMessage<ActionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ActionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "action_type", kind: "enum", T: proto3.getEnumType(ActionTypeEnum) },
    { no: 2, name: "is_approve", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_approve_for_all_section", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "approve_for_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActionStatus {
    return new ActionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActionStatus {
    return new ActionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActionStatus {
    return new ActionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: ActionStatus | PlainMessage<ActionStatus> | undefined, b: ActionStatus | PlainMessage<ActionStatus> | undefined): boolean {
    return proto3.util.equals(ActionStatus, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminResponse
 */
export class UpdateContentApprovalByAdminResponse extends Message<UpdateContentApprovalByAdminResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string resource_id = 4;
   */
  resourceId = "";

  /**
   * @generated from field: string action_status = 5;
   */
  actionStatus = "";

  constructor(data?: PartialMessage<UpdateContentApprovalByAdminResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.UpdateContentApprovalByAdminResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "action_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateContentApprovalByAdminResponse {
    return new UpdateContentApprovalByAdminResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminResponse {
    return new UpdateContentApprovalByAdminResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateContentApprovalByAdminResponse {
    return new UpdateContentApprovalByAdminResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateContentApprovalByAdminResponse | PlainMessage<UpdateContentApprovalByAdminResponse> | undefined, b: UpdateContentApprovalByAdminResponse | PlainMessage<UpdateContentApprovalByAdminResponse> | undefined): boolean {
    return proto3.util.equals(UpdateContentApprovalByAdminResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAssessmentReportsRequest
 */
export class FetchAssessmentReportsRequest extends Message<FetchAssessmentReportsRequest> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ReportTypeEnum type = 1;
   */
  type = ReportTypeEnum.REPORT_TYPE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 2;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum profile_type = 3;
   */
  profileType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ReportRoleTypeEnum report_role_type = 4;
   */
  reportRoleType = ReportRoleTypeEnum.UNDEFINED;

  /**
   * @generated from field: int32 school_class_section_id = 5;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 school_id = 6;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<FetchAssessmentReportsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAssessmentReportsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ReportTypeEnum) },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "profile_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 4, name: "report_role_type", kind: "enum", T: proto3.getEnumType(ReportRoleTypeEnum) },
    { no: 5, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentReportsRequest {
    return new FetchAssessmentReportsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentReportsRequest {
    return new FetchAssessmentReportsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentReportsRequest {
    return new FetchAssessmentReportsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentReportsRequest | PlainMessage<FetchAssessmentReportsRequest> | undefined, b: FetchAssessmentReportsRequest | PlainMessage<FetchAssessmentReportsRequest> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentReportsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FetchAssessmentReportsResponse
 */
export class FetchAssessmentReportsResponse extends Message<FetchAssessmentReportsResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ReportData data = 4;
   */
  data?: ReportData;

  constructor(data?: PartialMessage<FetchAssessmentReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FetchAssessmentReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ReportData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAssessmentReportsResponse {
    return new FetchAssessmentReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAssessmentReportsResponse {
    return new FetchAssessmentReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAssessmentReportsResponse {
    return new FetchAssessmentReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAssessmentReportsResponse | PlainMessage<FetchAssessmentReportsResponse> | undefined, b: FetchAssessmentReportsResponse | PlainMessage<FetchAssessmentReportsResponse> | undefined): boolean {
    return proto3.util.equals(FetchAssessmentReportsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ReportData
 */
export class ReportData extends Message<ReportData> {
  /**
   * @generated from field: geneo.lms.admin.portal.apis.ReportRoleTypeEnum report_role_type = 1;
   */
  reportRoleType = ReportRoleTypeEnum.UNDEFINED;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.KDTReport kdt_reports = 2;
   */
  kdtReports?: KDTReport;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.HomeworkReport homework_reports = 3;
   */
  homeworkReports?: HomeworkReport;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.FormativeAssessmentReport fa_reports = 4;
   */
  faReports?: FormativeAssessmentReport;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ConnectedClassroomReport cc_reports = 5;
   */
  ccReports?: ConnectedClassroomReport;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ReportTypeEnum type = 6;
   */
  type = ReportTypeEnum.REPORT_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ReportData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ReportData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_role_type", kind: "enum", T: proto3.getEnumType(ReportRoleTypeEnum) },
    { no: 2, name: "kdt_reports", kind: "message", T: KDTReport },
    { no: 3, name: "homework_reports", kind: "message", T: HomeworkReport },
    { no: 4, name: "fa_reports", kind: "message", T: FormativeAssessmentReport },
    { no: 5, name: "cc_reports", kind: "message", T: ConnectedClassroomReport },
    { no: 6, name: "type", kind: "enum", T: proto3.getEnumType(ReportTypeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportData {
    return new ReportData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportData {
    return new ReportData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportData {
    return new ReportData().fromJsonString(jsonString, options);
  }

  static equals(a: ReportData | PlainMessage<ReportData> | undefined, b: ReportData | PlainMessage<ReportData> | undefined): boolean {
    return proto3.util.equals(ReportData, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.KDTReport
 */
export class KDTReport extends Message<KDTReport> {
  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.KdtStudentReport student_reports = 2;
   */
  studentReports: KdtStudentReport[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.KdtTeacherReport teacher_reports = 3;
   */
  teacherReports: KdtTeacherReport[] = [];

  constructor(data?: PartialMessage<KDTReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.KDTReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "student_reports", kind: "message", T: KdtStudentReport, repeated: true },
    { no: 3, name: "teacher_reports", kind: "message", T: KdtTeacherReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KDTReport {
    return new KDTReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KDTReport {
    return new KDTReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KDTReport {
    return new KDTReport().fromJsonString(jsonString, options);
  }

  static equals(a: KDTReport | PlainMessage<KDTReport> | undefined, b: KDTReport | PlainMessage<KDTReport> | undefined): boolean {
    return proto3.util.equals(KDTReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.HomeworkReport
 */
export class HomeworkReport extends Message<HomeworkReport> {
  /**
   * ReportRoleTypeEnum report_role_type = 1;
   *
   * @generated from field: repeated geneo.lms.admin.portal.apis.StudentReport student_reports = 2;
   */
  studentReports: StudentReport[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.TeacherReport teacher_reports = 3;
   */
  teacherReports: TeacherReport[] = [];

  constructor(data?: PartialMessage<HomeworkReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.HomeworkReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "student_reports", kind: "message", T: StudentReport, repeated: true },
    { no: 3, name: "teacher_reports", kind: "message", T: TeacherReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkReport {
    return new HomeworkReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkReport {
    return new HomeworkReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkReport {
    return new HomeworkReport().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkReport | PlainMessage<HomeworkReport> | undefined, b: HomeworkReport | PlainMessage<HomeworkReport> | undefined): boolean {
    return proto3.util.equals(HomeworkReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.FormativeAssessmentReport
 */
export class FormativeAssessmentReport extends Message<FormativeAssessmentReport> {
  /**
   * ReportRoleTypeEnum report_role_type = 1;
   *
   * @generated from field: repeated geneo.lms.admin.portal.apis.StudentReport student_reports = 2;
   */
  studentReports: StudentReport[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.TeacherReport teacher_reports = 3;
   */
  teacherReports: TeacherReport[] = [];

  constructor(data?: PartialMessage<FormativeAssessmentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.FormativeAssessmentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "student_reports", kind: "message", T: StudentReport, repeated: true },
    { no: 3, name: "teacher_reports", kind: "message", T: TeacherReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormativeAssessmentReport {
    return new FormativeAssessmentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormativeAssessmentReport {
    return new FormativeAssessmentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormativeAssessmentReport {
    return new FormativeAssessmentReport().fromJsonString(jsonString, options);
  }

  static equals(a: FormativeAssessmentReport | PlainMessage<FormativeAssessmentReport> | undefined, b: FormativeAssessmentReport | PlainMessage<FormativeAssessmentReport> | undefined): boolean {
    return proto3.util.equals(FormativeAssessmentReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.ConnectedClassroomReport
 */
export class ConnectedClassroomReport extends Message<ConnectedClassroomReport> {
  /**
   * ReportRoleTypeEnum report_role_type = 1;
   *
   * @generated from field: repeated geneo.lms.admin.portal.apis.StudentReport student_reports = 2;
   */
  studentReports: StudentReport[] = [];

  /**
   * @generated from field: repeated geneo.lms.admin.portal.apis.TeacherReport teacher_reports = 3;
   */
  teacherReports: TeacherReport[] = [];

  constructor(data?: PartialMessage<ConnectedClassroomReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.ConnectedClassroomReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "student_reports", kind: "message", T: StudentReport, repeated: true },
    { no: 3, name: "teacher_reports", kind: "message", T: TeacherReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassroomReport {
    return new ConnectedClassroomReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassroomReport {
    return new ConnectedClassroomReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassroomReport {
    return new ConnectedClassroomReport().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassroomReport | PlainMessage<ConnectedClassroomReport> | undefined, b: ConnectedClassroomReport | PlainMessage<ConnectedClassroomReport> | undefined): boolean {
    return proto3.util.equals(ConnectedClassroomReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.KdtStudentReport
 */
export class KdtStudentReport extends Message<KdtStudentReport> {
  /**
   * @generated from field: string student_name = 1;
   */
  studentName = "";

  /**
   * @generated from field: string class = 2;
   */
  class = "";

  /**
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * @generated from field: string teacher_name = 4;
   */
  teacherName = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp completion_date = 5;
   */
  completionDate?: Timestamp;

  /**
   * @generated from field: repeated string subjects = 6;
   */
  subjects: string[] = [];

  /**
   * @generated from field: repeated string chapters = 7;
   */
  chapters: string[] = [];

  /**
   * @generated from field: optional string topic = 8;
   */
  topic?: string;

  /**
   * @generated from field: optional string report_url = 9;
   */
  reportUrl?: string;

  /**
   * @generated from field: optional int32 report_id = 10;
   */
  reportId?: number;

  /**
   * @generated from field: repeated string homework_names = 11;
   */
  homeworkNames: string[] = [];

  /**
   * bool is_report_generated = 12;
   *
   * @generated from field: repeated int32 homework_ids = 13;
   */
  homeworkIds: number[] = [];

  /**
   * @generated from field: int64 student_id = 14;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<KdtStudentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.KdtStudentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "completion_date", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "subjects", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "homework_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "homework_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 14, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KdtStudentReport {
    return new KdtStudentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KdtStudentReport {
    return new KdtStudentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KdtStudentReport {
    return new KdtStudentReport().fromJsonString(jsonString, options);
  }

  static equals(a: KdtStudentReport | PlainMessage<KdtStudentReport> | undefined, b: KdtStudentReport | PlainMessage<KdtStudentReport> | undefined): boolean {
    return proto3.util.equals(KdtStudentReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.KdtTeacherReport
 */
export class KdtTeacherReport extends Message<KdtTeacherReport> {
  /**
   * @generated from field: string teacher_name = 1;
   */
  teacherName = "";

  /**
   * @generated from field: string class = 2;
   */
  class = "";

  /**
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * @generated from field: repeated string subjects = 4;
   */
  subjects: string[] = [];

  /**
   * @generated from field: repeated string chapters = 5;
   */
  chapters: string[] = [];

  /**
   * @generated from field: optional string topic = 6;
   */
  topic?: string;

  /**
   * @generated from field: string report_url = 7;
   */
  reportUrl = "";

  /**
   * @generated from field: repeated string homework_names = 8;
   */
  homeworkNames: string[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp hw_deadline = 9;
   */
  hwDeadline?: Timestamp;

  /**
   * @generated from field: optional int32 report_id = 10;
   */
  reportId?: number;

  /**
   * bool is_report_generated = 12;
   *
   * @generated from field: repeated int32 homework_ids = 13;
   */
  homeworkIds: number[] = [];

  /**
   * @generated from field: int64 teacher_id = 14;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<KdtTeacherReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.KdtTeacherReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subjects", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "report_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "homework_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "hw_deadline", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "homework_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 14, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KdtTeacherReport {
    return new KdtTeacherReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KdtTeacherReport {
    return new KdtTeacherReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KdtTeacherReport {
    return new KdtTeacherReport().fromJsonString(jsonString, options);
  }

  static equals(a: KdtTeacherReport | PlainMessage<KdtTeacherReport> | undefined, b: KdtTeacherReport | PlainMessage<KdtTeacherReport> | undefined): boolean {
    return proto3.util.equals(KdtTeacherReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.StudentReport
 */
export class StudentReport extends Message<StudentReport> {
  /**
   * @generated from field: string student_name = 1;
   */
  studentName = "";

  /**
   * @generated from field: string class = 2;
   */
  class = "";

  /**
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * @generated from field: string teacher_name = 4;
   */
  teacherName = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp completion_date = 5;
   */
  completionDate?: Timestamp;

  /**
   * @generated from field: optional string subject = 6;
   */
  subject?: string;

  /**
   * @generated from field: repeated string chapters = 7;
   */
  chapters: string[] = [];

  /**
   * @generated from field: optional string topic = 8;
   */
  topic?: string;

  /**
   * @generated from field: optional string report_url = 9;
   */
  reportUrl?: string;

  /**
   * @generated from field: optional int32 report_id = 10;
   */
  reportId?: number;

  /**
   * @generated from field: optional string homework_name = 11;
   */
  homeworkName?: string;

  /**
   * @generated from field: optional string lesson_name = 12;
   */
  lessonName?: string;

  /**
   * bool is_report_generated = 12;
   *
   * @generated from field: optional int32 homework_id = 13;
   */
  homeworkId?: number;

  /**
   * @generated from field: optional int64 connected_class_session_id = 14;
   */
  connectedClassSessionId?: bigint;

  /**
   * @generated from field: int64 student_id = 15;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<StudentReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.StudentReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "completion_date", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "report_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "homework_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "lesson_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentReport {
    return new StudentReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentReport {
    return new StudentReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentReport {
    return new StudentReport().fromJsonString(jsonString, options);
  }

  static equals(a: StudentReport | PlainMessage<StudentReport> | undefined, b: StudentReport | PlainMessage<StudentReport> | undefined): boolean {
    return proto3.util.equals(StudentReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.TeacherReport
 */
export class TeacherReport extends Message<TeacherReport> {
  /**
   * @generated from field: string teacher_name = 1;
   */
  teacherName = "";

  /**
   * @generated from field: string class = 2;
   */
  class = "";

  /**
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * @generated from field: optional string subject = 4;
   */
  subject?: string;

  /**
   * @generated from field: repeated string chapters = 5;
   */
  chapters: string[] = [];

  /**
   * @generated from field: optional string topic = 6;
   */
  topic?: string;

  /**
   * @generated from field: string report_url = 7;
   */
  reportUrl = "";

  /**
   * @generated from field: optional string homework_name = 8;
   */
  homeworkName?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp hw_deadline = 9;
   */
  hwDeadline?: Timestamp;

  /**
   * @generated from field: optional int32 report_id = 10;
   */
  reportId?: number;

  /**
   * @generated from field: optional int32 no_of_students_submitted = 11;
   */
  noOfStudentsSubmitted?: number;

  /**
   * bool is_report_generated = 12;
   *
   * @generated from field: optional int32 homework_id = 13;
   */
  homeworkId?: number;

  /**
   * @generated from field: int64 teacher_id = 14;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: optional string lesson_name = 15;
   */
  lessonName?: string;

  /**
   * @generated from field: optional int64 connected_class_session_id = 16;
   */
  connectedClassSessionId?: bigint;

  constructor(data?: PartialMessage<TeacherReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.TeacherReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "chapters", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "report_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "homework_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "hw_deadline", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "no_of_students_submitted", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "lesson_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherReport {
    return new TeacherReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherReport {
    return new TeacherReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherReport {
    return new TeacherReport().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherReport | PlainMessage<TeacherReport> | undefined, b: TeacherReport | PlainMessage<TeacherReport> | undefined): boolean {
    return proto3.util.equals(TeacherReport, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.SendAssessmentReportEmailRequest
 */
export class SendAssessmentReportEmailRequest extends Message<SendAssessmentReportEmailRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: optional int32 report_id = 2;
   */
  reportId?: number;

  /**
   * @generated from field: geneo.lms.admin.portal.apis.ReportTypeEnum type = 3;
   */
  type = ReportTypeEnum.REPORT_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<SendAssessmentReportEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.SendAssessmentReportEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "report_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(ReportTypeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendAssessmentReportEmailRequest {
    return new SendAssessmentReportEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendAssessmentReportEmailRequest {
    return new SendAssessmentReportEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendAssessmentReportEmailRequest {
    return new SendAssessmentReportEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendAssessmentReportEmailRequest | PlainMessage<SendAssessmentReportEmailRequest> | undefined, b: SendAssessmentReportEmailRequest | PlainMessage<SendAssessmentReportEmailRequest> | undefined): boolean {
    return proto3.util.equals(SendAssessmentReportEmailRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.admin.portal.apis.SendAssessmentReportEmailResponse
 */
export class SendAssessmentReportEmailResponse extends Message<SendAssessmentReportEmailResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<SendAssessmentReportEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.admin.portal.apis.SendAssessmentReportEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendAssessmentReportEmailResponse {
    return new SendAssessmentReportEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendAssessmentReportEmailResponse {
    return new SendAssessmentReportEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendAssessmentReportEmailResponse {
    return new SendAssessmentReportEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendAssessmentReportEmailResponse | PlainMessage<SendAssessmentReportEmailResponse> | undefined, b: SendAssessmentReportEmailResponse | PlainMessage<SendAssessmentReportEmailResponse> | undefined): boolean {
    return proto3.util.equals(SendAssessmentReportEmailResponse, a, b);
  }
}

