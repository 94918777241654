import { LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { theme } from '../../../../src/theme/themeProvider';

interface IProps {
  score: number;
  variant: 'small' | 'md' | 'lg';
}

export const ScoreProgressBar = (props: IProps) => {
  const { score, variant } = props;

  const formattedScore = Number(score.toFixed(2));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  return (
    <Box sx={{ position: 'relative', width: '100%', boxSizing: 'border-box' }}>
      <LinearProgress
        variant="determinate"
        sx={{
          // minWidth: { xs: pxToRem(109), md: pxTovW(79.93) },
          height:
            variant === 'small'
              ? { xs: pxToRem(14), lg: pxTovW(16) }
              : variant === 'md'
              ? { xs: pxToRem(16), md: pxTovW(24) }
              : { xs: pxToRem(18), md: pxToRem(15), lg: pxTovW(27) },
          borderRadius: { xs: pxToRem(4), md: pxTovW(4) },
          backgroundColor: '#B3B3B3',
          width: '100%',
          // flexGrow: 1,
        }}
        color={
          formattedScore < 30
            ? 'error'
            : formattedScore < 70
            ? 'warning'
            : 'success'
        }
        value={formattedScore}
      />
      <Typography
        variant="smallText"
        sx={{
          position: 'absolute',
          color: 'white',
          top:
            variant === 'small'
              ? '1%'
              : variant === 'md' && isIpadOnly
              ? pxToRem(-2)
              : '10%',
          left: { xs: pxToRem(30), md: pxTovW(30) },
          transform: 'translateX(-50%)',
        }}
      >
        {formattedScore}%
      </Typography>
    </Box>
  );
};
