import { Timestamp } from '@bufbuild/protobuf';

import {
  ActionPopUpSingleButton,
  HomeworkPagination,
  HomeworkTimer,
  IQuestionStatus,
  IQuestionStatusObject,
  IStyles,
  QuestionContainerWithSolution,
  cloneObject,
  deserify,
  isCorrectAnswer,
  pxToRem,
  pxTovW,
  setLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import 'react-image-crop/src/ReactCrop.scss';

import {
  Question,
  Question_QuestionEnum,
} from '@protos/content_management/content.db_pb';
import {
  ContentAttempt,
  StudentTaskResponse,
  TaskStudentAttemptStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { HWStudentResponse } from '@protos/learning_management/lms.hw.student.apis_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import {
  HOME,
  HOMEWORK_STUDENT_DASHBOARD,
  HOMEWORK_SUBMITTED,
} from '../../../../routeHandling/RoutesNomenclature';
import { onHomeworkCardClick } from '../../../../utils/homework';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import {
  setActiveHomeworSessionId,
  setActiveHomeworkAttemptResult,
  setActiveHomeworkStudentResponse,
  setAllAnswers,
  setSelectedHomeworkData,
} from '../../reducer/homework.slice';
import {
  aiHomeworkCloseEvent,
  aiHomeworkOpenEvent,
  aiQuestionOpenEvent,
  aiQuestionSubmitEvent,
} from '../../utils_homework/homework.events';
import ActiveProgressbar from './ActiveProgressbar';
import { HwMemePage } from './HwMemePage';
import ImageUploadPopup from './ImageUploadPopup';
import { setToastInfo } from '../../../Home/reducer/homeDashboard.slice';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import { checkFeatureData } from '../../utils_homework/homeworkUtil';
import { AssessmentStudentResponse } from '@protos/learning_management/lms.assessment.student.apis_pb';
import { rename } from 'fs';

const styles: IStyles = {
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    p: {
      xs: `${pxToRem(15)} 0`,
      md: pxToRem(20),
      lg: `${pxTovW(40)} ${pxTovW(240)}`,
    },
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
  },
  heading: {
    mb: { xs: pxToRem(20), md: pxTovW(20) },
    px: { xs: pxToRem(8), md: 0 },
  },

  questionBox: {
    margin: 'auto',
    // width: 'max-content',
    // mt: { xs: pxToRem(20), md: pxTovW(30) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)}`,
      md: `${pxTovW(20)} ${pxTovW(40)}`,
    },
    boxSizing: 'border-box',
  },
};

export const ActiveHomework = () => {
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const { LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const { isOffline } = useDownloadContext();
  const [openUploadPopup, setOpenUploadPopup] = useState<boolean>(false);
  const homework_id =
    new URLSearchParams(location.search).get('homeworkId') || undefined;
  const sessionMode =
    new URLSearchParams(location.search).get('sessionMode') || undefined;

  const {
    active_homework_content,
    active_homework_student_response,
    active_homework_session_id,
    selected_homework_data,
    all_answers,
  } = deserify(useAppSelector((state) => state.homework));
  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const activeHomeworkContent =
    active_homework_content && homework_id
      ? active_homework_content[homework_id]
      : undefined;
  const activeHomeworkStudentResponse =
    active_homework_student_response && homework_id
      ? active_homework_student_response[homework_id]
      : undefined;
  const taskQuestionInfo =
    activeHomeworkContent?.questionsSequenceInfo?.taskQuestionInfo || [];
  const sortedQuestionIds = taskQuestionInfo
    .sort((a, b) => a.sequence - b.sequence)
    .map((val) => val.questionId)
    .filter((val) => val !== undefined) as string[];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(
    sortedQuestionIds[0]
  );
  const [isPaused, setIsPaused] = useState(false);
  const [timeupPopup, setTimeupPopup] = useState(false);
  const { selected_resource_info } = deserify(
    useAppSelector((state) => state.learn)
  );
  const selectedHomeworkContent = activeHomeworkContent?.assessmentContent.find(
    (hw) => hw.questionId == activeQuestionId
  );
  const activeQuestionIndex = sortedQuestionIds.indexOf(activeQuestionId);
  const totalQuestions = sortedQuestionIds.length;
  // const [answers, setAnswers] = useState<string[]>([]);
  const [startTime, setStartTime] = useState(Timestamp.fromDate(new Date()));
  const homework_end_path = useAppSelector(
    (state) => state.homework.homework_end_path
  );
  const [hwTimer, setHwTimer] = useState<number | undefined>();

  const [showExitPopup, setShowExitPopup] = useState(false);

  const [showMeme, setShowMeme] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setSelectedFunction, setSelectedLogoClickHandler } =
    useGlobalContext();

  // const backClick = async () => {
  //   setShowExitPopup(true);
  // };

  // const handleExit = (response: 'yes' | 'no') => {
  //   if (response === 'yes') {
  //     aiHomeworkCloseEvent({ homeworkId: selected_homework_data?.homeworkId });

  //     navigate(homework_end_path ? homework_end_path : HOME);
  //     dispatch(setHomeworkEndPath(undefined));
  //   } else {
  //     setSelectedFunction(() => backClick);
  //     setShowExitPopup(false);

  const submitBackHandler = async (isBackClicked?: boolean) => {
    const quesAttempt = new ContentAttempt({
      startTime: startTime,
      endTime: Timestamp.fromDate(new Date()),
      answer: [],
    });
    const questionTaskId = taskQuestionInfo.find(
      (val) => val.questionId === activeQuestionId
    )?.questionTaskId;
    const responseToSubmit = new StudentTaskResponse({
      // homeworkId: Number(homework_id),
      questionId: activeQuestionId,
      questionTaskId: questionTaskId,
      responses: [quesAttempt],
      isAttempted: false,
    });

    const activeHomeworkStudentResp = new AssessmentStudentResponse(
      activeHomeworkStudentResponse
    );
    const quesResponseIndex = activeHomeworkStudentResp.responses.findIndex(
      (resp) => resp.questionId === activeQuestionId
    );
    if (quesResponseIndex >= 0) {
      activeHomeworkStudentResp.responses[quesResponseIndex].responses.push(
        quesAttempt
      );
    } else {
      activeHomeworkStudentResp.responses.push(responseToSubmit);
    }
    try {
      setIsPaused(true);
      const hwSubmitResponse =
        await LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler.submitAssessmentResponse(
          {
            studentId: userInfo?.studentProfileId,
            assessmentId: Number(homework_id),
            assessmentAttemptStatus: isBackClicked
              ? TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_IN_PROGRESS
              : TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_TIMED_OUT,
            responses: activeHomeworkStudentResp.responses,
            timestamp: Timestamp.fromDate(new Date()),
            sessionId: active_homework_session_id,
            attemptSpentTimeInSecs: checkFeatureData({
              assignmentConfigInfo:
                activeHomeworkContent?.assessment?.assignmentInfo,
              userInfo: userInfo,
            }).timed
              ? selected_homework_data?.elapsedTime
              : undefined,
          }
        );
      if (hwSubmitResponse) {
        if (!isBackClicked) {
          setTimeupPopup(true);
        }
      } else {
        if (!isBackClicked) {
          dispatch(
            setToastInfo({
              label: 'Something went wrong. Please try again.',
              variant: 'error',
              open: true,
            })
          );
          navigate(HOMEWORK_STUDENT_DASHBOARD);
        } else {
          dispatch(
            setToastInfo({
              label: 'Something went wrong. ',
              variant: 'error',
              open: true,
            })
          );
        }
      }
    } catch (err: any) {
      if (!isBackClicked) {
        dispatch(
          setToastInfo({
            label: 'Something went wrong. Please try again.',
            variant: 'error',
            open: true,
          })
        );
        navigate(HOMEWORK_STUDENT_DASHBOARD);
      } else {
        dispatch(
          setToastInfo({
            label: 'Something went wrong. ',
            variant: 'error',
            open: true,
          })
        );
      }
      console.log(err);
    }
  };
  const onBackClick = async () => {
    const studId = userInfo?.studentProfileId;
    if (studId) {
      await aiHomeworkCloseEvent({
        homeworkId: selected_homework_data?.homeworkId,
        isOffline: isOffline,
      });
      if (!showMeme) {
        submitBackHandler(true);
      }
      onHomeworkCardClick(
        dispatch,
        navigate,
        Number(homework_id),
        studId,
        undefined,
        homework_end_path
      );
    }
  };
  const onLogoClick = async () => {
    const studId = userInfo?.studentProfileId;
    if (studId) {
      await aiHomeworkCloseEvent({
        homeworkId: selected_homework_data?.homeworkId,
        isOffline: isOffline,
      });
      submitBackHandler(true);
      navigate(HOME);
    }
  };

  useEffect(() => {
    // const resumeCheck = completedTasks > 0 && completedTasks < totalTasks;
    // const reattemptedCheck = completedTasks >= totalTasks;

    (async () => {
      await aiHomeworkOpenEvent({
        homeworkId: selected_homework_data?.homeworkId,
        questionIds: selected_homework_data?.questionIds,
        isResume: sessionMode === '3' ? true : false,
        isReattempted: sessionMode === '2' ? true : false,
        homeworkType: activeHomeworkContent?.assessment?.taskType,
        isOffline: isOffline,

        // isResume:
        //   sessionMode === HomeworkSessionModeEnum.HOMEWORK_SESSION_MODE_RESUME
        //     ? true
        //     : false,
        // isReattempted:
        //   sessionMode ===
        //   HomeworkSessionModeEnum.HOMEWORK_SESSION_MODE_REATTEMPT
        //     ? true
        //     : false,

        // isResume: selected_homework_data?.resumeCheck,
        // isReattempted: selected_homework_data?.reattemptedCheck,

        // isResume: resumeCheck,
        // isReattempted: reattemptedCheck,
      });
    })();

    if (
      activeHomeworkStudentResponse?.responses
      // &&
      // activeHomeworkStudentResponse?.responses.length !==
      //   activeHomeworkContent?.assessmentContent.length
    ) {
      const attemptedQuestionIds = activeHomeworkStudentResponse.responses
        .map((val) => (val.isAttempted && val.questionId) || '')
        .filter((val) => val !== '');
      const filteredQuestionIds = sortedQuestionIds.filter(
        (val) => !attemptedQuestionIds.includes(val)
      );
      if (filteredQuestionIds.length > 0) {
        // const lastQuestionAttempted =
        //   activeHomeworkStudentResponse.responses.length;
        activeHomeworkStudentResponse.responses.forEach((ans, i) => {
          const qIndex = sortedQuestionIds.findIndex(
            (val) => val === ans.questionId
          );
          questionStatusArray[qIndex].statusInfo = IQuestionStatus.Attempted;
        });
        setQuestionStatusArray(questionStatusArray);

        setActiveQuestionId(filteredQuestionIds[0]);
      }
    }
  }, []);

  useEffect(() => {
    setSelectedFunction(() => onBackClick);
    setSelectedLogoClickHandler(() => onLogoClick);
    return () => {
      setSelectedFunction(null);
      setSelectedLogoClickHandler(null);
    };
  }, [activeQuestionId, selected_homework_data?.elapsedTime, showMeme]);

  const [resumeQueOpenTrigger, setResumeQueOpenTrigger] = useState(false);
  useEffect(() => {
    setShowMeme(false);
    // setAnswers([]);
    dispatch(setAllAnswers([]));
    setStartTime(Timestamp.fromDate(new Date()));
    // Disabled so that we don't show previous ans
    // const quesResponse = activeHomeworkStudentResponse?.responses.find(resp => resp.questionId == activeQuestionId);
    // setAnswers(new ContentAttempt(getLastAttemptedResponse(quesResponse?.responses)).answer);

    (async () => {
      if (sessionMode === '3' && resumeQueOpenTrigger) {
        await aiQuestionOpenEvent({
          homeworkId: selected_homework_data?.homeworkId,
          questionId: activeQuestionId,
          isOffline: isOffline,
        });
      } else if (sessionMode !== '3') {
        await aiQuestionOpenEvent({
          homeworkId: selected_homework_data?.homeworkId,
          questionId: activeQuestionId,
          isOffline: isOffline,
        });
      }
      setResumeQueOpenTrigger(true);
    })();
  }, [activeQuestionId]);

  useEffect(() => {
    (async () => {
      if (isSubmitting) {
        await handleNext();
        setIsSubmitting(false); // Resetting isSubmitting after calling handleNext
      }
    })();
  }, [isSubmitting]);

  // useEffect(() => {
  //   if (!activeHomeworkContent?.homework?.estimatedTimeInMin) return;

  //   const totalTimeInSeconds =
  //     activeHomeworkContent.homework.estimatedTimeInMin * 60;

  //   // Initialize current time from Redux state or total time
  //   let currentTime = homework_time_remaining || totalTimeInSeconds;

  //   const timer = setInterval(() => {
  //     currentTime -= 1;

  //     if (currentTime <= 0) {
  //       clearInterval(timer);
  //       // Perform actions on timer completion
  //       handleNext();
  //       navigate(`${HOMEWORK_SUBMITTED}?homeworkId=${homework_id}`);
  //       dispatch(setHomeworkTimeRemaining(0)); // Update Redux state to 0
  //     } else {
  //       dispatch(setHomeworkTimeRemaining(currentTime)); // Update Redux state with remaining time
  //     }
  //   }, 1000);

  //   // Cleanup interval on component unmount or dependency change
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [
  //   activeHomeworkContent?.homework?.estimatedTimeInMin,
  //   homework_time_remaining,
  //   dispatch,
  // ]);

  const [questionStatusArray, setQuestionStatusArray] = useState<
    IQuestionStatusObject[]
  >(
    sortedQuestionIds.map((qId, index) => {
      return {
        index,
        statusInfo: IQuestionStatus.NotAttempted,
      };
    })
  );

  const handleNext = async () => {
    if (activeQuestionIndex < 0 || !homework_id || isNaN(Number(homework_id))) {
      return;
    }
    if (!active_homework_session_id) {
      // throw new Error('Invalid or missing session id');
      dispatch(
        setToastInfo({
          label: 'Invalid or missing Session id',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    const isLastQuestion = activeQuestionIndex === totalQuestions - 1;
    const quesAttempt = new ContentAttempt({
      startTime: startTime,
      endTime: Timestamp.fromDate(new Date()),
      answer: all_answers,
    });

    const questionTaskId = taskQuestionInfo.find(
      (val) => val.questionId === activeQuestionId
    )?.questionTaskId;

    const responseToSubmit = new StudentTaskResponse({
      // homeworkId: Number(homework_id),
      questionId: activeQuestionId,
      questionTaskId: questionTaskId,
      responses: [quesAttempt],
      isAttempted: true,
    });

    const activeHomeworkStudentResp = new AssessmentStudentResponse(
      activeHomeworkStudentResponse
    );
    const quesResponseIndex = activeHomeworkStudentResp.responses.findIndex(
      (resp) => resp.questionId === activeQuestionId
    );
    if (quesResponseIndex >= 0) {
      activeHomeworkStudentResp.responses[quesResponseIndex].responses.push(
        quesAttempt
      );
    } else {
      activeHomeworkStudentResp.responses.push(responseToSubmit);
    }
    try {
      setIsPaused(true);
      const hwSubmitResponse =
        await LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler.submitAssessmentResponse(
          {
            studentId: userInfo?.studentProfileId,
            assessmentId: Number(homework_id),
            assessmentAttemptStatus: isLastQuestion
              ? TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_COMPLETED
              : TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_IN_PROGRESS,
            responses: activeHomeworkStudentResp.responses,
            timestamp: Timestamp.fromDate(new Date()),
            sessionId: active_homework_session_id,
            attemptSpentTimeInSecs: checkFeatureData({
              assignmentConfigInfo:
                activeHomeworkContent?.assessment?.assignmentInfo,
              userInfo: userInfo,
            }).timed
              ? selected_homework_data?.elapsedTime
              : undefined,
          }
        );
      if (hwSubmitResponse.status === 200) {
        // dispatch(
        //   setActiveHomeworkStudentResponse({
        //     homeworkId: Number(homework_id),
        //     response: activeHomeworkStudentResp,
        //   })
        // );
        questionStatusArray[activeQuestionIndex].statusInfo =
          IQuestionStatus.Attempted;
        setQuestionStatusArray(questionStatusArray);

        const studentHomeworkResp =
          await LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler.fetchStudentAssessmentResponse(
            {
              studentId: userInfo?.studentProfileId,
              assessmentId: Number(homework_id),
            }
          );

        const filteredSubmitResponse = studentHomeworkResp.data?.responses.find(
          (response) => response.questionId === activeQuestionId
        );

        if (
          [
            'veryShortQuesContentModel',
            'shortQuesContentModel',
            'longQuesContentModel',
            'fibContentModel',
            'mcqMultipleContentModel',
          ].includes(selectedHomeworkContent?.question?.model?.case || '')
        ) {
          // interaction event "NEXT" if subjective &
          await interactionEvent({
            url: '',
            context: 'homework',
            name: 'NEXT',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }

        await aiQuestionSubmitEvent({
          questionId: activeQuestionId,
          homeworkId: Number(homework_id),
          submitStatus: filteredSubmitResponse?.isCorrect,
          answer: all_answers,
          isOffline: isOffline,
        });

        dispatch(setAllAnswers([]));
        const oldValues = cloneObject(selected_homework_data);
        dispatch(
          setSelectedHomeworkData({
            ...oldValues,
            elapsedTime: studentHomeworkResp.data?.attemptSpentTimeInSecs || 0, // Update the elapsed time in Redux
          })
        );
        dispatch(
          setActiveHomeworkStudentResponse({
            homeworkId: Number(homework_id),
            response: studentHomeworkResp.data,
          })
        );
        setIsPaused(false);
        if (!isLastQuestion) {
          if (checkFeatureData({ userInfo: userInfo }).display_meme)
            setShowMeme(true);
          else memeEndHandler();
        } else if (isLastQuestion) {
          const activeHomeworkAttemptResult = hwSubmitResponse.attemptResult;
          dispatch(
            setActiveHomeworkAttemptResult({
              homeworkId: Number(homework_id),
              result: activeHomeworkAttemptResult,
            })
          );
          setIsPaused(true);
          dispatch(setActiveHomeworSessionId(undefined));
          navigate(`${HOMEWORK_SUBMITTED}?homeworkId=${homework_id}`);
        }
      } else {
        dispatch(
          setToastInfo({
            label: 'Question Submission failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setToastInfo({
          label: `${
            err.rawMessage
              ? err.rawMessage?.replace(/.*\]\s*/, '').trim()
              : err.message
              ? err.message.replace(/.*\]\s*/, '').trim()
              : 'Homework Submission Failed'
          }`,
          variant: 'error',
          open: true,
        })
      );
      console.log(err);
    }
  };

  const handleAnswerChange = (val: string, ind?: number) => {
    // let answersCopy = structuredClone(all_answers);
    let answersCopy = cloneObject(all_answers);
    switch (selectedHomeworkContent?.questionType) {
      case Question_QuestionEnum.QUESTION_TYPE_MCQM:
        if (!answersCopy.includes(val)) {
          answersCopy.push(val);
        } else {
          answersCopy = answersCopy.filter((v) => v !== val);
        }
        break;
      case Question_QuestionEnum.QUESTION_TYPE_FIB:
        if (typeof ind == 'number') {
          if (ind >= 1) {
            for (let i = 0; i < ind; i++) {
              if (!answersCopy[i]) {
                answersCopy[i] = '';
              }
            }
          }
          answersCopy[ind] = val;
        }
        break;
      case Question_QuestionEnum.QUESTION_TYPE_MCQS:
      case Question_QuestionEnum.QUESTION_TYPE_TF:
        answersCopy = [val];
        break;
      case Question_QuestionEnum.QUESTION_TYPE_LONG:
      case Question_QuestionEnum.QUESTION_TYPE_SHORT:
      case Question_QuestionEnum.QUESTION_TYPE_VERY_SHORT:
        answersCopy = [val];
        break;
    }
    // setAnswers(answersCopy);
    dispatch(setAllAnswers(answersCopy));
    // Move to next question if tf or mcq-s
    switch (selectedHomeworkContent?.questionType) {
      case Question_QuestionEnum.QUESTION_TYPE_MCQS:
      case Question_QuestionEnum.QUESTION_TYPE_TF:
        setIsSubmitting(true);
        break;
      default:
        break;
    }
  };

  const memeEndHandler = () => {
    const attemptedQuestionIds =
      activeHomeworkStudentResponse?.responses
        .map((val) => (val.isAttempted && val.questionId) || '')
        .filter((val) => val !== '') || [];
    const filteredQuestionIds = sortedQuestionIds
      .slice(activeQuestionIndex + 1)
      .filter((val) => !attemptedQuestionIds.includes(val));
    setActiveQuestionId(filteredQuestionIds[0]);
  };

  const studentResponseForCurrentQuestion = new HWStudentResponse(
    activeHomeworkStudentResponse
  ).responses.find((resp) => resp.questionId === activeQuestionId);
  //^
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (showMeme)
    return (
      <HwMemePage
        isCorrectAnswer={isCorrectAnswer(
          new Question(selectedHomeworkContent),
          // answers
          all_answers
        )}
        timeTaken={(new Date().getTime() - startTime.toDate().getTime()) / 1000}
        actualQuestionTime={
          selectedHomeworkContent?.question?.model.value?.commonQuestionContent
            ?.time
        }
        memeEndHandler={memeEndHandler}
        onClickOnly={async () => {
          await interactionEvent({
            url: '',
            context: 'meme_page',
            name: 'NEXT_QUESTION',
            pathSegments: pathSegments,
            isOffline: isOffline,
          });
        }}
      />
    );

  const scanClickHandler = async () => {
    setOpenUploadPopup(true);
    await interactionEvent({
      url: '',
      context: 'homework',
      name: 'SCAN',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
  };

  const handleTimeUpdate = (remainingTime: number) => {
    if (remainingTime < 0) {
      return;
    }
    if (activeHomeworkContent?.assessment?.estimatedTimeInMin) {
      const oldValues = cloneObject(selected_homework_data);
      const newElapsedTimeinSecs =
        activeHomeworkContent?.assessment?.estimatedTimeInMin * 60 -
        remainingTime;
      if (
        newElapsedTimeinSecs <=
        activeHomeworkContent?.assessment?.estimatedTimeInMin * 60
      ) {
        dispatch(
          setSelectedHomeworkData({
            ...oldValues,
            elapsedTime: newElapsedTimeinSecs, // Update the elapsed time in Redux
          })
        );
      }
    }
  };
  return (
    <Grid container spacing={0} sx={styles.root}>
      <ActiveProgressbar
        percentage={((activeQuestionIndex + 1) * 100) / totalQuestions}
      />
      <Grid item xs={12} md={4.5} sx={styles.heading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(6), md: pxTovW(250) },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              width: { xs: '100%', md: pxTovW(550), lg: pxTovW(450) },
              wordWrap: 'break-word',
            }}
          >
            {activeHomeworkContent?.assessment?.assessmentTitle}
          </Typography>
          {/* {activeHomeworkContent?.homework?.estimatedTimeInMin && (
            <HomeworkTimer timeRemaining={estimatedTimeInMin} />
          )} */}
        </Box>
        <Box sx={{ p: { md: 20 } }}>
          {checkFeatureData({
            assignmentConfigInfo:
              activeHomeworkContent?.assessment?.assignmentInfo,
            userInfo: userInfo,
          }).timed && (
            <HomeworkTimer
              initialTime={
                (activeHomeworkContent?.assessment?.estimatedTimeInMin || 0) *
                  60 -
                (activeHomeworkStudentResponse?.attemptSpentTimeInSecs || 0)
              }
              onTimeUpdate={handleTimeUpdate}
              isPaused={isPaused}
              onTestEnd={() => {
                submitBackHandler();
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={7.5} sx={{ paddingX: 0 }}>
        {/* <Box
          sx={{
            mb: { xs: pxToRem(20), md: pxTovW(20) },
            px: { xs: pxToRem(8) },
          }}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {activeHomeworkContent?.assessment?.assessmentTitle}
          </Typography>
        </Box> */}
        <Box
          sx={{
            overflowX: 'scroll',
            px: { xs: pxToRem(8) },
            overflowY: 'clip',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <HomeworkPagination
            currentPage={activeQuestionIndex + 1}
            totalPages={totalQuestions}
            showPreviousNextButtons={false}
            questionStatusArray={questionStatusArray}
            // onPageChange={(page) => setIndex(page - 1)}
            maxButtonsToDisplay={10}
          />
        </Box>
        {/* <input type="file" accept="image/*" onChange={onSelectFile} />
        <ReactCrop crop={crop} onChange={(a, b) => setCrop(b)}>
          <img src={imgSrc} ref={imgRef} onLoad={onImageLoad} />
        </ReactCrop> */}
        {/* Question Renderer */}
        {/* add Next button for Fill in the blanks */}
        {selectedHomeworkContent && (
          <Box sx={styles.questionBox}>
            <QuestionContainerWithSolution
              userAttemptedAnswer={all_answers}
              questionNumber={activeQuestionIndex + 1}
              question={new Question(selectedHomeworkContent)}
              handleAnswerChange={handleAnswerChange}
              handleNext={handleNext}
              showNextButton
              showHintIcon
              homeworkAttempt={true}
              scanClickHandler={scanClickHandler}
              selected_resource_info={selected_resource_info}
              disableInput={isSubmitting}
              eventCollection={{
                hintBoxOpen: async () => {
                  await interactionEvent({
                    url: '',
                    context: 'popup',
                    name: 'CLUE',
                    pathSegments: pathSegments,
                    isOffline: isOffline,
                  });
                },
                hintBoxOk: async () => {
                  await interactionEvent({
                    url: '',
                    context: 'clue',
                    name: 'OK',
                    pathSegments: pathSegments,
                    isOffline: isOffline,
                  });
                },
                answerSelection: async () => {
                  await interactionEvent({
                    url: '',
                    context: 'question',
                    name: 'ANSWER_SELECTION',
                    pathSegments: pathSegments,
                    isOffline: isOffline,
                  });
                },
              }}
            />
          </Box>
        )}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - */}
      </Grid>
      <ImageUploadPopup
        open={openUploadPopup}
        handleClose={() => setOpenUploadPopup(false)}
        studentId={userInfo?.studentProfileId}
        homeworkId={Number(homework_id)}
        sessionId={active_homework_session_id}
        questionId={selectedHomeworkContent?.questionId}
        handleAnswerChange={handleAnswerChange}
      />
      <ActionPopUpSingleButton
        fontSmall
        open={timeupPopup}
        iconName="info_red"
        popupText="The allotted time for the assessment has ended."
        buttontext="Ok"
        ctaHandler={() => {
          setTimeupPopup(false);
          navigate(HOME);
        }}
        background="#0AA34F"
        textcolor="white"
      />
      {/* <ActionsPopup
        open={showExitPopup}
        iconName="homework"
        popupText="Exiting now will erase all your current homework progress. Confirm?"
        handleClose={() => handleExit('no')}
        noClickHandler={() => handleExit('no')}
        yesClickHandler={() => handleExit('yes')}
        fontSmall
      /> */}
    </Grid>
  );
};
function splitQuestionIds(questionIds: string[], activeQuestionId: string) {
  // Find the index of the activeQuestionId in the sorted list
  const activeIndex = questionIds.indexOf(activeQuestionId);
  // If activeQuestionId is not in the list, return empty arrays
  if (activeIndex === -1) {
    return [[], []];
  }
  // Slice the list into two parts
  const idsBeforeAndIncludingActive = questionIds.slice(0, activeIndex + 1);
  const idsAfterActive = questionIds.slice(activeIndex + 1);

  return [idsBeforeAndIncludingActive, idsAfterActive];
}
