// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.hw.student.apis.proto (package geneo.lms.hw.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DifficultyLevelEnum, FileEnum, FileExtensionEnum, Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { CloudUpload, HomeworkSessionModeEnum, MetricsModel, StudentAssessmentResponse, StudentTaskResponse, SubmissionType, TaskActiveStatusEnum, TaskEnum, TaskEvaluationStatusEnum, TaskStudentAttemptStatusEnum } from "@protos/learning_management/lms.db_pb";

/**
 * @generated from enum geneo.lms.hw.student.apis.MimeEnum
 */
export enum MimeEnum {
  /**
   * Represents an undefined file type
   *
   * @generated from enum value: MIME_UNDEFINED = 0;
   */
  MIME_UNDEFINED = 0,

  /**
   * @generated from enum value: MIME_PNG = 1;
   */
  MIME_PNG = 1,

  /**
   * @generated from enum value: MIME_JPEG = 2;
   */
  MIME_JPEG = 2,

  /**
   * @generated from enum value: MIME_JPG = 3;
   */
  MIME_JPG = 3,

  /**
   * @generated from enum value: MIME_SVG = 4;
   */
  MIME_SVG = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(MimeEnum)
proto3.util.setEnumType(MimeEnum, "geneo.lms.hw.student.apis.MimeEnum", [
  { no: 0, name: "MIME_UNDEFINED" },
  { no: 1, name: "MIME_PNG" },
  { no: 2, name: "MIME_JPEG" },
  { no: 3, name: "MIME_JPG" },
  { no: 4, name: "MIME_SVG" },
]);

/**
 * @generated from enum geneo.lms.hw.student.apis.UpdatedMessageEnum
 */
export enum UpdatedMessageEnum {
  /**
   * @generated from enum value: UPDATE_UNDEFINED = 0;
   */
  UPDATE_UNDEFINED = 0,

  /**
   * @generated from enum value: UPDATE_SUCCESSFULLY = 1;
   */
  UPDATE_SUCCESSFULLY = 1,

  /**
   * @generated from enum value: HOMEWORK_SUBMITTED = 2;
   */
  HOMEWORK_SUBMITTED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UpdatedMessageEnum)
proto3.util.setEnumType(UpdatedMessageEnum, "geneo.lms.hw.student.apis.UpdatedMessageEnum", [
  { no: 0, name: "UPDATE_UNDEFINED" },
  { no: 1, name: "UPDATE_SUCCESSFULLY" },
  { no: 2, name: "HOMEWORK_SUBMITTED" },
]);

/**
 * @generated from message geneo.lms.hw.student.apis.SignedUrlRequest
 */
export class SignedUrlRequest extends Message<SignedUrlRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: string question_id = 3;
   */
  questionId = "";

  /**
   * @generated from field: int32 session_id = 4;
   */
  sessionId = 0;

  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.FileInfo files_info = 5;
   */
  filesInfo: FileInfo[] = [];

  constructor(data?: PartialMessage<SignedUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.SignedUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "files_info", kind: "message", T: FileInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlRequest {
    return new SignedUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlRequest {
    return new SignedUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlRequest {
    return new SignedUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlRequest | PlainMessage<SignedUrlRequest> | undefined, b: SignedUrlRequest | PlainMessage<SignedUrlRequest> | undefined): boolean {
    return proto3.util.equals(SignedUrlRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.FileInfo
 */
export class FileInfo extends Message<FileInfo> {
  /**
   * Image-name
   *
   * @generated from field: string original_image_path = 1;
   */
  originalImagePath = "";

  /**
   * @generated from field: geneo.lms.hw.student.apis.MimeEnum mime_type = 2;
   */
  mimeType = MimeEnum.MIME_UNDEFINED;

  /**
   * @generated from field: optional int32 size = 3;
   */
  size?: number;

  constructor(data?: PartialMessage<FileInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.FileInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_image_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mime_type", kind: "enum", T: proto3.getEnumType(MimeEnum) },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileInfo {
    return new FileInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileInfo {
    return new FileInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileInfo {
    return new FileInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FileInfo | PlainMessage<FileInfo> | undefined, b: FileInfo | PlainMessage<FileInfo> | undefined): boolean {
    return proto3.util.equals(FileInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.SignedUrl
 */
export class SignedUrl extends Message<SignedUrl> {
  /**
   * @generated from field: string signed_url = 1;
   */
  signedUrl = "";

  /**
   * @generated from field: optional string original_image_path = 2;
   */
  originalImagePath?: string;

  constructor(data?: PartialMessage<SignedUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.SignedUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "original_image_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrl {
    return new SignedUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrl | PlainMessage<SignedUrl> | undefined, b: SignedUrl | PlainMessage<SignedUrl> | undefined): boolean {
    return proto3.util.equals(SignedUrl, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.SignedUrlReponse
 */
export class SignedUrlReponse extends Message<SignedUrlReponse> {
  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.SignedUrl response = 1;
   */
  response: SignedUrl[] = [];

  constructor(data?: PartialMessage<SignedUrlReponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.SignedUrlReponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: SignedUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlReponse {
    return new SignedUrlReponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlReponse {
    return new SignedUrlReponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlReponse {
    return new SignedUrlReponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlReponse | PlainMessage<SignedUrlReponse> | undefined, b: SignedUrlReponse | PlainMessage<SignedUrlReponse> | undefined): boolean {
    return proto3.util.equals(SignedUrlReponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.UploadFileResponse
 */
export class UploadFileResponse extends Message<UploadFileResponse> {
  /**
   * @generated from field: string signed_url = 1;
   */
  signedUrl = "";

  /**
   * @generated from field: bool is_uploaded = 2;
   */
  isUploaded = false;

  /**
   * Image-name
   *
   * @generated from field: string original_image_path = 3;
   */
  originalImagePath = "";

  /**
   * @generated from field: optional bool is_deleted = 4;
   */
  isDeleted?: boolean;

  /**
   * @generated from field: geneo.content.db.FileEnum file_type = 5;
   */
  fileType = FileEnum.FILE_TYPE_UNKNOWN;

  /**
   * @generated from field: geneo.content.db.FileExtensionEnum file_extension_type = 6;
   */
  fileExtensionType = FileExtensionEnum.FILE_EXTENSION_UNKNOWN;

  /**
   * @generated from field: optional float file_size_in_mb = 7;
   */
  fileSizeInMb?: number;

  /**
   * @generated from field: optional string preview_url = 8;
   */
  previewUrl?: string;

  constructor(data?: PartialMessage<UploadFileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.UploadFileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_uploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "original_image_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "file_type", kind: "enum", T: proto3.getEnumType(FileEnum) },
    { no: 6, name: "file_extension_type", kind: "enum", T: proto3.getEnumType(FileExtensionEnum) },
    { no: 7, name: "file_size_in_mb", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 8, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileResponse {
    return new UploadFileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileResponse {
    return new UploadFileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileResponse {
    return new UploadFileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileResponse | PlainMessage<UploadFileResponse> | undefined, b: UploadFileResponse | PlainMessage<UploadFileResponse> | undefined): boolean {
    return proto3.util.equals(UploadFileResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentGetOCRTextRequest
 */
export class StudentGetOCRTextRequest extends Message<StudentGetOCRTextRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: int32 session_id = 3;
   */
  sessionId = 0;

  /**
   * @generated from field: string question_id = 4;
   */
  questionId = "";

  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.UploadFileResponse upload_file_response = 5;
   */
  uploadFileResponse: UploadFileResponse[] = [];

  constructor(data?: PartialMessage<StudentGetOCRTextRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentGetOCRTextRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "upload_file_response", kind: "message", T: UploadFileResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentGetOCRTextRequest {
    return new StudentGetOCRTextRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentGetOCRTextRequest {
    return new StudentGetOCRTextRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentGetOCRTextRequest {
    return new StudentGetOCRTextRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentGetOCRTextRequest | PlainMessage<StudentGetOCRTextRequest> | undefined, b: StudentGetOCRTextRequest | PlainMessage<StudentGetOCRTextRequest> | undefined): boolean {
    return proto3.util.equals(StudentGetOCRTextRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentGetOCRTextResponse
 */
export class StudentGetOCRTextResponse extends Message<StudentGetOCRTextResponse> {
  /**
   * @generated from field: repeated string ocr_text_blocks = 1;
   */
  ocrTextBlocks: string[] = [];

  /**
   * @generated from field: repeated string signed_urls = 2;
   */
  signedUrls: string[] = [];

  constructor(data?: PartialMessage<StudentGetOCRTextResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentGetOCRTextResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ocr_text_blocks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "signed_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentGetOCRTextResponse {
    return new StudentGetOCRTextResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentGetOCRTextResponse {
    return new StudentGetOCRTextResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentGetOCRTextResponse {
    return new StudentGetOCRTextResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentGetOCRTextResponse | PlainMessage<StudentGetOCRTextResponse> | undefined, b: StudentGetOCRTextResponse | PlainMessage<StudentGetOCRTextResponse> | undefined): boolean {
    return proto3.util.equals(StudentGetOCRTextResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.GetStudentHWListRequest
 */
export class GetStudentHWListRequest extends Message<GetStudentHWListRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: optional int32 page_number = 2;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 subject_id = 3;
   */
  subjectId?: number;

  constructor(data?: PartialMessage<GetStudentHWListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.GetStudentHWListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetStudentHWListRequest {
    return new GetStudentHWListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetStudentHWListRequest {
    return new GetStudentHWListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetStudentHWListRequest {
    return new GetStudentHWListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetStudentHWListRequest | PlainMessage<GetStudentHWListRequest> | undefined, b: GetStudentHWListRequest | PlainMessage<GetStudentHWListRequest> | undefined): boolean {
    return proto3.util.equals(GetStudentHWListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.OptionInfo
 */
export class OptionInfo extends Message<OptionInfo> {
  /**
   * @generated from field: string option_text = 1;
   */
  optionText = "";

  /**
   * @generated from field: bool is_correct = 2;
   */
  isCorrect = false;

  constructor(data?: PartialMessage<OptionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.OptionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "option_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_correct", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OptionInfo {
    return new OptionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OptionInfo {
    return new OptionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OptionInfo {
    return new OptionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: OptionInfo | PlainMessage<OptionInfo> | undefined, b: OptionInfo | PlainMessage<OptionInfo> | undefined): boolean {
    return proto3.util.equals(OptionInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWResponseFetchRequest
 */
export class StudentHWResponseFetchRequest extends Message<StudentHWResponseFetchRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  constructor(data?: PartialMessage<StudentHWResponseFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWResponseFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWResponseFetchRequest {
    return new StudentHWResponseFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWResponseFetchRequest {
    return new StudentHWResponseFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWResponseFetchRequest {
    return new StudentHWResponseFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWResponseFetchRequest | PlainMessage<StudentHWResponseFetchRequest> | undefined, b: StudentHWResponseFetchRequest | PlainMessage<StudentHWResponseFetchRequest> | undefined): boolean {
    return proto3.util.equals(StudentHWResponseFetchRequest, a, b);
  }
}

/**
 * Message for student's homework response
 *
 * @generated from message geneo.lms.hw.student.apis.HWStudentResponse
 */
export class HWStudentResponse extends Message<HWStudentResponse> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * ID of the student
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * Date when the task was commenced
   *
   * @generated from field: google.protobuf.Timestamp task_commence_date = 3;
   */
  taskCommenceDate?: Timestamp;

  /**
   * Target date for task completion
   *
   * @generated from field: google.protobuf.Timestamp task_target_date = 4;
   */
  taskTargetDate?: Timestamp;

  /**
   * Date when the task was completed
   *
   * @generated from field: google.protobuf.Timestamp task_completed_date = 5;
   */
  taskCompletedDate?: Timestamp;

  /**
   * Active status of the homework
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum homework_active_status = 6;
   */
  homeworkActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Attempt status of the homework
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum homework_attempt_status = 7;
   */
  homeworkAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Evaluation status of the homework
   *
   * @generated from field: geneo.lms.db.TaskEvaluationStatusEnum homework_evaluation_status = 8;
   */
  homeworkEvaluationStatus = TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_UNDEFINED;

  /**
   * Metrics associated with the homework response
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 9;
   */
  metrics?: MetricsModel;

  /**
   * List of student's responses to the homework questions
   *
   * @generated from field: repeated geneo.lms.db.StudentAssessmentResponse responses = 10;
   */
  responses: StudentAssessmentResponse[] = [];

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 12;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 13;
   */
  userUploads: CloudUpload[] = [];

  constructor(data?: PartialMessage<HWStudentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.HWStudentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "task_commence_date", kind: "message", T: Timestamp },
    { no: 4, name: "task_target_date", kind: "message", T: Timestamp },
    { no: 5, name: "task_completed_date", kind: "message", T: Timestamp },
    { no: 6, name: "homework_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 7, name: "homework_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 8, name: "homework_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum) },
    { no: 9, name: "metrics", kind: "message", T: MetricsModel },
    { no: 10, name: "responses", kind: "message", T: StudentAssessmentResponse, repeated: true },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HWStudentResponse {
    return new HWStudentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HWStudentResponse {
    return new HWStudentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HWStudentResponse {
    return new HWStudentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HWStudentResponse | PlainMessage<HWStudentResponse> | undefined, b: HWStudentResponse | PlainMessage<HWStudentResponse> | undefined): boolean {
    return proto3.util.equals(HWStudentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWResponseFetchResponse
 */
export class StudentHWResponseFetchResponse extends Message<StudentHWResponseFetchResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.student.apis.HWStudentResponse data = 4;
   */
  data?: HWStudentResponse;

  constructor(data?: PartialMessage<StudentHWResponseFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWResponseFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: HWStudentResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWResponseFetchResponse {
    return new StudentHWResponseFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWResponseFetchResponse {
    return new StudentHWResponseFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWResponseFetchResponse {
    return new StudentHWResponseFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWResponseFetchResponse | PlainMessage<StudentHWResponseFetchResponse> | undefined, b: StudentHWResponseFetchResponse | PlainMessage<StudentHWResponseFetchResponse> | undefined): boolean {
    return proto3.util.equals(StudentHWResponseFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHomeworkListResponse
 */
export class StudentHomeworkListResponse extends Message<StudentHomeworkListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.student.apis.StudentHomeworkList data = 4;
   */
  data?: StudentHomeworkList;

  constructor(data?: PartialMessage<StudentHomeworkListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHomeworkListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentHomeworkList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHomeworkListResponse {
    return new StudentHomeworkListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHomeworkListResponse {
    return new StudentHomeworkListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHomeworkListResponse {
    return new StudentHomeworkListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHomeworkListResponse | PlainMessage<StudentHomeworkListResponse> | undefined, b: StudentHomeworkListResponse | PlainMessage<StudentHomeworkListResponse> | undefined): boolean {
    return proto3.util.equals(StudentHomeworkListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHomeworkList
 */
export class StudentHomeworkList extends Message<StudentHomeworkList> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.hw.student.apis.StudentHWs all_homeworks = 2;
   */
  allHomeworks?: StudentHWs;

  /**
   * @generated from field: optional geneo.lms.hw.student.apis.StudentHWCountInfo hwListInfo = 3;
   */
  hwListInfo?: StudentHWCountInfo;

  constructor(data?: PartialMessage<StudentHomeworkList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHomeworkList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "all_homeworks", kind: "message", T: StudentHWs },
    { no: 3, name: "hwListInfo", kind: "message", T: StudentHWCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHomeworkList {
    return new StudentHomeworkList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHomeworkList {
    return new StudentHomeworkList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHomeworkList {
    return new StudentHomeworkList().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHomeworkList | PlainMessage<StudentHomeworkList> | undefined, b: StudentHomeworkList | PlainMessage<StudentHomeworkList> | undefined): boolean {
    return proto3.util.equals(StudentHomeworkList, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWCountInfo
 */
export class StudentHWCountInfo extends Message<StudentHWCountInfo> {
  /**
   * @generated from field: int32 total_hw_count = 1;
   */
  totalHwCount = 0;

  /**
   * @generated from field: int32 active_hw_count = 2;
   */
  activeHwCount = 0;

  /**
   * @generated from field: int32 ended_hw_count = 3;
   */
  endedHwCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 4;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<StudentHWCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "active_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "ended_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWCountInfo {
    return new StudentHWCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWCountInfo {
    return new StudentHWCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWCountInfo {
    return new StudentHWCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWCountInfo | PlainMessage<StudentHWCountInfo> | undefined, b: StudentHWCountInfo | PlainMessage<StudentHWCountInfo> | undefined): boolean {
    return proto3.util.equals(StudentHWCountInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWs
 */
export class StudentHWs extends Message<StudentHWs> {
  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.StudentHomeworkTask active = 2;
   */
  active: StudentHomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.StudentHomeworkTask ended = 3;
   */
  ended: StudentHomeworkTask[] = [];

  constructor(data?: PartialMessage<StudentHWs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "active", kind: "message", T: StudentHomeworkTask, repeated: true },
    { no: 3, name: "ended", kind: "message", T: StudentHomeworkTask, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWs {
    return new StudentHWs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWs {
    return new StudentHWs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWs {
    return new StudentHWs().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWs | PlainMessage<StudentHWs> | undefined, b: StudentHWs | PlainMessage<StudentHWs> | undefined): boolean {
    return proto3.util.equals(StudentHWs, a, b);
  }
}

/**
 * Message for active homework list
 *
 * @generated from message geneo.lms.hw.student.apis.StudentHomeworkTask
 */
export class StudentHomeworkTask extends Message<StudentHomeworkTask> {
  /**
   * ID of the homework
   *
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * Title of the homework
   *
   * @generated from field: string homework_title = 2;
   */
  homeworkTitle = "";

  /**
   * Description of the homework
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * URL of the homework poster image
   *
   * @generated from field: string homework_poster_img_url = 4;
   */
  homeworkPosterImgUrl = "";

  /**
   * Difficulty level of the homework
   *
   * @generated from field: geneo.content.db.DifficultyLevelEnum difficulty_level = 5;
   */
  difficultyLevel = DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;

  /**
   * Estimated time for which the homework is assigned
   *
   * @generated from field: float estimated_time_in_min = 6;
   */
  estimatedTimeInMin = 0;

  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 7;
   */
  teacherId = protoInt64.zero;

  /**
   * Name of the teacher who assigned the homework
   *
   * @generated from field: string teacher_name = 8;
   */
  teacherName = "";

  /**
   * URL of the teacher's profile image
   *
   * @generated from field: string teacher_profile_image_url = 9;
   */
  teacherProfileImageUrl = "";

  /**
   * Active status of the homework
   *
   * @generated from field: geneo.lms.db.TaskActiveStatusEnum homework_active_status = 10;
   */
  homeworkActiveStatus = TaskActiveStatusEnum.TASK_ACTIVE_STATUS_UNDEFINED;

  /**
   * Attempt status of the homework for students
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum student_attempt_status = 11;
   */
  studentAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * ID of the course the homework belongs to
   *
   * @generated from field: int32 subject_id = 12;
   */
  subjectId = 0;

  /**
   * ID of the module the homework belongs to
   *
   * @generated from field: int32 module_id = 13;
   */
  moduleId = 0;

  /**
   * ID of the module
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 14;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * Subject of the homework
   *
   * @generated from field: string subject = 15;
   */
  subject = "";

  /**
   * Number of questions in the homework
   *
   * @generated from field: int32 no_of_questions = 16;
   */
  noOfQuestions = 0;

  /**
   * Start date of the homework
   *
   * @generated from field: google.protobuf.Timestamp start_date = 17;
   */
  startDate?: Timestamp;

  /**
   * End date of the homework
   *
   * @generated from field: google.protobuf.Timestamp end_date = 18;
   */
  endDate?: Timestamp;

  /**
   * Score received for the homework
   *
   * @generated from field: float score_percent = 19;
   */
  scorePercent = 0;

  /**
   * @generated from field: string module_name = 20;
   */
  moduleName = "";

  /**
   * @generated from field: optional float max_marks = 21;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 22;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 23;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 24;
   */
  userUploads: CloudUpload[] = [];

  constructor(data?: PartialMessage<StudentHomeworkTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHomeworkTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homework_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "homework_poster_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "difficulty_level", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum) },
    { no: 6, name: "estimated_time_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "teacher_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "teacher_profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "homework_active_status", kind: "enum", T: proto3.getEnumType(TaskActiveStatusEnum) },
    { no: 11, name: "student_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 12, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 15, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "start_date", kind: "message", T: Timestamp },
    { no: 18, name: "end_date", kind: "message", T: Timestamp },
    { no: 19, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 20, name: "module_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 22, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 23, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 24, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHomeworkTask {
    return new StudentHomeworkTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHomeworkTask {
    return new StudentHomeworkTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHomeworkTask {
    return new StudentHomeworkTask().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHomeworkTask | PlainMessage<StudentHomeworkTask> | undefined, b: StudentHomeworkTask | PlainMessage<StudentHomeworkTask> | undefined): boolean {
    return proto3.util.equals(StudentHomeworkTask, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWResponseSubmitRequest
 */
export class StudentHWResponseSubmitRequest extends Message<StudentHWResponseSubmitRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum homework_attempt_status = 3;
   */
  homeworkAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.StudentTaskResponse responses = 4;
   */
  responses: StudentTaskResponse[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 5;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: int32 session_id = 6;
   */
  sessionId = 0;

  /**
   * @generated from field: repeated geneo.lms.hw.student.apis.UploadFileResponse user_uploads = 7;
   */
  userUploads: UploadFileResponse[] = [];

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 8;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  constructor(data?: PartialMessage<StudentHWResponseSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWResponseSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "homework_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 4, name: "responses", kind: "message", T: StudentTaskResponse, repeated: true },
    { no: 5, name: "timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "user_uploads", kind: "message", T: UploadFileResponse, repeated: true },
    { no: 8, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWResponseSubmitRequest {
    return new StudentHWResponseSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWResponseSubmitRequest {
    return new StudentHWResponseSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWResponseSubmitRequest {
    return new StudentHWResponseSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWResponseSubmitRequest | PlainMessage<StudentHWResponseSubmitRequest> | undefined, b: StudentHWResponseSubmitRequest | PlainMessage<StudentHWResponseSubmitRequest> | undefined): boolean {
    return proto3.util.equals(StudentHWResponseSubmitRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWResponseSubmitResponse
 */
export class StudentHWResponseSubmitResponse extends Message<StudentHWResponseSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: optional geneo.lms.hw.student.apis.StudentHWAttemptResult attempt_result = 4;
   */
  attemptResult?: StudentHWAttemptResult;

  constructor(data?: PartialMessage<StudentHWResponseSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWResponseSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attempt_result", kind: "message", T: StudentHWAttemptResult, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWResponseSubmitResponse {
    return new StudentHWResponseSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWResponseSubmitResponse {
    return new StudentHWResponseSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWResponseSubmitResponse {
    return new StudentHWResponseSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWResponseSubmitResponse | PlainMessage<StudentHWResponseSubmitResponse> | undefined, b: StudentHWResponseSubmitResponse | PlainMessage<StudentHWResponseSubmitResponse> | undefined): boolean {
    return proto3.util.equals(StudentHWResponseSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHWAttemptResult
 */
export class StudentHWAttemptResult extends Message<StudentHWAttemptResult> {
  /**
   * @generated from field: float response_score = 1;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 2;
   */
  maxScore = 0;

  constructor(data?: PartialMessage<StudentHWAttemptResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHWAttemptResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWAttemptResult {
    return new StudentHWAttemptResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWAttemptResult {
    return new StudentHWAttemptResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWAttemptResult {
    return new StudentHWAttemptResult().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWAttemptResult | PlainMessage<StudentHWAttemptResult> | undefined, b: StudentHWAttemptResult | PlainMessage<StudentHWAttemptResult> | undefined): boolean {
    return proto3.util.equals(StudentHWAttemptResult, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.UpdatedMessageForSubmit
 */
export class UpdatedMessageForSubmit extends Message<UpdatedMessageForSubmit> {
  /**
   * @generated from field: geneo.lms.hw.student.apis.UpdatedMessageEnum updated_message = 1;
   */
  updatedMessage = UpdatedMessageEnum.UPDATE_UNDEFINED;

  /**
   * @generated from field: int32 score = 3;
   */
  score = 0;

  /**
   * @generated from field: string message = 4;
   */
  message = "";

  constructor(data?: PartialMessage<UpdatedMessageForSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.UpdatedMessageForSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_message", kind: "enum", T: proto3.getEnumType(UpdatedMessageEnum) },
    { no: 3, name: "score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatedMessageForSubmit {
    return new UpdatedMessageForSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatedMessageForSubmit {
    return new UpdatedMessageForSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatedMessageForSubmit {
    return new UpdatedMessageForSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatedMessageForSubmit | PlainMessage<UpdatedMessageForSubmit> | undefined, b: UpdatedMessageForSubmit | PlainMessage<UpdatedMessageForSubmit> | undefined): boolean {
    return proto3.util.equals(UpdatedMessageForSubmit, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.UpdatedMessage
 */
export class UpdatedMessage extends Message<UpdatedMessage> {
  /**
   * @generated from field: geneo.lms.hw.student.apis.UpdatedMessageEnum updated_message = 1;
   */
  updatedMessage = UpdatedMessageEnum.UPDATE_UNDEFINED;

  /**
   * @generated from field: string gif_url = 2;
   */
  gifUrl = "";

  constructor(data?: PartialMessage<UpdatedMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.UpdatedMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_message", kind: "enum", T: proto3.getEnumType(UpdatedMessageEnum) },
    { no: 2, name: "gif_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatedMessage {
    return new UpdatedMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatedMessage {
    return new UpdatedMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatedMessage {
    return new UpdatedMessage().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatedMessage | PlainMessage<UpdatedMessage> | undefined, b: UpdatedMessage | PlainMessage<UpdatedMessage> | undefined): boolean {
    return proto3.util.equals(UpdatedMessage, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.CreateStudentHomeworkSessionRequest
 */
export class CreateStudentHomeworkSessionRequest extends Message<CreateStudentHomeworkSessionRequest> {
  /**
   * ID of the student associated with the lesson session.
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * Section of the student.
   *
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * Start time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;

  /**
   * Status of the lesson session (e.g., in progress, completed, etc.).
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum session_status = 4;
   */
  sessionStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Mode of the lesson session (e.g., online, offline, etc.).
   *
   * @generated from field: geneo.lms.db.HomeworkSessionModeEnum session_mode = 5;
   */
  sessionMode = HomeworkSessionModeEnum.HOMEWORK_SESSION_MODE_UNDEFINED;

  constructor(data?: PartialMessage<CreateStudentHomeworkSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.CreateStudentHomeworkSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "session_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 5, name: "session_mode", kind: "enum", T: proto3.getEnumType(HomeworkSessionModeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentHomeworkSessionRequest {
    return new CreateStudentHomeworkSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentHomeworkSessionRequest {
    return new CreateStudentHomeworkSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentHomeworkSessionRequest {
    return new CreateStudentHomeworkSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentHomeworkSessionRequest | PlainMessage<CreateStudentHomeworkSessionRequest> | undefined, b: CreateStudentHomeworkSessionRequest | PlainMessage<CreateStudentHomeworkSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateStudentHomeworkSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.student.apis.StudentHomeworkSessionResponse
 */
export class StudentHomeworkSessionResponse extends Message<StudentHomeworkSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int32 session_id = 4;
   */
  sessionId = 0;

  /**
   * @generated from field: optional int32 attempt_number = 5;
   */
  attemptNumber?: number;

  constructor(data?: PartialMessage<StudentHomeworkSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.student.apis.StudentHomeworkSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "attempt_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHomeworkSessionResponse {
    return new StudentHomeworkSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHomeworkSessionResponse {
    return new StudentHomeworkSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHomeworkSessionResponse {
    return new StudentHomeworkSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHomeworkSessionResponse | PlainMessage<StudentHomeworkSessionResponse> | undefined, b: StudentHomeworkSessionResponse | PlainMessage<StudentHomeworkSessionResponse> | undefined): boolean {
    return proto3.util.equals(StudentHomeworkSessionResponse, a, b);
  }
}

