// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.connected.apis.proto (package geneo.lms.connected.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConnectedClassSessionDataFixRequest, ConnectedClassSessionDataFixResponse, GetBaseStationConnectionRequest, GetBaseStationConnectionResponse, GetBaseStationMappingDataRequest, GetBaseStationMappingDataResponse, GetClassStudentListRequest, GetClassStudentListResponse, GetConnectedClassSessionAnalyticsRequest, GetConnectedClassSessionAnalyticsResponse, GetConnectedClassSessionDetailsRequest, GetConnectedClassSessionDetailsResponse, RegisterBaseReceiverRequest, RegisterBaseReceiverResponse, RegisterClickerRequest, RegisterClickerResponse, SubmitConnectedClassStudentResponse, SubmitConnectedClassStudentResponseRequest, UpdateBaseStationMappingRequest, UpdateBaseStationMappingResponse, UpdateBaseStationStatusRequest, UpdateBaseStationStatusResponse, UpdateStudentKeypadResponseRequest, UpdateStudentKeypadResponseRequestV2, UpdateStudentKeypadResponseResponse, UpdateStudentKeypadStatusRequest, UpdateStudentKeypadStatusRequestV2, UpdateStudentKeypadStatusResponse, UpsertClassReceiverConfigRequest, UpsertClassReceiverConfigResponse, UpsertConnectedClassQuestionSessionRequest, UpsertConnectedClassQuestionSessionResponse, UpsertConnectedClassResourceSessionRequest, UpsertConnectedClassResourceSessionResponse, UpsertConnectedClassSessionRequest, UpsertConnectedClassSessionResponse, UpsertStudentClickerConfigRequest, UpsertStudentClickerConfigResponse, UpsertStudentClickerSessionRequest, UpsertStudentClickerSessionResponse } from "./lms.connected.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1
 */
export const LmsConnectedClassAPIServiceV1 = {
  typeName: "geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.registerBaseReceiver
     */
    registerBaseReceiver: {
      name: "registerBaseReceiver",
      I: RegisterBaseReceiverRequest,
      O: RegisterBaseReceiverResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.registerClicker
     */
    registerClicker: {
      name: "registerClicker",
      I: RegisterClickerRequest,
      O: RegisterClickerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.upsertClassReceiverConfig
     */
    upsertClassReceiverConfig: {
      name: "upsertClassReceiverConfig",
      I: UpsertClassReceiverConfigRequest,
      O: UpsertClassReceiverConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.upsertStudentClickerConfig
     */
    upsertStudentClickerConfig: {
      name: "upsertStudentClickerConfig",
      I: UpsertStudentClickerConfigRequest,
      O: UpsertStudentClickerConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.getBaseStationConnectionStatus
     */
    getBaseStationConnectionStatus: {
      name: "getBaseStationConnectionStatus",
      I: GetBaseStationConnectionRequest,
      O: GetBaseStationConnectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.upsertConnectedClassSession
     */
    upsertConnectedClassSession: {
      name: "upsertConnectedClassSession",
      I: UpsertConnectedClassSessionRequest,
      O: UpsertConnectedClassSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.upsertStudentClickerSession
     */
    upsertStudentClickerSession: {
      name: "upsertStudentClickerSession",
      I: UpsertStudentClickerSessionRequest,
      O: UpsertStudentClickerSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.upsertConnectedClassResourceSession
     */
    upsertConnectedClassResourceSession: {
      name: "upsertConnectedClassResourceSession",
      I: UpsertConnectedClassResourceSessionRequest,
      O: UpsertConnectedClassResourceSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.upsertConnectedClassQuestionSession
     */
    upsertConnectedClassQuestionSession: {
      name: "upsertConnectedClassQuestionSession",
      I: UpsertConnectedClassQuestionSessionRequest,
      O: UpsertConnectedClassQuestionSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.submitConnectedClassStudentResponse
     */
    submitConnectedClassStudentResponse: {
      name: "submitConnectedClassStudentResponse",
      I: SubmitConnectedClassStudentResponseRequest,
      O: SubmitConnectedClassStudentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.getConnectedClassSessionAnalytics
     */
    getConnectedClassSessionAnalytics: {
      name: "getConnectedClassSessionAnalytics",
      I: GetConnectedClassSessionAnalyticsRequest,
      O: GetConnectedClassSessionAnalyticsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.getConnectedClassSessionDetails
     */
    getConnectedClassSessionDetails: {
      name: "getConnectedClassSessionDetails",
      I: GetConnectedClassSessionDetailsRequest,
      O: GetConnectedClassSessionDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.getClassStudentList
     */
    getClassStudentList: {
      name: "getClassStudentList",
      I: GetClassStudentListRequest,
      O: GetClassStudentListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.updateBaseStationStatus
     */
    updateBaseStationStatus: {
      name: "updateBaseStationStatus",
      I: UpdateBaseStationStatusRequest,
      O: UpdateBaseStationStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.updateStudentKeypadStatus
     */
    updateStudentKeypadStatus: {
      name: "updateStudentKeypadStatus",
      I: UpdateStudentKeypadStatusRequest,
      O: UpdateStudentKeypadStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.updateStudentKeypadStatusV2
     */
    updateStudentKeypadStatusV2: {
      name: "updateStudentKeypadStatusV2",
      I: UpdateStudentKeypadStatusRequestV2,
      O: UpdateStudentKeypadStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.updateStudentKeypadResponse
     */
    updateStudentKeypadResponse: {
      name: "updateStudentKeypadResponse",
      I: UpdateStudentKeypadResponseRequest,
      O: UpdateStudentKeypadResponseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.updateStudentKeypadResponseV2
     */
    updateStudentKeypadResponseV2: {
      name: "updateStudentKeypadResponseV2",
      I: UpdateStudentKeypadResponseRequestV2,
      O: UpdateStudentKeypadResponseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all the class section mapping data for a given base station
     *
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.getBaseStationMappingData
     */
    getBaseStationMappingData: {
      name: "getBaseStationMappingData",
      I: GetBaseStationMappingDataRequest,
      O: GetBaseStationMappingDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Validate the given base station configuration data (Basestation - class section)
     * Make other Class section mapping for the base station as inactive in both Geneo DB
     * Make the requested Class section mapping for the base station as active in both Geneo DB and Firestore DB
     * Get all the clickers connected for the base station
     * Make other class section clicker mappings as inactive
     * Make the requested class section clicker mapping as active
     *
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.updateBaseStationMapping
     */
    updateBaseStationMapping: {
      name: "updateBaseStationMapping",
      I: UpdateBaseStationMappingRequest,
      O: UpdateBaseStationMappingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.connected.apis.LmsConnectedClassAPIServiceV1.connectedClassSessionDataFix
     */
    connectedClassSessionDataFix: {
      name: "connectedClassSessionDataFix",
      I: ConnectedClassSessionDataFixRequest,
      O: ConnectedClassSessionDataFixResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

