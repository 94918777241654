import { Code, ConnectError, Interceptor } from '@bufbuild/connect';
import {
  Loader as AppLoader,
  CommonServiceClientContextProvider,
  ManageSession,
  callStudentEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  getLocalStorage,
  pushUserDetailsV1ToAndroid,
  setLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import * as Sentry from '@sentry/react';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { resetAuthState } from '../../pages/Auth/reducer/auth.slice';
import { resetHomeState } from '../../pages/Home/reducer/homeDashboard.slice';
import { resetHomeworkState } from '../../pages/Homework/reducer/homework.slice';
import { resetLearnState } from '../../pages/Learn/reducer/learn.slice';
import { resetPerformanceState } from '../../pages/Performance/reducer/performance.slice';
import { useAppSelector } from '../../reduxStore/reduxHooks';
import { LOGIN } from '../../routeHandling/RoutesNomenclature';
import {
  DownloadContextProviderV2,
  useDownloadContext,
} from './DownloadContextProviderV2';
import { useGlobalContext } from './GlobalContextProvider';

interface IProviderProps {
  children?: ReactNode;
}

export function ServiceClientProvider(props: IProviderProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAppLoader } = useGlobalContext();
  const { is_logged_in_offline, userInfo } = useAppSelector(
    (state) => state.auth
  );
  const activeSessionId = getLocalStorage('activeSession');
  useEffect(() => {
    if (userInfo?.studentProfileId) {
      Sentry.setUser({
        id: userInfo.studentProfileId.toString(),
        email: userInfo.email,
        username: userInfo.userName, // Modify according to your user object structure
        name: userInfo.firstName,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [userInfo]);

  const handleLogout = () => {
    dispatch(resetAuthState());
    dispatch(resetHomeState());
    dispatch(resetLearnState());
    dispatch(resetPerformanceState());
    dispatch(resetHomeworkState());
    // localStorage.clear();
    clearLocalStorageKeys();
    deleteOfflineAccessKeyFromCache();
    navigate(LOGIN);
  };

  const loginInterceptor: Interceptor = (next) => async (req) => {
    if (
      is_logged_in_offline &&
      ![
        'validateLoginSession',
        'validateUser',
        'studentLoginWithUsername',
      ].includes(req.method.name)
    ) {
      throw new ConnectError('Unverified user', Code.NotFound);
    }
    const res = await next(req);
    return res;
  };

  const headerInterceptor: Interceptor = (next) => async (req) => {
    if (process.env.NX_GENEO_FRONTEND_VERSION) {
      req.header.append(
        'X-Geneo-Version',
        process.env.NX_GENEO_FRONTEND_VERSION
      );
    }
    req.header.append('X-Application-Type', 'student');
    const res = await next(req);
    const token = res.header.get('X-Refresh-Token');
    const profileLoginDetailsId = getLocalStorage('profileLoginDetailsId');
    if (token && userInfo && profileLoginDetailsId) {
      pushUserDetailsV1ToAndroid(
        userInfo.studentProfileId.toString(),
        token,
        profileLoginDetailsId,
        activeSessionId.toString()
      );
    }
    return res;
  };

  const getResponseStatusCodeFunctions = () => {
    return {
      '401': handleLogout,
    };
  };

  return (
    <CommonServiceClientContextProvider
      responseStatusCodeFunction={getResponseStatusCodeFunctions()}
      interceptors={[loginInterceptor, headerInterceptor]}
    >
      <DownloadContextProviderV2>
        <UpdateOfflineStatus />
        <StudentEvents />
        <ManageSession role={ProfileRolesEnum.PROFILE_ROLE_STUDENT} />
        {showAppLoader && <AppLoader sx={{ position: 'fixed' }} />}
        <Outlet />
      </DownloadContextProviderV2>
    </CommonServiceClientContextProvider>
  );
}

export const StudentEvents = () => {
  let isFetching = false;
  const POSTING_EVENTS_INTERVAL = 1000;
  const commonServiceClientContext = useCommonServiceClientContext();
  const { isOffline } = useDownloadContext();
  const { is_logged_in_offline } = useAppSelector((state) => state.auth);

  const fetchData = async () => {
    // needs discussion
    let auth = getLocalStorage('auth');
    if (auth === 'true' || auth === 'false') {
      auth = JSON.parse(auth);
    }
    // Skip if a fetch is already in progress || user is not logged in || if app is offline
    if (isFetching || !auth || isOffline) return;

    // setIsFetching(true);
    isFetching = true;
    try {
      await callStudentEventsApi(commonServiceClientContext);
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      // setIsFetching(false);
      isFetching = false;
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchData, POSTING_EVENTS_INTERVAL);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [isOffline, is_logged_in_offline]);

  return null;
};

export const UpdateOfflineStatus = () => {
  const { setIsOffline } = useDownloadContext();

  useEffect(() => {
    const updateOnlineStatus = async () => {
      try {
        const pingUrl =
          process.env.NX_GENEO_BACKEND_URL || 'https://www.google.com';
        await fetch(pingUrl, { credentials: 'include' });
        setIsOffline(false);
        setLocalStorage('isOffline', 'false');
      } catch (error) {
        setIsOffline(true);
        setLocalStorage('isOffline', 'true');
      }
    };

    updateOnlineStatus(); // call initially
    const intervalId = setInterval(updateOnlineStatus, 30000); // Ping every 30 seconds
    window.addEventListener('online', updateOnlineStatus); // on status change in navigator - online
    window.addEventListener('offline', updateOnlineStatus); // on status change in navigator - offline
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return null;
};
