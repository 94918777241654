import { Box } from '@mui/system';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import CustomToolbar, { formats, modules } from './components/CustomToolBar';
import './styles.module.css';
interface IProps {
  value: string | undefined;
  onChange?: (e: string | undefined) => void;
}

export const TextEditor = (props: IProps) => {
  const { onChange, value } = props;

  return (
    <Box
      className="textEditor"
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        // gap: { xs: pxToRem(20), md: pxTovW(20) },
        padding: { xs: pxToRem(10), md: pxTovW(15) },
        justifyContent: 'space-between',
        width: '100%',
        height: '90%',
        maxHeight: '90%',
      }}
    >
      <CustomToolbar />
      <Box
        sx={{
          boxSizing: 'border-box',
          height: '80%',
          maxHeight: '80%',
        }}
      >
        <ReactQuill
          value={value}
          onChange={(content, delta, source, editor) => {
            if (!editor.getText().trim()) {
              onChange && onChange(undefined);
              return;
            }
            onChange && onChange(content);
          }}
          theme="snow"
          placeholder={'Enter instructions...'}
          modules={modules}
          formats={formats}
          style={{
            borderRadius: pxToRem(9),
            height: '100%',
            maxHeight: '100%',
            backgroundColor: 'white',
          }}
        ></ReactQuill>
      </Box>
    </Box>
  );
};
