import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { pxToRem } from '../../../commonUtils/resizeUtils';

interface IProps {
  currentPage: number;
  totalPages: number;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  disabledPrevious?: boolean;
  disabledNext?: boolean;
}

export const PaginationButton = (props: IProps) => {
  const {
    currentPage,
    totalPages,
    onPreviousClick,
    disabledPrevious,
    disabledNext,
    onNextClick,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        width: { xs: '100%', md: 'initial' },
        paddingLeft: { xs: pxToRem(10), md: 0 },
        paddingRight: { xs: pxToRem(10), md: 0 },
      }}
    >
      <PrevButton
        disabled={disabledPrevious}
        onPreviousClick={onPreviousClick}
      />
      <NumberButton currentPage={currentPage} totalPages={totalPages} />
      <NextButton disabled={disabledNext} onNextClick={onNextClick} />
    </Box>
  );
};

interface PrevButtonProps {
  onPreviousClick?: () => void;
  disabled?: boolean;
}

const PrevButton = (props: PrevButtonProps) => {
  console.log('xxxx', props.disabled);
  return (
    <Button
      sx={{
        width: { xs: '30%', md: pxToRem(110) }, //130
        height: { xs: '58px', md: pxToRem(40), lg: pxToRem(50) }, //60
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: { xs: '18px', md: pxToRem(21) },
        boxShadow: 'inset 0px 0px 8px #00602B,0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px 0px 0px 10px',
        '&:disabled': {
          cursor: 'not-allowed',
          backgroundColor: 'neutral.grey',
        },
      }}
      variant="contained"
      color="secondary"
      onClick={props.onPreviousClick}
      disabled={!!props.disabled}
    >
      Prev
    </Button>
  );
};

interface NumberButtonProps {
  currentPage: number;
  totalPages: number;
}

const NumberButton = (props: NumberButtonProps) => {
  return (
    <Button
      sx={{
        width: { xs: '40%', md: pxToRem(110) },
        height: { xs: '58px', md: pxToRem(40), lg: pxToRem(50) },
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: { xs: '18px', md: '21px' },
        boxShadow: 'inset 0px 0px 8px #00602B,0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '0px 0px 0px 0px',
      }}
      variant="contained"
      color="secondary"
    >
      {props.currentPage}/{props.totalPages}
    </Button>
  );
};

interface NextButtonProps {
  onNextClick?: () => void;
  disabled?: boolean;
}

const NextButton = (props: NextButtonProps) => {
  return (
    <Button
      sx={{
        width: { xs: '30%', md: pxToRem(110) },
        height: { xs: '58px', md: pxToRem(40), lg: pxToRem(50) },
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: { xs: '18px', md: '21px' },
        boxShadow: 'inset 0px 0px 8px #00602B,0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '0px 10px 10px 0px',
        '&:disabled': {
          cursor: 'not-allowed',
          backgroundColor: 'neutral.grey',
        },
      }}
      variant="contained"
      color="secondary"
      onClick={props.onNextClick}
      disabled={!!props.disabled}
    >
      Next
    </Button>
  );
};
