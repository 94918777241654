import { Message, PlainMessage, Timestamp } from '@bufbuild/protobuf';
import ungapStructuredClone from '@ungap/structured-clone'; // Import the polyfill
import {
  Content_ContentEnum,
  DifficultyLevelEnum,
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import {
  SubjectList,
  TeachClassSubjects,
} from '@protos/user_management/ums.login.apis_pb';
import { jwtDecode } from 'jwt-decode';
import getDeviceType from './getDeviceType';
export function isValidMobileNumber(input: string): boolean {
  // Number can start with either 6, 7, 8, or 9.
  // The number can also start with "+91" followed by 10 digits. This is optional
  // const indianMobileNumberRegex = /^(\+91)?[6-9]\d{9}$/;  ^(?:(?:\+91)?)(?:\d{10}|\d{2}\s?\d{10})$
  const indianMobileNumberRegex = /^(\+91)?[6-9]\d{9}$/;
  return indianMobileNumberRegex.test(input);
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
interface IlocalStorageKeys {
  userId: string;
  token: string;
  subjectId: string;
  role: 'teacher' | 'student';
  auth: 'true' | 'false';
  deviceID: number;
  fcmToken: string;
  lastUpdatedTime: string;
  lastUpdatedTimeForSession: string;
  activeSession: string;
  homeAPILastTime: string;
  isOffline: 'true' | 'false';
  profileLoginDetailsId: string;
}
export function setLocalStorage(key: keyof IlocalStorageKeys, value: any) {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
}

export function deleteLocalStorage(key: keyof IlocalStorageKeys) {
  localStorage.removeItem(key);
}
export function clearLocalStorageKeys() {
  const keysToClear: Array<keyof IlocalStorageKeys> = Object.keys(
    localStorage
  ) as Array<keyof IlocalStorageKeys>;
  console.log('keysToClear', keysToClear);
  keysToClear.forEach((key) => {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  });
  localStorage.removeItem('persist:root');
  // localStorage.clear();
}

export function getLocalStorage(key: keyof IlocalStorageKeys) {
  const item = localStorage.getItem(key);
  if (item === null || item === undefined || item === '') {
    return null;
  }
  if (key === 'auth') {
    try {
      return JSON.parse(item);
    } catch (error) {
      return item;
    }
  } else {
    return item;
  }
}

export const getHumanReadableTimestampString = (
  timeStamp?: PlainMessage<Timestamp> | Timestamp
) => {
  if (!timeStamp) {
    return undefined;
  }
  const date = new Timestamp(timeStamp).toDate();
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const diffInDays = Math.floor(
    (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
  );

  if (date.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else if (diffInDays <= 7) {
    const daysAgo = diffInDays === 1 ? 'day' : 'days';
    return `${diffInDays} ${daysAgo} ago`;
  } else {
    // Format the date to human-readable format
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString(undefined, options);
  }
};

/**
 * Convert any Message to a PlainMessage - a clone as a plain object without
 * methods.
 */
export function toPlainMessage<T extends Message<T>>(
  message: T
): PlainMessage<T> {
  const val = cloneObject(message);
  return val as PlainMessage<T>;
}

export function arrayToPlainMessageArray<T extends Message<T>>(
  messages?: T[]
): PlainMessage<T>[] | undefined {
  return messages?.map((message) => {
    const plainMessage = cloneObject(message);
    return plainMessage as PlainMessage<T>;
  });
}
export const getDifficultyLevelString = (
  level: DifficultyLevelEnum | undefined
): string => {
  switch (level) {
    case DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED:
      return 'Undefined';
    case DifficultyLevelEnum.DIFFICULTY_LEVEL_HIGH:
      return 'Hard';
    case DifficultyLevelEnum.DIFFICULTY_LEVEL_MEDIUM:
      return 'Medium';
    case DifficultyLevelEnum.DIFFICULTY_LEVEL_LOW:
      return 'Easy';
    default:
      return 'Invalid';
  }
};
export const formatSecondsToDateTimeString = (
  seconds: bigint | undefined,
  fullmonth?: boolean
): string => {
  if (seconds === undefined) {
    return 'Nil';
  }
  const milliseconds = Number(seconds) * 1000;
  const dateObj = new Date(milliseconds);

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = months[monthIndex];

  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

  const formattedDate = `${day}${getDaySuffix(day)} ${
    fullmonth ? month : month.slice(0, 3)
  }, ${formattedHours}:${minutes.toString().padStart(2, '0')}${amOrPm}`;
  return formattedDate;
};
export const formatTimeFromDeadline = (
  remainingSeconds: bigint | undefined
): string => {
  if (remainingSeconds === undefined) {
    return 'Nil';
  }

  const days = Math.floor(Number(remainingSeconds) / (60 * 60 * 24));
  const hours = Math.floor(
    (Number(remainingSeconds) % (60 * 60 * 24)) / (60 * 60)
  );
  const minutes = Math.floor((Number(remainingSeconds) % (60 * 60)) / 60);

  const formattedTime = `${days}D : ${hours
    .toString()
    .padStart(2, '0')}h : ${minutes.toString().padStart(2, '0')}m`;
  return formattedTime;
};
export const formatRemainingTime = (
  targetDateStr: Timestamp | undefined
): string => {
  if (!targetDateStr) {
    return 'Nil';
  }
  const targetDate = new Timestamp(targetDateStr).toDate();
  const currentTime = new Date();
  const timeDifferenceMs = targetDate.getTime() - currentTime.getTime();
  if (timeDifferenceMs <= 0) {
    return '00D : 00h : 00m';
  }
  const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifferenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
  );
  const formattedTime = `${days.toString().padStart(2, '0')}D : ${hours
    .toString()
    .padStart(2, '0')}h : ${minutes.toString().padStart(2, '0')}m`;
  return formattedTime;
};
export const formatDateAsDayMonth = (
  targetDateStr: Timestamp | undefined
): string => {
  if (!targetDateStr) {
    return 'Nil';
  }
  const targetDate = new Timestamp(targetDateStr).toDate();
  const day = targetDate.getDate();
  const month = targetDate.toLocaleString('default', { month: 'short' });

  const dayWithSuffix =
    day +
    (day === 1 || day === 21 || day === 31
      ? 'st'
      : day === 2 || day === 22
      ? 'nd'
      : day === 3 || day === 23
      ? 'rd'
      : 'th');

  const formattedDate = `${dayWithSuffix} ${month}`;
  return formattedDate;
};
function getDaySuffix(day: number) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

// export const apiRequestWrapper = async <T>(
//   clientFunctionCallback: () => Promise<T>
// ): Promise<{ response: T | null; loading: boolean; error: any | null }> => {
//   let response: T | null = null;
//   let loading = true;
//   let error: any | null = null;
//   try {
//     response = await clientFunctionCallback();
//     loading = false;
//   } catch (err) {
//     error = err;
//     loading = false;
//   }

//   return { response, loading, error };
// };

export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    // console.log('Text copied to clipboard');
  } catch (err) {
    console.error('Failed to copy text to clipboard', err);
  }
}

export const getTeacherSubjectEnum = (
  subjectId?: number,
  teachClassSubjects?: TeachClassSubjects[]
) => {
  if (!subjectId || !teachClassSubjects) {
    return undefined;
  }
  for (let i = 0; i < teachClassSubjects.length; i++) {
    const teachClassSubject = teachClassSubjects[i];
    const subjects = teachClassSubject.subjects || [];
    for (let k = 0; k < subjects.length; k++) {
      const subject = subjects[k];
      if (subject.subjectId === subjectId) {
        return subject.subjectEnum;
      }
    }
  }
  return undefined;
};

export const getStudentSubjectEnum = (
  subjectId?: number,
  subjectList?: SubjectList[]
) => {
  if (!subjectId || !subjectList) {
    return undefined;
  }
  for (let j = 0; j < subjectList.length; j++) {
    const subject = subjectList[j];
    if (subject.subjectId === subjectId) {
      return subject.subjectEnum;
    }
  }
  return undefined;
};

interface Info {
  icon: string;
  name: string;
}

export const resourceTypeName = (resourceType: Resource_ResourceEnum): Info => {
  const result: Info = { icon: '', name: '' };
  const enumValue = Resource_ResourceEnum[resourceType];
  const transformedValue = enumValue.replace('RESOURCE_TYPE_', '');

  // Remove underscores and capitalize the first letter of each word
  const words = transformedValue.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  const name = capitalizedWords.join(' ');
  switch (resourceType) {
    case Resource_ResourceEnum.RESOURCE_TYPE_VIDEO:
      result.icon = 'video';
      result.name = 'Video';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_AUDIO:
      result.icon = 'audio-2';
      result.name = 'Audio';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_READING:
      result.icon = 'reading-2';
      result.name = 'Reading';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_QUESTION_SET:
      result.icon = 'questions';
      result.name = 'Question Set';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_QUESTION:
      result.icon = 'question';
      result.name = 'Question';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_PRACTICE:
      result.icon = 'practice';
      result.name = 'Practice';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_TEST:
      result.icon = 'test';
      result.name = 'Test';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_ACTIVITY:
      result.icon = 'activity';
      result.name = 'Activity';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_SIMULATION:
      result.icon = 'simulation';
      result.name = 'Simulation';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_EXPLORIMENT:
      result.icon = 'exploriment';
      result.name = 'Experiment';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_GAME:
      result.icon = 'game';
      result.name = 'Game';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_AR_VR:
      result.icon = 'ar-vr';
      result.name = 'AR VR';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_PPT:
      result.icon = 'PPT';
      result.name = 'PPT';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_E_BOOK:
      result.icon = 'e-book';
      result.name = 'E-Book';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_PDF:
      result.icon = 'pdf-green';
      result.name = 'PDF';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_FLASHCARDS:
      result.icon = 'flashcards';
      result.name = 'Flashcards';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_MINDMAP:
      result.icon = 'mindmap';
      result.name = 'Mindmap';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_WORKSHEET:
      result.icon = 'worksheet';
      result.name = 'Worksheet';
      break;

    case Resource_ResourceEnum.RESOURCE_TYPE_UNDEFINED:
      result.icon = '';
      result.name = '';
      break;
    default:
      result.icon = 'question';
      result.name = name;
      break;
  }

  return result;
};

export const getResourceCategory = (
  categoryNumber: ResourceCategoryEnum
): string => {
  let returnValue = '';

  const enumValue = ResourceCategoryEnum[categoryNumber];
  const transformedValue = enumValue.replace('RESOURCE_CATEGORY_', '');

  // Remove underscores and capitalize the first letter of each word
  const words = transformedValue.split('_');
  const capitalizedWords = words.map(
    (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the capitalized words to form the final result
  const name = capitalizedWords.join(' ');

  switch (categoryNumber) {
    case ResourceCategoryEnum.RESOURCE_CATEGORY_HOOK:
      returnValue = 'Hook';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_EXPLANATION:
      returnValue = 'Explanation';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_SOLVED_EXAMPLES:
      returnValue = 'Solved Examples';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_EXAMPLES:
      returnValue = 'Examples';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_PRACTICE:
      returnValue = 'Practice';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_REVISION:
      returnValue = 'Revision';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_REAL_LIFE_CONNECT:
      returnValue = 'Real Life Connect';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_ACTIVITY:
      returnValue = 'Activity';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_COMPETENCY_BASED_QUESTIONS:
      returnValue = 'Competency Based Questions';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_INTRODUCTION:
      returnValue = 'Introduction';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_COMPETITIVE_EXAM:
      returnValue = 'Competitive Exam';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_READING:
      returnValue = 'Reading';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_RECITATION:
      returnValue = 'Recitation';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_END_OF_CHAPTER:
      returnValue = 'End of Chapter';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_GRAMMAR:
      returnValue = 'Grammar';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_END_OF_POEM:
      returnValue = 'Poem';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_TEST_PAPER:
      returnValue = 'Test Paper';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_WORKSHEET:
      returnValue = 'Worksheet';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_BOOK:
      returnValue = 'Book';
      break;

    case ResourceCategoryEnum.RESOURCE_CATEGORY_UNDEFINED:
      returnValue = '';
      break;

    default:
      returnValue = name;
      break;
  }

  return returnValue;
};

export function convertPercentageToMarks(
  percentage?: number,
  maxMarks?: number
) {
  if (!percentage || !maxMarks) {
    return undefined;
  }
  return Math.round((percentage * maxMarks) / 100);
}

interface IPhoneProps {
  countryCode?: string;
  phoneNumber: string;
}

// export function mergeCountryCodeAndPhone(props: IPhoneProps) {
//   const { countryCode, phoneNumber } = props;
//   if (countryCode) {
//     let newCountryCode = countryCode;
//     // Ensure country code starts with a +
//     if (!countryCode.startsWith('+')) {
//       newCountryCode = '+' + countryCode;
//     }

//     // If the phone number already starts with the country code return it as it is
//     if (phoneNumber.length > 10) {
//       return phoneNumber;
//     }

//     // Merge country code and phone number
//     const mergedNumber = newCountryCode + phoneNumber;

//     return mergedNumber;
//   } else {
//     return phoneNumber;
//   }
// }
export function mergeCountryCodeAndPhone(props: IPhoneProps): string {
  const { countryCode, phoneNumber } = props;

  // Ensure country code starts with a +
  const normalizedCountryCode =
    countryCode && countryCode.trim().startsWith('+')
      ? countryCode
      : `+${countryCode}`;

  // If the phone number already starts with the country code, return it as it is
  if (phoneNumber.startsWith(normalizedCountryCode)) {
    return phoneNumber;
  }

  // Merge country code and phone number
  const mergedPhoneNumber = `${normalizedCountryCode}${phoneNumber}`;

  return mergedPhoneNumber;
}

export function getEnumKeyByEnumValue<
  T extends Record<string, string | number>
>(enumObj: T, value: string | number): keyof T | undefined {
  return Object.keys(enumObj).find(
    (key) => enumObj[key as keyof T] === value
  ) as keyof T | undefined;
}
export const getTeacherClassSubjtects = (data?: TeachClassSubjects[]) => {
  if (!data) return;
  const temp = data?.map((elem) =>
    elem.subjects.map(
      (e) => `${elem.className}${elem.sectionName}  ${e.subjectName}`
    )
  );
  return temp;
};

export function shuffleListWithSeed<T>(list: T[], seed: number): T[] {
  // Seeded Random Number Generator (LCG)
  function seedRandom(seed: number): () => number {
    return function () {
      seed = (seed * 9301 + 49297) % 233280;
      return seed / 233280;
    };
  }

  const random = seedRandom(seed); // Create a seeded random function

  // Create a copy of the list to shuffle
  const shuffledList = [...list];

  // Shuffle the list using Fisher-Yates algorithm with seeded random
  for (let i = shuffledList.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]]; // Swap elements
  }

  return shuffledList;
}

// export function cloneObject<ObjectType>(value: ObjectType): ObjectType {
//   function isStructuredCloneAvailable(): boolean {
//     return typeof structuredClone === 'function';
//   }

//   function jsonClone(obj: ObjectType): ObjectType {
//     return JSON.parse(JSON.stringify(obj));
//   }

//   if (isStructuredCloneAvailable()) {
//     return structuredClone(value);
//   } else {
//     return jsonClone(value);
//   }
// }

// src/types/declarations.d.ts or any directory of your choice

export function cloneObject<ObjectType>(value: ObjectType): ObjectType {
  function isStructuredCloneAvailableNatively(): boolean {
    return typeof structuredClone === 'function';
  }
  if (isStructuredCloneAvailableNatively()) {
    return structuredClone(value); // Native structuredClone
  } else {
    return ungapStructuredClone(value) as any; // Polyfill from @ungap
  }
}

export const pushUserDetailsV1ToAndroid = (
  userId: string,
  uToken: string,
  profileLoginDetailsId: string,
  activeSessionId: string
) => {
  const deviceType = getDeviceType();
  const android = window.Android;
  try {
    if (!uToken || !userId) {
      console.log('Token or user ID not found');
      return;
    }
    // Decode the token and prepare data
    const decoded = jwtDecode(uToken);
    // Check for Android device and required method availability
    if (deviceType !== 'android' || !android?.pushUserDetailsV1) return;
    console.log('function running');

    // Push user details to Android
    android.pushUserDetailsV1(
      userId,
      uToken,
      decoded.iat,
      decoded.exp,
      profileLoginDetailsId,
      activeSessionId
    );
  } catch (error) {
    console.log('Error pushing user details to Android:', error);
  }
};

export function roundingAssessmentNumber(assessment: number) {
  if (assessment <= 20) {
    return assessment.toString();
  }
  const rounded = Math.floor(assessment / 10) * 10;
  return rounded + '+';
}

export const contentTypeName = (contentType: Content_ContentEnum): Info => {
  const result: Info = { icon: '', name: '' };
  const enumValue = Resource_ResourceEnum[contentType];
  const transformedValue = enumValue.replace('RESOURCE_TYPE_', '');

  // Remove underscores and capitalize the first letter of each word
  const words = transformedValue.split('_');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  const name = capitalizedWords.join(' ');
  switch (contentType) {
    case Content_ContentEnum.CONTENT_TYPE_CONTENT_VIDEO:
      result.icon = 'video';
      result.name = 'Video';
      break;

    case Content_ContentEnum.CONTENT_TYPE_PASSAGE:
      result.icon = 'reading-2';
      result.name = 'Passage';
      break;

    case Content_ContentEnum.CONTENT_TYPE_EXPERIMENT:
      result.icon = 'exploriment';
      result.name = 'Experiment';
      break;

    case Content_ContentEnum.CONTENT_TYPE_FLASHCARD:
      result.icon = 'flashcards';
      result.name = 'Flashcards';
      break;

    case Content_ContentEnum.CONTENT_TYPE_MINDMAP:
      result.icon = 'mindmap';
      result.name = 'Mindmap';
      break;

    case Content_ContentEnum.CONTENT_TYPE_UNDEFINED:
      result.icon = '';
      result.name = '';
      break;
    default:
      result.icon = 'question';
      result.name = name;
      break;
  }

  return result;
};
