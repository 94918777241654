import {
  FileEnum,
  FileExtensionEnum,
} from '@protos/content_management/content.db_pb';
import { MimeEnum } from '@protos/learning_management/lms.common.apis_pb';

export function getImageType(fileType: string) {
  switch (fileType.toLowerCase()) {
    case 'image/png':
      return MimeEnum.MIME_PNG;
    case 'image/jpeg':
      return MimeEnum.MIME_JPEG;
    case 'image/jpg':
      return MimeEnum.MIME_JPG;
    case 'application/pdf':
      return MimeEnum.MIME_PDF;
    case 'application/msword':
      return MimeEnum.MIME_DOC;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return MimeEnum.MIME_DOCX;
    case 'application/vnd.ms-powerpoint':
      return MimeEnum.MIME_PPT;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return MimeEnum.MIME_PPTX;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return MimeEnum.MIME_XLSX;
    default:
      return MimeEnum.MIME_UNDEFINED;
  }
}

export function getFileType(fileType: string): FileEnum {
  switch (fileType.toLowerCase()) {
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
      return FileEnum.FILE_TYPE_IMAGE;
    case 'application/msword':
      return FileEnum.FILE_TYPE_DOCUMENT;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return FileEnum.FILE_TYPE_DOCUMENT;
    case 'application/vnd.ms-powerpoint':
      return FileEnum.FILE_TYPE_PRESENTATION;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return FileEnum.FILE_TYPE_PRESENTATION;
    case 'application/pdf':
      return FileEnum.FILE_TYPE_PDF;

    default:
      return FileEnum.FILE_TYPE_UNKNOWN;
  }
}

export function getFileTypeName(fileType: FileEnum | undefined): string {
  if (!fileType) return 'unknown_file';
  switch (fileType) {
    case FileEnum.FILE_TYPE_IMAGE:
      return 'image_icon';
    case FileEnum.FILE_TYPE_DOCUMENT:
      return 'document_paper';
    case FileEnum.FILE_TYPE_PRESENTATION:
      return 'powerpoint';
    case FileEnum.FILE_TYPE_PDF:
      return 'pdf_gray';
    default:
      return 'unknown_file';
  }
}

export function getFileExtensionType(fileExtension: string) {
  switch (fileExtension.toLowerCase()) {
    case 'image/jpg':
      return FileExtensionEnum.FILE_EXTENSION_JPG;
    case 'image/jpeg':
      return FileExtensionEnum.FILE_EXTENSION_JPEG;
    case 'image/png':
      return FileExtensionEnum.FILE_EXTENSION_PNG;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return FileExtensionEnum.FILE_EXTENSION_DOCX;
    case 'application/msword':
      return FileExtensionEnum.FILE_EXTENSION_DOC;
    // todo: PPTX vs PPT ??
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return FileExtensionEnum.FILE_EXTENSION_PPTX;

    case 'application/vnd.ms-powerpoint':
      return FileExtensionEnum.FILE_EXTENSION_PPT;
    case 'application/pdf':
      return FileExtensionEnum.FILE_EXTENSION_PDF;
    default:
      return FileExtensionEnum.FILE_EXTENSION_UNKNOWN;
  }
}

export const getFileSizeInMB = (fileSizeInBytes: number) => {
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  return Number(fileSizeInMB.toFixed(2));
};
