// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.teacher.apis.proto (package geneo.analysis.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { QAttemptResultEnum, TaskQuestionInfo } from "@protos/learning_management/lms.db_pb";
import { Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";

/**
 * @generated from message geneo.analysis.teacher.apis.FetchHomeworkStatisticsRequest
 */
export class FetchHomeworkStatisticsRequest extends Message<FetchHomeworkStatisticsRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  constructor(data?: PartialMessage<FetchHomeworkStatisticsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.FetchHomeworkStatisticsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchHomeworkStatisticsRequest {
    return new FetchHomeworkStatisticsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchHomeworkStatisticsRequest {
    return new FetchHomeworkStatisticsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchHomeworkStatisticsRequest {
    return new FetchHomeworkStatisticsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchHomeworkStatisticsRequest | PlainMessage<FetchHomeworkStatisticsRequest> | undefined, b: FetchHomeworkStatisticsRequest | PlainMessage<FetchHomeworkStatisticsRequest> | undefined): boolean {
    return proto3.util.equals(FetchHomeworkStatisticsRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.FetchHomeworkStatisticsResponse
 */
export class FetchHomeworkStatisticsResponse extends Message<FetchHomeworkStatisticsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.analysis.teacher.apis.HomeworkStatisticsData data = 4;
   */
  data?: HomeworkStatisticsData;

  constructor(data?: PartialMessage<FetchHomeworkStatisticsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.FetchHomeworkStatisticsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: HomeworkStatisticsData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchHomeworkStatisticsResponse {
    return new FetchHomeworkStatisticsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchHomeworkStatisticsResponse {
    return new FetchHomeworkStatisticsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchHomeworkStatisticsResponse {
    return new FetchHomeworkStatisticsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchHomeworkStatisticsResponse | PlainMessage<FetchHomeworkStatisticsResponse> | undefined, b: FetchHomeworkStatisticsResponse | PlainMessage<FetchHomeworkStatisticsResponse> | undefined): boolean {
    return proto3.util.equals(FetchHomeworkStatisticsResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.HomeworkStatisticsData
 */
export class HomeworkStatisticsData extends Message<HomeworkStatisticsData> {
  /**
   * @generated from field: repeated geneo.lms.db.TaskQuestionInfo questions = 1;
   */
  questions: TaskQuestionInfo[] = [];

  /**
   * @generated from field: repeated geneo.analysis.teacher.apis.StudentResponse studentResponses = 2;
   */
  studentResponses: StudentResponse[] = [];

  constructor(data?: PartialMessage<HomeworkStatisticsData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.HomeworkStatisticsData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: TaskQuestionInfo, repeated: true },
    { no: 2, name: "studentResponses", kind: "message", T: StudentResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkStatisticsData {
    return new HomeworkStatisticsData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkStatisticsData {
    return new HomeworkStatisticsData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkStatisticsData {
    return new HomeworkStatisticsData().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkStatisticsData | PlainMessage<HomeworkStatisticsData> | undefined, b: HomeworkStatisticsData | PlainMessage<HomeworkStatisticsData> | undefined): boolean {
    return proto3.util.equals(HomeworkStatisticsData, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.StudentResponse
 */
export class StudentResponse extends Message<StudentResponse> {
  /**
   * @generated from field: string studentName = 1;
   */
  studentName = "";

  /**
   * @generated from field: repeated geneo.analysis.teacher.apis.StudentAnswer responses = 2;
   */
  responses: StudentAnswer[] = [];

  /**
   * @generated from field: int32 totalScore = 3;
   */
  totalScore = 0;

  constructor(data?: PartialMessage<StudentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.StudentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "studentName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "responses", kind: "message", T: StudentAnswer, repeated: true },
    { no: 3, name: "totalScore", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentResponse {
    return new StudentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentResponse {
    return new StudentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentResponse {
    return new StudentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentResponse | PlainMessage<StudentResponse> | undefined, b: StudentResponse | PlainMessage<StudentResponse> | undefined): boolean {
    return proto3.util.equals(StudentResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.StudentAnswer
 */
export class StudentAnswer extends Message<StudentAnswer> {
  /**
   * @generated from field: string questionId = 1;
   */
  questionId = "";

  /**
   * @generated from field: int32 responseScore = 2;
   */
  responseScore = 0;

  /**
   * @generated from field: geneo.lms.db.QAttemptResultEnum isCorrect = 3;
   */
  isCorrect = QAttemptResultEnum.RESPONSE_UNDEFINED;

  /**
   * @generated from field: string answer = 4;
   */
  answer = "";

  constructor(data?: PartialMessage<StudentAnswer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.StudentAnswer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "responseScore", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "isCorrect", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum) },
    { no: 4, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentAnswer {
    return new StudentAnswer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentAnswer {
    return new StudentAnswer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentAnswer {
    return new StudentAnswer().fromJsonString(jsonString, options);
  }

  static equals(a: StudentAnswer | PlainMessage<StudentAnswer> | undefined, b: StudentAnswer | PlainMessage<StudentAnswer> | undefined): boolean {
    return proto3.util.equals(StudentAnswer, a, b);
  }
}

/**
 * Teacher Analysis Request
 *
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysisRequest
 */
export class TeacherAnalysisRequest extends Message<TeacherAnalysisRequest> {
  /**
   * ID of the teacher (required)
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<TeacherAnalysisRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysisRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysisRequest {
    return new TeacherAnalysisRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysisRequest {
    return new TeacherAnalysisRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysisRequest {
    return new TeacherAnalysisRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysisRequest | PlainMessage<TeacherAnalysisRequest> | undefined, b: TeacherAnalysisRequest | PlainMessage<TeacherAnalysisRequest> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysisRequest, a, b);
  }
}

/**
 * Teacher Analysis Response
 *
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysisResponse
 */
export class TeacherAnalysisResponse extends Message<TeacherAnalysisResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Teacher analysis data (required)
   *
   * @generated from field: geneo.analysis.teacher.apis.TeacherAnalysis data = 4;
   */
  data?: TeacherAnalysis;

  constructor(data?: PartialMessage<TeacherAnalysisResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysisResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAnalysis },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysisResponse {
    return new TeacherAnalysisResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysisResponse {
    return new TeacherAnalysisResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysisResponse {
    return new TeacherAnalysisResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysisResponse | PlainMessage<TeacherAnalysisResponse> | undefined, b: TeacherAnalysisResponse | PlainMessage<TeacherAnalysisResponse> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysisResponse, a, b);
  }
}

/**
 * Teacher Analysis
 *
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysis
 */
export class TeacherAnalysis extends Message<TeacherAnalysis> {
  /**
   * ID of the teacher (required)
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * Number of lessons taught (required)
   *
   * @generated from field: int32 lesson_taughts = 2;
   */
  lessonTaughts = 0;

  /**
   * Number of homework assignments (required)
   *
   * @generated from field: int32 assigned_hw_count = 3;
   */
  assignedHwCount = 0;

  /**
   * Time spent in minutes (required)
   *
   * @generated from field: float time_spent_in_minutes = 4;
   */
  timeSpentInMinutes = 0;

  /**
   * List of class subject performance (required)
   *
   * @generated from field: repeated geneo.analysis.teacher.apis.ListClassSubjectPerformance list_class_subject_performance = 5;
   */
  listClassSubjectPerformance: ListClassSubjectPerformance[] = [];

  constructor(data?: PartialMessage<TeacherAnalysis>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysis";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "lesson_taughts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "assigned_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "time_spent_in_minutes", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "list_class_subject_performance", kind: "message", T: ListClassSubjectPerformance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysis {
    return new TeacherAnalysis().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysis {
    return new TeacherAnalysis().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysis {
    return new TeacherAnalysis().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysis | PlainMessage<TeacherAnalysis> | undefined, b: TeacherAnalysis | PlainMessage<TeacherAnalysis> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysis, a, b);
  }
}

/**
 * List Class Subject Performance
 *
 * @generated from message geneo.analysis.teacher.apis.ListClassSubjectPerformance
 */
export class ListClassSubjectPerformance extends Message<ListClassSubjectPerformance> {
  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * Subject (required)
   *
   * @generated from field: string subject = 2;
   */
  subject = "";

  /**
   * Class type (required)
   *
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * Class type (required)
   *
   * @generated from field: string class = 4;
   */
  class = "";

  /**
   * ID of the school class section (required)
   *
   * @generated from field: int32 section_id = 5;
   */
  sectionId = 0;

  /**
   * Section of the class (required)
   *
   * @generated from field: string section = 6;
   */
  section = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 7;
   */
  scorePercent = 0;

  /**
   * URL of the subject icon image (required)
   *
   * @generated from field: string subject_icon_image_url = 8;
   */
  subjectIconImageUrl = "";

  /**
   * @generated from field: optional float spent_time = 9;
   */
  spentTime?: number;

  /**
   * @generated from field: optional int32 assigned_hW = 10;
   */
  assignedHW?: number;

  /**
   * @generated from field: optional int32 lesson_taught = 11;
   */
  lessonTaught?: number;

  constructor(data?: PartialMessage<ListClassSubjectPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.ListClassSubjectPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "subject_icon_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "spent_time", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 10, name: "assigned_hW", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "lesson_taught", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListClassSubjectPerformance {
    return new ListClassSubjectPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListClassSubjectPerformance {
    return new ListClassSubjectPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListClassSubjectPerformance {
    return new ListClassSubjectPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: ListClassSubjectPerformance | PlainMessage<ListClassSubjectPerformance> | undefined, b: ListClassSubjectPerformance | PlainMessage<ListClassSubjectPerformance> | undefined): boolean {
    return proto3.util.equals(ListClassSubjectPerformance, a, b);
  }
}

/**
 * Teacher Analysis Class Subject Request
 *
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysisClassSubjectRequest
 */
export class TeacherAnalysisClassSubjectRequest extends Message<TeacherAnalysisClassSubjectRequest> {
  /**
   * ID of the teacher (required)
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * ID of the school class section (required)
   *
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  constructor(data?: PartialMessage<TeacherAnalysisClassSubjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysisClassSubjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysisClassSubjectRequest {
    return new TeacherAnalysisClassSubjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubjectRequest {
    return new TeacherAnalysisClassSubjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubjectRequest {
    return new TeacherAnalysisClassSubjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysisClassSubjectRequest | PlainMessage<TeacherAnalysisClassSubjectRequest> | undefined, b: TeacherAnalysisClassSubjectRequest | PlainMessage<TeacherAnalysisClassSubjectRequest> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysisClassSubjectRequest, a, b);
  }
}

/**
 * Teacher Analysis Class Subject Response
 *
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysisClassSubjectResponse
 */
export class TeacherAnalysisClassSubjectResponse extends Message<TeacherAnalysisClassSubjectResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Teacher analysis class subject data (required)
   *
   * @generated from field: geneo.analysis.teacher.apis.TeacherAnalysisClassSubject data = 4;
   */
  data?: TeacherAnalysisClassSubject;

  constructor(data?: PartialMessage<TeacherAnalysisClassSubjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysisClassSubjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAnalysisClassSubject },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysisClassSubjectResponse {
    return new TeacherAnalysisClassSubjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubjectResponse {
    return new TeacherAnalysisClassSubjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubjectResponse {
    return new TeacherAnalysisClassSubjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysisClassSubjectResponse | PlainMessage<TeacherAnalysisClassSubjectResponse> | undefined, b: TeacherAnalysisClassSubjectResponse | PlainMessage<TeacherAnalysisClassSubjectResponse> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysisClassSubjectResponse, a, b);
  }
}

/**
 * Teacher Analysis Class Subject
 *
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysisClassSubject
 */
export class TeacherAnalysisClassSubject extends Message<TeacherAnalysisClassSubject> {
  /**
   * ID of the teacher (required)
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * ID of the school class section (required)
   *
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * Number of lessons taught (required)
   *
   * @generated from field: int32 lesson_taughts = 4;
   */
  lessonTaughts = 0;

  /**
   * Number of homework assignments (required)
   *
   * @generated from field: int32 assigned_hw_count = 5;
   */
  assignedHwCount = 0;

  /**
   * Time spent in minutes (required)
   *
   * @generated from field: float time_spent_in_minutes = 6;
   */
  timeSpentInMinutes = 0;

  /**
   * @generated from field: int32 class_id = 7;
   */
  classId = 0;

  /**
   * Class type (required)
   *
   * @generated from field: string class = 8;
   */
  class = "";

  /**
   * Section of the class (required)
   *
   * @generated from field: string section = 9;
   */
  section = "";

  /**
   * Subject (required)
   *
   * @generated from field: string subject = 10;
   */
  subject = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 11;
   */
  scorePercent = 0;

  /**
   * URL of the subject icon (required)
   *
   * @generated from field: string subject_icon_image_url = 12;
   */
  subjectIconImageUrl = "";

  /**
   * List of student performance (optional)
   *
   * @generated from field: repeated geneo.analysis.teacher.apis.StudentPerformance students_performance_list = 13;
   */
  studentsPerformanceList: StudentPerformance[] = [];

  constructor(data?: PartialMessage<TeacherAnalysisClassSubject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysisClassSubject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lesson_taughts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "assigned_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "time_spent_in_minutes", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "subject_icon_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "students_performance_list", kind: "message", T: StudentPerformance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysisClassSubject {
    return new TeacherAnalysisClassSubject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubject {
    return new TeacherAnalysisClassSubject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubject {
    return new TeacherAnalysisClassSubject().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysisClassSubject | PlainMessage<TeacherAnalysisClassSubject> | undefined, b: TeacherAnalysisClassSubject | PlainMessage<TeacherAnalysisClassSubject> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysisClassSubject, a, b);
  }
}

/**
 * Student Performance
 *
 * @generated from message geneo.analysis.teacher.apis.StudentPerformance
 */
export class StudentPerformance extends Message<StudentPerformance> {
  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * Name of the student (required)
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * URL of the profile image (required)
   *
   * @generated from field: string profile_image_url = 3;
   */
  profileImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<StudentPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.StudentPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentPerformance {
    return new StudentPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentPerformance {
    return new StudentPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentPerformance {
    return new StudentPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: StudentPerformance | PlainMessage<StudentPerformance> | undefined, b: StudentPerformance | PlainMessage<StudentPerformance> | undefined): boolean {
    return proto3.util.equals(StudentPerformance, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.TeacherAnalysisClassSubjectChapterResponse
 */
export class TeacherAnalysisClassSubjectChapterResponse extends Message<TeacherAnalysisClassSubjectChapterResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Teacher analysis class subject data (required)
   *
   * @generated from field: geneo.analysis.teacher.apis.ClassSubjectChapter data = 4;
   */
  data?: ClassSubjectChapter;

  constructor(data?: PartialMessage<TeacherAnalysisClassSubjectChapterResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TeacherAnalysisClassSubjectChapterResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ClassSubjectChapter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAnalysisClassSubjectChapterResponse {
    return new TeacherAnalysisClassSubjectChapterResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubjectChapterResponse {
    return new TeacherAnalysisClassSubjectChapterResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAnalysisClassSubjectChapterResponse {
    return new TeacherAnalysisClassSubjectChapterResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAnalysisClassSubjectChapterResponse | PlainMessage<TeacherAnalysisClassSubjectChapterResponse> | undefined, b: TeacherAnalysisClassSubjectChapterResponse | PlainMessage<TeacherAnalysisClassSubjectChapterResponse> | undefined): boolean {
    return proto3.util.equals(TeacherAnalysisClassSubjectChapterResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.ClassSubjectChapter
 */
export class ClassSubjectChapter extends Message<ClassSubjectChapter> {
  /**
   * Class type (required)
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class = 1;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * Section of the class (required)
   *
   * @generated from field: string section = 2;
   */
  section = "";

  /**
   * Subject (required)
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 3;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * URL of the subject icon image (required)
   *
   * @generated from field: string subject_icon_image_url = 5;
   */
  subjectIconImageUrl = "";

  /**
   * ID of the school class section (required)
   *
   * @generated from field: int32 section_id = 6;
   */
  sectionId = 0;

  /**
   * List of chapter performance stats
   *
   * @generated from field: repeated geneo.analysis.teacher.apis.ChapterPerformanceStats chapter_performance = 7;
   */
  chapterPerformance: ChapterPerformanceStats[] = [];

  constructor(data?: PartialMessage<ClassSubjectChapter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.ClassSubjectChapter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 2, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "subject_icon_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "chapter_performance", kind: "message", T: ChapterPerformanceStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSubjectChapter {
    return new ClassSubjectChapter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSubjectChapter {
    return new ClassSubjectChapter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSubjectChapter {
    return new ClassSubjectChapter().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSubjectChapter | PlainMessage<ClassSubjectChapter> | undefined, b: ClassSubjectChapter | PlainMessage<ClassSubjectChapter> | undefined): boolean {
    return proto3.util.equals(ClassSubjectChapter, a, b);
  }
}

/**
 * Chapter Performance Stats
 *
 * @generated from message geneo.analysis.teacher.apis.ChapterPerformanceStats
 */
export class ChapterPerformanceStats extends Message<ChapterPerformanceStats> {
  /**
   * ID of the chapter (required)
   *
   * @generated from field: int32 chapter_id = 1;
   */
  chapterId = 0;

  /**
   * Title of the chapter (required)
   *
   * @generated from field: string chapter_title = 2;
   */
  chapterTitle = "";

  /**
   * URL of the poster image (required)
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * List of topic performance stats
   *
   * @generated from field: repeated geneo.analysis.teacher.apis.TopicPerformanceStats topics_performance = 5;
   */
  topicsPerformance: TopicPerformanceStats[] = [];

  constructor(data?: PartialMessage<ChapterPerformanceStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.ChapterPerformanceStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "topics_performance", kind: "message", T: TopicPerformanceStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterPerformanceStats {
    return new ChapterPerformanceStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterPerformanceStats {
    return new ChapterPerformanceStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterPerformanceStats {
    return new ChapterPerformanceStats().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterPerformanceStats | PlainMessage<ChapterPerformanceStats> | undefined, b: ChapterPerformanceStats | PlainMessage<ChapterPerformanceStats> | undefined): boolean {
    return proto3.util.equals(ChapterPerformanceStats, a, b);
  }
}

/**
 * Topic Performance Stats
 *
 * @generated from message geneo.analysis.teacher.apis.TopicPerformanceStats
 */
export class TopicPerformanceStats extends Message<TopicPerformanceStats> {
  /**
   * ID of the topic (required)
   *
   * @generated from field: int32 topic_id = 1;
   */
  topicId = 0;

  /**
   * Title of the topic (required)
   *
   * @generated from field: string topic_title = 2;
   */
  topicTitle = "";

  /**
   * URL of the poster image (required)
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<TopicPerformanceStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.TopicPerformanceStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topic_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicPerformanceStats {
    return new TopicPerformanceStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicPerformanceStats {
    return new TopicPerformanceStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicPerformanceStats {
    return new TopicPerformanceStats().fromJsonString(jsonString, options);
  }

  static equals(a: TopicPerformanceStats | PlainMessage<TopicPerformanceStats> | undefined, b: TopicPerformanceStats | PlainMessage<TopicPerformanceStats> | undefined): boolean {
    return proto3.util.equals(TopicPerformanceStats, a, b);
  }
}

/**
 * Fetch Student Subject Performance Stats Request
 *
 * @generated from message geneo.analysis.teacher.apis.FetchStudentSubjectPerformanceStatsRequest
 */
export class FetchStudentSubjectPerformanceStatsRequest extends Message<FetchStudentSubjectPerformanceStatsRequest> {
  /**
   * ID of the teacher (required)
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  constructor(data?: PartialMessage<FetchStudentSubjectPerformanceStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.FetchStudentSubjectPerformanceStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudentSubjectPerformanceStatsRequest {
    return new FetchStudentSubjectPerformanceStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudentSubjectPerformanceStatsRequest {
    return new FetchStudentSubjectPerformanceStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudentSubjectPerformanceStatsRequest {
    return new FetchStudentSubjectPerformanceStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudentSubjectPerformanceStatsRequest | PlainMessage<FetchStudentSubjectPerformanceStatsRequest> | undefined, b: FetchStudentSubjectPerformanceStatsRequest | PlainMessage<FetchStudentSubjectPerformanceStatsRequest> | undefined): boolean {
    return proto3.util.equals(FetchStudentSubjectPerformanceStatsRequest, a, b);
  }
}

/**
 * Fetch Student Subject Performance Stats Response
 *
 * @generated from message geneo.analysis.teacher.apis.FetchStudentSubjectPerformanceStatsResponse
 */
export class FetchStudentSubjectPerformanceStatsResponse extends Message<FetchStudentSubjectPerformanceStatsResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Student subject performance stats data (required)
   *
   * @generated from field: geneo.analysis.teacher.apis.StudentSubjectPerformanceStats data = 4;
   */
  data?: StudentSubjectPerformanceStats;

  constructor(data?: PartialMessage<FetchStudentSubjectPerformanceStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.FetchStudentSubjectPerformanceStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentSubjectPerformanceStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchStudentSubjectPerformanceStatsResponse {
    return new FetchStudentSubjectPerformanceStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchStudentSubjectPerformanceStatsResponse {
    return new FetchStudentSubjectPerformanceStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchStudentSubjectPerformanceStatsResponse {
    return new FetchStudentSubjectPerformanceStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchStudentSubjectPerformanceStatsResponse | PlainMessage<FetchStudentSubjectPerformanceStatsResponse> | undefined, b: FetchStudentSubjectPerformanceStatsResponse | PlainMessage<FetchStudentSubjectPerformanceStatsResponse> | undefined): boolean {
    return proto3.util.equals(FetchStudentSubjectPerformanceStatsResponse, a, b);
  }
}

/**
 * Student Subject Performance Stats
 *
 * @generated from message geneo.analysis.teacher.apis.StudentSubjectPerformanceStats
 */
export class StudentSubjectPerformanceStats extends Message<StudentSubjectPerformanceStats> {
  /**
   * ID of the student (required)
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the school course (required)
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * Subject (required)
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 3;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * Number of submitted homework assignments (required)
   *
   * @generated from field: int32 submitted_hw_count = 5;
   */
  submittedHwCount = 0;

  /**
   * Time spent in minutes (required)
   *
   * @generated from field: float time_spent_in_min = 6;
   */
  timeSpentInMin = 0;

  /**
   * List of student chapter performance stats (required)
   *
   * @generated from field: repeated geneo.analysis.teacher.apis.StudentChapterPerformanceStats chapters_performance = 7;
   */
  chaptersPerformance: StudentChapterPerformanceStats[] = [];

  constructor(data?: PartialMessage<StudentSubjectPerformanceStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.StudentSubjectPerformanceStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "submitted_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "time_spent_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "chapters_performance", kind: "message", T: StudentChapterPerformanceStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentSubjectPerformanceStats {
    return new StudentSubjectPerformanceStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentSubjectPerformanceStats {
    return new StudentSubjectPerformanceStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentSubjectPerformanceStats {
    return new StudentSubjectPerformanceStats().fromJsonString(jsonString, options);
  }

  static equals(a: StudentSubjectPerformanceStats | PlainMessage<StudentSubjectPerformanceStats> | undefined, b: StudentSubjectPerformanceStats | PlainMessage<StudentSubjectPerformanceStats> | undefined): boolean {
    return proto3.util.equals(StudentSubjectPerformanceStats, a, b);
  }
}

/**
 * Student Chapter Performance Stats
 *
 * @generated from message geneo.analysis.teacher.apis.StudentChapterPerformanceStats
 */
export class StudentChapterPerformanceStats extends Message<StudentChapterPerformanceStats> {
  /**
   * ID of the chapter (required)
   *
   * @generated from field: int32 chapter_id = 1;
   */
  chapterId = 0;

  /**
   * Title of the chapter (required)
   *
   * @generated from field: string chapter_title = 2;
   */
  chapterTitle = "";

  /**
   * URL of the poster image (required)
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * List of student topic performance stats
   *
   * @generated from field: repeated geneo.analysis.teacher.apis.StudentTopicPerformanceStats topics_performance = 5;
   */
  topicsPerformance: StudentTopicPerformanceStats[] = [];

  constructor(data?: PartialMessage<StudentChapterPerformanceStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.StudentChapterPerformanceStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "topics_performance", kind: "message", T: StudentTopicPerformanceStats, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentChapterPerformanceStats {
    return new StudentChapterPerformanceStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentChapterPerformanceStats {
    return new StudentChapterPerformanceStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentChapterPerformanceStats {
    return new StudentChapterPerformanceStats().fromJsonString(jsonString, options);
  }

  static equals(a: StudentChapterPerformanceStats | PlainMessage<StudentChapterPerformanceStats> | undefined, b: StudentChapterPerformanceStats | PlainMessage<StudentChapterPerformanceStats> | undefined): boolean {
    return proto3.util.equals(StudentChapterPerformanceStats, a, b);
  }
}

/**
 * Student Topic Performance Stats
 *
 * @generated from message geneo.analysis.teacher.apis.StudentTopicPerformanceStats
 */
export class StudentTopicPerformanceStats extends Message<StudentTopicPerformanceStats> {
  /**
   * ID of the topic (required)
   *
   * @generated from field: int32 topic_id = 1;
   */
  topicId = 0;

  /**
   * Title of the topic (required)
   *
   * @generated from field: string topic_title = 2;
   */
  topicTitle = "";

  /**
   * URL of the poster image (required)
   *
   * @generated from field: string poster_image_url = 3;
   */
  posterImageUrl = "";

  /**
   * Score percentage (required)
   *
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  constructor(data?: PartialMessage<StudentTopicPerformanceStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.StudentTopicPerformanceStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topic_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "poster_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentTopicPerformanceStats {
    return new StudentTopicPerformanceStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentTopicPerformanceStats {
    return new StudentTopicPerformanceStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentTopicPerformanceStats {
    return new StudentTopicPerformanceStats().fromJsonString(jsonString, options);
  }

  static equals(a: StudentTopicPerformanceStats | PlainMessage<StudentTopicPerformanceStats> | undefined, b: StudentTopicPerformanceStats | PlainMessage<StudentTopicPerformanceStats> | undefined): boolean {
    return proto3.util.equals(StudentTopicPerformanceStats, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.GetChapterAnalysisRequest
 */
export class GetChapterAnalysisRequest extends Message<GetChapterAnalysisRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 chapter_id = 4;
   */
  chapterId = 0;

  constructor(data?: PartialMessage<GetChapterAnalysisRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.GetChapterAnalysisRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetChapterAnalysisRequest {
    return new GetChapterAnalysisRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetChapterAnalysisRequest {
    return new GetChapterAnalysisRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetChapterAnalysisRequest {
    return new GetChapterAnalysisRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetChapterAnalysisRequest | PlainMessage<GetChapterAnalysisRequest> | undefined, b: GetChapterAnalysisRequest | PlainMessage<GetChapterAnalysisRequest> | undefined): boolean {
    return proto3.util.equals(GetChapterAnalysisRequest, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.GetChapterAnalysisResponse
 */
export class GetChapterAnalysisResponse extends Message<GetChapterAnalysisResponse> {
  /**
   * Response status
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Teacher analysis class subject data (required)
   *
   * @generated from field: geneo.analysis.teacher.apis.ChapterPerformanceStats chapter_performance = 4;
   */
  chapterPerformance?: ChapterPerformanceStats;

  /**
   * @generated from field: repeated geneo.analysis.teacher.apis.LearningOutcomes learning_outcomes = 5;
   */
  learningOutcomes: LearningOutcomes[] = [];

  constructor(data?: PartialMessage<GetChapterAnalysisResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.GetChapterAnalysisResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chapter_performance", kind: "message", T: ChapterPerformanceStats },
    { no: 5, name: "learning_outcomes", kind: "message", T: LearningOutcomes, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetChapterAnalysisResponse {
    return new GetChapterAnalysisResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetChapterAnalysisResponse {
    return new GetChapterAnalysisResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetChapterAnalysisResponse {
    return new GetChapterAnalysisResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetChapterAnalysisResponse | PlainMessage<GetChapterAnalysisResponse> | undefined, b: GetChapterAnalysisResponse | PlainMessage<GetChapterAnalysisResponse> | undefined): boolean {
    return proto3.util.equals(GetChapterAnalysisResponse, a, b);
  }
}

/**
 * @generated from message geneo.analysis.teacher.apis.LearningOutcomes
 */
export class LearningOutcomes extends Message<LearningOutcomes> {
  /**
   * @generated from field: string learning_outcome_id = 1;
   */
  learningOutcomeId = "";

  /**
   * @generated from field: string learing_outcome_type = 2;
   */
  learingOutcomeType = "";

  /**
   * @generated from field: string learning_outcome = 3;
   */
  learningOutcome = "";

  constructor(data?: PartialMessage<LearningOutcomes>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.analysis.teacher.apis.LearningOutcomes";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "learning_outcome_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "learing_outcome_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "learning_outcome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LearningOutcomes {
    return new LearningOutcomes().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LearningOutcomes {
    return new LearningOutcomes().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LearningOutcomes {
    return new LearningOutcomes().fromJsonString(jsonString, options);
  }

  static equals(a: LearningOutcomes | PlainMessage<LearningOutcomes> | undefined, b: LearningOutcomes | PlainMessage<LearningOutcomes> | undefined): boolean {
    return proto3.util.equals(LearningOutcomes, a, b);
  }
}

