// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.teacher.apis.proto (package geneo.analysis.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchHomeworkStatisticsRequest, FetchHomeworkStatisticsResponse, FetchStudentSubjectPerformanceStatsRequest, FetchStudentSubjectPerformanceStatsResponse, GetChapterAnalysisRequest, GetChapterAnalysisResponse, TeacherAnalysisClassSubjectChapterResponse, TeacherAnalysisClassSubjectRequest, TeacherAnalysisClassSubjectResponse, TeacherAnalysisRequest, TeacherAnalysisResponse } from "./analysis.teacher.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1
 */
export const TeacherAnalysisAPIServiceV1 = {
  typeName: "geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1.getOverallAnalysis
     */
    getOverallAnalysis: {
      name: "getOverallAnalysis",
      I: TeacherAnalysisRequest,
      O: TeacherAnalysisResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1.getClassSubjectAnalysis
     */
    getClassSubjectAnalysis: {
      name: "getClassSubjectAnalysis",
      I: TeacherAnalysisClassSubjectRequest,
      O: TeacherAnalysisClassSubjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1.getClassSubjectChapterAnalysis
     */
    getClassSubjectChapterAnalysis: {
      name: "getClassSubjectChapterAnalysis",
      I: TeacherAnalysisClassSubjectRequest,
      O: TeacherAnalysisClassSubjectChapterResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1.fetchStudentSubjectPerformanceStats
     */
    fetchStudentSubjectPerformanceStats: {
      name: "fetchStudentSubjectPerformanceStats",
      I: FetchStudentSubjectPerformanceStatsRequest,
      O: FetchStudentSubjectPerformanceStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1.fetchHomeworkStatistics
     */
    fetchHomeworkStatistics: {
      name: "fetchHomeworkStatistics",
      I: FetchHomeworkStatisticsRequest,
      O: FetchHomeworkStatisticsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.analysis.teacher.apis.TeacherAnalysisAPIServiceV1.getChapterAnalysis
     */
    getChapterAnalysis: {
      name: "getChapterAnalysis",
      I: GetChapterAnalysisRequest,
      O: GetChapterAnalysisResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

