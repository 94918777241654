import { LmsSchoolAdminAPIServiceV1 } from '@protos/learning_management/lms.school.apis_connect';
import { createPromiseClient } from '@bufbuild/connect';
import { ConnectTransportOptions } from '@bufbuild/connect-web';
import {
  ResponseStatusCodeFunction,
  lmsTransport,
  lmsTransportWithLogoutHandler,
} from './transports';

export const LmsSchoolAdminAPIServiceV1Client = createPromiseClient(
  LmsSchoolAdminAPIServiceV1,
  lmsTransport
);

export const useLmsSchoolAdminAPIServiceV1ClientWithStatusCodeHandler = (
  options: Partial<ConnectTransportOptions>,
  responseStatusCodeFunction: ResponseStatusCodeFunction
) =>
  createPromiseClient(
    LmsSchoolAdminAPIServiceV1,
    lmsTransportWithLogoutHandler(options, responseStatusCodeFunction)
  );
