import { Code, ConnectError } from '@bufbuild/connect';
import {
  ActionPopUpSingleButton,
  IStyles,
  InputField,
  InputFieldContainer,
  LinkButton,
  Loader,
  OtpInputs,
  PrimaryButton,
  addOfflineUserRequest,
  deserify,
  findOfflineUserRequestByParamsFromCache,
  generatePasswordHash,
  getDeviceType,
  pushUserDetailsV1ToAndroid,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  LoginTypeEnum,
  OTPEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import {
  StudentLoginResponse,
  StudentLoginResponseType,
  StudentLoginWithPhoneOrEmailPasswordResponse,
} from '@protos/user_management/ums.login.apis_pb';
// import useNotificationPermission from 'libs/shared-ui/src/commonUtils/Notification';
import { getSessionData } from '@events/student/eventsCreator/utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  CHOOSE_PROFILE,
  ENTER_UID,
  HOME,
  RESET_PASSWORD_OTP,
} from '../../../routeHandling/RoutesNomenclature';
import ConfigSelector from '../../OfflineScreens/Learn/components/ConfigSelector';
import {
  setIsLoggedInOffline,
  setMultipleProfiles,
  setOtpInfo,
  setToastInfo,
  setUserInfo,
} from '../reducer/auth.slice';
import { aiLoginEvent, interactionEvent } from './login_logout.events';

const styles: IStyles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'flex-start', md: 'center' },
    position: 'relative',
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(5),
    textAlign: { xs: 'left', md: 'center' },
    flexGrow: 1,
    marginBottom: { xs: pxToRem(25), md: pxTovW(41) },
  },
  inputContainer: {
    mt: { xs: pxToRem(50), md: pxTovW(60) },
  },
  secondaryButtonBox: {
    width: { xs: '100%', md: pxTovW(517) },
    height: { xs: pxToRem(55), md: pxTovW(77) },
    margin: 'auto',
    mt: { xs: pxToRem(91), md: pxTovW(40) },
  },
  helperText: {
    bgcolor: '#FFDFDF',
    color: 'error.main',
    mt: pxToRem(4),
    padding: { xs: pxToRem(10), md: `${pxTovW(12)} ${pxTovW(30)}` },
  },
  helperText2: {
    height: { xs: pxToRem(12), md: pxTovW(20) },
  },
};
interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

// Declare the Android object
declare global {
  interface Window {
    Android: any;
  }
}

interface ILoginNew {
  isVk12School?: boolean;
}

export const LoginNew = (props: ILoginNew) => {
  const { isVk12School } = props;
  const [otp, setotp] = useState('');
  const [otpBoxDisplay, setOtpBoxDisplay] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [otpStatus, setOtpStatus] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [actionPopupState, setActionPopupState] = useState(false);
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const [showPassword, setShowPassword] = useState(false);
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });
  const android = window.Android;
  console.log(window.Android, 'android');
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );

  const navigate = useNavigate();
  const getCurrentTime = () => new Date().getTime();
  const {
    login_Type,
    login_uid,
    login_email,
    login_phone,
    otp_info,
    user_validation_info,
    is_mcb_user,
  } = deserify(useAppSelector((state) => state.auth));
  const { isOffline } = useDownloadContext();
  const { permissionGranted, requestPermission } =
    useNotificationPermission('student');
  const handlePasswordLogin = async () => {
    setLoading('loading');
    const passwordHash = generatePasswordHash(password);
    try {
      let response:
        | StudentLoginWithPhoneOrEmailPasswordResponse
        | StudentLoginResponse
        | undefined;
      if (login_Type === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
        response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.studentLoginWithUsername(
            {
              userName: login_uid,
              password: password,
            }
          );
      } else if (login_Type === LoginTypeEnum.LOGIN_TYPE_EMAIL) {
        response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.studentLoginWithEmailPassword(
            {
              email: login_email,
              password: password,
            }
          );
      } else if (login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER) {
        response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.studentLoginWithPhonePassword(
            {
              phoneNumber: login_phone,
              password: password,
            }
          );
      } else {
        response = undefined;
      }
      if (response) {
        if (response.activeSessionId) {
          setLocalStorage('activeSession', response.activeSessionId.toString());
          const currentTime = getCurrentTime();
          setLocalStorage('lastUpdatedTime', String(currentTime));
        }
        if (response.profileLoginDetailsId) {
          setLocalStorage(
            'profileLoginDetailsId',
            response.profileLoginDetailsId.toString()
          );
        }
        const androidData = {
          profileLoginDetailsId: response.profileLoginDetailsId.toString(),
          activeSessionId: response.activeSessionId?.toString() || '',
        };

        if (response.data && response.status !== 0) {
          setLoading('completed');
          if (Array.isArray(response.data)) {
            for (let i = 0; i < response.data.length; i++) {
              const user = response.data[i];
              await addOfflineUserRequest(user, passwordHash);
            }
            if (response.data.length === 1) {
              const userInfo = response.data[0];
              console.log(userInfo);

              dispatch(setUserInfo(userInfo));
              setLocalStorage('userId', userInfo.studentProfileId.toString());
              setLocalStorage('role', 'student');
              setLocalStorage('auth', 'true');
              const accessKey = userInfo.studentProfileId
                ? 'student-' + userInfo.studentProfileId.toString()
                : 'geneo';
              await storeOfflineAccessKeyInCache(accessKey);
              navigate(HOME);
              setTimeout(async () => {
                await interactionEvent({
                  url: 'student_login',
                  context: 'navigation_bar',
                  name: 'Login',
                  isOffline: isOffline,
                });
              }, 3000);
              await aiLoginEvent({
                studentId: Number(userInfo.studentProfileId),
                sessionId: getSessionData().sessionId,
                classId: userInfo.classSectionDetails?.classId,
                sectionId: userInfo.classSectionDetails?.sectionId,
                schoolId: userInfo.schoolDetails?.schoolId,
                boardId: userInfo.boardMediumDetails?.boardId,
                regionId: userInfo.regionId,
                mediumId: userInfo.boardMediumDetails?.mediumId,
                isOffline: isOffline,
              });
              pushUserDetailsToAndroid(
                userInfo.token,
                userInfo.studentProfileId.toString()
              );
              pushUserDetailsV1ToAndroid(
                userInfo.studentProfileId.toString(),
                userInfo.token,
                androidData.profileLoginDetailsId,
                androidData.activeSessionId
              );
              navigate(HOME);
            } else {
              dispatch(setMultipleProfiles(response.data));
              navigate(CHOOSE_PROFILE);
            }
          } else {
            const userInfo = response.data;

            dispatch(setUserInfo(userInfo));
            await addOfflineUserRequest(userInfo, passwordHash);
            setLocalStorage('userId', userInfo.studentProfileId.toString());
            setLocalStorage('role', 'student');
            setLocalStorage('auth', 'true');
            const accessKey = userInfo.studentProfileId
              ? 'student-' + userInfo.studentProfileId.toString()
              : 'geneo';
            await storeOfflineAccessKeyInCache(accessKey);
            setTimeout(async () => {
              await interactionEvent({
                url: 'student_login',
                context: 'navigation_bar',
                name: 'Login',
                isOffline: isOffline,
              });
            }, 3000);
            await aiLoginEvent({
              studentId: Number(userInfo.studentProfileId),
              sessionId: getSessionData().sessionId,
              classId: userInfo.classSectionDetails?.classId,
              sectionId: userInfo.classSectionDetails?.sectionId,
              schoolId: userInfo.schoolDetails?.schoolId,
              boardId: userInfo.boardMediumDetails?.boardId,
              regionId: userInfo.regionId,
              mediumId: userInfo.boardMediumDetails?.mediumId,
              isOffline: isOffline,
            });
            pushUserDetailsToAndroid(
              userInfo.token,
              userInfo.studentProfileId.toString()
            );
            pushUserDetailsV1ToAndroid(
              userInfo.studentProfileId.toString(),
              userInfo.token,
              androidData.profileLoginDetailsId,
              androidData.activeSessionId
            );
            navigate(HOME);
          }
          if (deviceType !== 'android') {
            await requestPermission();
          }
        } else {
          setLoading('error');
          setPasswordError('Incorrect Password');
        }
      } else {
        setLoading('error');
        console.error('Invalid request');
        return;
      }
    } catch (error: any) {
      if (error instanceof ConnectError) {
        if (error.code === Code.Unknown || error.code === Code.Internal) {
          if (login_Type === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
            const searchParams = {
              username: login_uid,
              passwordHash,
            };
            const user = await findOfflineUserRequestByParamsFromCache(
              searchParams
            );
            if (user) {
              const userFound = new StudentLoginResponseType().fromJsonString(
                user || ''
              );
              dispatch(setUserInfo(userFound));
              setLocalStorage('userId', userFound.studentProfileId.toString());
              setLocalStorage('role', 'teacher');
              setLocalStorage('auth', 'true');
              const accessKey = userFound.studentProfileId.toString()
                ? 'student-' + userFound.studentProfileId.toString()
                : 'geneo';
              await storeOfflineAccessKeyInCache(accessKey);
              dispatch(setIsLoggedInOffline(true));
              await aiLoginEvent({
                studentId: Number(userFound.studentProfileId),
                sessionId: getSessionData().sessionId,
                classId: userFound.classSectionDetails?.classId,
                sectionId: userFound.classSectionDetails?.sectionId,
                schoolId: userFound.schoolDetails?.schoolId,
                boardId: userFound.boardMediumDetails?.boardId,
                regionId: userFound.regionId,
                mediumId: userFound.boardMediumDetails?.mediumId,
                isOffline: isOffline,
              });
              await interactionEvent({
                url: 'student_Offlinelogin',
                context: 'LoginOffline_Navigation_bar',
                name: 'Login',
                isOffline: isOffline,
              });
              setLoading('completed');
              navigate(HOME);
              return;
            }
          }
        }
      }
      setLoading('error');
      const message = error.message
        ? error.message.replace(/.*\]\s*/, '').trim()
        : undefined;
      if (message) {
        setPasswordError(message);
      }
      console.log(error);
    }
  };

  // // Check device type
  const deviceType = getDeviceType();

  function pushUserDetailsToAndroid(uToken: string, userID: string): void {
    try {
      if (deviceType === 'android' && android?.pushUserDetails) {
        android.pushUserDetails(uToken, userID);
      }
    } catch (error) {
      console.log('Error pushing user details to Android', error);
    }
  }

  const handleOtpLogin = async () => {
    setLoading('loading');
    try {
      const check =
        login_Type === LoginTypeEnum.LOGIN_TYPE_EMAIL
          ? login_email
          : login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER
          ? login_phone
          : undefined;
      if (otp_info && check) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.verifyOTP({
            otp: otp,
            verificationCode: otp_info.verification_code,
            check: check,
          });

        if (response.data && response.data.studentProfiles.length > 0) {
          setLoading('completed');
          if (response.data.studentProfiles.length === 1) {
            dispatch(setUserInfo(response.data.studentProfiles[0]));
            setLocalStorage(
              'userId',
              response.data.studentProfiles[0].studentProfileId.toString()
            );
            setLocalStorage('role', 'student');
            setLocalStorage('auth', 'true');
            await aiLoginEvent({
              studentId: Number(
                response.data.studentProfiles[0].studentProfileId
              ),
              sessionId: getSessionData().sessionId,
              classId:
                response.data.studentProfiles[0].classSectionDetails?.classId,
              sectionId:
                response.data.studentProfiles[0].classSectionDetails?.sectionId,
              schoolId:
                response.data.studentProfiles[0].schoolDetails?.schoolId,
              boardId:
                response.data.studentProfiles[0].boardMediumDetails?.boardId,
              regionId: response.data.studentProfiles[0].regionId,
              mediumId:
                response.data.studentProfiles[0].boardMediumDetails?.mediumId,
              isOffline: isOffline,
            });

            await interactionEvent({
              url: 'Student_login_new',
              context: 'navigation_bar',
              name: 'START',
              isOffline: isOffline,
            });
            pushUserDetailsToAndroid(
              response?.data?.token,
              response?.data?.studentProfiles[0].studentProfileId.toString()
            );

            pushUserDetailsV1ToAndroid(
              response.data.studentProfiles[0].studentProfileId.toString(),
              response.data.token,
              response.profileLoginDetailsId.toString(),
              response.activeSessionId?.toString() || ''
            );

            navigate(HOME);
            if (deviceType !== 'android') {
              await requestPermission();
            }
          } else {
            dispatch(setMultipleProfiles(response.data.studentProfiles));
            navigate(CHOOSE_PROFILE);
          }
        } else {
          setLoading('error');
          setOtpError(true);
        }
      }
    } catch (error: any) {
      setLoading('error');
      if (error.message) {
        const str: string = error.message.replace(/.*\]\s*/, '').trim();
        dispatch(
          setToastInfo({
            label: str,
            variant: 'error',
            open: true,
          })
        );
      }
      console.log(error);
    }
  };
  const sendOtp = async () => {
    setSeconds(30);
    try {
      if (login_Type === LoginTypeEnum.LOGIN_TYPE_EMAIL) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getEmailOTP({
            email: login_email,
            otpType: OTPEnum.STUDENT_LOGIN,
          });
        if (response.status === 200) {
          dispatch(
            setToastInfo({
              label: 'otp sent to your email',
              variant: 'success',
              open: true,
            })
          );
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.STUDENT_LOGIN,
            })
          );
        } else {
          dispatch(
            setToastInfo({
              label: 'invalid email id',
              variant: 'error',
              open: true,
            })
          );
        }
      } else if (login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
            phoneNumber: login_phone,
            otpType: OTPEnum.STUDENT_LOGIN,
          });
        if (response.status === 200) {
          dispatch(
            setToastInfo({
              label: 'otp sent to your phone',
              variant: 'success',
              open: true,
            })
          );
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.STUDENT_LOGIN,
            })
          );
        } else {
          dispatch(
            setToastInfo({
              label: 'invalid email id',
              variant: 'error',
              open: true,
            })
          );
        }
      }
    } catch (error: any) {
      console.log(error);
      dispatch(
        setToastInfo({
          label: error.message
            ? `${error.message.replace(/\[.*?\]/g, '')}`
            : 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const handleForgotPassword = async () => {
    try {
      await interactionEvent({
        url: 'Student_login_new',
        context: 'navigation_bar',
        name: 'FORGOT_PASSWORD',
        isOffline: isOffline,
      });

      setLoading('loading');
      const resetRequestData = {
        loginType: login_Type,
        otpType: OTPEnum.STUDENT_RESET_PASSWORD,
        role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        userLoginInput: login_phone,
      };

      if (
        login_Type === LoginTypeEnum.LOGIN_TYPE_EMAIL ||
        login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER
      ) {
        resetRequestData.userLoginInput = login_phone || login_email;
      } else if (login_Type === LoginTypeEnum.LOGIN_TYPE_USERNAME) {
        if (
          !user_validation_info?.phoneVerify &&
          !user_validation_info?.emailVerify
        ) {
          setLoading('completed');
          setPopupDetails({
            iconName: 'costumer-service',
            buttontext: 'ok',
            popupText: 'Please Contact Your School Admin for Registration',
            ctaHandler: () => setActionPopupState(false),
          });
          setActionPopupState(true);
          return;
        } else {
          resetRequestData.userLoginInput = login_uid;
        }
      } else {
        setLoading('completed');
        setPopupDetails({
          iconName: 'costumer-service',
          buttontext: 'ok',
          popupText: 'No login type detected.',
          ctaHandler: () => setActionPopupState(false),
        });
        setActionPopupState(true);
        return;
      }
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.resetPasswordInit(
          resetRequestData
        );
      setLoading('completed');
      let messageType: string;

      if (
        login_Type === LoginTypeEnum.LOGIN_TYPE_EMAIL ||
        login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER
      ) {
        messageType =
          login_Type === LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER
            ? `+91 ${login_phone?.slice(0, 2)}......${login_phone?.slice(-2)}`
            : `${login_email?.slice(0, 4)}......${login_email?.slice(-8)}`;
      } else {
        if (
          user_validation_info?.phoneVerify === 1 &&
          user_validation_info.emailVerify === 1
        ) {
          messageType = `${user_validation_info.email.slice(
            0,
            4
          )}.....${user_validation_info.email?.slice(
            -8
          )} and +91 ${user_validation_info.phoneNumber?.slice(
            0,
            2
          )}.......${user_validation_info.phoneNumber?.slice(-2)}`;
        } else if (
          user_validation_info?.phoneVerify === 1 ||
          user_validation_info?.emailVerify === 1
        ) {
          messageType =
            user_validation_info.phoneVerify === 1
              ? `+91 ${user_validation_info.phoneNumber?.slice(
                  0,
                  2
                )}.......${user_validation_info.phoneNumber?.slice(-2)}`
              : `${user_validation_info.email.slice(
                  0,
                  4
                )}.....${user_validation_info.email?.slice(-8)}`;
        } else {
          messageType = '';
        }
      }

      if (response.verificationCode) {
        dispatch(
          setOtpInfo({
            verification_code: response.verificationCode,
            otp_type: OTPEnum.STUDENT_LOGIN,
          })
        );
        setPopupDetails({
          iconName: 'reset-password',
          buttontext: 'ok',
          popupText: `A verification code was just sent to `,
          splitText: messageType,
          ctaHandler: () => {
            setActionPopupState(false);
            navigate(RESET_PASSWORD_OTP);
          },
        });
        setActionPopupState(true);
      } else {
        setPopupDetails({
          iconName: 'costumer-service',
          buttontext: 'ok',
          popupText: 'Please Contact Your School Admin for Registration',
          ctaHandler: () => setActionPopupState(false),
        });
        setActionPopupState(true);
      }
    } catch (error) {
      setLoading('error');
      console.log('Invalid user');
    }
  };
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            // backgroundColor: 'red',
            alignItems: 'flex-end',
            justifyContent: { xs: 'space-between', md: 'center' },
          }}
        >
          <Box sx={styles.heading}>
            <Typography variant="h1" fontWeight="bold">
              Welcome
            </Typography>
            <Typography variant="h1" color="primary" fontWeight="bold">
              {user_validation_info
                ? user_validation_info.firstName
                : 'Student'}
            </Typography>
          </Box>
        </Box>
        {isOffline && (
          <ConfigSelector
            rootStyle={{
              marginTop: 0,
              marginBottom: { xs: pxToRem(25), md: pxTovW(41) },
              width: '100%',
            }}
          />
        )}
        <Box>
          <InputFieldContainer
            helperTextvariant="success"
            topLabel=""
            value={login_uid || login_email || login_phone || ''}
            helperText={otpBoxDisplay ? otpStatus : undefined}
            nonEditable={true}
            editFunction={async () => {
              await interactionEvent({
                url: 'Student_login_new',
                context: 'navigation_bar',
                name: 'EDIT',
                isOffline: isOffline,
              });
              navigate(ENTER_UID);
            }}
          />
        </Box>

        <Box>
          {otpBoxDisplay === true ? (
            <OtpInputs
              enteredOtp={otp}
              otpWrong={otpError}
              setEnteredOtp={setotp}
              otpLength={5}
              onPressEnter={handleOtpLogin}
            />
          ) : (
            <Box>
              <Typography variant="bodyText" fontWeight="bold">
                Enter your password
              </Typography>
              <Box>
                <InputField
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => e.code === 'Enter' && handlePasswordLogin()}
                  variant="outlined"
                  fullWidth
                  boldtext
                  nonCircular
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility
                              sx={{
                                margin: pxToRem(13),
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{
                                margin: pxToRem(13),
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {passwordError ? (
                  <Typography variant="h4" sx={styles.helperText}>
                    {passwordError}
                  </Typography>
                ) : (
                  <Typography sx={styles.helperText2}></Typography>
                )}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              mt:
                otpBoxDisplay === true
                  ? {
                      xs: pxToRem(40),
                      md: pxTovW(45),
                    }
                  : '0px',
              gap: { xs: pxToRem(4), md: pxTovW(4) },
              justifyContent:
                login_Type === LoginTypeEnum.LOGIN_TYPE_USERNAME
                  ? 'center'
                  : 'space-between',
            }}
          >
            {otpBoxDisplay === true ? (
              seconds === 0 ? (
                <LinkButton onClick={sendOtp}>Resend otp</LinkButton>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: pxToRem(4), md: pxTovW(4) },
                  }}
                >
                  <Typography variant="cardText" sx={{ color: '#007CDC' }}>
                    Resend otp in {'  '}
                  </Typography>
                  <Typography
                    sx={{ color: '#0AA34F' }}
                    variant="bodyText"
                    fontWeight="bold"
                  >
                    <Timer seconds={seconds} setSeconds={setSeconds} />
                  </Typography>
                </Box>
              )
            ) : (
              !is_mcb_user &&
              !isVk12School && (
                <Box>
                  <Typography
                    variant="h4"
                    fontWeight="regular"
                    sx={{
                      color: '#007CDC',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleForgotPassword()}
                  >
                    Forgot Password?
                  </Typography>
                </Box>
              )
            )}
            {login_Type !== LoginTypeEnum.LOGIN_TYPE_USERNAME && (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (otpBoxDisplay === false) {
                    sendOtp();
                  }
                  setOtpBoxDisplay(!otpBoxDisplay);
                }}
              >
                {otpBoxDisplay === true ? (
                  <Typography
                    variant="cardText"
                    sx={{
                      color: '#007CDC',
                      textDecoration: { md: 'underline' },
                    }}
                  >
                    USE PASSWORD
                  </Typography>
                ) : !isOffline ? (
                  <Typography
                    variant="cardText"
                    sx={{
                      color: '#007CDC',
                      textDecoration: { md: 'underline' },
                    }}
                  >
                    SEND OTP
                  </Typography>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={styles.secondaryButtonBox}>
          <PrimaryButton
            fullWidth
            disabled={otpBoxDisplay ? otp.length === 0 : password.length === 0}
            onClick={otpBoxDisplay ? handleOtpLogin : handlePasswordLogin}
          >
            <Typography variant="h1" color="success.light">
              Start
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
      {/* {deviceType === 'web' && <Notification />} */}
    </Box>
  );
};
interface TimerProps {
  seconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
}
const Timer = (props: TimerProps) => {
  const { seconds, setSeconds } = props;
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <Typography sx={{ color: '#0AA34F' }} variant="bodyText" fontWeight="bold">
      {seconds}
    </Typography>
  );
};
