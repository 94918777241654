// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.student.apis.proto (package geneo.lms.assessment.student.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateStudentAssessmentSessionRequest, CreateStudentAssessmentSessionResponse, FetchStudentAssessmentResponseFetchRequest, FetchStudentAssessmentResponseFetchResponse, GetStudentAssessmentListRequest, GetStudentAssessmentListResponse, SubmitAssessmentResponseSubmitRequest, SubmitAssessmentResponseSubmitResponse, SubmitStudentAssessmentResponseSubmitRequest, SubmitStudentAssessmentResponseSubmitResponse } from "./lms.assessment.student.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV1
 */
export const LmsAssessmentStudentAPIServiceV1 = {
  typeName: "geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV1.getStudentAssessmentList
     */
    getStudentAssessmentList: {
      name: "getStudentAssessmentList",
      I: GetStudentAssessmentListRequest,
      O: GetStudentAssessmentListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV1.createStudentAssessmentSession
     */
    createStudentAssessmentSession: {
      name: "createStudentAssessmentSession",
      I: CreateStudentAssessmentSessionRequest,
      O: CreateStudentAssessmentSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV1.submitAssessmentResponse
     */
    submitAssessmentResponse: {
      name: "submitAssessmentResponse",
      I: SubmitAssessmentResponseSubmitRequest,
      O: SubmitAssessmentResponseSubmitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV1.fetchStudentAssessmentResponse
     */
    fetchStudentAssessmentResponse: {
      name: "fetchStudentAssessmentResponse",
      I: FetchStudentAssessmentResponseFetchRequest,
      O: FetchStudentAssessmentResponseFetchResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV2
 */
export const LmsAssessmentStudentAPIServiceV2 = {
  typeName: "geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV2",
  methods: {
    /**
     * @generated from rpc geneo.lms.assessment.student.apis.LmsAssessmentStudentAPIServiceV2.submitStudentAssessmentResponse
     */
    submitStudentAssessmentResponse: {
      name: "submitStudentAssessmentResponse",
      I: SubmitStudentAssessmentResponseSubmitRequest,
      O: SubmitStudentAssessmentResponseSubmitResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

