// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.hw.teacher.apis.proto (package geneo.lms.hw.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { CloudUpload, MetricsModel, StudentAssessmentResponse, SubmissionType, TaskCreationStatusEnum, TaskEnum, TaskEvaluationStatusEnum, TaskStudentAttemptStatusEnum, TaskTeacherReview } from "@protos/learning_management/lms.db_pb";
import { HomeworkTask } from "@protos/learning_management/lms.hw.common.apis_pb";
import { DifficultyLevelEnum, Module_ModuleCategoryEnum, Question, Question_QuestionEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from enum geneo.lms.hw.teacher.apis.HomeworkListEnum
 */
export enum HomeworkListEnum {
  /**
   * @generated from enum value: HOMEWORK_UNDEFINED = 0;
   */
  HOMEWORK_UNDEFINED = 0,

  /**
   * @generated from enum value: HOMEWORK_ACTIVE = 1;
   */
  HOMEWORK_ACTIVE = 1,

  /**
   * @generated from enum value: HOMEWORK_ENDED = 2;
   */
  HOMEWORK_ENDED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(HomeworkListEnum)
proto3.util.setEnumType(HomeworkListEnum, "geneo.lms.hw.teacher.apis.HomeworkListEnum", [
  { no: 0, name: "HOMEWORK_UNDEFINED" },
  { no: 1, name: "HOMEWORK_ACTIVE" },
  { no: 2, name: "HOMEWORK_ENDED" },
]);

/**
 * @generated from message geneo.lms.hw.teacher.apis.ScheduledMsgsRequest
 */
export class ScheduledMsgsRequest extends Message<ScheduledMsgsRequest> {
  /**
   * @generated from field: string topic = 1;
   */
  topic = "";

  /**
   * @generated from field: string homework_title = 2;
   */
  homeworkTitle = "";

  /**
   * @generated from field: string reminder_type = 3;
   */
  reminderType = "";

  /**
   * @generated from field: google.protobuf.Timestamp daily_reminder_time = 5;
   */
  dailyReminderTime?: Timestamp;

  constructor(data?: PartialMessage<ScheduledMsgsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.ScheduledMsgsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "homework_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "reminder_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "daily_reminder_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduledMsgsRequest {
    return new ScheduledMsgsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduledMsgsRequest {
    return new ScheduledMsgsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduledMsgsRequest {
    return new ScheduledMsgsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduledMsgsRequest | PlainMessage<ScheduledMsgsRequest> | undefined, b: ScheduledMsgsRequest | PlainMessage<ScheduledMsgsRequest> | undefined): boolean {
    return proto3.util.equals(ScheduledMsgsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.ScheduledMsgsResponse
 */
export class ScheduledMsgsResponse extends Message<ScheduledMsgsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<ScheduledMsgsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.ScheduledMsgsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduledMsgsResponse {
    return new ScheduledMsgsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduledMsgsResponse {
    return new ScheduledMsgsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduledMsgsResponse {
    return new ScheduledMsgsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduledMsgsResponse | PlainMessage<ScheduledMsgsResponse> | undefined, b: ScheduledMsgsResponse | PlainMessage<ScheduledMsgsResponse> | undefined): boolean {
    return proto3.util.equals(ScheduledMsgsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.StudentHWPhysicalSubmissionUpdateRequest
 */
export class StudentHWPhysicalSubmissionUpdateRequest extends Message<StudentHWPhysicalSubmissionUpdateRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: repeated geneo.lms.hw.teacher.apis.StudentSubmissionInfo submissions_info = 3;
   */
  submissionsInfo: StudentSubmissionInfo[] = [];

  /**
   * @generated from field: optional float max_marks = 4;
   */
  maxMarks?: number;

  constructor(data?: PartialMessage<StudentHWPhysicalSubmissionUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.StudentHWPhysicalSubmissionUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "submissions_info", kind: "message", T: StudentSubmissionInfo, repeated: true },
    { no: 4, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWPhysicalSubmissionUpdateRequest {
    return new StudentHWPhysicalSubmissionUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWPhysicalSubmissionUpdateRequest {
    return new StudentHWPhysicalSubmissionUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWPhysicalSubmissionUpdateRequest {
    return new StudentHWPhysicalSubmissionUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWPhysicalSubmissionUpdateRequest | PlainMessage<StudentHWPhysicalSubmissionUpdateRequest> | undefined, b: StudentHWPhysicalSubmissionUpdateRequest | PlainMessage<StudentHWPhysicalSubmissionUpdateRequest> | undefined): boolean {
    return proto3.util.equals(StudentHWPhysicalSubmissionUpdateRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.StudentSubmissionInfo
 */
export class StudentSubmissionInfo extends Message<StudentSubmissionInfo> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: bool is_submitted = 2;
   */
  isSubmitted = false;

  /**
   * @generated from field: optional float obtained_marks = 3;
   */
  obtainedMarks?: number;

  constructor(data?: PartialMessage<StudentSubmissionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.StudentSubmissionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "is_submitted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "obtained_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentSubmissionInfo {
    return new StudentSubmissionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentSubmissionInfo {
    return new StudentSubmissionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentSubmissionInfo {
    return new StudentSubmissionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: StudentSubmissionInfo | PlainMessage<StudentSubmissionInfo> | undefined, b: StudentSubmissionInfo | PlainMessage<StudentSubmissionInfo> | undefined): boolean {
    return proto3.util.equals(StudentSubmissionInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.StudentHWPhysicalSubmissionUpdateResponse
 */
export class StudentHWPhysicalSubmissionUpdateResponse extends Message<StudentHWPhysicalSubmissionUpdateResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<StudentHWPhysicalSubmissionUpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.StudentHWPhysicalSubmissionUpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentHWPhysicalSubmissionUpdateResponse {
    return new StudentHWPhysicalSubmissionUpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentHWPhysicalSubmissionUpdateResponse {
    return new StudentHWPhysicalSubmissionUpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentHWPhysicalSubmissionUpdateResponse {
    return new StudentHWPhysicalSubmissionUpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentHWPhysicalSubmissionUpdateResponse | PlainMessage<StudentHWPhysicalSubmissionUpdateResponse> | undefined, b: StudentHWPhysicalSubmissionUpdateResponse | PlainMessage<StudentHWPhysicalSubmissionUpdateResponse> | undefined): boolean {
    return proto3.util.equals(StudentHWPhysicalSubmissionUpdateResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.UpdateHWTeacherReviewRequest
 */
export class UpdateHWTeacherReviewRequest extends Message<UpdateHWTeacherReviewRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: int64 student_id = 3;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string question_id = 4;
   */
  questionId = "";

  /**
   * @generated from field: int32 sessionId = 5;
   */
  sessionId = 0;

  /**
   * @generated from field: repeated string remarks = 6;
   */
  remarks: string[] = [];

  /**
   * @generated from field: float obtained_marks = 7;
   */
  obtainedMarks = 0;

  constructor(data?: PartialMessage<UpdateHWTeacherReviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.UpdateHWTeacherReviewRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sessionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "remarks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "obtained_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateHWTeacherReviewRequest {
    return new UpdateHWTeacherReviewRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateHWTeacherReviewRequest {
    return new UpdateHWTeacherReviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateHWTeacherReviewRequest {
    return new UpdateHWTeacherReviewRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateHWTeacherReviewRequest | PlainMessage<UpdateHWTeacherReviewRequest> | undefined, b: UpdateHWTeacherReviewRequest | PlainMessage<UpdateHWTeacherReviewRequest> | undefined): boolean {
    return proto3.util.equals(UpdateHWTeacherReviewRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.UpdateHWTeacherReviewResponse
 */
export class UpdateHWTeacherReviewResponse extends Message<UpdateHWTeacherReviewResponse> {
  /**
   * Primary key for task_student_assignment
   *
   * @generated from field: int32 task_student_assignment_id = 1;
   */
  taskStudentAssignmentId = 0;

  /**
   * Foreign key referencing task.task_id
   *
   * @generated from field: optional string task_id = 2;
   */
  taskId?: string;

  /**
   * Student's user ID
   *
   * @generated from field: optional int64 student_id = 3;
   */
  studentId?: bigint;

  /**
   * Student group ID (foreign key referencing student_group.student_group_id)
   *
   * @generated from field: optional int32 student_group_id = 4;
   */
  studentGroupId?: number;

  /**
   * Target date for task completion by the student
   *
   * @generated from field: google.protobuf.Timestamp task_target_date = 5;
   */
  taskTargetDate?: Timestamp;

  /**
   * Task status for the student (enum: task_student_status_enum)
   *
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum task_student_status = 6;
   */
  taskStudentStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * Task evaluation status for the student (enum: task_evaluation_enum)
   *
   * @generated from field: geneo.lms.db.TaskEvaluationStatusEnum task_evaluation_status = 17;
   */
  taskEvaluationStatus = TaskEvaluationStatusEnum.TASK_EVALUATION_STATUS_UNDEFINED;

  /**
   * Metrics related to the student's task performance in JSONB format
   *
   * @generated from field: geneo.lms.db.MetricsModel metrics = 8;
   */
  metrics?: MetricsModel;

  /**
   * Student's response to the task in JSONB format
   *
   * @generated from field: repeated geneo.lms.db.StudentAssessmentResponse response = 9;
   */
  response: StudentAssessmentResponse[] = [];

  /**
   * Teacher's review of the student's task in JSONB format
   *
   * @generated from field: geneo.lms.db.TaskTeacherReview teacher_review = 10;
   */
  teacherReview?: TaskTeacherReview;

  constructor(data?: PartialMessage<UpdateHWTeacherReviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.UpdateHWTeacherReviewResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_student_assignment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "task_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 4, name: "student_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "task_target_date", kind: "message", T: Timestamp },
    { no: 6, name: "task_student_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 17, name: "task_evaluation_status", kind: "enum", T: proto3.getEnumType(TaskEvaluationStatusEnum) },
    { no: 8, name: "metrics", kind: "message", T: MetricsModel },
    { no: 9, name: "response", kind: "message", T: StudentAssessmentResponse, repeated: true },
    { no: 10, name: "teacher_review", kind: "message", T: TaskTeacherReview },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateHWTeacherReviewResponse {
    return new UpdateHWTeacherReviewResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateHWTeacherReviewResponse {
    return new UpdateHWTeacherReviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateHWTeacherReviewResponse {
    return new UpdateHWTeacherReviewResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateHWTeacherReviewResponse | PlainMessage<UpdateHWTeacherReviewResponse> | undefined, b: UpdateHWTeacherReviewResponse | PlainMessage<UpdateHWTeacherReviewResponse> | undefined): boolean {
    return proto3.util.equals(UpdateHWTeacherReviewResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.GetModuleQuestionStatsRequest
 */
export class GetModuleQuestionStatsRequest extends Message<GetModuleQuestionStatsRequest> {
  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  constructor(data?: PartialMessage<GetModuleQuestionStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.GetModuleQuestionStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleQuestionStatsRequest {
    return new GetModuleQuestionStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleQuestionStatsRequest {
    return new GetModuleQuestionStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleQuestionStatsRequest {
    return new GetModuleQuestionStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleQuestionStatsRequest | PlainMessage<GetModuleQuestionStatsRequest> | undefined, b: GetModuleQuestionStatsRequest | PlainMessage<GetModuleQuestionStatsRequest> | undefined): boolean {
    return proto3.util.equals(GetModuleQuestionStatsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.GetModuleQuestionResponse
 */
export class GetModuleQuestionResponse extends Message<GetModuleQuestionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.QuestionStatsList data = 4;
   */
  data?: QuestionStatsList;

  constructor(data?: PartialMessage<GetModuleQuestionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.GetModuleQuestionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: QuestionStatsList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleQuestionResponse {
    return new GetModuleQuestionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleQuestionResponse {
    return new GetModuleQuestionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleQuestionResponse {
    return new GetModuleQuestionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleQuestionResponse | PlainMessage<GetModuleQuestionResponse> | undefined, b: GetModuleQuestionResponse | PlainMessage<GetModuleQuestionResponse> | undefined): boolean {
    return proto3.util.equals(GetModuleQuestionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.QuestionStatsList
 */
export class QuestionStatsList extends Message<QuestionStatsList> {
  /**
   * @generated from field: int32 no_of_questions = 4;
   */
  noOfQuestions = 0;

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.DifficutlyLevelQuestions question_difficulty_level = 5;
   */
  questionDifficultyLevel?: DifficutlyLevelQuestions;

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.QuestionTypeCount question_types = 6;
   */
  questionTypes?: QuestionTypeCount;

  constructor(data?: PartialMessage<QuestionStatsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.QuestionStatsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "question_difficulty_level", kind: "message", T: DifficutlyLevelQuestions },
    { no: 6, name: "question_types", kind: "message", T: QuestionTypeCount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionStatsList {
    return new QuestionStatsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionStatsList {
    return new QuestionStatsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionStatsList {
    return new QuestionStatsList().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionStatsList | PlainMessage<QuestionStatsList> | undefined, b: QuestionStatsList | PlainMessage<QuestionStatsList> | undefined): boolean {
    return proto3.util.equals(QuestionStatsList, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.DifficutlyLevelQuestions
 */
export class DifficutlyLevelQuestions extends Message<DifficutlyLevelQuestions> {
  /**
   * @generated from field: int32 no_of_high_questions = 1;
   */
  noOfHighQuestions = 0;

  /**
   * @generated from field: int32 no_of_low_questions = 2;
   */
  noOfLowQuestions = 0;

  /**
   * @generated from field: int32 no_of_medium_questions = 3;
   */
  noOfMediumQuestions = 0;

  constructor(data?: PartialMessage<DifficutlyLevelQuestions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.DifficutlyLevelQuestions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "no_of_high_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "no_of_low_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "no_of_medium_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DifficutlyLevelQuestions {
    return new DifficutlyLevelQuestions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DifficutlyLevelQuestions {
    return new DifficutlyLevelQuestions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DifficutlyLevelQuestions {
    return new DifficutlyLevelQuestions().fromJsonString(jsonString, options);
  }

  static equals(a: DifficutlyLevelQuestions | PlainMessage<DifficutlyLevelQuestions> | undefined, b: DifficutlyLevelQuestions | PlainMessage<DifficutlyLevelQuestions> | undefined): boolean {
    return proto3.util.equals(DifficutlyLevelQuestions, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.QuestionTypeCount
 */
export class QuestionTypeCount extends Message<QuestionTypeCount> {
  /**
   * @generated from field: int32 no_of_mcqs = 1;
   */
  noOfMcqs = 0;

  /**
   * @generated from field: int32 no_of_mcqm = 2;
   */
  noOfMcqm = 0;

  /**
   * @generated from field: int32 no_of_fib = 3;
   */
  noOfFib = 0;

  /**
   * @generated from field: int32 no_of_tf = 4;
   */
  noOfTf = 0;

  /**
   * @generated from field: int32 no_of_very_short = 5;
   */
  noOfVeryShort = 0;

  /**
   * @generated from field: int32 no_of_short = 6;
   */
  noOfShort = 0;

  /**
   * @generated from field: int32 no_of_long = 7;
   */
  noOfLong = 0;

  constructor(data?: PartialMessage<QuestionTypeCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.QuestionTypeCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "no_of_mcqs", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "no_of_mcqm", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "no_of_fib", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "no_of_tf", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "no_of_very_short", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "no_of_short", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "no_of_long", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionTypeCount {
    return new QuestionTypeCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionTypeCount {
    return new QuestionTypeCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionTypeCount {
    return new QuestionTypeCount().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionTypeCount | PlainMessage<QuestionTypeCount> | undefined, b: QuestionTypeCount | PlainMessage<QuestionTypeCount> | undefined): boolean {
    return proto3.util.equals(QuestionTypeCount, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.SendHWReminderRequest
 */
export class SendHWReminderRequest extends Message<SendHWReminderRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  constructor(data?: PartialMessage<SendHWReminderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.SendHWReminderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendHWReminderRequest {
    return new SendHWReminderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendHWReminderRequest {
    return new SendHWReminderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendHWReminderRequest {
    return new SendHWReminderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendHWReminderRequest | PlainMessage<SendHWReminderRequest> | undefined, b: SendHWReminderRequest | PlainMessage<SendHWReminderRequest> | undefined): boolean {
    return proto3.util.equals(SendHWReminderRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.SendHWReminderResponse
 */
export class SendHWReminderResponse extends Message<SendHWReminderResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<SendHWReminderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.SendHWReminderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendHWReminderResponse {
    return new SendHWReminderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendHWReminderResponse {
    return new SendHWReminderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendHWReminderResponse {
    return new SendHWReminderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendHWReminderResponse | PlainMessage<SendHWReminderResponse> | undefined, b: SendHWReminderResponse | PlainMessage<SendHWReminderResponse> | undefined): boolean {
    return proto3.util.equals(SendHWReminderResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TaskInfoModel
 */
export class TaskInfoModel extends Message<TaskInfoModel> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: int32 sequence = 2;
   */
  sequence = 0;

  constructor(data?: PartialMessage<TaskInfoModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TaskInfoModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromJsonString(jsonString, options);
  }

  static equals(a: TaskInfoModel | PlainMessage<TaskInfoModel> | undefined, b: TaskInfoModel | PlainMessage<TaskInfoModel> | undefined): boolean {
    return proto3.util.equals(TaskInfoModel, a, b);
  }
}

/**
 * Message for teacher's homework creation submit request
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWCreationSubmitRequest
 */
export class TeacherHWCreationSubmitRequest extends Message<TeacherHWCreationSubmitRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * ID of the school class section
   *
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * Name of the task
   *
   * @generated from field: string task_name = 4;
   */
  taskName = "";

  /**
   * ID of the chapter
   *
   * @generated from field: int32 chapter_id = 5;
   */
  chapterId = 0;

  /**
   * ID of the topic
   *
   * @generated from field: int32 topic_id = 6;
   */
  topicId = 0;

  /**
   * Information about selected tasks
   *
   * @generated from field: repeated geneo.lms.hw.teacher.apis.TaskInfoModel selected_tasks_info = 7;
   */
  selectedTasksInfo: TaskInfoModel[] = [];

  /**
   * ID of the homework (optional)
   *
   * @generated from field: int32 homework_id = 8;
   */
  homeworkId = 0;

  /**
   * Instructions for the homework (optional)
   *
   * @generated from field: repeated string instructions = 9;
   */
  instructions: string[] = [];

  /**
   * Status of the creation process
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum creation_status = 10;
   */
  creationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<TeacherHWCreationSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWCreationSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "task_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "selected_tasks_info", kind: "message", T: TaskInfoModel, repeated: true },
    { no: 8, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWCreationSubmitRequest {
    return new TeacherHWCreationSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmitRequest {
    return new TeacherHWCreationSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmitRequest {
    return new TeacherHWCreationSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWCreationSubmitRequest | PlainMessage<TeacherHWCreationSubmitRequest> | undefined, b: TeacherHWCreationSubmitRequest | PlainMessage<TeacherHWCreationSubmitRequest> | undefined): boolean {
    return proto3.util.equals(TeacherHWCreationSubmitRequest, a, b);
  }
}

/**
 * Message for teacher's homework creation submit request
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWCreationSubmitRequestV2
 */
export class TeacherHWCreationSubmitRequestV2 extends Message<TeacherHWCreationSubmitRequestV2> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * ID of the school class section
   *
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * Name of the task
   *
   * @generated from field: string task_name = 4;
   */
  taskName = "";

  /**
   * ID of the chapter
   *
   * @generated from field: int32 chapter_id = 5;
   */
  chapterId = 0;

  /**
   * ID of the topic
   *
   * @generated from field: int32 topic_id = 6;
   */
  topicId = 0;

  /**
   * Information about selected tasks
   *
   * @generated from field: repeated geneo.lms.hw.teacher.apis.TaskInfoModel selected_tasks_info = 7;
   */
  selectedTasksInfo: TaskInfoModel[] = [];

  /**
   * ID of the homework (optional)
   *
   * @generated from field: optional int32 homework_id = 8;
   */
  homeworkId?: number;

  /**
   * Instructions for the homework (optional)
   *
   * @generated from field: repeated string instructions = 9;
   */
  instructions: string[] = [];

  /**
   * Status of the creation process
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum creation_status = 10;
   */
  creationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 11;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload task_uploads = 12;
   */
  taskUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float max_marks = 13;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 14;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: optional int32 source_geneo_hw_id = 15;
   */
  sourceGeneoHwId?: number;

  constructor(data?: PartialMessage<TeacherHWCreationSubmitRequestV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWCreationSubmitRequestV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "task_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "selected_tasks_info", kind: "message", T: TaskInfoModel, repeated: true },
    { no: 8, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
    { no: 11, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 12, name: "task_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 13, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 14, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 15, name: "source_geneo_hw_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWCreationSubmitRequestV2 {
    return new TeacherHWCreationSubmitRequestV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmitRequestV2 {
    return new TeacherHWCreationSubmitRequestV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmitRequestV2 {
    return new TeacherHWCreationSubmitRequestV2().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWCreationSubmitRequestV2 | PlainMessage<TeacherHWCreationSubmitRequestV2> | undefined, b: TeacherHWCreationSubmitRequestV2 | PlainMessage<TeacherHWCreationSubmitRequestV2> | undefined): boolean {
    return proto3.util.equals(TeacherHWCreationSubmitRequestV2, a, b);
  }
}

/**
 * Response model for /teacher/homework/creation/submit
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWCreationSubmitResponse
 */
export class TeacherHWCreationSubmitResponse extends Message<TeacherHWCreationSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.TeacherHWCreationSubmit data = 4;
   */
  data?: TeacherHWCreationSubmit;

  constructor(data?: PartialMessage<TeacherHWCreationSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWCreationSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherHWCreationSubmit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWCreationSubmitResponse {
    return new TeacherHWCreationSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmitResponse {
    return new TeacherHWCreationSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmitResponse {
    return new TeacherHWCreationSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWCreationSubmitResponse | PlainMessage<TeacherHWCreationSubmitResponse> | undefined, b: TeacherHWCreationSubmitResponse | PlainMessage<TeacherHWCreationSubmitResponse> | undefined): boolean {
    return proto3.util.equals(TeacherHWCreationSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWCreationSubmit
 */
export class TeacherHWCreationSubmit extends Message<TeacherHWCreationSubmit> {
  /**
   * @generated from field: geneo.lms.hw.common.apis.HomeworkTask homework = 1;
   */
  homework?: HomeworkTask;

  constructor(data?: PartialMessage<TeacherHWCreationSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWCreationSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework", kind: "message", T: HomeworkTask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWCreationSubmit {
    return new TeacherHWCreationSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmit {
    return new TeacherHWCreationSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWCreationSubmit {
    return new TeacherHWCreationSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWCreationSubmit | PlainMessage<TeacherHWCreationSubmit> | undefined, b: TeacherHWCreationSubmit | PlainMessage<TeacherHWCreationSubmit> | undefined): boolean {
    return proto3.util.equals(TeacherHWCreationSubmit, a, b);
  }
}

/**
 * Request model for /teacher/homework/assignment/submit
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmitRequest
 */
export class TeacherHWAssignmentSubmitRequest extends Message<TeacherHWAssignmentSubmitRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 4;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp daily_reminder_time = 5;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: bool allow_late_submission = 6;
   */
  allowLateSubmission = false;

  /**
   * @generated from field: repeated string homework_instructions = 7;
   */
  homeworkInstructions: string[] = [];

  /**
   * @generated from field: int32 section_id = 8;
   */
  sectionId = 0;

  /**
   * @generated from field: string homework_name = 9;
   */
  homeworkName = "";

  constructor(data?: PartialMessage<TeacherHWAssignmentSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "end_time", kind: "message", T: Timestamp },
    { no: 5, name: "daily_reminder_time", kind: "message", T: Timestamp },
    { no: 6, name: "allow_late_submission", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "homework_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "homework_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWAssignmentSubmitRequest {
    return new TeacherHWAssignmentSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWAssignmentSubmitRequest {
    return new TeacherHWAssignmentSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWAssignmentSubmitRequest {
    return new TeacherHWAssignmentSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWAssignmentSubmitRequest | PlainMessage<TeacherHWAssignmentSubmitRequest> | undefined, b: TeacherHWAssignmentSubmitRequest | PlainMessage<TeacherHWAssignmentSubmitRequest> | undefined): boolean {
    return proto3.util.equals(TeacherHWAssignmentSubmitRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/assignment/submit
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmitResponse
 */
export class TeacherHWAssignmentSubmitResponse extends Message<TeacherHWAssignmentSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmit data = 4;
   */
  data?: TeacherHWAssignmentSubmit;

  constructor(data?: PartialMessage<TeacherHWAssignmentSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherHWAssignmentSubmit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWAssignmentSubmitResponse {
    return new TeacherHWAssignmentSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWAssignmentSubmitResponse {
    return new TeacherHWAssignmentSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWAssignmentSubmitResponse {
    return new TeacherHWAssignmentSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWAssignmentSubmitResponse | PlainMessage<TeacherHWAssignmentSubmitResponse> | undefined, b: TeacherHWAssignmentSubmitResponse | PlainMessage<TeacherHWAssignmentSubmitResponse> | undefined): boolean {
    return proto3.util.equals(TeacherHWAssignmentSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmit
 */
export class TeacherHWAssignmentSubmit extends Message<TeacherHWAssignmentSubmit> {
  /**
   * @generated from field: geneo.lms.hw.common.apis.HomeworkTask updated_HW = 1;
   */
  updatedHW?: HomeworkTask;

  constructor(data?: PartialMessage<TeacherHWAssignmentSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWAssignmentSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_HW", kind: "message", T: HomeworkTask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWAssignmentSubmit {
    return new TeacherHWAssignmentSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWAssignmentSubmit {
    return new TeacherHWAssignmentSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWAssignmentSubmit {
    return new TeacherHWAssignmentSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWAssignmentSubmit | PlainMessage<TeacherHWAssignmentSubmit> | undefined, b: TeacherHWAssignmentSubmit | PlainMessage<TeacherHWAssignmentSubmit> | undefined): boolean {
    return proto3.util.equals(TeacherHWAssignmentSubmit, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWListRequest
 */
export class TeacherHWListRequest extends Message<TeacherHWListRequest> {
  /**
   * Add request fields as needed
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 module_id = 4;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 5;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: optional int32 page_number = 6;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional geneo.lms.hw.teacher.apis.HomeworkListEnum homework_list_type = 7;
   */
  homeworkListType?: HomeworkListEnum;

  constructor(data?: PartialMessage<TeacherHWListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 6, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "homework_list_type", kind: "enum", T: proto3.getEnumType(HomeworkListEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWListRequest {
    return new TeacherHWListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWListRequest {
    return new TeacherHWListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWListRequest {
    return new TeacherHWListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWListRequest | PlainMessage<TeacherHWListRequest> | undefined, b: TeacherHWListRequest | PlainMessage<TeacherHWListRequest> | undefined): boolean {
    return proto3.util.equals(TeacherHWListRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/list
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWListResponse
 */
export class TeacherHWListResponse extends Message<TeacherHWListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.TeacherHWList data = 4;
   */
  data?: TeacherHWList;

  constructor(data?: PartialMessage<TeacherHWListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherHWList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWListResponse {
    return new TeacherHWListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWListResponse {
    return new TeacherHWListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWListResponse {
    return new TeacherHWListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWListResponse | PlainMessage<TeacherHWListResponse> | undefined, b: TeacherHWListResponse | PlainMessage<TeacherHWListResponse> | undefined): boolean {
    return proto3.util.equals(TeacherHWListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWList
 */
export class TeacherHWList extends Message<TeacherHWList> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 module_id = 4;
   */
  moduleId?: number;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum module_category = 5;
   */
  moduleCategory = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.HomeworkList homework_list = 6;
   */
  homeworkList?: HomeworkList;

  constructor(data?: PartialMessage<TeacherHWList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "module_category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 6, name: "homework_list", kind: "message", T: HomeworkList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWList {
    return new TeacherHWList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWList {
    return new TeacherHWList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWList {
    return new TeacherHWList().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWList | PlainMessage<TeacherHWList> | undefined, b: TeacherHWList | PlainMessage<TeacherHWList> | undefined): boolean {
    return proto3.util.equals(TeacherHWList, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.HomeworkList
 */
export class HomeworkList extends Message<HomeworkList> {
  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask draft = 1;
   */
  draft: HomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask assigned = 2;
   */
  assigned: HomeworkTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.hw.common.apis.HomeworkTask ended = 3;
   */
  ended: HomeworkTask[] = [];

  /**
   * @generated from field: optional geneo.lms.hw.teacher.apis.HWListCountInfo hwListInfo = 4;
   */
  hwListInfo?: HWListCountInfo;

  constructor(data?: PartialMessage<HomeworkList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.HomeworkList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft", kind: "message", T: HomeworkTask, repeated: true },
    { no: 2, name: "assigned", kind: "message", T: HomeworkTask, repeated: true },
    { no: 3, name: "ended", kind: "message", T: HomeworkTask, repeated: true },
    { no: 4, name: "hwListInfo", kind: "message", T: HWListCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkList {
    return new HomeworkList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkList {
    return new HomeworkList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkList {
    return new HomeworkList().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkList | PlainMessage<HomeworkList> | undefined, b: HomeworkList | PlainMessage<HomeworkList> | undefined): boolean {
    return proto3.util.equals(HomeworkList, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.HWListCountInfo
 */
export class HWListCountInfo extends Message<HWListCountInfo> {
  /**
   * @generated from field: int32 total_hw_count = 1;
   */
  totalHwCount = 0;

  /**
   * @generated from field: int32 draft_hw_count = 2;
   */
  draftHwCount = 0;

  /**
   * @generated from field: int32 active_hw_count = 3;
   */
  activeHwCount = 0;

  /**
   * @generated from field: int32 ended_hw_count = 4;
   */
  endedHwCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 5;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<HWListCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.HWListCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "draft_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "active_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "ended_hw_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HWListCountInfo {
    return new HWListCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HWListCountInfo {
    return new HWListCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HWListCountInfo {
    return new HWListCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: HWListCountInfo | PlainMessage<HWListCountInfo> | undefined, b: HWListCountInfo | PlainMessage<HWListCountInfo> | undefined): boolean {
    return proto3.util.equals(HWListCountInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.HomeworkContentInfo
 */
export class HomeworkContentInfo extends Message<HomeworkContentInfo> {
  /**
   * @generated from field: int32 time_duration_in_min = 1;
   */
  timeDurationInMin = 0;

  /**
   * @generated from field: int32 number_of_questions = 2;
   */
  numberOfQuestions = 0;

  constructor(data?: PartialMessage<HomeworkContentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.HomeworkContentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_duration_in_min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "number_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkContentInfo {
    return new HomeworkContentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkContentInfo {
    return new HomeworkContentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkContentInfo {
    return new HomeworkContentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkContentInfo | PlainMessage<HomeworkContentInfo> | undefined, b: HomeworkContentInfo | PlainMessage<HomeworkContentInfo> | undefined): boolean {
    return proto3.util.equals(HomeworkContentInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.GetModuleFilteredQuestionsRequest
 */
export class GetModuleFilteredQuestionsRequest extends Message<GetModuleFilteredQuestionsRequest> {
  /**
   * Add request fields as needed
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * @generated from field: int32 module_id = 3;
   */
  moduleId = 0;

  /**
   * @generated from field: repeated geneo.content.db.Question.QuestionEnum required_question_types = 4;
   */
  requiredQuestionTypes: Question_QuestionEnum[] = [];

  /**
   * @generated from field: repeated geneo.content.db.DifficultyLevelEnum required_difficulty_types = 5;
   */
  requiredDifficultyTypes: DifficultyLevelEnum[] = [];

  /**
   * @generated from field: optional geneo.lms.hw.teacher.apis.RequiredQuestionCountInfoForDifficultyLevel required_difficulty_levels_count_info = 6;
   */
  requiredDifficultyLevelsCountInfo?: RequiredQuestionCountInfoForDifficultyLevel;

  /**
   * @generated from field: repeated string qIdsToBeIgnored = 7;
   */
  qIdsToBeIgnored: string[] = [];

  constructor(data?: PartialMessage<GetModuleFilteredQuestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.GetModuleFilteredQuestionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "required_question_types", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum), repeated: true },
    { no: 5, name: "required_difficulty_types", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum), repeated: true },
    { no: 6, name: "required_difficulty_levels_count_info", kind: "message", T: RequiredQuestionCountInfoForDifficultyLevel, opt: true },
    { no: 7, name: "qIdsToBeIgnored", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleFilteredQuestionsRequest {
    return new GetModuleFilteredQuestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsRequest {
    return new GetModuleFilteredQuestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsRequest {
    return new GetModuleFilteredQuestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleFilteredQuestionsRequest | PlainMessage<GetModuleFilteredQuestionsRequest> | undefined, b: GetModuleFilteredQuestionsRequest | PlainMessage<GetModuleFilteredQuestionsRequest> | undefined): boolean {
    return proto3.util.equals(GetModuleFilteredQuestionsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.RequiredQuestionCountInfoForDifficultyLevel
 */
export class RequiredQuestionCountInfoForDifficultyLevel extends Message<RequiredQuestionCountInfoForDifficultyLevel> {
  /**
   * @generated from field: int32 no_of_low_questions = 1;
   */
  noOfLowQuestions = 0;

  /**
   * @generated from field: int32 no_of_medium_questions = 2;
   */
  noOfMediumQuestions = 0;

  /**
   * @generated from field: int32 no_of_high_questions = 3;
   */
  noOfHighQuestions = 0;

  constructor(data?: PartialMessage<RequiredQuestionCountInfoForDifficultyLevel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.RequiredQuestionCountInfoForDifficultyLevel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "no_of_low_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "no_of_medium_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "no_of_high_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequiredQuestionCountInfoForDifficultyLevel {
    return new RequiredQuestionCountInfoForDifficultyLevel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequiredQuestionCountInfoForDifficultyLevel {
    return new RequiredQuestionCountInfoForDifficultyLevel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequiredQuestionCountInfoForDifficultyLevel {
    return new RequiredQuestionCountInfoForDifficultyLevel().fromJsonString(jsonString, options);
  }

  static equals(a: RequiredQuestionCountInfoForDifficultyLevel | PlainMessage<RequiredQuestionCountInfoForDifficultyLevel> | undefined, b: RequiredQuestionCountInfoForDifficultyLevel | PlainMessage<RequiredQuestionCountInfoForDifficultyLevel> | undefined): boolean {
    return proto3.util.equals(RequiredQuestionCountInfoForDifficultyLevel, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.GetModuleFilteredQuestionsResponse
 */
export class GetModuleFilteredQuestionsResponse extends Message<GetModuleFilteredQuestionsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.ModuleFilteredQuestions data = 4;
   */
  data?: ModuleFilteredQuestions;

  constructor(data?: PartialMessage<GetModuleFilteredQuestionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.GetModuleFilteredQuestionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ModuleFilteredQuestions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleFilteredQuestionsResponse {
    return new GetModuleFilteredQuestionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsResponse {
    return new GetModuleFilteredQuestionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsResponse {
    return new GetModuleFilteredQuestionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleFilteredQuestionsResponse | PlainMessage<GetModuleFilteredQuestionsResponse> | undefined, b: GetModuleFilteredQuestionsResponse | PlainMessage<GetModuleFilteredQuestionsResponse> | undefined): boolean {
    return proto3.util.equals(GetModuleFilteredQuestionsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.ModuleFilteredQuestions
 */
export class ModuleFilteredQuestions extends Message<ModuleFilteredQuestions> {
  /**
   * @generated from field: repeated geneo.content.db.Question questions = 1;
   */
  questions: Question[] = [];

  constructor(data?: PartialMessage<ModuleFilteredQuestions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.ModuleFilteredQuestions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleFilteredQuestions {
    return new ModuleFilteredQuestions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleFilteredQuestions {
    return new ModuleFilteredQuestions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleFilteredQuestions {
    return new ModuleFilteredQuestions().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleFilteredQuestions | PlainMessage<ModuleFilteredQuestions> | undefined, b: ModuleFilteredQuestions | PlainMessage<ModuleFilteredQuestions> | undefined): boolean {
    return proto3.util.equals(ModuleFilteredQuestions, a, b);
  }
}

/**
 * Request model for /teacher/homework/student/response/fetch
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherStudentResponseFetchRequest
 */
export class TeacherStudentResponseFetchRequest extends Message<TeacherStudentResponseFetchRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  /**
   * @generated from field: int64 student_id = 3;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<TeacherStudentResponseFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherStudentResponseFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherStudentResponseFetchRequest {
    return new TeacherStudentResponseFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherStudentResponseFetchRequest {
    return new TeacherStudentResponseFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherStudentResponseFetchRequest {
    return new TeacherStudentResponseFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherStudentResponseFetchRequest | PlainMessage<TeacherStudentResponseFetchRequest> | undefined, b: TeacherStudentResponseFetchRequest | PlainMessage<TeacherStudentResponseFetchRequest> | undefined): boolean {
    return proto3.util.equals(TeacherStudentResponseFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherStudentResponseFetchResponse
 */
export class TeacherStudentResponseFetchResponse extends Message<TeacherStudentResponseFetchResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.TeacherStudentResponseFetch data = 4;
   */
  data?: TeacherStudentResponseFetch;

  constructor(data?: PartialMessage<TeacherStudentResponseFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherStudentResponseFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherStudentResponseFetch },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherStudentResponseFetchResponse {
    return new TeacherStudentResponseFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherStudentResponseFetchResponse {
    return new TeacherStudentResponseFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherStudentResponseFetchResponse {
    return new TeacherStudentResponseFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherStudentResponseFetchResponse | PlainMessage<TeacherStudentResponseFetchResponse> | undefined, b: TeacherStudentResponseFetchResponse | PlainMessage<TeacherStudentResponseFetchResponse> | undefined): boolean {
    return proto3.util.equals(TeacherStudentResponseFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherStudentResponseFetch
 */
export class TeacherStudentResponseFetch extends Message<TeacherStudentResponseFetch> {
  /**
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string student_name = 3;
   */
  studentName = "";

  /**
   * @generated from field: string profile_image_url = 4;
   */
  profileImageUrl = "";

  /**
   * @generated from field: geneo.lms.db.TaskStudentAttemptStatusEnum task_attempt_status = 5;
   */
  taskAttemptStatus = TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_UNDEFINED;

  /**
   * @generated from field: float response_score = 6;
   */
  responseScore = 0;

  /**
   * @generated from field: float max_score = 7;
   */
  maxScore = 0;

  /**
   * @generated from field: int32 total_questions = 8;
   */
  totalQuestions = 0;

  /**
   * @generated from field: int32 correct_responses = 9;
   */
  correctResponses = 0;

  /**
   * @generated from field: float time_spent = 10;
   */
  timeSpent = 0;

  /**
   * @generated from field: repeated geneo.lms.db.StudentAssessmentResponse responses = 11;
   */
  responses: StudentAssessmentResponse[] = [];

  /**
   * @generated from field: geneo.lms.db.MetricsModel metrics = 12;
   */
  metrics?: MetricsModel;

  /**
   * Teacher's review of the student's task in JSONB format
   *
   * @generated from field: optional geneo.lms.db.TaskTeacherReview teacher_review = 13;
   */
  teacherReview?: TaskTeacherReview;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload user_uploads = 14;
   */
  userUploads: CloudUpload[] = [];

  constructor(data?: PartialMessage<TeacherStudentResponseFetch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherStudentResponseFetch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "task_attempt_status", kind: "enum", T: proto3.getEnumType(TaskStudentAttemptStatusEnum) },
    { no: 6, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "max_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "total_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "correct_responses", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "time_spent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "responses", kind: "message", T: StudentAssessmentResponse, repeated: true },
    { no: 12, name: "metrics", kind: "message", T: MetricsModel },
    { no: 13, name: "teacher_review", kind: "message", T: TaskTeacherReview, opt: true },
    { no: 14, name: "user_uploads", kind: "message", T: CloudUpload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherStudentResponseFetch {
    return new TeacherStudentResponseFetch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherStudentResponseFetch {
    return new TeacherStudentResponseFetch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherStudentResponseFetch {
    return new TeacherStudentResponseFetch().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherStudentResponseFetch | PlainMessage<TeacherStudentResponseFetch> | undefined, b: TeacherStudentResponseFetch | PlainMessage<TeacherStudentResponseFetch> | undefined): boolean {
    return proto3.util.equals(TeacherStudentResponseFetch, a, b);
  }
}

/**
 * Request model for /teacher/homework/student/response/fetch
 *
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWClassStatsRequest
 */
export class TeacherHWClassStatsRequest extends Message<TeacherHWClassStatsRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 homework_id = 2;
   */
  homeworkId = 0;

  constructor(data?: PartialMessage<TeacherHWClassStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWClassStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWClassStatsRequest {
    return new TeacherHWClassStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWClassStatsRequest {
    return new TeacherHWClassStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWClassStatsRequest {
    return new TeacherHWClassStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWClassStatsRequest | PlainMessage<TeacherHWClassStatsRequest> | undefined, b: TeacherHWClassStatsRequest | PlainMessage<TeacherHWClassStatsRequest> | undefined): boolean {
    return proto3.util.equals(TeacherHWClassStatsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.StudentPerformanceInfo
 */
export class StudentPerformanceInfo extends Message<StudentPerformanceInfo> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string profile_img_url = 3;
   */
  profileImgUrl = "";

  /**
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * @generated from field: float response_score = 5;
   */
  responseScore = 0;

  /**
   * @generated from field: optional bool is_submitted = 6;
   */
  isSubmitted?: boolean;

  /**
   * @generated from field: optional bool is_evaluated = 7;
   */
  isEvaluated?: boolean;

  constructor(data?: PartialMessage<StudentPerformanceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.StudentPerformanceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "is_submitted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "is_evaluated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentPerformanceInfo {
    return new StudentPerformanceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentPerformanceInfo {
    return new StudentPerformanceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentPerformanceInfo {
    return new StudentPerformanceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: StudentPerformanceInfo | PlainMessage<StudentPerformanceInfo> | undefined, b: StudentPerformanceInfo | PlainMessage<StudentPerformanceInfo> | undefined): boolean {
    return proto3.util.equals(StudentPerformanceInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWClassStatsResponse
 */
export class TeacherHWClassStatsResponse extends Message<TeacherHWClassStatsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.TeacherHWClassStats data = 4;
   */
  data?: TeacherHWClassStats;

  constructor(data?: PartialMessage<TeacherHWClassStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWClassStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherHWClassStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWClassStatsResponse {
    return new TeacherHWClassStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWClassStatsResponse {
    return new TeacherHWClassStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWClassStatsResponse {
    return new TeacherHWClassStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWClassStatsResponse | PlainMessage<TeacherHWClassStatsResponse> | undefined, b: TeacherHWClassStatsResponse | PlainMessage<TeacherHWClassStatsResponse> | undefined): boolean {
    return proto3.util.equals(TeacherHWClassStatsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.hw.teacher.apis.TeacherHWClassStats
 */
export class TeacherHWClassStats extends Message<TeacherHWClassStats> {
  /**
   * @generated from field: geneo.lms.hw.common.apis.HomeworkTask homework = 1;
   */
  homework?: HomeworkTask;

  /**
   * @generated from field: repeated geneo.lms.hw.teacher.apis.StudentPerformanceInfo student_performance_info = 2;
   */
  studentPerformanceInfo: StudentPerformanceInfo[] = [];

  /**
   * @generated from field: int32 assigned_students_count = 3;
   */
  assignedStudentsCount = 0;

  /**
   * @generated from field: int32 students_submission_count = 4;
   */
  studentsSubmissionCount = 0;

  constructor(data?: PartialMessage<TeacherHWClassStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.hw.teacher.apis.TeacherHWClassStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework", kind: "message", T: HomeworkTask },
    { no: 2, name: "student_performance_info", kind: "message", T: StudentPerformanceInfo, repeated: true },
    { no: 3, name: "assigned_students_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "students_submission_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherHWClassStats {
    return new TeacherHWClassStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherHWClassStats {
    return new TeacherHWClassStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherHWClassStats {
    return new TeacherHWClassStats().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherHWClassStats | PlainMessage<TeacherHWClassStats> | undefined, b: TeacherHWClassStats | PlainMessage<TeacherHWClassStats> | undefined): boolean {
    return proto3.util.equals(TeacherHWClassStats, a, b);
  }
}

