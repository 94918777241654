import { Timestamp } from '@bufbuild/protobuf';
import {
  AssessInfoCard,
  AssessmentTopicPopup,
  EarlyHomeworkPopup,
  IStyles,
  InfoDisplayCard,
  NewSectionList,
  deserify,
  formatDateAsDayMonth,
  getEnvConfig,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  remainingTimeInHoursAndMins,
  setLocalStorage,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { TaskStudentAttemptStatusEnum } from '@protos/learning_management/lms.db_pb';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { HOMEWORK_STUDENT_DASHBOARD } from '../../../../routeHandling/RoutesNomenclature';
import { onHomeworkCardClick } from '../../../../utils/homework';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { setHomeHomeworkList } from '../../reducer/homeDashboard.slice';
import SectionListSckeleton, { ShimmerActiveHwCard } from '../../shimmer';
import { useMediaQuery } from '@mui/material';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import { StudentAssessmentTask } from '@protos/learning_management/lms.assessment.student.apis_pb';

const styles: IStyles = {
  idcRootStyle: {
    backgroundColor: 'common.white',
    minWidth: { xs: pxToRem(313), md: pxToRem(270), lg: pxTovW(476) },
    width: { xs: pxToRem(313), md: pxToRem(270), lg: pxTovW(476) },
    maxWidth: { xs: pxToRem(313), md: pxToRem(270), lg: pxTovW(476) },
    height: { xs: pxToRem(157), md: pxToRem(120), lg: pxTovW(196) },
  },
};

export default function ActiveHomeworkList() {
  const { isOffline } = useDownloadContext();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [error, setError] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const [selectedAssessment, setSelectedAssessment] = useState<
    StudentAssessmentTask | undefined
  >();
  const [chapterListPopup, setChapterListPopup] = useState(false);
  const config = getEnvConfig();
  const prevClassSubjectInfo = useRef<number | undefined>(undefined);
  const isFirstRender = useRef(false); // Tracks if it's the initial render
  const width = window.innerWidth;
  console.log('width', width);
  const home_homework_list =
    deserify(useAppSelector((state) => state.home.home_homework_list)) || [];

  const dispatch = useDispatch();
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState<Timestamp | undefined>(undefined);
  const { LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  useEffect(() => {
    const lastFetchTime = getLocalStorage('homeAPILastTime'); // Retrieve the last fetch time
    const currentTime = new Date().getTime();
    const shouldFetchFromAPI =
      !lastFetchTime ||
      (lastFetchTime &&
        currentTime - Number(lastFetchTime) >=
          Number(config.homeExpirationTime));

    if (shouldFetchFromAPI || !home_homework_list) {
      getHomeworkList();
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = true;
      prevClassSubjectInfo.current = selectedSubjectId; // Initialize the ref
      return;
    }

    // Convert objects to strings for deep comparison
    const prevValueString = prevClassSubjectInfo.current;
    const currentValueString = selectedSubjectId;

    if (prevValueString !== currentValueString) {
      getHomeworkList();
    }

    // Update the ref to the current value
    prevClassSubjectInfo.current = selectedSubjectId;
  }, [selectedSubjectId]);

  const onHomeworkClick = async () => {
    // console.log('Homework Clicked');
    navigate(HOMEWORK_STUDENT_DASHBOARD);
    await interactionEvent({
      url: 'Student_Home',
      context: 'active_homework',
      name: 'SEE_ALL',
      isOffline: isOffline,
    });
  };
  const ChapterListPopupHandler = (assessment: StudentAssessmentTask) => {
    setSelectedAssessment(assessment);
    setChapterListPopup(true);
  };
  const getHomeworkList = async () => {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      const response =
        await LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler.getStudentAssessmentList(
          {
            studentId: studentId,
            subjectId: selectedSubjectId,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          const list = [...(response.data?.allAssessment?.active || [])];
          dispatch(setHomeHomeworkList(list));
          setLocalStorage('homeAPILastTime', String(Date.now()));
          return;
        }
      }
      dispatch(setHomeHomeworkList([]));
    } catch (err) {
      dispatch(setHomeHomeworkList(undefined));
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  function hasTimePassed(milliseconds: string) {
    const currentTime = new Date().getTime();
    const targetTime = Number(milliseconds);
    return targetTime <= currentTime;
  }

  const getCardItems = () => {
    if (!home_homework_list) {
      return [];
    }
    return home_homework_list.slice(0, 20).map((val, index) => {
      const currentTabValue =
        val.endDate &&
        hasTimePassed(new Timestamp(val.endDate).toDate().getTime().toString())
          ? 'Ended'
          : 'Active';
      return (
        <AssessInfoCard
          chapterListPopupHandler={ChapterListPopupHandler}
          onCardClick={async () => {
            await interactionEvent({
              url: 'Student_Home',
              context: 'Active_homework',
              name: 'HOMEWORK_OPEN',
              isOffline: isOffline,
            });

            if (!studentId) {
              return;
            }
            if (val.startDate) {
              if (
                new Date(new Timestamp(val.startDate).toDate()) > new Date()
              ) {
                setStartTime(val.startDate);
                return;
              }
            }
            onHomeworkCardClick(
              dispatch,
              navigate,
              val.assessmentId,
              studentId,
              currentTabValue === 'Active' ? 'active' : 'ended',
              location.pathname
            );
          }}
          key={`home_homework_${index}`}
          // variant="large"
          // image={getMediaBasePath(
          //   val.assessmentPosterImgUrl,
          //   'schoolnetStaticAssetBucket'
          // )}
          currentTabValue={currentTabValue}
          // // submissionType={val.submissionType}
          homeworkItem={val}
          // iconDetails={[
          //   {
          //     iconName: 'questions',
          //     text: val.noOfQuestions.toString(),
          //     label: 'Questions',
          //   },
          //   {
          //     iconName: currentTabValue === 'Active' ? 'clock' : 'calender',
          //     text:
          //       currentTabValue === 'Active'
          //         ? remainingTimeInHoursAndMins(val.endDate)
          //         : formatDateAsDayMonth(val.endDate),
          //     label: currentTabValue === 'Active' ? 'Remaining' : 'Deadline',
          //   },
          // ]}
          // rootStyle={styles.idcRootStyle}
        />
      );
    });
  };

  return loading === true ? (
    <SectionListSckeleton children={ActiveHwShimmerArray} />
  ) : error === true || getCardItems().length == 0 ? (
    <></>
  ) : (
    <>
      <NewSectionList
        noContentMessage="No Homeworks for Today"
        itemsPerPage={isIpadOnly && width > 975 ? 3 : 2}
        isError={error}
        sectionTitle="Active Assessments"
        background="#FCF1C7"
        items={getCardItems()}
        handleSeeAll={onHomeworkClick}
        //   items={[tempIDC(), tempIDC(), tempIDC()]}
      />
      <EarlyHomeworkPopup
        open={!!startTime}
        okHandler={() => setStartTime(undefined)}
        startTime={startTime}
      />
      <AssessmentTopicPopup
        assessment={selectedAssessment}
        open={chapterListPopup}
        handleClose={() => setChapterListPopup(false)}
      />
    </>
    // <SectionListSckeleton children={ActiveHwShimmerArray} />
  );
}

const ActiveHwShimmerArray = [
  <ShimmerActiveHwCard key={1} variant="large" />,
  <ShimmerActiveHwCard key={2} variant="large" />,
];
