// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.assessment.teacher.apis.proto (package geneo.lms.assessment.teacher.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { AssessmentEnum, AssignmentInfo, ChapterIdsInfo, CloudUpload, SubmissionType, TaskCreationStatusEnum, TaskEnum } from "@protos/learning_management/lms.db_pb";
import { AssessmentListEnum } from "@protos/learning_management/lms.assessment.student.apis_pb";
import { AssessmentTask } from "@protos/learning_management/lms.assessment.common.apis_pb";
import { BloomLevelEnum, DifficultyLevelEnum, Module_ModuleCategoryEnum, Question, Question_QuestionEnum } from "@protos/content_management/content.db_pb";
import { QuestionStatsList, RequiredQuestionCountInfoForDifficultyLevel } from "@protos/learning_management/lms.hw.teacher.apis_pb";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";

/**
 * @generated from enum geneo.lms.assessment.teacher.apis.PerformanceEnum
 */
export enum PerformanceEnum {
  /**
   * @generated from enum value: PERFORMANCE_UNDEFINED = 0;
   */
  PERFORMANCE_UNDEFINED = 0,

  /**
   * @generated from enum value: PERFORMANCE_LOW = 1;
   */
  PERFORMANCE_LOW = 1,

  /**
   * @generated from enum value: PERFORMANCE_AVERAGE = 2;
   */
  PERFORMANCE_AVERAGE = 2,

  /**
   * @generated from enum value: PERFORMANCE_EXCELLENT = 3;
   */
  PERFORMANCE_EXCELLENT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PerformanceEnum)
proto3.util.setEnumType(PerformanceEnum, "geneo.lms.assessment.teacher.apis.PerformanceEnum", [
  { no: 0, name: "PERFORMANCE_UNDEFINED" },
  { no: 1, name: "PERFORMANCE_LOW" },
  { no: 2, name: "PERFORMANCE_AVERAGE" },
  { no: 3, name: "PERFORMANCE_EXCELLENT" },
]);

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListRequest
 */
export class GetTeacherAssessmentsListRequest extends Message<GetTeacherAssessmentsListRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course (optional)
   *
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 page_number = 5;
   */
  pageNumber?: number;

  /**
   * @generated from field: optional int32 items_per_page = 6;
   */
  itemsPerPage?: number;

  /**
   * @generated from field: optional geneo.lms.db.AssessmentEnum assessment_type = 7;
   */
  assessmentType?: AssessmentEnum;

  /**
   * @generated from field: optional geneo.lms.assessment.student.apis.AssessmentListEnum assessment_list_type = 8;
   */
  assessmentListType?: AssessmentListEnum;

  constructor(data?: PartialMessage<GetTeacherAssessmentsListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "items_per_page", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "assessment_type", kind: "enum", T: proto3.getEnumType(AssessmentEnum), opt: true },
    { no: 8, name: "assessment_list_type", kind: "enum", T: proto3.getEnumType(AssessmentListEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListRequest {
    return new GetTeacherAssessmentsListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherAssessmentsListRequest | PlainMessage<GetTeacherAssessmentsListRequest> | undefined, b: GetTeacherAssessmentsListRequest | PlainMessage<GetTeacherAssessmentsListRequest> | undefined): boolean {
    return proto3.util.equals(GetTeacherAssessmentsListRequest, a, b);
  }
}

/**
 * Response model for /teacher/homework/list
 *
 * @generated from message geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListResponse
 */
export class GetTeacherAssessmentsListResponse extends Message<GetTeacherAssessmentsListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessmentsList assessment_list = 4;
   */
  assessmentList?: TeacherAssessmentsList;

  constructor(data?: PartialMessage<GetTeacherAssessmentsListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetTeacherAssessmentsListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "assessment_list", kind: "message", T: TeacherAssessmentsList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeacherAssessmentsListResponse {
    return new GetTeacherAssessmentsListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeacherAssessmentsListResponse | PlainMessage<GetTeacherAssessmentsListResponse> | undefined, b: GetTeacherAssessmentsListResponse | PlainMessage<GetTeacherAssessmentsListResponse> | undefined): boolean {
    return proto3.util.equals(GetTeacherAssessmentsListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentsList
 */
export class TeacherAssessmentsList extends Message<TeacherAssessmentsList> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: optional int32 subject_id = 2;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 section_id = 3;
   */
  sectionId?: number;

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.AssessmentList assessment_list = 6;
   */
  assessmentList?: AssessmentList;

  constructor(data?: PartialMessage<TeacherAssessmentsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "assessment_list", kind: "message", T: AssessmentList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentsList {
    return new TeacherAssessmentsList().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentsList | PlainMessage<TeacherAssessmentsList> | undefined, b: TeacherAssessmentsList | PlainMessage<TeacherAssessmentsList> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentsList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentList
 */
export class AssessmentList extends Message<AssessmentList> {
  /**
   * @generated from field: repeated geneo.lms.assessment.common.apis.AssessmentTask draft = 1;
   */
  draft: AssessmentTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.assessment.common.apis.AssessmentTask assigned = 2;
   */
  assigned: AssessmentTask[] = [];

  /**
   * @generated from field: repeated geneo.lms.assessment.common.apis.AssessmentTask ended = 3;
   */
  ended: AssessmentTask[] = [];

  /**
   * @generated from field: optional geneo.lms.assessment.teacher.apis.AssessmentListCountInfo assesment_list_info = 4;
   */
  assesmentListInfo?: AssessmentListCountInfo;

  constructor(data?: PartialMessage<AssessmentList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft", kind: "message", T: AssessmentTask, repeated: true },
    { no: 2, name: "assigned", kind: "message", T: AssessmentTask, repeated: true },
    { no: 3, name: "ended", kind: "message", T: AssessmentTask, repeated: true },
    { no: 4, name: "assesment_list_info", kind: "message", T: AssessmentListCountInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentList {
    return new AssessmentList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentList {
    return new AssessmentList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentList {
    return new AssessmentList().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentList | PlainMessage<AssessmentList> | undefined, b: AssessmentList | PlainMessage<AssessmentList> | undefined): boolean {
    return proto3.util.equals(AssessmentList, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentListCountInfo
 */
export class AssessmentListCountInfo extends Message<AssessmentListCountInfo> {
  /**
   * @generated from field: int32 total_count = 1;
   */
  totalCount = 0;

  /**
   * @generated from field: int32 draft_count = 2;
   */
  draftCount = 0;

  /**
   * @generated from field: int32 active_count = 3;
   */
  activeCount = 0;

  /**
   * @generated from field: int32 ended_count = 4;
   */
  endedCount = 0;

  /**
   * @generated from field: int32 count_in_pagination = 5;
   */
  countInPagination = 0;

  constructor(data?: PartialMessage<AssessmentListCountInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentListCountInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "draft_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "active_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "ended_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "count_in_pagination", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentListCountInfo {
    return new AssessmentListCountInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentListCountInfo | PlainMessage<AssessmentListCountInfo> | undefined, b: AssessmentListCountInfo | PlainMessage<AssessmentListCountInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentListCountInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitRequest
 */
export class AssessmentCreationSubmitRequest extends Message<AssessmentCreationSubmitRequest> {
  /**
   * ID of the teacher
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the course
   *
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * ID of the school class section
   *
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * Name of the task
   *
   * @generated from field: string task_name = 4;
   */
  taskName = "";

  /**
   * ID of the chapter
   *
   * @generated from field: int32 module_id = 5;
   */
  moduleId = 0;

  /**
   * ID of the topic
   *
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 6;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * Information about selected tasks
   *
   * @generated from field: repeated geneo.lms.assessment.teacher.apis.TaskInfoModel selected_tasks_info = 7;
   */
  selectedTasksInfo: TaskInfoModel[] = [];

  /**
   * ID of the homework (optional)
   *
   * @generated from field: optional int32 assessment_id = 8;
   */
  assessmentId?: number;

  /**
   * Instructions for the homework (optional)
   *
   * @generated from field: repeated string instructions = 9;
   */
  instructions: string[] = [];

  /**
   * Status of the creation process
   *
   * @generated from field: geneo.lms.db.TaskCreationStatusEnum creation_status = 10;
   */
  creationStatus = TaskCreationStatusEnum.TASK_CREATION_STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.lms.db.TaskEnum task_type = 11;
   */
  taskType = TaskEnum.TASK_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.db.CloudUpload task_uploads = 12;
   */
  taskUploads: CloudUpload[] = [];

  /**
   * @generated from field: optional float max_marks = 13;
   */
  maxMarks?: number;

  /**
   * Only for teacher created CUSTOM hws
   *
   * @generated from field: optional geneo.lms.db.SubmissionType submission_type = 14;
   */
  submissionType?: SubmissionType;

  /**
   * @generated from field: optional int32 source_geneo_assessment_id = 15;
   */
  sourceGeneoAssessmentId?: number;

  /**
   * @generated from field: repeated geneo.lms.db.ChapterIdsInfo chapter_ids_info = 16;
   */
  chapterIdsInfo: ChapterIdsInfo[] = [];

  constructor(data?: PartialMessage<AssessmentCreationSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "task_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 7, name: "selected_tasks_info", kind: "message", T: TaskInfoModel, repeated: true },
    { no: 8, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "creation_status", kind: "enum", T: proto3.getEnumType(TaskCreationStatusEnum) },
    { no: 11, name: "task_type", kind: "enum", T: proto3.getEnumType(TaskEnum) },
    { no: 12, name: "task_uploads", kind: "message", T: CloudUpload, repeated: true },
    { no: 13, name: "max_marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 14, name: "submission_type", kind: "enum", T: proto3.getEnumType(SubmissionType), opt: true },
    { no: 15, name: "source_geneo_assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 16, name: "chapter_ids_info", kind: "message", T: ChapterIdsInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentCreationSubmitRequest {
    return new AssessmentCreationSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitRequest {
    return new AssessmentCreationSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitRequest {
    return new AssessmentCreationSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentCreationSubmitRequest | PlainMessage<AssessmentCreationSubmitRequest> | undefined, b: AssessmentCreationSubmitRequest | PlainMessage<AssessmentCreationSubmitRequest> | undefined): boolean {
    return proto3.util.equals(AssessmentCreationSubmitRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TaskInfoModel
 */
export class TaskInfoModel extends Message<TaskInfoModel> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: int32 sequence = 2;
   */
  sequence = 0;

  constructor(data?: PartialMessage<TaskInfoModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TaskInfoModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskInfoModel {
    return new TaskInfoModel().fromJsonString(jsonString, options);
  }

  static equals(a: TaskInfoModel | PlainMessage<TaskInfoModel> | undefined, b: TaskInfoModel | PlainMessage<TaskInfoModel> | undefined): boolean {
    return proto3.util.equals(TaskInfoModel, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitResponse
 */
export class AssessmentCreationSubmitResponse extends Message<AssessmentCreationSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessmentCreationSubmit data = 4;
   */
  data?: TeacherAssessmentCreationSubmit;

  constructor(data?: PartialMessage<AssessmentCreationSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentCreationSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAssessmentCreationSubmit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentCreationSubmitResponse {
    return new AssessmentCreationSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitResponse {
    return new AssessmentCreationSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentCreationSubmitResponse {
    return new AssessmentCreationSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentCreationSubmitResponse | PlainMessage<AssessmentCreationSubmitResponse> | undefined, b: AssessmentCreationSubmitResponse | PlainMessage<AssessmentCreationSubmitResponse> | undefined): boolean {
    return proto3.util.equals(AssessmentCreationSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentCreationSubmit
 */
export class TeacherAssessmentCreationSubmit extends Message<TeacherAssessmentCreationSubmit> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask assessment = 1;
   */
  assessment?: AssessmentTask;

  constructor(data?: PartialMessage<TeacherAssessmentCreationSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentCreationSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: AssessmentTask },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentCreationSubmit {
    return new TeacherAssessmentCreationSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentCreationSubmit {
    return new TeacherAssessmentCreationSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentCreationSubmit {
    return new TeacherAssessmentCreationSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentCreationSubmit | PlainMessage<TeacherAssessmentCreationSubmit> | undefined, b: TeacherAssessmentCreationSubmit | PlainMessage<TeacherAssessmentCreationSubmit> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentCreationSubmit, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitRequest
 */
export class AssessmentAssignmentSubmitRequest extends Message<AssessmentAssignmentSubmitRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  /**
   * @generated from field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 4;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp daily_reminder_time = 5;
   */
  dailyReminderTime?: Timestamp;

  /**
   * @generated from field: repeated string assessment_instructions = 6;
   */
  assessmentInstructions: string[] = [];

  /**
   * @generated from field: repeated int32 section_ids = 7;
   */
  sectionIds: number[] = [];

  /**
   * @generated from field: string assessment_name = 8;
   */
  assessmentName = "";

  /**
   * @generated from field: repeated geneo.lms.db.AssignmentInfo assignment_info = 9;
   */
  assignmentInfo: AssignmentInfo[] = [];

  /**
   * @generated from field: optional float time_duration_in_min = 10;
   */
  timeDurationInMin?: number;

  constructor(data?: PartialMessage<AssessmentAssignmentSubmitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "end_time", kind: "message", T: Timestamp },
    { no: 5, name: "daily_reminder_time", kind: "message", T: Timestamp },
    { no: 6, name: "assessment_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "section_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "assessment_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "assignment_info", kind: "message", T: AssignmentInfo, repeated: true },
    { no: 10, name: "time_duration_in_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentAssignmentSubmitRequest {
    return new AssessmentAssignmentSubmitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitRequest {
    return new AssessmentAssignmentSubmitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitRequest {
    return new AssessmentAssignmentSubmitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentAssignmentSubmitRequest | PlainMessage<AssessmentAssignmentSubmitRequest> | undefined, b: AssessmentAssignmentSubmitRequest | PlainMessage<AssessmentAssignmentSubmitRequest> | undefined): boolean {
    return proto3.util.equals(AssessmentAssignmentSubmitRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitResponse
 */
export class AssessmentAssignmentSubmitResponse extends Message<AssessmentAssignmentSubmitResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessAssignmentSubmit data = 4;
   */
  data?: TeacherAssessAssignmentSubmit;

  constructor(data?: PartialMessage<AssessmentAssignmentSubmitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentAssignmentSubmitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAssessAssignmentSubmit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentAssignmentSubmitResponse {
    return new AssessmentAssignmentSubmitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitResponse {
    return new AssessmentAssignmentSubmitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentAssignmentSubmitResponse {
    return new AssessmentAssignmentSubmitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentAssignmentSubmitResponse | PlainMessage<AssessmentAssignmentSubmitResponse> | undefined, b: AssessmentAssignmentSubmitResponse | PlainMessage<AssessmentAssignmentSubmitResponse> | undefined): boolean {
    return proto3.util.equals(AssessmentAssignmentSubmitResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessAssignmentSubmit
 */
export class TeacherAssessAssignmentSubmit extends Message<TeacherAssessAssignmentSubmit> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask updated_Assessment = 1;
   */
  updatedAssessment?: AssessmentTask;

  /**
   * @generated from field: repeated int32 section_ids = 2;
   */
  sectionIds: number[] = [];

  constructor(data?: PartialMessage<TeacherAssessAssignmentSubmit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessAssignmentSubmit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_Assessment", kind: "message", T: AssessmentTask },
    { no: 2, name: "section_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessAssignmentSubmit {
    return new TeacherAssessAssignmentSubmit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessAssignmentSubmit {
    return new TeacherAssessAssignmentSubmit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessAssignmentSubmit {
    return new TeacherAssessAssignmentSubmit().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessAssignmentSubmit | PlainMessage<TeacherAssessAssignmentSubmit> | undefined, b: TeacherAssessAssignmentSubmit | PlainMessage<TeacherAssessAssignmentSubmit> | undefined): boolean {
    return proto3.util.equals(TeacherAssessAssignmentSubmit, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsRequest
 */
export class GetModuleFilteredQuestionsRequest extends Message<GetModuleFilteredQuestionsRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * @generated from field: repeated int32 module_ids = 3;
   */
  moduleIds: number[] = [];

  /**
   * @generated from field: repeated geneo.content.db.Question.QuestionEnum required_question_types = 4;
   */
  requiredQuestionTypes: Question_QuestionEnum[] = [];

  /**
   * @generated from field: repeated geneo.content.db.DifficultyLevelEnum required_difficulty_types = 5;
   */
  requiredDifficultyTypes: DifficultyLevelEnum[] = [];

  /**
   * @generated from field: optional geneo.lms.hw.teacher.apis.RequiredQuestionCountInfoForDifficultyLevel required_difficulty_levels_count_info = 6;
   */
  requiredDifficultyLevelsCountInfo?: RequiredQuestionCountInfoForDifficultyLevel;

  /**
   * @generated from field: repeated string qIdsToBeIgnored = 7;
   */
  qIdsToBeIgnored: string[] = [];

  constructor(data?: PartialMessage<GetModuleFilteredQuestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "module_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "required_question_types", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum), repeated: true },
    { no: 5, name: "required_difficulty_types", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum), repeated: true },
    { no: 6, name: "required_difficulty_levels_count_info", kind: "message", T: RequiredQuestionCountInfoForDifficultyLevel, opt: true },
    { no: 7, name: "qIdsToBeIgnored", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleFilteredQuestionsRequest {
    return new GetModuleFilteredQuestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsRequest {
    return new GetModuleFilteredQuestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsRequest {
    return new GetModuleFilteredQuestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleFilteredQuestionsRequest | PlainMessage<GetModuleFilteredQuestionsRequest> | undefined, b: GetModuleFilteredQuestionsRequest | PlainMessage<GetModuleFilteredQuestionsRequest> | undefined): boolean {
    return proto3.util.equals(GetModuleFilteredQuestionsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsResponse
 */
export class GetModuleFilteredQuestionsResponse extends Message<GetModuleFilteredQuestionsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.ModuleFilteredQuestions data = 4;
   */
  data?: ModuleFilteredQuestions;

  constructor(data?: PartialMessage<GetModuleFilteredQuestionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ModuleFilteredQuestions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleFilteredQuestionsResponse {
    return new GetModuleFilteredQuestionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsResponse {
    return new GetModuleFilteredQuestionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsResponse {
    return new GetModuleFilteredQuestionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleFilteredQuestionsResponse | PlainMessage<GetModuleFilteredQuestionsResponse> | undefined, b: GetModuleFilteredQuestionsResponse | PlainMessage<GetModuleFilteredQuestionsResponse> | undefined): boolean {
    return proto3.util.equals(GetModuleFilteredQuestionsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.ModuleFilteredQuestions
 */
export class ModuleFilteredQuestions extends Message<ModuleFilteredQuestions> {
  /**
   * @generated from field: repeated geneo.content.db.Question questions = 1;
   */
  questions: Question[] = [];

  constructor(data?: PartialMessage<ModuleFilteredQuestions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.ModuleFilteredQuestions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: Question, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleFilteredQuestions {
    return new ModuleFilteredQuestions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleFilteredQuestions {
    return new ModuleFilteredQuestions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleFilteredQuestions {
    return new ModuleFilteredQuestions().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleFilteredQuestions | PlainMessage<ModuleFilteredQuestions> | undefined, b: ModuleFilteredQuestions | PlainMessage<ModuleFilteredQuestions> | undefined): boolean {
    return proto3.util.equals(ModuleFilteredQuestions, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsV2Request
 */
export class GetModuleFilteredQuestionsV2Request extends Message<GetModuleFilteredQuestionsV2Request> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * @generated from field: repeated int32 module_ids = 3;
   */
  moduleIds: number[] = [];

  /**
   * @generated from field: repeated geneo.content.db.Question.QuestionEnum required_question_types = 4;
   */
  requiredQuestionTypes: Question_QuestionEnum[] = [];

  /**
   * @generated from field: repeated geneo.content.db.DifficultyLevelEnum required_difficulty_types = 5;
   */
  requiredDifficultyTypes: DifficultyLevelEnum[] = [];

  /**
   * @generated from field: optional geneo.lms.hw.teacher.apis.RequiredQuestionCountInfoForDifficultyLevel required_difficulty_levels_count_info = 6;
   */
  requiredDifficultyLevelsCountInfo?: RequiredQuestionCountInfoForDifficultyLevel;

  /**
   * @generated from field: repeated string qIdsToBeIgnored = 7;
   */
  qIdsToBeIgnored: string[] = [];

  /**
   * @generated from field: repeated geneo.content.db.BloomLevelEnum bloom_levels = 8;
   */
  bloomLevels: BloomLevelEnum[] = [];

  constructor(data?: PartialMessage<GetModuleFilteredQuestionsV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "module_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "required_question_types", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum), repeated: true },
    { no: 5, name: "required_difficulty_types", kind: "enum", T: proto3.getEnumType(DifficultyLevelEnum), repeated: true },
    { no: 6, name: "required_difficulty_levels_count_info", kind: "message", T: RequiredQuestionCountInfoForDifficultyLevel, opt: true },
    { no: 7, name: "qIdsToBeIgnored", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "bloom_levels", kind: "enum", T: proto3.getEnumType(BloomLevelEnum), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleFilteredQuestionsV2Request {
    return new GetModuleFilteredQuestionsV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsV2Request {
    return new GetModuleFilteredQuestionsV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsV2Request {
    return new GetModuleFilteredQuestionsV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleFilteredQuestionsV2Request | PlainMessage<GetModuleFilteredQuestionsV2Request> | undefined, b: GetModuleFilteredQuestionsV2Request | PlainMessage<GetModuleFilteredQuestionsV2Request> | undefined): boolean {
    return proto3.util.equals(GetModuleFilteredQuestionsV2Request, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsV2Response
 */
export class GetModuleFilteredQuestionsV2Response extends Message<GetModuleFilteredQuestionsV2Response> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.ModuleFilteredQuestions data = 4;
   */
  data?: ModuleFilteredQuestions;

  constructor(data?: PartialMessage<GetModuleFilteredQuestionsV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetModuleFilteredQuestionsV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ModuleFilteredQuestions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleFilteredQuestionsV2Response {
    return new GetModuleFilteredQuestionsV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsV2Response {
    return new GetModuleFilteredQuestionsV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleFilteredQuestionsV2Response {
    return new GetModuleFilteredQuestionsV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleFilteredQuestionsV2Response | PlainMessage<GetModuleFilteredQuestionsV2Response> | undefined, b: GetModuleFilteredQuestionsV2Response | PlainMessage<GetModuleFilteredQuestionsV2Response> | undefined): boolean {
    return proto3.util.equals(GetModuleFilteredQuestionsV2Response, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetModuleQuestionStatsV2Request
 */
export class GetModuleQuestionStatsV2Request extends Message<GetModuleQuestionStatsV2Request> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: repeated int32 module_ids = 3;
   */
  moduleIds: number[] = [];

  constructor(data?: PartialMessage<GetModuleQuestionStatsV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetModuleQuestionStatsV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "module_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleQuestionStatsV2Request {
    return new GetModuleQuestionStatsV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleQuestionStatsV2Request {
    return new GetModuleQuestionStatsV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleQuestionStatsV2Request {
    return new GetModuleQuestionStatsV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleQuestionStatsV2Request | PlainMessage<GetModuleQuestionStatsV2Request> | undefined, b: GetModuleQuestionStatsV2Request | PlainMessage<GetModuleQuestionStatsV2Request> | undefined): boolean {
    return proto3.util.equals(GetModuleQuestionStatsV2Request, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.GetModuleQuestionStatsV2Response
 */
export class GetModuleQuestionStatsV2Response extends Message<GetModuleQuestionStatsV2Response> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.hw.teacher.apis.QuestionStatsList data = 4;
   */
  data?: QuestionStatsList;

  constructor(data?: PartialMessage<GetModuleQuestionStatsV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.GetModuleQuestionStatsV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: QuestionStatsList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetModuleQuestionStatsV2Response {
    return new GetModuleQuestionStatsV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetModuleQuestionStatsV2Response {
    return new GetModuleQuestionStatsV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetModuleQuestionStatsV2Response {
    return new GetModuleQuestionStatsV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: GetModuleQuestionStatsV2Response | PlainMessage<GetModuleQuestionStatsV2Response> | undefined, b: GetModuleQuestionStatsV2Response | PlainMessage<GetModuleQuestionStatsV2Response> | undefined): boolean {
    return proto3.util.equals(GetModuleQuestionStatsV2Response, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsRequest
 */
export class TeacherAssessmentClassStatsRequest extends Message<TeacherAssessmentClassStatsRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  constructor(data?: PartialMessage<TeacherAssessmentClassStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentClassStatsRequest {
    return new TeacherAssessmentClassStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsRequest {
    return new TeacherAssessmentClassStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsRequest {
    return new TeacherAssessmentClassStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentClassStatsRequest | PlainMessage<TeacherAssessmentClassStatsRequest> | undefined, b: TeacherAssessmentClassStatsRequest | PlainMessage<TeacherAssessmentClassStatsRequest> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentClassStatsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.StudentPerformanceInfo
 */
export class StudentPerformanceInfo extends Message<StudentPerformanceInfo> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string profile_img_url = 3;
   */
  profileImgUrl = "";

  /**
   * @generated from field: float score_percent = 4;
   */
  scorePercent = 0;

  /**
   * @generated from field: float response_score = 5;
   */
  responseScore = 0;

  /**
   * @generated from field: optional bool is_submitted = 6;
   */
  isSubmitted?: boolean;

  /**
   * @generated from field: optional bool is_evaluated = 7;
   */
  isEvaluated?: boolean;

  constructor(data?: PartialMessage<StudentPerformanceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.StudentPerformanceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "response_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "is_submitted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "is_evaluated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentPerformanceInfo {
    return new StudentPerformanceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentPerformanceInfo {
    return new StudentPerformanceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentPerformanceInfo {
    return new StudentPerformanceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: StudentPerformanceInfo | PlainMessage<StudentPerformanceInfo> | undefined, b: StudentPerformanceInfo | PlainMessage<StudentPerformanceInfo> | undefined): boolean {
    return proto3.util.equals(StudentPerformanceInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsResponse
 */
export class TeacherAssessmentClassStatsResponse extends Message<TeacherAssessmentClassStatsResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStats data = 4;
   */
  data?: TeacherAssessmentClassStats;

  constructor(data?: PartialMessage<TeacherAssessmentClassStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAssessmentClassStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentClassStatsResponse {
    return new TeacherAssessmentClassStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsResponse {
    return new TeacherAssessmentClassStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsResponse {
    return new TeacherAssessmentClassStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentClassStatsResponse | PlainMessage<TeacherAssessmentClassStatsResponse> | undefined, b: TeacherAssessmentClassStatsResponse | PlainMessage<TeacherAssessmentClassStatsResponse> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentClassStatsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStats
 */
export class TeacherAssessmentClassStats extends Message<TeacherAssessmentClassStats> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask assessment = 1;
   */
  assessment?: AssessmentTask;

  /**
   * @generated from field: repeated geneo.lms.assessment.teacher.apis.StudentPerformanceInfo student_performance_info = 2;
   */
  studentPerformanceInfo: StudentPerformanceInfo[] = [];

  /**
   * @generated from field: int32 assigned_students_count = 3;
   */
  assignedStudentsCount = 0;

  /**
   * @generated from field: int32 students_submission_count = 4;
   */
  studentsSubmissionCount = 0;

  constructor(data?: PartialMessage<TeacherAssessmentClassStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: AssessmentTask },
    { no: 2, name: "student_performance_info", kind: "message", T: StudentPerformanceInfo, repeated: true },
    { no: 3, name: "assigned_students_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "students_submission_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentClassStats {
    return new TeacherAssessmentClassStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStats {
    return new TeacherAssessmentClassStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStats {
    return new TeacherAssessmentClassStats().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentClassStats | PlainMessage<TeacherAssessmentClassStats> | undefined, b: TeacherAssessmentClassStats | PlainMessage<TeacherAssessmentClassStats> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentClassStats, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.DeleteTeacherCreatedAssessmentRequest
 */
export class DeleteTeacherCreatedAssessmentRequest extends Message<DeleteTeacherCreatedAssessmentRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  constructor(data?: PartialMessage<DeleteTeacherCreatedAssessmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.DeleteTeacherCreatedAssessmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTeacherCreatedAssessmentRequest {
    return new DeleteTeacherCreatedAssessmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTeacherCreatedAssessmentRequest {
    return new DeleteTeacherCreatedAssessmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTeacherCreatedAssessmentRequest {
    return new DeleteTeacherCreatedAssessmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTeacherCreatedAssessmentRequest | PlainMessage<DeleteTeacherCreatedAssessmentRequest> | undefined, b: DeleteTeacherCreatedAssessmentRequest | PlainMessage<DeleteTeacherCreatedAssessmentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteTeacherCreatedAssessmentRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.DeleteTeacherCreatedAssessmentResponse
 */
export class DeleteTeacherCreatedAssessmentResponse extends Message<DeleteTeacherCreatedAssessmentResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  constructor(data?: PartialMessage<DeleteTeacherCreatedAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.DeleteTeacherCreatedAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTeacherCreatedAssessmentResponse {
    return new DeleteTeacherCreatedAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTeacherCreatedAssessmentResponse {
    return new DeleteTeacherCreatedAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTeacherCreatedAssessmentResponse {
    return new DeleteTeacherCreatedAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTeacherCreatedAssessmentResponse | PlainMessage<DeleteTeacherCreatedAssessmentResponse> | undefined, b: DeleteTeacherCreatedAssessmentResponse | PlainMessage<DeleteTeacherCreatedAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(DeleteTeacherCreatedAssessmentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2Request
 */
export class TeacherAssessmentClassStatsV2Request extends Message<TeacherAssessmentClassStatsV2Request> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 assessment_id = 2;
   */
  assessmentId = 0;

  constructor(data?: PartialMessage<TeacherAssessmentClassStatsV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "assessment_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentClassStatsV2Request {
    return new TeacherAssessmentClassStatsV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsV2Request {
    return new TeacherAssessmentClassStatsV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsV2Request {
    return new TeacherAssessmentClassStatsV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentClassStatsV2Request | PlainMessage<TeacherAssessmentClassStatsV2Request> | undefined, b: TeacherAssessmentClassStatsV2Request | PlainMessage<TeacherAssessmentClassStatsV2Request> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentClassStatsV2Request, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2Response
 */
export class TeacherAssessmentClassStatsV2Response extends Message<TeacherAssessmentClassStatsV2Response> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2 data = 4;
   */
  data?: TeacherAssessmentClassStatsV2;

  constructor(data?: PartialMessage<TeacherAssessmentClassStatsV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherAssessmentClassStatsV2 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentClassStatsV2Response {
    return new TeacherAssessmentClassStatsV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsV2Response {
    return new TeacherAssessmentClassStatsV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsV2Response {
    return new TeacherAssessmentClassStatsV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentClassStatsV2Response | PlainMessage<TeacherAssessmentClassStatsV2Response> | undefined, b: TeacherAssessmentClassStatsV2Response | PlainMessage<TeacherAssessmentClassStatsV2Response> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentClassStatsV2Response, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2
 */
export class TeacherAssessmentClassStatsV2 extends Message<TeacherAssessmentClassStatsV2> {
  /**
   * @generated from field: geneo.lms.assessment.common.apis.AssessmentTask assessment = 1;
   */
  assessment?: AssessmentTask;

  /**
   * @generated from field: repeated geneo.lms.assessment.teacher.apis.StudentPerformanceInfo student_performance_info = 2;
   */
  studentPerformanceInfo: StudentPerformanceInfo[] = [];

  /**
   * @generated from field: int32 assigned_students_count = 3;
   */
  assignedStudentsCount = 0;

  /**
   * @generated from field: int32 students_submission_count = 4;
   */
  studentsSubmissionCount = 0;

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.AssessmentReportInfo assessment_report_info = 5;
   */
  assessmentReportInfo?: AssessmentReportInfo;

  constructor(data?: PartialMessage<TeacherAssessmentClassStatsV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.TeacherAssessmentClassStatsV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: AssessmentTask },
    { no: 2, name: "student_performance_info", kind: "message", T: StudentPerformanceInfo, repeated: true },
    { no: 3, name: "assigned_students_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "students_submission_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "assessment_report_info", kind: "message", T: AssessmentReportInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherAssessmentClassStatsV2 {
    return new TeacherAssessmentClassStatsV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsV2 {
    return new TeacherAssessmentClassStatsV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherAssessmentClassStatsV2 {
    return new TeacherAssessmentClassStatsV2().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherAssessmentClassStatsV2 | PlainMessage<TeacherAssessmentClassStatsV2> | undefined, b: TeacherAssessmentClassStatsV2 | PlainMessage<TeacherAssessmentClassStatsV2> | undefined): boolean {
    return proto3.util.equals(TeacherAssessmentClassStatsV2, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.AssessmentReportInfo
 */
export class AssessmentReportInfo extends Message<AssessmentReportInfo> {
  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.BloomLevelBreackdown bloom_level_breackdown = 1;
   */
  bloomLevelBreackdown?: BloomLevelBreackdown;

  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.StrengthAndWeakness strengths_and_weaknessess = 2;
   */
  strengthsAndWeaknessess?: StrengthAndWeakness;

  constructor(data?: PartialMessage<AssessmentReportInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.AssessmentReportInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bloom_level_breackdown", kind: "message", T: BloomLevelBreackdown },
    { no: 2, name: "strengths_and_weaknessess", kind: "message", T: StrengthAndWeakness },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentReportInfo {
    return new AssessmentReportInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentReportInfo {
    return new AssessmentReportInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentReportInfo {
    return new AssessmentReportInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentReportInfo | PlainMessage<AssessmentReportInfo> | undefined, b: AssessmentReportInfo | PlainMessage<AssessmentReportInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentReportInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.BloomLevelBreackdown
 */
export class BloomLevelBreackdown extends Message<BloomLevelBreackdown> {
  /**
   * @generated from field: int32 creation_score = 1;
   */
  creationScore = 0;

  /**
   * @generated from field: int32 evaluation_score = 2;
   */
  evaluationScore = 0;

  /**
   * @generated from field: int32 application_score = 3;
   */
  applicationScore = 0;

  /**
   * @generated from field: int32 analysis_score = 4;
   */
  analysisScore = 0;

  /**
   * @generated from field: int32 comprehension_score = 5;
   */
  comprehensionScore = 0;

  /**
   * @generated from field: int32 retentionScore = 6;
   */
  retentionScore = 0;

  /**
   * @generated from field: string overallStatement = 7;
   */
  overallStatement = "";

  constructor(data?: PartialMessage<BloomLevelBreackdown>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.BloomLevelBreackdown";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creation_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "evaluation_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "application_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "analysis_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "comprehension_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "retentionScore", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "overallStatement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BloomLevelBreackdown {
    return new BloomLevelBreackdown().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BloomLevelBreackdown {
    return new BloomLevelBreackdown().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BloomLevelBreackdown {
    return new BloomLevelBreackdown().fromJsonString(jsonString, options);
  }

  static equals(a: BloomLevelBreackdown | PlainMessage<BloomLevelBreackdown> | undefined, b: BloomLevelBreackdown | PlainMessage<BloomLevelBreackdown> | undefined): boolean {
    return proto3.util.equals(BloomLevelBreackdown, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.StrengthAndWeakness
 */
export class StrengthAndWeakness extends Message<StrengthAndWeakness> {
  /**
   * @generated from field: repeated string strength = 1;
   */
  strength: string[] = [];

  /**
   * @generated from field: repeated string weakness = 2;
   */
  weakness: string[] = [];

  constructor(data?: PartialMessage<StrengthAndWeakness>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.StrengthAndWeakness";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "strength", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "weakness", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StrengthAndWeakness {
    return new StrengthAndWeakness().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StrengthAndWeakness {
    return new StrengthAndWeakness().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StrengthAndWeakness {
    return new StrengthAndWeakness().fromJsonString(jsonString, options);
  }

  static equals(a: StrengthAndWeakness | PlainMessage<StrengthAndWeakness> | undefined, b: StrengthAndWeakness | PlainMessage<StrengthAndWeakness> | undefined): boolean {
    return proto3.util.equals(StrengthAndWeakness, a, b);
  }
}

/**
 * @generated from message geneo.lms.assessment.teacher.apis.ClassLevelPerformanceInfo
 */
export class ClassLevelPerformanceInfo extends Message<ClassLevelPerformanceInfo> {
  /**
   * @generated from field: geneo.lms.assessment.teacher.apis.PerformanceEnum performance_type = 1;
   */
  performanceType = PerformanceEnum.PERFORMANCE_UNDEFINED;

  /**
   * @generated from field: int32 no_of_students = 2;
   */
  noOfStudents = 0;

  /**
   * @generated from field: int32 percentage_of_students = 3;
   */
  percentageOfStudents = 0;

  constructor(data?: PartialMessage<ClassLevelPerformanceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.assessment.teacher.apis.ClassLevelPerformanceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "performance_type", kind: "enum", T: proto3.getEnumType(PerformanceEnum) },
    { no: 2, name: "no_of_students", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "percentage_of_students", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassLevelPerformanceInfo {
    return new ClassLevelPerformanceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassLevelPerformanceInfo {
    return new ClassLevelPerformanceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassLevelPerformanceInfo {
    return new ClassLevelPerformanceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ClassLevelPerformanceInfo | PlainMessage<ClassLevelPerformanceInfo> | undefined, b: ClassLevelPerformanceInfo | PlainMessage<ClassLevelPerformanceInfo> | undefined): boolean {
    return proto3.util.equals(ClassLevelPerformanceInfo, a, b);
  }
}

