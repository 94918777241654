import { Interceptor, PromiseClient } from '@bufbuild/connect';
import {
  useAiStudentAPIServiceV1ClientWithStatusCodeHandler,
  useCommonDbAPIServiceV1ClientWithStatusCodeHandler,
  useContentCommonAPIServiceV1ClientWithStatusCodeHandler,
  useContentParserAPIServiceV1ClientWithStatusCodeHandler,
  useLessonCommonAPIServiceV1ClientWithStatusCodeHandler,
  useLessonLearnAPIServiceV1ClientWithStatusCodeHandler,
  useLessonTeachAPIServiceV1ClientWithStatusCodeHandler,
  useLmsBookAPIServiceV1ClientWithStatusCodeHandler,
  useLmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  useLmsConnectedClassAPIServiceV1ClientWithStatusCodeHandler,
  useLmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler,
  useLmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler,
  useLmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
  useLmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler,
  useLmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
  useLmsStudentAPIServiceV1ClientWithStatusCodeHandler,
  useLmsTeacherAPIServiceV1ClientWithStatusCodeHandler,
  useLmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
  useSchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler,
  useStudentAnalysisAPIServiceV1ClientWithStatusCodeHandler,
  useStudentEventsAPIServiceV1ClientWithStatusCodeHandler,
  useTeacherAnalysisAPIServiceV1ClientWithStatusCodeHandler,
  useTeacherEventsAPIServiceV1ClientWithStatusCodeHandler,
  useUMSLoginAPIServiceV1ClientWithLStatusCodeHandler,
  useUmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler,
  useUmsSsoAPIServiceV1ClientWithStatusCodeHandler,
  useUmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  useTeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler,
  useLmsAdminAPIServiceV1ClientWithStatusCodeHandler,
  useAdminUploadContentAPIServiceV1ClientWithStatusCodeHandler,
  useLmsCommonAPIServiceV2ClientWithStatusCodeHandler,
  useContentCommonAPIServiceV2ClientWithStatusCodeHandler,
  useLmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler,
  useLmsAssessmentStudentAPIServiceV1ClientWithStatusCodeHandler,
  useLmsSchoolAdminAPIServiceV1ClientWithStatusCodeHandler,
  useLmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler,
} from '@geneo2-web/services-clients';
import { SchoolAssessmentAPIServiceV1 } from '@protos/analysis_management/analysis.assessment.apis_connect';
import { StudentAnalysisAPIServiceV1 } from '@protos/analysis_management/analysis.student.apis_connect';
import { TeacherAnalysisAPIServiceV1 } from '@protos/analysis_management/analysis.teacher.apis_connect';
import { CommonDbAPIServiceV1 } from '@protos/common/common.db.apis_connect';
import { ContentParserAPIServiceV1 } from '@protos/content_management/cms.content.apis_connect';
import {
  ContentCommonAPIServiceV1,
  ContentCommonAPIServiceV2,
} from '@protos/content_management/content.common.apis_connect';
import {
  LmsAssessmentTeacherAPIServiceV1,
  LmsAssessmentTeacherAPIServiceV2,
} from '@protos/learning_management/lms.assessment.teacher.apis_connect';
import { StudentEventsAPIServiceV1 } from '@protos/geneo_ai/ai.student.events.apis_connect';
import { AiStudentAPIServiceV1 } from '@protos/geneo_ai/ai.student.recommendation.apis_connect';
import { TeacherEventsAPIServiceV1 } from '@protos/geneo_ai/ai.teacher.events.apis_connect';
import { LmsAdminPortalAPIServiceV1 } from '@protos/learning_management/lms.admin.portal.apis_connect';
import { LmsBookAPIServiceV1 } from '@protos/learning_management/lms.book.apis_connect';
import {
  LmsCommonAPIServiceV1,
  LmsCommonAPIServiceV2,
} from '@protos/learning_management/lms.common.apis_connect';
import { LmsConnectedClassAPIServiceV1 } from '@protos/learning_management/lms.connected.apis_connect';
import { LmsHomeworkCommonAPIServiceV1 } from '@protos/learning_management/lms.hw.common.apis_connect';
import {
  LmsHomewokStudentAPIServiceV1,
  LmsHomewokStudentAPIServiceV2,
} from '@protos/learning_management/lms.hw.student.apis_connect';
import {
  LmsHomewokTeacherAPIServiceV1,
  LmsHomewokTeacherAPIServiceV2,
} from '@protos/learning_management/lms.hw.teacher.apis_connect';
import { LessonCommonAPIServiceV1 } from '@protos/learning_management/lms.lesson.common.apis_connect';
import { LessonLearnAPIServiceV1 } from '@protos/learning_management/lms.lesson.learn.apis_connect';
import { LessonTeachAPIServiceV1 } from '@protos/learning_management/lms.lesson.teach.apis_connect';
import { LmsStudentAPIServiceV1 } from '@protos/learning_management/lms.student.apis_connect';
import { LmsTeacherAPIServiceV1 } from '@protos/learning_management/lms.teacher.apis_connect';
import { LmsAssessmentCommonAPIServiceV1 } from '@protos/learning_management/lms.assessment.common.apis_connect';
import { UmsUtilityApiServiceV1 } from '@protos/user_management/ums.common.apis_connect';
import { UMSLoginAPIServiceV1 } from '@protos/user_management/ums.login.apis_connect';
import { UmsSsoLoginApiServiceV1 } from '@protos/user_management/ums.login.sso.apis_connect';
import { UmsSelfRegistrationAPIServiceV1 } from '@protos/user_management/ums.self_registration.apis_connect';
import { AdminUploadContentAPIServiceV1 } from '@protos/content_management/cms.admin.content.apis_connect';
import { LmsAssessmentStudentAPIServiceV1 } from '@protos/learning_management/lms.assessment.student.apis_connect';

import React, { ReactNode, createContext, useContext } from 'react';
import { TeacherUploadContentAPIServiceV1 } from '@protos/content_management/cms.teacher.content.apis_connect';
import { LmsSchoolAdminAPIServiceV1 } from '@protos/learning_management/lms.school.apis_connect';

// Define the shape of your context data
export interface CommonServiceClientContextData {
  UMSLoginAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof UMSLoginAPIServiceV1
  >;
  UmsUtilityAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof UmsUtilityApiServiceV1
  >;
  TeacherAnalysisAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof TeacherAnalysisAPIServiceV1
  >;
  AiStudentAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof AiStudentAPIServiceV1
  >;
  CommonDbAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof CommonDbAPIServiceV1
  >;
  ContentCommonAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof ContentCommonAPIServiceV1
  >;
  ContentCommonAPIServiceV2ClientWithStatusCodeHandler: PromiseClient<
    typeof ContentCommonAPIServiceV2
  >;
  ContentParserAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof ContentParserAPIServiceV1
  >;
  LessonCommonAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LessonCommonAPIServiceV1
  >;
  LessonLearnAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LessonLearnAPIServiceV1
  >;
  LessonTeachAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LessonTeachAPIServiceV1
  >;
  LmsBookAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsBookAPIServiceV1
  >;
  LmsCommonAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsCommonAPIServiceV1
  >;
  LmsCommonAPIServiceV2ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsCommonAPIServiceV2
  >;
  LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsHomewokStudentAPIServiceV1
  >;
  LmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsHomewokStudentAPIServiceV2
  >;
  LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsHomewokTeacherAPIServiceV1
  >;
  LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsHomeworkCommonAPIServiceV1
  >;
  LmsStudentAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsStudentAPIServiceV1
  >;
  LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsAssessmentStudentAPIServiceV1
  >;
  LmsTeacherAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsTeacherAPIServiceV1
  >;
  LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsAssessmentCommonAPIServiceV1
  >;
  StudentAnalysisAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof StudentAnalysisAPIServiceV1
  >;
  StudentEventsAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof StudentEventsAPIServiceV1
  >;
  TeacherEventsAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof TeacherEventsAPIServiceV1
  >;
  LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler: PromiseClient<
    typeof LmsConnectedClassAPIServiceV1
  >;
  LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsHomewokTeacherAPIServiceV2
  >;
  UmsSsoAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof UmsSsoLoginApiServiceV1
  >;
  UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler: PromiseClient<
    typeof UmsSelfRegistrationAPIServiceV1
  >;
  TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof TeacherUploadContentAPIServiceV1
  >;
  SchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof SchoolAssessmentAPIServiceV1
  >;
  LmsAdminAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof LmsAdminPortalAPIServiceV1
  >;
  CmsAdminUploadContentAPIServiceV1ClientWithStatusCodeHandler: PromiseClient<
    typeof AdminUploadContentAPIServiceV1
  >;
  LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler: PromiseClient<
    typeof LmsAssessmentTeacherAPIServiceV1
  >;
  LmsSchoolAdminAPIServiceV1WithStatusCodeHandler: PromiseClient<
    typeof LmsSchoolAdminAPIServiceV1
  >;
  LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler: PromiseClient<
    typeof LmsAssessmentTeacherAPIServiceV2
  >;
}

// Create the context
const commonServiceClientContext = createContext<
  CommonServiceClientContextData | undefined
>(undefined);

const { Provider } = commonServiceClientContext;

// Create a provider component
interface IProviderProps {
  children: ReactNode;
  responseStatusCodeFunction: {
    [key: string]: () => void;
  };
  interceptors?: Interceptor[];
}

export const CommonServiceClientContextProvider: React.FC<IProviderProps> = ({
  children,
  responseStatusCodeFunction,
  interceptors,
}) => {
  const UMSLoginAPIServiceV1ClientWithStatusCodeHandler =
    useUMSLoginAPIServiceV1ClientWithLStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const UmsUtilityAPIServiceV1ClientWithStatusCodeHandler =
    useUmsUtilityAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const TeacherAnalysisAPIServiceV1ClientWithStatusCodeHandler =
    useTeacherAnalysisAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const AiStudentAPIServiceV1ClientWithStatusCodeHandler =
    useAiStudentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const CommonDbAPIServiceV1ClientWithStatusCodeHandler =
    useCommonDbAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const ContentCommonAPIServiceV1ClientWithStatusCodeHandler =
    useContentCommonAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const ContentCommonAPIServiceV2ClientWithStatusCodeHandler =
    useContentCommonAPIServiceV2ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const ContentParserAPIServiceV1ClientWithStatusCodeHandler =
    useContentParserAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LessonCommonAPIServiceV1ClientWithStatusCodeHandler =
    useLessonCommonAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LessonLearnAPIServiceV1ClientWithStatusCodeHandler =
    useLessonLearnAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LessonTeachAPIServiceV1ClientWithStatusCodeHandler =
    useLessonTeachAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsBookAPIServiceV1ClientWithStatusCodeHandler =
    useLmsBookAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsCommonAPIServiceV1ClientWithStatusCodeHandler =
    useLmsCommonAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsCommonAPIServiceV2ClientWithStatusCodeHandler =
    useLmsCommonAPIServiceV2ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler =
    useLmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler =
    useLmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler =
    useLmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler =
    useLmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsStudentAPIServiceV1ClientWithStatusCodeHandler =
    useLmsStudentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsTeacherAPIServiceV1ClientWithStatusCodeHandler =
    useLmsTeacherAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler =
    useLmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const StudentAnalysisAPIServiceV1ClientWithStatusCodeHandler =
    useStudentAnalysisAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const StudentEventsAPIServiceV1ClientWithStatusCodeHandler =
    useStudentEventsAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const TeacherEventsAPIServiceV1ClientWithStatusCodeHandler =
    useTeacherEventsAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler =
    useLmsConnectedClassAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler =
    useLmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const UmsSsoAPIServiceV1ClientWithStatusCodeHandler =
    useUmsSsoAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler =
    useUmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler =
    useTeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const SchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler =
    useSchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsAdminAPIServiceV1ClientWithStatusCodeHandler =
    useLmsAdminAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const CmsAdminUploadContentAPIServiceV1ClientWithStatusCodeHandler =
    useAdminUploadContentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler =
    useLmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler =
    useLmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler =
    useLmsAssessmentStudentAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );
  const LmsSchoolAdminAPIServiceV1WithStatusCodeHandler =
    useLmsSchoolAdminAPIServiceV1ClientWithStatusCodeHandler(
      {
        interceptors: interceptors,
      },
      responseStatusCodeFunction
    );

  const contextValue: CommonServiceClientContextData = {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
    TeacherAnalysisAPIServiceV1ClientWithStatusCodeHandler,
    AiStudentAPIServiceV1ClientWithStatusCodeHandler,
    CommonDbAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
    ContentParserAPIServiceV1ClientWithStatusCodeHandler,
    LessonCommonAPIServiceV1ClientWithStatusCodeHandler,
    LessonLearnAPIServiceV1ClientWithStatusCodeHandler,
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    LmsBookAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomewokStudentAPIServiceV2ClientWithStatusCodeHandler,
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsStudentAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentStudentApiServiceV1ClientWithStatusCodeHandler,
    LmsTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
    StudentAnalysisAPIServiceV1ClientWithStatusCodeHandler,
    StudentEventsAPIServiceV1ClientWithStatusCodeHandler,
    TeacherEventsAPIServiceV1ClientWithStatusCodeHandler,
    LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler,
    LmsHomewokTeacherAPIServiceV2ClientWithStatusCodeHandler,
    UmsSsoAPIServiceV1ClientWithStatusCodeHandler,
    UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler,
    TeacherUploadContentAPIServiceV1ClientWithStatusCodeHandler,
    SchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler,
    LmsAdminAPIServiceV1ClientWithStatusCodeHandler,
    CmsAdminUploadContentAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV2ClientWithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV1WithStatusCodeHandler,
    LmsSchoolAdminAPIServiceV1WithStatusCodeHandler,
    LmsAssessmentTeacherAPIServiceV2WithStatusCodeHandler,
  };
  return <Provider value={contextValue}>{children}</Provider>;
};

export const useCommonServiceClientContext = () => {
  const context = useContext(commonServiceClientContext);
  if (!context) {
    throw new Error(
      'useCommonServiceClientContext must be used within a MyContextProvider'
    );
  }
  return context;
};
