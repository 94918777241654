import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';
import {
  ImageOption,
  McqMultipleContentModel,
  McqSingleContentModel,
  QuestionContentModel,
  ResourceCategoryEnum,
  TextOption,
  TfContentModel_AnswerEnum,
} from '@protos/content_management/content.db_pb';
import { useEffect, useRef, useState } from 'react';
import {
  firstLetterImage,
  getMediaBasePath,
} from '../../../../commonUtils/images';
import { pxToRem, pxTovW } from '../../../../commonUtils/resizeUtils';
import { IStyles } from '../../../../commonUtils/styleUtils';
import ImageWrapper from '../../../elements/ImageWrapper';
import InputField from '../../../elements/InputField';
import ElementRenderer from '../../ElementRenderer';
import { SelectedResourceInfo } from '../../VideoPlayerWrapper/VideoPlayerWrapper';

import { QuestionEventCollection } from '../QuestionContainerWithSolution';
import TypographyHtml from '../TypographyHtml';
interface QuestionProps {
  questionContent?: QuestionContentModel;
  disableInput?: boolean;
  userAttemptedAnswer?: string[];
  onDisableButtonClick?: () => void;
  handleAnswerChange?: (value: string, index?: number) => void;
  questionStyles?: IStyles;
  homeworkAttempt?: boolean;
  scanClickHandler?: () => void;
  selected_resource_info?: SelectedResourceInfo;
  disableScan?: boolean;
  eventCollection?: QuestionEventCollection;
  resourceCategory?: ResourceCategoryEnum;
}

declare global {
  interface Window {
    Android: any;
  }
}

const tfOption: SxProps = {
  cursor: 'pointer',
  width: '100%',
  flexGrow: 1,
  padding: {
    xs: pxToRem(17),
    md: pxTovW(17),
  },
  borderRadius: '5px',
  textAlign: 'center',
};

const optionStyles: SxProps = {
  cursor: 'pointer',
  border: '1.5px solid #1023F280',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  padding: {
    xs: pxToRem(16),
    md: pxTovW(16),
  },
  width: '100%',
};

const styles: IStyles = {
  optionWrapper: optionStyles,
  selectedOptionWrapper: {
    ...optionStyles,
    backgroundColor: '#D9EDFD',
    border: '1.5px solid #1023F2',
  },
  checkbox: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: pxToRem(20),
    },
    marginRight: {
      xs: pxToRem(10),
      // md: pxToRem(45),
    },
    color: '#007CDC',
    '&.Mui-checked': {
      color: '#007CDC',
    },
  },
  trueFalseWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginY: {
      xs: pxToRem(10),
      md: pxTovW(10),
    },
  },
  true: {
    ...tfOption,
    marginRight: {
      xs: pxToRem(7.5),
      md: pxTovW(7.5),
    },
    border: '0.8px solid #0af274',
    backgroundColor: '#c1f8da',
  },
  false: {
    ...tfOption,
    marginLeft: {
      xs: pxToRem(7.5),
      md: pxTovW(7.5),
    },
    border: '0.8px solid #FFA8A8',
    backgroundColor: '#FFEEEE',
  },
  selectedTrue: {
    ...tfOption,
    marginRight: {
      xs: pxToRem(7.5),
      md: pxTovW(7.5),
    },
    border: '2px solid #03D563',
    backgroundColor: '#60F9A6',
    '&>span': {
      fontWeight: 'bold',
    },
  },
  selectedFalse: {
    ...tfOption,
    marginLeft: {
      xs: pxToRem(7.5),
      md: pxTovW(7.5),
    },
    border: '2px solid #F88A8A',
    backgroundColor: '#FDCFCF',
    '&>span': {
      fontWeight: 'bold',
    },
  },
};

export default function Question(props: QuestionProps) {
  const {
    questionContent,
    disableInput,
    handleAnswerChange,
    userAttemptedAnswer,
    onDisableButtonClick,
    questionStyles,
    homeworkAttempt,
    scanClickHandler,
    selected_resource_info,
    disableScan,
    eventCollection,
    resourceCategory,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Function to update container width
  const updateContainerWidth = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };

  useEffect(() => {
    updateContainerWidth(); // Set initial width
    window.addEventListener('resize', updateContainerWidth);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  if (!questionContent) {
    return null;
  }
  const questionCase = questionContent.model.case;
  if (!questionCase) {
    return null;
  }
  const questionValue = questionContent.model.value;
  const content = questionValue?.commonQuestionContent;
  const elements = content?.elements || [];

  const renderElements = () => {
    return (
      <ElementRenderer
        elements={elements}
        isFibText={questionCase === 'fibContentModel'}
        handleAnswerChange={handleAnswerChange}
        userAttemptedAnswer={userAttemptedAnswer}
        disableInput={disableInput}
        selected_resource_info={selected_resource_info}
        resourceCategory={resourceCategory}
      />
    );
  };

  const renderOptions = () => {
    if (questionCase === 'tfContentModel') {
      const trueSelected =
        userAttemptedAnswer &&
        userAttemptedAnswer[0] ===
          TfContentModel_AnswerEnum.TF_ANSWER_T.toString();
      const falseSelected =
        userAttemptedAnswer &&
        userAttemptedAnswer[0] ===
          TfContentModel_AnswerEnum.TF_ANSWER_F.toString();
      return (
        <Box sx={styles.trueFalseWrapper}>
          <Box
            aria-disabled="true"
            sx={trueSelected ? styles.selectedTrue : styles.true}
            onClick={() => {
              if (disableInput) {
                return;
              }
              if (handleAnswerChange) {
                handleAnswerChange(
                  TfContentModel_AnswerEnum.TF_ANSWER_T.toString()
                );
              }
              eventCollection?.answerSelection &&
                eventCollection?.answerSelection();
            }}
          >
            <Typography variant="elementBodyText">TRUE</Typography>
          </Box>
          <Box
            sx={falseSelected ? styles.selectedFalse : styles.false}
            onClick={() => {
              if (disableInput) {
                return;
              }
              if (handleAnswerChange) {
                handleAnswerChange(
                  TfContentModel_AnswerEnum.TF_ANSWER_F.toString()
                );
              }
              eventCollection?.answerSelection &&
                eventCollection?.answerSelection();
            }}
          >
            <Typography variant="elementBodyText">FALSE</Typography>
          </Box>
        </Box>
      );
    }
    if (
      ['mcqSingleContentModel', 'mcqMultipleContentModel'].includes(
        questionCase
      )
    ) {
      const hasOptions =
        questionValue instanceof McqMultipleContentModel ||
        questionValue instanceof McqSingleContentModel;
      const options = hasOptions ? questionValue.options : [];
      const optionLength = options.map((opt) => {
        const val = opt.optionType.value;
        if (val instanceof TextOption) {
          return val.optionText.length;
        }
        return 0;
      });
      const maxLength = Math.max(...optionLength);
      const type = optionType(maxLength, questionCase, containerWidth || 0);
      return (
        <Grid sx={{ marginTop: 0 }} container spacing={2}>
          {options.map((option, index) => {
            const isChecked =
              !!userAttemptedAnswer &&
              userAttemptedAnswer?.includes((index + 1).toString());
            const optionCase = option.optionType.case;
            const optionValue = option.optionType.value;
            const optionStyles =
              questionCase === 'mcqSingleContentModel' && isChecked
                ? styles.selectedOptionWrapper
                : styles.optionWrapper;
            switch (optionCase) {
              case 'imageOption':
                return optionValue instanceof ImageOption ? (
                  <Grid
                    key={index}
                    sx={{ display: 'flex' }}
                    item
                    xs={12}
                    md={6}
                    onClick={() => {
                      eventCollection?.answerSelection &&
                        eventCollection?.answerSelection();
                    }}
                  >
                    <Box
                      sx={{ ...optionStyles, alignItems: 'center' }}
                      onClick={() => {
                        if (disableInput) {
                          return;
                        }
                        if (handleAnswerChange) {
                          handleAnswerChange((index + 1).toString());
                        }
                      }}
                    >
                      {questionCase === 'mcqMultipleContentModel' && (
                        <Checkbox
                          sx={styles.checkbox}
                          checked={isChecked ? true : false}
                        />
                      )}
                      <Typography
                        variant="elementBodyText"
                        sx={{ marginRight: pxToRem(8) }}
                      >
                        <ImageWrapper
                          styles={{
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                          }}
                          name="mn"
                          path={firstLetterImage(
                            convertIndexIntoOptions(index)
                          )}
                        />
                      </Typography>
                      <ImageWrapper
                        path={getMediaBasePath(
                          optionValue.image?.imageUrl,
                          resourceCategory ===
                            ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                            ? 'userDataBucket'
                            : 'processedMediaBucket'
                        )}
                        name={optionValue.image?.imageName || 'image'}
                        styles={{
                          width: '80%',
                          marginLeft: '5px',
                        }}
                      />
                    </Box>
                  </Grid>
                ) : null;
              case 'textOption':
                // console.log(type);
                return optionValue instanceof TextOption ? (
                  <Grid
                    key={index}
                    sx={{ display: 'flex' }}
                    item
                    xs={12}
                    md={
                      type === 'shortOption' || type === 'multipleOption'
                        ? 6
                        : 12
                    }
                    onClick={() => {
                      eventCollection?.answerSelection &&
                        eventCollection?.answerSelection();
                    }}
                  >
                    <Box
                      sx={{
                        ...optionStyles,
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                      onClick={() => {
                        if (disableInput) {
                          return;
                        }
                        if (handleAnswerChange) {
                          handleAnswerChange((index + 1).toString());
                        }
                      }}
                    >
                      {questionCase === 'mcqMultipleContentModel' && (
                        <Checkbox
                          sx={styles.checkbox}
                          checked={isChecked ? true : false}
                        />
                      )}
                      <Typography
                        variant="elementBodyText"
                        sx={{ marginRight: pxToRem(8) }}
                      >
                        <ImageWrapper
                          styles={{
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                          }}
                          name="mn"
                          path={firstLetterImage(
                            convertIndexIntoOptions(index)
                          )}
                        />
                      </Typography>
                      <TypographyHtml
                        variant="elementBodyText"
                        sx={{ marginX: 'auto' }}
                      >
                        {optionValue.optionText}
                      </TypographyHtml>
                    </Box>
                  </Grid>
                ) : null;
            }
          })}
        </Grid>
      );
    }
    if (
      [
        'veryShortQuesContentModel',
        'shortQuesContentModel',
        'longQuesContentModel',
      ].includes(questionCase) &&
      homeworkAttempt
    ) {
      const rowsToShow =
        questionCase === 'longQuesContentModel'
          ? 8
          : questionCase === 'shortQuesContentModel'
          ? 5
          : 3;
      return (
        <Box sx={{ marginY: { xs: pxToRem(30), md: pxTovW(30) } }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3">Your Answer</Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: { xs: pxToRem(27), md: pxTovW(27) },
                width: { xs: pxToRem(95), md: pxTovW(156) },
                gap: '4px',
              }}
              onClick={scanClickHandler}
              disabled={disableScan}
            >
              <CameraAltIcon />
              SCAN
            </Button>
          </Box>
          <Box sx={{ marginY: { xs: pxToRem(15), md: pxTovW(15) } }}>
            <InputField
              variant="outlined"
              fullWidth
              multiline
              rows={rowsToShow}
              // value={userAttemptedAnswer && userAttemptedAnswer[0]}
              value={
                userAttemptedAnswer && userAttemptedAnswer.length > 0
                  ? userAttemptedAnswer[0]
                  : ''
              }
              onChange={(ev) => {
                if (handleAnswerChange) {
                  handleAnswerChange(ev.target.value);
                }
              }}
              disablePaste={true}
            />
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box ref={containerRef}>
      {renderElements()}
      {renderOptions()}
    </Box>
  );
}

export type OptionType =
  | 'shortOption'
  | 'longOption'
  | 'multipleOption'
  | 'multipleOptionLong';

const optionType = (
  optLength: number,
  type: string,
  containerWidth: number
): OptionType => {
  if (!containerWidth) {
    return 'longOption';
  }
  if (type === 'mcqSingleContentModel') {
    // Assuming one letter taks 12px width
    // Here 5 represents the gap withing the option
    // 4 represents the border of the option
    return optLength * 12 < containerWidth / 2 - 5 - 2
      ? 'shortOption'
      : 'longOption';
  } else {
    //long increases the width of the element so that more characters can be placed
    // 65 px is the width of checkbox (with margin)
    return optLength * 12 < containerWidth / 2 - 5 - 2 - 65
      ? 'multipleOption'
      : 'multipleOptionLong';
  }
};

const convertIndexIntoOptions = (index: number) => {
  return String.fromCharCode(65 + index) + '.  ';
};
