import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { IStyles, IconWrapper, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const styles: IStyles = {
  root: {},
  tableContainer: {
    border: '1px solid #E8E8E8',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
  },
  headerRow: {
    backgroundColor: '#E8E8E8',
  },
  headerCell: {
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)}`,
      md: ` ${pxTovW(20)} ${pxTovW(0)} ${pxTovW(20)} ${pxTovW(38)}`,
    },
  },
  bodyCell: {
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)}`,
      md: ` ${pxTovW(20)} ${pxTovW(0)} ${pxTovW(20)} ${pxTovW(38)}`,
    },
  },
};

interface ITempData {
  class: number;
  section: string;
  subject: string;
  board: string;
  bookId: string;
}

interface ITempKeyMap {
  [key: string]: keyof ITempData;
}

interface IManualDisplay {
  [key: string]: ReactNode;
}

type IManagementTableCellType = 'text' | 'button' | 'toggle';
export interface IHeaderDetails {
  name: String;
  headerCellTypes: IManagementTableCellType;
  cellClickAction?: (cellIndex: number) => void;
}

interface IProps {
  headers: string[];
  data: ITempData[];
  keyMap: ITempKeyMap;
  manualDisplay?: IManualDisplay;
}
export const ManagementTable = (props: IProps) => {
  const { headers, data, keyMap, manualDisplay } = props;

  // const checkManualDisplay = () => {
  //   if (!manualDisplay) return false;
  //   let returnData;
  //   headers.map((header) => {
  //     const key = header.toLowerCase(); // Convert header to lowercase for comparison
  //     console.log('key:', key);

  //     const matchedKey = Object.keys(manualDisplay).find(
  //       (manualKey) => manualKey.toLowerCase() === key
  //     );
  //     console.log('matchedKey:', matchedKey);

  //     if (matchedKey) {
  //       console.log('manualDisplay[matchedKey]:', manualDisplay[matchedKey]);
  //       // return matchedKey ? manualDisplay[matchedKey] : false;
  //       returnData = manualDisplay[matchedKey];
  //     }
  //     console.log('-----------------------');
  //   });
  //   return returnData;
  // };

  return (
    <Box>
      <TableContainer sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow sx={styles.headerRow}>
              {headers.map((header, index) => (
                <TableCell key={index} sx={styles.headerCell}>
                  <Typography variant="h3" fontWeight="bold" textAlign="center">
                    {header.toUpperCase()}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((item, rowIndex) => (
              <TableRow key={rowIndex}>
                {/* {headers.map((header, colIndex) => (
                  <ManagementCell
                    key={colIndex}
                    cellClickAction={() => {
                      //
                    }}
                  />
                ))} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface IProps {
  cellType: 'text' | 'button' | 'toggle';
  cellText?: string;
  cellNode?: ReactNode;
  cellClickAction: () => void;
  rootStyles?: SxProps;
}

export const ManagementCell = (props: IProps) => {
  const { cellText, cellNode, cellClickAction, rootStyles } = props;

  let rootSx = { ...styles.bodyCell };
  if (rootStyles) rootSx = { ...rootSx, ...rootStyles };

  return (
    <TableCell sx={rootSx}>
      {/* {cellNode ? (
        cellNode
      ) : (
        <Typography variant="h3" fontWeight="regular" textAlign="center">
          {cellText}
        </Typography>
      )} */}
      {cellNode}
    </TableCell>
  );
};

{
  /* {checkManualDisplay() ? (
        checkManualDisplay()
      ) : (
            <Typography
              variant="h3"
              fontWeight="regular"
              textAlign="center"
            >
              {item[keyMap[header]] || '-'}
            </Typography>
          )
      }
  */
}

{
  /* <TableCell key={colIndex} sx={styles.bodyCell}>
  <Typography variant="h3" fontWeight="regular" textAlign="center">
    {item[keyMap[header]] || '-'}
  </Typography>
</TableCell>; */
}
