import {
  Avatar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import { theme } from '../../../theme/themeProvider';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  getMediaBasePath,
  firstLetterImage,
} from '../../../commonUtils/images';
import {
  AssessmentEnum,
  SubmissionType,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { remainingTimeInHoursAndMins } from '../../../commonUtils/homework';
import { ScoreProgressBar } from '../ScoreProgressBar';
import { useState } from 'react';
import PrimaryButton from '../../elements/PrimaryButton';
import { ContentListAccordian } from '../ContentListAccordian';
import { AssessmentTask } from '@protos/learning_management/lms.assessment.common.apis_pb';
const styles: IStyles = {
  root: {
    height: { xs: pxToRem(148), md: pxTovW(280), lg: pxTovW(199) },
    width: { xs: '100%', md: pxTovW(590), lg: pxTovW(464) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0DFDE',
    padding: {
      xs: pxToRem(8),
      md: `${pxTovW(18)} ${pxTovW(15)}`,
      lg: `${pxTovW(13)} ${pxTovW(12)}`,
    },
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(15), md: pxTovW(15), lg: pxTovW(23) },
    boxSizing: 'border-box',
    boxShadow: `0px 0px ${pxToRem(11)} #E7E7E7D9`,
    marginX: 'auto',
  },
  imageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: { xs: '100%', md: '100%', lg: pxTovW(157) },
    width: { xs: pxToRem(103), md: pxTovW(221), lg: pxTovW(157) },
    minWidth: { xs: pxToRem(103), md: pxTovW(221), lg: pxTovW(157) },
    boxSizing: 'border-box',
    borderRadius: { xs: pxToRem(10) },
    backgroundColor: '#E0DFDE',
    position: 'relative',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
  },
  iconBox: {
    display: 'flex',
    gap: { xs: pxToRem(2), md: pxTovW(2) },
    alignItems: 'center',
    width: 'max-content',
  },
  progressContainer: {
    // border: '1px solid green',
    boxSizing: 'border-box',
    position: 'absolute',
    bgcolor: '#FFFFFFE6',
    width: '100%',
    height: { xs: pxToRem(39), md: pxTovW(53) },
    bottom: 0,
    opacity: '90%',
    borderRadius: {
      xs: `0 0 ${pxToRem(10)} ${pxToRem(10)}`,
      md: `0 0 ${pxTovW(10)} ${pxTovW(10)}`,
    },
    p: { xs: pxToRem(5), md: pxTovW(5) },
    justifyContent: 'center',
    display: 'flex',
    gap: { xs: pxToRem(2), md: pxTovW(2) },
    flexDirection: 'column',
  },
};
interface IProps {
  homework?: AssessmentTask;
  onClick?: () => void;
  assessCardType?: 'Draft' | 'Assigned' | 'Ended';
  chapterListPopupHandler?: (assessment: AssessmentTask) => void;
  onDeleteClick?: () => void;
}
export const AssessmentCard = (props: IProps) => {
  const {
    homework,
    onClick,
    assessCardType,
    chapterListPopupHandler,
    onDeleteClick,
  } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;

  return (
    <Box sx={styles.root} onClick={onClick}>
      <Box sx={styles.imageBox}>
        <ImageWrapper
          name="chapter1"
          type="png"
          parentFolder="tempAssets"
          styles={{
            ...styles.image,
            height: '100%',
          }}
          path={getMediaBasePath(
            homework?.assessmentPosterImgUrl,
            'processedMediaBucket'
          )}
        />
        {homework?.maxMarks !== undefined && assessCardType !== 'Draft' && (
          <Box sx={styles.progressContainer}>
            <ScoreProgressBar
              variant={isIpadOnly ? 'md' : 'small'}
              score={Math.round(homework?.classScore || 0)}
            />
            <Typography variant="smallestText" sx={{ marginLeft: pxToRem(5) }}>
              Class Score
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        {assessCardType === 'Assigned' && homework?.teacherName !== 'Geneo' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              position: 'absolute',
              right: pxToRem(5),
              top: pxToRem(0),
            }}
            onClick={(ev?: React.MouseEvent) => {
              ev?.stopPropagation();
              onDeleteClick && onDeleteClick(); // Open delete confirmation popup
            }}
          >
            <IconWrapper
              name="deletehw"
              size={isIpadOnly ? 'md' : 'small'}
              parentFolder="icons"
              type="png"
              customSx={{
                cursor: 'pointer',
                width: {
                  xs: pxToRem(20),
                  md: pxTovW(30),
                  lg: pxTovW(25),
                },
                height: {
                  xs: pxToRem(20),
                  md: pxTovW(30),
                  lg: pxTovW(25),
                },
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: { xs: pxToRem(3), md: pxTovW(4) },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: { xs: pxToRem(17), md: pxTovW(28) },
              gap: { xs: pxToRem(10), md: pxTovW(16) },
              marginTop: { xs: pxToRem(2), md: pxTovW(10), lg: pxTovW(5) },
              marginBottom: { xs: pxToRem(1), md: pxTovW(5) },
            }}
          >
            {homework?.taskType === TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: { xs: pxToRem(5), md: pxTovW(10), lg: pxTovW(5) },
                  background: '#F8C807',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(7)} ${pxToRem(4)}`,
                    md: `${pxTovW(5)} ${pxTovW(14)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(16),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  fontWeight={700}
                >
                  Custom Homework
                </Typography>
              </Box>
            ) : homework?.assessmentType ===
              AssessmentEnum.ASSESSMENT_HOMEWORK ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: { xs: pxToRem(5), md: pxTovW(10), lg: pxTovW(5) },
                  background: '#0AA34F',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(7)} ${pxToRem(4)}`,
                    md: `${pxTovW(4)} ${pxTovW(10)} ${pxTovW(6)}`,
                    lg: `${pxTovW(5)} ${pxTovW(10)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(15),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  color={'common.white'}
                  fontWeight={700}
                >
                  Homework
                </Typography>
              </Box>
            ) : homework?.assessmentType ===
              AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'max-content',
                  background: '#007CDC',
                  padding: {
                    xs: `${pxToRem(3)} ${pxToRem(7)} ${pxToRem(4)}`,
                    md: `${pxTovW(4)} ${pxTovW(14)} ${pxTovW(7)}`,
                    lg: `${pxTovW(5)} ${pxTovW(14)} ${pxTovW(6)}`,
                  },
                  borderRadius: {
                    xs: pxToRem(10),
                    md: pxTovW(20),
                    lg: pxTovW(15),
                  },
                }}
              >
                <Typography
                  variant={isMobile ? 'smallestText' : 'subText'}
                  color={'common.white'}
                  fontWeight={700}
                >
                  Formative
                </Typography>
              </Box>
            ) : (
              homework?.assessmentType ===
                AssessmentEnum.ASSESSMENT_DIAGNOSTICS && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#F87606',
                    padding: {
                      xs: `${pxToRem(3)} ${pxToRem(8)} ${pxToRem(4)}`,
                      md: `${pxTovW(5)} ${pxTovW(9)} ${pxTovW(6)}`,
                    },
                    borderRadius: {
                      xs: pxToRem(10),
                      md: pxTovW(20),
                      lg: pxTovW(15),
                    },
                  }}
                >
                  <Typography
                    variant={isMobile ? 'smallestText' : 'subText'}
                    color={'common.white'}
                    fontWeight={'700'}
                  >
                    Diagnostic
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: { xs: pxToRem(12), md: pxTovW(15) },
                      height: { xs: pxToRem(12), md: pxTovW(15) },
                      background: 'black',
                      borderRadius: {
                        xs: pxToRem(10),
                        md: pxTovW(20),
                        lg: pxTovW(15),
                      },
                    }}
                  >
                    <Typography variant="smallestText" color={'common.white'}>
                      i
                    </Typography>
                  </Box>
                </Box>
              )
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(5), md: pxTovW(5) },
              }}
            >
              <ImageWrapper
                name="geneo-blue"
                type="png"
                parentFolder="icons"
                styles={{
                  width: { xs: pxToRem(17), md: pxTovW(34), lg: pxTovW(24) },
                  height: { xs: pxToRem(17), md: pxTovW(34), lg: pxTovW(24) },
                  borderRadius: '50%',
                }}
                path={
                  homework?.teacherProfileImageUrl
                    ? getMediaBasePath(
                        homework?.teacherProfileImageUrl,
                        'schoolnetCdnImagesBucket'
                      )
                    : firstLetterImage('Geneo')
                }
              />
              <Typography
                variant={isMobile ? 'smallestText' : 'smallText'}
                fontWeight="regular"
              >
                {homework?.teacherId?.toString()
                  ? 'You'
                  : homework?.teacherName || 'Geneo'}
              </Typography>
            </Box>
            {/* <Box>x</Box> */}
          </Box>
          <Typography
            variant={isMobile ? 'smallestText' : 'subText'}
            fontWeight={700}
            color="primary.main"
          >
            {homework?.class}
            {homework?.section} {homework?.subject}
          </Typography>
          <Typography
            variant={isMobile ? 'h4' : 'h5'}
            fontWeight={700}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              maxWidth: {
                xs: pxToRem(190),
                md: pxTovW(320),
                lg: pxTovW(220),
              },
            }}
          >
            {homework?.moduleName}
          </Typography>
          {homework?.assessmentType ===
            AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT &&
            homework.taskMetaInfo?.chapterInfo?.chapterTopicsInfo && (
              <Box
                sx={{
                  display: 'flex',
                  // height: { xs: pxToRem(12), md: pxTovW(15) },
                  width: 'max-content',
                  gap: { xs: pxToRem(3), md: pxTovW(8), lg: pxTovW(5) },
                }}
                onClick={(ev?: React.MouseEvent) => {
                  ev?.stopPropagation();
                  chapterListPopupHandler && chapterListPopupHandler(homework);
                }}
              >
                {homework.taskMetaInfo?.chapterInfo?.chapterTopicsInfo.length >
                  1 && (
                  <Typography
                    variant={isMobile ? 'smallestText' : 'subText'}
                    fontWeight="bold"
                  >
                    {`+${
                      homework.taskMetaInfo?.chapterInfo?.chapterTopicsInfo
                        .length - 1
                    } more`}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: {
                      xs: `0 ${pxToRem(6)} ${pxToRem(1)}`,
                      md: `${pxTovW(1)} ${pxTovW(10)} ${pxTovW(4)}`,
                      lg: `0 ${pxTovW(6)} ${pxTovW(1)}`,
                    },
                    background: 'black',
                    borderRadius: {
                      xs: pxToRem(10),
                      md: pxTovW(20),
                      lg: pxTovW(15),
                    },
                  }}
                >
                  <Typography variant="smallestText" color={'common.white'}>
                    i
                  </Typography>
                </Box>
              </Box>
            )}
          <Typography
            variant={isMobile ? 'smallestText' : 'subText'}
            fontWeight={500}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              maxWidth: {
                xs: pxToRem(190),
                md: pxTovW(320),
                lg: pxTovW(220),
              },
              // marginTop: { xs: pxToRem(4), md: pxTovW(2) },
            }}
          >
            {homework?.assessmentTitle}
          </Typography>
        </Box>

        <Divider
          sx={{
            borderColor: '1px solid #E0E0E0',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              boxSizing: 'border-box',
            }}
          >
            {homework?.taskType === TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM ? (
              <Box
                sx={{
                  display: 'flex',
                  marginRight: 'auto',
                  gap: { xs: pxToRem(4), md: pxTovW(6) },
                  marginBottom: { xs: pxToRem(4), md: pxTovW(10) },
                  alignItems: 'center',
                }}
              >
                <IconWrapper
                  name="tnc"
                  size={isIpadOnly ? 'md' : 'small'}
                  parentFolder="icons"
                  type="png"
                />
                {homework?.maxMarks === undefined ? (
                  <Typography variant="smallText" fontWeight={900}>
                    {homework?.submissionType ===
                    SubmissionType.SUBMISSION_ONLINE
                      ? 'Digital without Marks'
                      : 'Physical without Marks'}
                  </Typography>
                ) : (
                  <Typography variant="smallText" fontWeight={900}>
                    {homework?.submissionType ===
                    SubmissionType.SUBMISSION_ONLINE
                      ? 'Digital with Marks'
                      : 'Physical with Marks'}
                  </Typography>
                )}
              </Box>
            ) : assessCardType === 'Draft' ? (
              <>
                <Box sx={styles.iconBox}>
                  <IconWrapper
                    name="clock"
                    size={isIpadOnly ? 'md' : 'small'}
                    parentFolder="icons"
                    type="png"
                  />

                  <Typography variant={isMobile ? 'smallestText' : 'subText'}>
                    {`${homework?.assessmentContentInfo?.timeDurationInMin}mins`}
                  </Typography>
                </Box>
                <Box sx={styles.iconBox}>
                  <IconWrapper
                    name="questions"
                    size={isIpadOnly ? 'md' : 'small'}
                    parentFolder="icons"
                    type="png"
                  />
                  <Typography variant={isMobile ? 'smallestText' : 'subText'}>
                    {`${homework?.assessmentContentInfo?.numberOfQuestions}Qs`}
                  </Typography>
                </Box>
                <Box sx={styles.iconBox}>
                  <IconWrapper
                    name={
                      homework?.difficultyLevel === 1
                        ? 'difficulty-hard'
                        : homework?.difficultyLevel === 2
                        ? 'level'
                        : 'difficulty-easy'
                    }
                    size={isIpadOnly ? 'md' : 'small'}
                    parentFolder="icons"
                    type="png"
                  />
                  <Typography variant={isMobile ? 'smallestText' : 'subText'}>
                    {difficultyLevelToString(homework?.difficultyLevel || 0)}
                  </Typography>
                </Box>
              </>
            ) : (
              // For assessments which are neither custom hw nor shown in the assessment page
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  boxSizing: 'border-box',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={styles.iconBox}>
                    <IconWrapper
                      name="profile"
                      size={isIpadOnly ? 'md' : 'small'}
                      parentFolder="icons"
                      type="png"
                    />
                    <Typography variant="smallText">
                      {`${homework?.studentsSubmissionCount || 0}/${
                        homework?.assignedStudentsCount || 0
                      }`}
                    </Typography>
                  </Box>
                  <Typography variant="smallText" fontWeight="regular">
                    Submissions
                  </Typography>
                </Box>
                {assessCardType === 'Assigned' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={styles.iconBox}>
                      <IconWrapper
                        name="clock"
                        size={isIpadOnly ? 'md' : 'small'}
                        parentFolder="icons"
                        type="png"
                      />
                      <Typography
                        variant="smallText"
                        sx={{
                          maxWidth: { md: pxTovW(85) },
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }}
                      >
                        {remainingTimeInHoursAndMins(
                          homework?.assessmentTargetDate
                        )}
                      </Typography>
                    </Box>
                    <Typography variant="smallText" fontWeight="regular">
                      Remaining
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#E1FFEE',
                      border: '1px solid #0AA34F',
                      width: {
                        xs: pxToRem(65),
                        md: pxTovW(110),
                        lg: pxTovW(86),
                      },
                      height: {
                        xs: pxToRem(23),
                        md: pxTovW(40),
                        lg: pxTovW(31),
                      },
                      borderRadius: {
                        xs: pxToRem(10),
                        md: pxTovW(21),
                        lg: pxTovW(16),
                      },
                    }}
                  >
                    <Typography variant="smallText" color={'secondary.main'}>
                      Ended
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const difficultyLevelToString = (value: number) => {
  switch (value) {
    case 0:
      return 'Undefined';
      break;
    case 1:
      return 'High';
      break;
    case 2:
      return 'Medium';
      break;
    case 3:
      return 'Easy';
      break;

    default:
      break;
  }
};
