// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.school.apis.proto (package geneo.lms.school.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ActivateSchoolRequest, ActivateSchoolResponse, ClickerSetAssignmentRequest, ClickerSetAssignmentResponse, ConnectedClassroomRequest, ConnectedClassroomResponse, DeactivateSchoolRequest, DeactivateSchoolResponse, DeleteStudentUsersRequest, DeleteStudentUsersResponse, DeleteTeacherUsersRequest, DeleteTeacherUsersResponse, FetchClickerSetListRequest, FetchClickerSetListResponse, FetchPaginatedSchoolDataRequest, FetchPaginatedSchoolDataResponse, FetchSchoolInfoRequest, FetchSchoolInfoResponse, FileRequest, FileResponse, GenerateExcelRequest, GenerateExcelResponse, GetSchoolInfoRequest, ResetStudentPasswordsRequest, ResetStudentPasswordsResponse, ResetTeacherPasswordsRequest, ResetTeacherPasswordsResponse, ResetUserPasswordRequest, ResetUserPasswordResponse, SaveSchoolDeviceInfoRequest, SaveSchoolDeviceInfoResponse, SaveValidatedDataRequest, SaveValidatedDataResponse, SchoolInfoResponse, SyncSchoolClassSectionRequest, SyncSchoolClassSectionResponse, SyncSchoolCoursesRequest, SyncSchoolCoursesResponse, SyncSchoolDataRequest, SyncSchoolDataResponse, SyncSchoolRequest, SyncSchoolResponse, SyncSchoolStudentListRequest, SyncSchoolStudentListResponse, SyncSchoolTeacherCourseMapRequest, SyncSchoolTeacherCourseMapResponse, SyncSchoolTeacherListRequest, SyncSchoolTeacherListResponse, UpdateSchoolInfoRequest, UpdateSchoolInfoResponse, UpdateStudentClickerMapRequest, UpdateStudentClickerMapResponse, UpsertClassSectionMappingRequest, UpsertClassSectionMappingResponse, ValidateFileRequest, ValidateFileResponse } from "./lms.school.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.school.apis.LmsSchoolAPIServiceV1
 */
export const LmsSchoolAPIServiceV1 = {
  typeName: "geneo.lms.school.apis.LmsSchoolAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.syncSchool
     */
    syncSchool: {
      name: "syncSchool",
      I: SyncSchoolRequest,
      O: SyncSchoolResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.syncSchoolClassSection
     */
    syncSchoolClassSection: {
      name: "syncSchoolClassSection",
      I: SyncSchoolClassSectionRequest,
      O: SyncSchoolClassSectionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.syncSchoolCourses
     */
    syncSchoolCourses: {
      name: "syncSchoolCourses",
      I: SyncSchoolCoursesRequest,
      O: SyncSchoolCoursesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.syncSchoolTeacherList
     */
    syncSchoolTeacherList: {
      name: "syncSchoolTeacherList",
      I: SyncSchoolTeacherListRequest,
      O: SyncSchoolTeacherListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.syncSchoolStudentList
     */
    syncSchoolStudentList: {
      name: "syncSchoolStudentList",
      I: SyncSchoolStudentListRequest,
      O: SyncSchoolStudentListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.syncSchoolTeacherCourseMap
     */
    syncSchoolTeacherCourseMap: {
      name: "syncSchoolTeacherCourseMap",
      I: SyncSchoolTeacherCourseMapRequest,
      O: SyncSchoolTeacherCourseMapResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.SaveConnectedClassroomData
     */
    saveConnectedClassroomData: {
      name: "SaveConnectedClassroomData",
      I: ConnectedClassroomRequest,
      O: ConnectedClassroomResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV1.saveSchoolDeviceInfo
     */
    saveSchoolDeviceInfo: {
      name: "saveSchoolDeviceInfo",
      I: SaveSchoolDeviceInfoRequest,
      O: SaveSchoolDeviceInfoResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * New service for AdminControl
 *
 * @generated from service geneo.lms.school.apis.LmsSchoolAPIServiceV2
 */
export const LmsSchoolAPIServiceV2 = {
  typeName: "geneo.lms.school.apis.LmsSchoolAPIServiceV2",
  methods: {
    /**
     * Activate a school
     *
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.activateSchool
     */
    activateSchool: {
      name: "activateSchool",
      I: ActivateSchoolRequest,
      O: ActivateSchoolResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Deactivate a school
     *
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.deactivateSchool
     */
    deactivateSchool: {
      name: "deactivateSchool",
      I: DeactivateSchoolRequest,
      O: DeactivateSchoolResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Delete student users by username
     *
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.deleteStudentUsers
     */
    deleteStudentUsers: {
      name: "deleteStudentUsers",
      I: DeleteStudentUsersRequest,
      O: DeleteStudentUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Delete teacher users by username
     *
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.deleteTeacherUsers
     */
    deleteTeacherUsers: {
      name: "deleteTeacherUsers",
      I: DeleteTeacherUsersRequest,
      O: DeleteTeacherUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Reset passwords for students
     *
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.resetStudentPasswords
     */
    resetStudentPasswords: {
      name: "resetStudentPasswords",
      I: ResetStudentPasswordsRequest,
      O: ResetStudentPasswordsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Reset passwords for teachers
     *
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.resetTeacherPasswords
     */
    resetTeacherPasswords: {
      name: "resetTeacherPasswords",
      I: ResetTeacherPasswordsRequest,
      O: ResetTeacherPasswordsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.validateFile
     */
    validateFile: {
      name: "validateFile",
      I: ValidateFileRequest,
      O: ValidateFileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAPIServiceV2.saveValidatedData
     */
    saveValidatedData: {
      name: "saveValidatedData",
      I: SaveValidatedDataRequest,
      O: SaveValidatedDataResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 *
 * UPDATE user_management.teacher_profile
 * SET "isActive" = 0,
 * "isProfileVerified" = false,
 * "isPasswordUpdated" = false
 * WHERE "userName" IN ('gds_7a_001');
 *
 * @generated from service geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1
 */
export const LmsSchoolAdminAPIServiceV1 = {
  typeName: "geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.generateExcelFileForBulkUpload
     */
    generateExcelFileForBulkUpload: {
      name: "generateExcelFileForBulkUpload",
      I: GenerateExcelRequest,
      O: GenerateExcelResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.fetchPaginatedSchoolData
     */
    fetchPaginatedSchoolData: {
      name: "fetchPaginatedSchoolData",
      I: FetchPaginatedSchoolDataRequest,
      O: FetchPaginatedSchoolDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.resetUserPassword
     */
    resetUserPassword: {
      name: "resetUserPassword",
      I: ResetUserPasswordRequest,
      O: ResetUserPasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.fetchSchoolInfo
     */
    fetchSchoolInfo: {
      name: "fetchSchoolInfo",
      I: FetchSchoolInfoRequest,
      O: FetchSchoolInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.updateSchoolInfo
     */
    updateSchoolInfo: {
      name: "updateSchoolInfo",
      I: UpdateSchoolInfoRequest,
      O: UpdateSchoolInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.downloadExcelFile
     */
    downloadExcelFile: {
      name: "downloadExcelFile",
      I: FileRequest,
      O: FileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.uploadSchoolInfo
     */
    uploadSchoolInfo: {
      name: "uploadSchoolInfo",
      I: GetSchoolInfoRequest,
      O: SchoolInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.syncSchoolData
     */
    syncSchoolData: {
      name: "syncSchoolData",
      I: SyncSchoolDataRequest,
      O: SyncSchoolDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.upsertClassSectionMapping
     */
    upsertClassSectionMapping: {
      name: "upsertClassSectionMapping",
      I: UpsertClassSectionMappingRequest,
      O: UpsertClassSectionMappingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.fetchClickerSetList
     */
    fetchClickerSetList: {
      name: "fetchClickerSetList",
      I: FetchClickerSetListRequest,
      O: FetchClickerSetListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.clickerSetAssignment
     */
    clickerSetAssignment: {
      name: "clickerSetAssignment",
      I: ClickerSetAssignmentRequest,
      O: ClickerSetAssignmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.school.apis.LmsSchoolAdminAPIServiceV1.updateStudentClickerMap
     */
    updateStudentClickerMap: {
      name: "updateStudentClickerMap",
      I: UpdateStudentClickerMapRequest,
      O: UpdateStudentClickerMapResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

