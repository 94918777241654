// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.db.proto (package geneo.ums.db, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Enum for USER_LOGIN_TYPE
 *
 * @generated from enum geneo.ums.db.LoginTypeEnum
 */
export enum LoginTypeEnum {
  /**
   * Represents an undefined Login type
   *
   * @generated from enum value: LOGIN_TYPE_UNDEFINED = 0;
   */
  LOGIN_TYPE_UNDEFINED = 0,

  /**
   * Represents an login type for username
   *
   * @generated from enum value: LOGIN_TYPE_USERNAME = 1;
   */
  LOGIN_TYPE_USERNAME = 1,

  /**
   * Represents an login type for phone number
   *
   * @generated from enum value: LOGIN_TYPE_PHONE_NUMBER = 2;
   */
  LOGIN_TYPE_PHONE_NUMBER = 2,

  /**
   * Represents an login type for email
   *
   * @generated from enum value: LOGIN_TYPE_EMAIL = 3;
   */
  LOGIN_TYPE_EMAIL = 3,

  /**
   * Represents an login type for SSO
   *
   * @generated from enum value: LOGIN_TYPE_SSO = 4;
   */
  LOGIN_TYPE_SSO = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(LoginTypeEnum)
proto3.util.setEnumType(LoginTypeEnum, "geneo.ums.db.LoginTypeEnum", [
  { no: 0, name: "LOGIN_TYPE_UNDEFINED" },
  { no: 1, name: "LOGIN_TYPE_USERNAME" },
  { no: 2, name: "LOGIN_TYPE_PHONE_NUMBER" },
  { no: 3, name: "LOGIN_TYPE_EMAIL" },
  { no: 4, name: "LOGIN_TYPE_SSO" },
]);

/**
 * Enum for OTPEnum
 *
 * @generated from enum geneo.ums.db.OTPEnum
 */
export enum OTPEnum {
  /**
   * Represents an undefined OTP type
   *
   * @generated from enum value: OTP_TYPE_UNDEFINED = 0;
   */
  OTP_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: STUDENT_REGISTRATION = 1;
   */
  STUDENT_REGISTRATION = 1,

  /**
   * @generated from enum value: STUDENT_LOGIN = 2;
   */
  STUDENT_LOGIN = 2,

  /**
   * @generated from enum value: TEACHER_REGISTRATION = 3;
   */
  TEACHER_REGISTRATION = 3,

  /**
   * @generated from enum value: TEACHER_LOGIN = 4;
   */
  TEACHER_LOGIN = 4,

  /**
   * @generated from enum value: STUDENT_VERIFICATION = 5;
   */
  STUDENT_VERIFICATION = 5,

  /**
   * @generated from enum value: TEACHER_VERIFICATION = 6;
   */
  TEACHER_VERIFICATION = 6,

  /**
   * @generated from enum value: STUDENT_RESET_PASSWORD = 7;
   */
  STUDENT_RESET_PASSWORD = 7,

  /**
   * @generated from enum value: TEACHER_RESET_PASSWORD = 8;
   */
  TEACHER_RESET_PASSWORD = 8,

  /**
   * @generated from enum value: TEACHER_SELF_REGISTRATION = 9;
   */
  TEACHER_SELF_REGISTRATION = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(OTPEnum)
proto3.util.setEnumType(OTPEnum, "geneo.ums.db.OTPEnum", [
  { no: 0, name: "OTP_TYPE_UNDEFINED" },
  { no: 1, name: "STUDENT_REGISTRATION" },
  { no: 2, name: "STUDENT_LOGIN" },
  { no: 3, name: "TEACHER_REGISTRATION" },
  { no: 4, name: "TEACHER_LOGIN" },
  { no: 5, name: "STUDENT_VERIFICATION" },
  { no: 6, name: "TEACHER_VERIFICATION" },
  { no: 7, name: "STUDENT_RESET_PASSWORD" },
  { no: 8, name: "TEACHER_RESET_PASSWORD" },
  { no: 9, name: "TEACHER_SELF_REGISTRATION" },
]);

/**
 * Enum for DeviceEnum
 *
 * @generated from enum geneo.ums.db.DeviceEnum
 */
export enum DeviceEnum {
  /**
   * Represents an undefined device type
   *
   * @generated from enum value: DEVICE_TYPE_UNDEFINED = 0;
   */
  DEVICE_TYPE_UNDEFINED = 0,

  /**
   * Represents an iOS device
   *
   * @generated from enum value: DEVICE_TYPE_IOS = 1;
   */
  DEVICE_TYPE_IOS = 1,

  /**
   * Represents an Android device
   *
   * @generated from enum value: DEVICE_TYPE_ANDROID = 2;
   */
  DEVICE_TYPE_ANDROID = 2,

  /**
   * Represents a web device
   *
   * @generated from enum value: DEVICE_TYPE_WEB = 3;
   */
  DEVICE_TYPE_WEB = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceEnum)
proto3.util.setEnumType(DeviceEnum, "geneo.ums.db.DeviceEnum", [
  { no: 0, name: "DEVICE_TYPE_UNDEFINED" },
  { no: 1, name: "DEVICE_TYPE_IOS" },
  { no: 2, name: "DEVICE_TYPE_ANDROID" },
  { no: 3, name: "DEVICE_TYPE_WEB" },
]);

/**
 * Enum for ParentStudentRelationEnum
 *
 * @generated from enum geneo.ums.db.ParentStudentRelationEnum
 */
export enum ParentStudentRelationEnum {
  /**
   * Represents an undefined parent-student relation
   *
   * @generated from enum value: PARENT_STUDENT_RELATION_UNDEFINED = 0;
   */
  PARENT_STUDENT_RELATION_UNDEFINED = 0,

  /**
   * Represents a father as a parent
   *
   * @generated from enum value: PARENT_STUDENT_RELATION_FATHER = 1;
   */
  PARENT_STUDENT_RELATION_FATHER = 1,

  /**
   * Represents a mother as a parent
   *
   * @generated from enum value: PARENT_STUDENT_RELATION_MOTHER = 2;
   */
  PARENT_STUDENT_RELATION_MOTHER = 2,

  /**
   * Represents a grandfather as a parent
   *
   * @generated from enum value: PARENT_STUDENT_RELATION_GRAND_FATHER = 3;
   */
  PARENT_STUDENT_RELATION_GRAND_FATHER = 3,

  /**
   * Represents a grandmother as a parent
   *
   * @generated from enum value: PARENT_STUDENT_RELATION_GRAND_MOTHER = 4;
   */
  PARENT_STUDENT_RELATION_GRAND_MOTHER = 4,

  /**
   * Represents an uncle as a parent
   *
   * @generated from enum value: PARENT_STUDENT_RELATION_UNCLE = 5;
   */
  PARENT_STUDENT_RELATION_UNCLE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ParentStudentRelationEnum)
proto3.util.setEnumType(ParentStudentRelationEnum, "geneo.ums.db.ParentStudentRelationEnum", [
  { no: 0, name: "PARENT_STUDENT_RELATION_UNDEFINED" },
  { no: 1, name: "PARENT_STUDENT_RELATION_FATHER" },
  { no: 2, name: "PARENT_STUDENT_RELATION_MOTHER" },
  { no: 3, name: "PARENT_STUDENT_RELATION_GRAND_FATHER" },
  { no: 4, name: "PARENT_STUDENT_RELATION_GRAND_MOTHER" },
  { no: 5, name: "PARENT_STUDENT_RELATION_UNCLE" },
]);

/**
 * Enum for TeacherRolesEnum
 *
 * @generated from enum geneo.ums.db.TeacherRolesEnum
 */
export enum TeacherRolesEnum {
  /**
   * Represents an undefined teacher role
   *
   * @generated from enum value: TEACHER_ROLE_UNDEFINED = 0;
   */
  TEACHER_ROLE_UNDEFINED = 0,

  /**
   * Represents a regular teacher role
   *
   * @generated from enum value: TEACHER_ROLE_TEACHER = 1;
   */
  TEACHER_ROLE_TEACHER = 1,

  /**
   * Represents a Subject Matter Expert (SME) teacher role
   *
   * @generated from enum value: TEACHER_ROLE_SME = 2;
   */
  TEACHER_ROLE_SME = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TeacherRolesEnum)
proto3.util.setEnumType(TeacherRolesEnum, "geneo.ums.db.TeacherRolesEnum", [
  { no: 0, name: "TEACHER_ROLE_UNDEFINED" },
  { no: 1, name: "TEACHER_ROLE_TEACHER" },
  { no: 2, name: "TEACHER_ROLE_SME" },
]);

/**
 * Enum definition for profile_roles_enum
 *
 * @generated from enum geneo.ums.db.ProfileRolesEnum
 */
export enum ProfileRolesEnum {
  /**
   * @generated from enum value: PROFILE_ROLE_UNDEFINED = 0;
   */
  PROFILE_ROLE_UNDEFINED = 0,

  /**
   * @generated from enum value: PROFILE_ROLE_STUDENT = 1;
   */
  PROFILE_ROLE_STUDENT = 1,

  /**
   * @generated from enum value: PROFILE_ROLE_PARENT = 2;
   */
  PROFILE_ROLE_PARENT = 2,

  /**
   * @generated from enum value: PROFILE_ROLE_TEACHER = 3;
   */
  PROFILE_ROLE_TEACHER = 3,

  /**
   * @generated from enum value: PROFILE_ROLE_SCHOOL_ADMIN = 4;
   */
  PROFILE_ROLE_SCHOOL_ADMIN = 4,

  /**
   * @generated from enum value: PROFILE_ROLE_GENEO_ADMIN = 5;
   */
  PROFILE_ROLE_GENEO_ADMIN = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileRolesEnum)
proto3.util.setEnumType(ProfileRolesEnum, "geneo.ums.db.ProfileRolesEnum", [
  { no: 0, name: "PROFILE_ROLE_UNDEFINED" },
  { no: 1, name: "PROFILE_ROLE_STUDENT" },
  { no: 2, name: "PROFILE_ROLE_PARENT" },
  { no: 3, name: "PROFILE_ROLE_TEACHER" },
  { no: 4, name: "PROFILE_ROLE_SCHOOL_ADMIN" },
  { no: 5, name: "PROFILE_ROLE_GENEO_ADMIN" },
]);

/**
 * @generated from enum geneo.ums.db.ProfileCreationModeEnum
 */
export enum ProfileCreationModeEnum {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: SPREAD_SHEET = 1;
   */
  SPREAD_SHEET = 1,

  /**
   * @generated from enum value: SELF_REGISTRATION = 2;
   */
  SELF_REGISTRATION = 2,

  /**
   * @generated from enum value: SSO_LOGIN = 3;
   */
  SSO_LOGIN = 3,

  /**
   * @generated from enum value: APP_ADMIN = 4;
   */
  APP_ADMIN = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileCreationModeEnum)
proto3.util.setEnumType(ProfileCreationModeEnum, "geneo.ums.db.ProfileCreationModeEnum", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "SPREAD_SHEET" },
  { no: 2, name: "SELF_REGISTRATION" },
  { no: 3, name: "SSO_LOGIN" },
  { no: 4, name: "APP_ADMIN" },
]);

/**
 * @generated from enum geneo.ums.db.ProfileVerificationStatusEnum
 */
export enum ProfileVerificationStatusEnum {
  /**
   * @generated from enum value: PROFILE_VERIFICATION_UNDEFINED = 0;
   */
  PROFILE_VERIFICATION_UNDEFINED = 0,

  /**
   * @generated from enum value: PROFILE_VERIFICATION_PENDING = 1;
   */
  PROFILE_VERIFICATION_PENDING = 1,

  /**
   * @generated from enum value: PROFILE_PARTIALLY_VERIFIED = 2;
   */
  PROFILE_PARTIALLY_VERIFIED = 2,

  /**
   * @generated from enum value: PROFILE_VERIFIED = 3;
   */
  PROFILE_VERIFIED = 3,

  /**
   * @generated from enum value: PROFILE_REJECTED = 4;
   */
  PROFILE_REJECTED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileVerificationStatusEnum)
proto3.util.setEnumType(ProfileVerificationStatusEnum, "geneo.ums.db.ProfileVerificationStatusEnum", [
  { no: 0, name: "PROFILE_VERIFICATION_UNDEFINED" },
  { no: 1, name: "PROFILE_VERIFICATION_PENDING" },
  { no: 2, name: "PROFILE_PARTIALLY_VERIFIED" },
  { no: 3, name: "PROFILE_VERIFIED" },
  { no: 4, name: "PROFILE_REJECTED" },
]);

/**
 * @generated from enum geneo.ums.db.ProfileRejectionEnum
 */
export enum ProfileRejectionEnum {
  /**
   * @generated from enum value: PROFILE_REJECTION_UNDEFINED = 0;
   */
  PROFILE_REJECTION_UNDEFINED = 0,

  /**
   * @generated from enum value: PROFILE_REJECTION_INCORRECT_DATA = 1;
   */
  PROFILE_REJECTION_INCORRECT_DATA = 1,

  /**
   * @generated from enum value: PROFILE_REJECTION_NOT_VALID_USER = 2;
   */
  PROFILE_REJECTION_NOT_VALID_USER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileRejectionEnum)
proto3.util.setEnumType(ProfileRejectionEnum, "geneo.ums.db.ProfileRejectionEnum", [
  { no: 0, name: "PROFILE_REJECTION_UNDEFINED" },
  { no: 1, name: "PROFILE_REJECTION_INCORRECT_DATA" },
  { no: 2, name: "PROFILE_REJECTION_NOT_VALID_USER" },
]);

/**
 * @generated from message geneo.ums.db.ProfilePics
 */
export class ProfilePics extends Message<ProfilePics> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: int32 size = 3;
   */
  size = 0;

  /**
   * @generated from field: string mime_type = 4;
   */
  mimeType = "";

  constructor(data?: PartialMessage<ProfilePics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.ProfilePics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfilePics {
    return new ProfilePics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfilePics {
    return new ProfilePics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfilePics {
    return new ProfilePics().fromJsonString(jsonString, options);
  }

  static equals(a: ProfilePics | PlainMessage<ProfilePics> | undefined, b: ProfilePics | PlainMessage<ProfilePics> | undefined): boolean {
    return proto3.util.equals(ProfilePics, a, b);
  }
}

/**
 * @generated from message geneo.ums.db.DeviceDetails
 */
export class DeviceDetails extends Message<DeviceDetails> {
  constructor(data?: PartialMessage<DeviceDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.DeviceDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeviceDetails {
    return new DeviceDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeviceDetails {
    return new DeviceDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeviceDetails {
    return new DeviceDetails().fromJsonString(jsonString, options);
  }

  static equals(a: DeviceDetails | PlainMessage<DeviceDetails> | undefined, b: DeviceDetails | PlainMessage<DeviceDetails> | undefined): boolean {
    return proto3.util.equals(DeviceDetails, a, b);
  }
}

/**
 * @generated from message geneo.ums.db.Location
 */
export class Location extends Message<Location> {
  constructor(data?: PartialMessage<Location>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.Location";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Location {
    return new Location().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJsonString(jsonString, options);
  }

  static equals(a: Location | PlainMessage<Location> | undefined, b: Location | PlainMessage<Location> | undefined): boolean {
    return proto3.util.equals(Location, a, b);
  }
}

/**
 * Table definition for bounce_email
 *
 * @generated from message geneo.ums.db.BounceEmail
 */
export class BounceEmail extends Message<BounceEmail> {
  /**
   * Primary key for bounce_email table
   *
   * @generated from field: optional int64 bounce_email_id = 1;
   */
  bounceEmailId?: bigint;

  /**
   * Email address
   *
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * Foreign key referencing account.account_id
   *
   * @generated from field: optional int64 profile_id = 3;
   */
  profileId?: bigint;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 4;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 5;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 6;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 7;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 8;
   */
  version?: number;

  constructor(data?: PartialMessage<BounceEmail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.BounceEmail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bounce_email_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 4, name: "created_on", kind: "message", T: Timestamp },
    { no: 5, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BounceEmail {
    return new BounceEmail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BounceEmail {
    return new BounceEmail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BounceEmail {
    return new BounceEmail().fromJsonString(jsonString, options);
  }

  static equals(a: BounceEmail | PlainMessage<BounceEmail> | undefined, b: BounceEmail | PlainMessage<BounceEmail> | undefined): boolean {
    return proto3.util.equals(BounceEmail, a, b);
  }
}

/**
 * Table definition for device
 *
 * @generated from message geneo.ums.db.Device
 */
export class Device extends Message<Device> {
  /**
   * Primary key for device table
   *
   * @generated from field: int64 device_id = 1;
   */
  deviceId = protoInt64.zero;

  /**
   * Foreign key referencing account.account_id
   *
   * @generated from field: int64 profile_id = 2;
   */
  profileId = protoInt64.zero;

  /**
   * Type of the device
   *
   * @generated from field: geneo.ums.db.DeviceEnum device_type = 3;
   */
  deviceType = DeviceEnum.DEVICE_TYPE_UNDEFINED;

  /**
   * JSON object containing device details
   *
   * @generated from field: optional geneo.ums.db.DeviceDetails device_details = 4;
   */
  deviceDetails?: DeviceDetails;

  /**
   * Device token
   *
   * @generated from field: string device_token = 5;
   */
  deviceToken = "";

  /**
   * JSON object containing location details
   *
   * @generated from field: optional geneo.ums.db.Location location = 6;
   */
  location?: Location;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 9;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * Flag indicating if the token is active or not
   *
   * @generated from field: optional bool is_active = 10;
   */
  isActive?: boolean;

  /**
   * @generated from field: optional string nano_id = 11;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 12;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 13;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 14;
   */
  version?: number;

  constructor(data?: PartialMessage<Device>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.Device";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "device_type", kind: "enum", T: proto3.getEnumType(DeviceEnum) },
    { no: 4, name: "device_details", kind: "message", T: DeviceDetails, opt: true },
    { no: 5, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "location", kind: "message", T: Location, opt: true },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 10, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Device {
    return new Device().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJsonString(jsonString, options);
  }

  static equals(a: Device | PlainMessage<Device> | undefined, b: Device | PlainMessage<Device> | undefined): boolean {
    return proto3.util.equals(Device, a, b);
  }
}

/**
 * Table definition for profile_login_details
 *
 * @generated from message geneo.ums.db.ProfileLoginDetails
 */
export class ProfileLoginDetails extends Message<ProfileLoginDetails> {
  /**
   * Primary key for profile_login_details table
   *
   * @generated from field: optional int64 profile_login_details_id = 1;
   */
  profileLoginDetailsId?: bigint;

  /**
   * int64 account_login_details_id = 2; // Foreign key referencing account_login_details.account_login_details_id
   * int64 account_id = 3; // Foreign key referencing account.account_id
   *
   * Profile ID
   *
   * @generated from field: optional int64 profile_id = 4;
   */
  profileId?: bigint;

  /**
   * Role of the account
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum profile_role = 5;
   */
  profileRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * Type of login
   *
   * @generated from field: geneo.ums.db.LoginTypeEnum login_type = 6;
   */
  loginType = LoginTypeEnum.LOGIN_TYPE_UNDEFINED;

  /**
   * Login timestamp
   *
   * @generated from field: google.protobuf.Timestamp login_time = 7;
   */
  loginTime?: Timestamp;

  /**
   * Logout timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp logout_time = 8;
   */
  logoutTime?: Timestamp;

  /**
   * Authentication token
   *
   * @generated from field: string token = 9;
   */
  token = "";

  /**
   * Expiration timestamp for the token
   *
   * @generated from field: google.protobuf.Timestamp token_expiry_time = 10;
   */
  tokenExpiryTime?: Timestamp;

  /**
   * Foreign key referencing device.device_id
   *
   * @generated from field: optional int64 device_id = 11;
   */
  deviceId?: bigint;

  /**
   * @generated from field: optional string app_version = 12;
   */
  appVersion?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 13;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional int64 root_profile_login_details_id = 14;
   */
  rootProfileLoginDetailsId?: bigint;

  /**
   * @generated from field: optional string nano_id = 15;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 16;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 17;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional string refresh_token = 18;
   */
  refreshToken?: string;

  /**
   * @generated from field: optional int32 version = 19;
   */
  version?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 20;
   */
  modifiedOn?: Timestamp;

  constructor(data?: PartialMessage<ProfileLoginDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.ProfileLoginDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_login_details_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 4, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 5, name: "profile_role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 6, name: "login_type", kind: "enum", T: proto3.getEnumType(LoginTypeEnum) },
    { no: 7, name: "login_time", kind: "message", T: Timestamp },
    { no: 8, name: "logout_time", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "token_expiry_time", kind: "message", T: Timestamp },
    { no: 11, name: "device_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "root_profile_login_details_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 17, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 20, name: "modified_on", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileLoginDetails {
    return new ProfileLoginDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileLoginDetails {
    return new ProfileLoginDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileLoginDetails {
    return new ProfileLoginDetails().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileLoginDetails | PlainMessage<ProfileLoginDetails> | undefined, b: ProfileLoginDetails | PlainMessage<ProfileLoginDetails> | undefined): boolean {
    return proto3.util.equals(ProfileLoginDetails, a, b);
  }
}

/**
 * @generated from message geneo.ums.db.ActiveSessions
 */
export class ActiveSessions extends Message<ActiveSessions> {
  /**
   * @generated from field: int32 active_session_id = 1;
   */
  activeSessionId = 0;

  /**
   * Profile ID
   *
   * @generated from field: optional int64 profile_id = 2;
   */
  profileId?: bigint;

  /**
   * Start time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp start_time = 3;
   */
  startTime?: Timestamp;

  /**
   * last usage time of the lesson session.
   *
   * @generated from field: google.protobuf.Timestamp last_usage_time = 4;
   */
  lastUsageTime?: Timestamp;

  /**
   * End time of the lesson session. Nullable field.
   *
   * @generated from field: google.protobuf.Timestamp end_time = 5;
   */
  endTime?: Timestamp;

  /**
   * Primary key for profile_login_details table
   *
   * @generated from field: int64 profile_login_details_id = 6;
   */
  profileLoginDetailsId = protoInt64.zero;

  constructor(data?: PartialMessage<ActiveSessions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.ActiveSessions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "last_usage_time", kind: "message", T: Timestamp },
    { no: 5, name: "end_time", kind: "message", T: Timestamp },
    { no: 6, name: "profile_login_details_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveSessions {
    return new ActiveSessions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveSessions {
    return new ActiveSessions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveSessions {
    return new ActiveSessions().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveSessions | PlainMessage<ActiveSessions> | undefined, b: ActiveSessions | PlainMessage<ActiveSessions> | undefined): boolean {
    return proto3.util.equals(ActiveSessions, a, b);
  }
}

/**
 * Table definition for student_profile
 *
 * @generated from message geneo.ums.db.StudentProfile
 */
export class StudentProfile extends Message<StudentProfile> {
  /**
   * Primary key for student_profile table
   *
   * @generated from field: optional int64 student_profile_id = 1;
   */
  studentProfileId?: bigint;

  /**
   * Name of the account
   *
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * Email address of the account
   *
   * @generated from field: optional string email = 3;
   */
  email?: string;

  /**
   * Country of the phone number
   *
   * @generated from field: optional string phone_country = 4;
   */
  phoneCountry?: string;

  /**
   * Phone number of the account
   *
   * @generated from field: optional string phone_number = 5;
   */
  phoneNumber?: string;

  /**
   * First name of the account holder
   *
   * @generated from field: string first_name = 6;
   */
  firstName = "";

  /**
   * Last name of the account holder
   *
   * @generated from field: optional string last_name = 7;
   */
  lastName?: string;

  /**
   * Middle name of the account holder
   *
   * @generated from field: optional string middle_name = 8;
   */
  middleName?: string;

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: optional bool is_profile_verified = 31;
   */
  isProfileVerified?: boolean;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: optional bool is_password_updated = 32;
   */
  isPasswordUpdated?: boolean;

  /**
   * Flag indicating if the email is verified
   *
   * @generated from field: optional int32 email_verify = 9;
   */
  emailVerify?: number;

  /**
   * Flag indicating if the phone number is verified
   *
   * @generated from field: optional int32 phone_verify = 10;
   */
  phoneVerify?: number;

  /**
   * Flag indicating if the account is active
   *
   * @generated from field: optional int32 is_active = 11;
   */
  isActive?: number;

  /**
   * Flag indicating if the account is deleted
   *
   * @generated from field: optional int32 is_delete = 12;
   */
  isDelete?: number;

  /**
   * JSON object containing profile pictures
   *
   * @generated from field: repeated geneo.ums.db.ProfilePics profile_pics = 13;
   */
  profilePics: ProfilePics[] = [];

  /**
   * int64 account_id = 14; // Foreign key referencing account.account_id
   *
   * Class ID
   *
   * @generated from field: optional int32 class_id = 15;
   */
  classId?: number;

  /**
   * Section ID
   *
   * @generated from field: optional int32 section_id = 16;
   */
  sectionId?: number;

  /**
   * School ID
   *
   * @generated from field: optional int32 school_id = 17;
   */
  schoolId?: number;

  /**
   * Board ID
   *
   * @generated from field: optional int32 board_id = 18;
   */
  boardId?: number;

  /**
   * Region ID
   *
   * @generated from field: optional int32 region_id = 19;
   */
  regionId?: number;

  /**
   * Medium ID
   *
   * @generated from field: optional int32 medium_id = 20;
   */
  mediumId?: number;

  /**
   * Address of the student
   *
   * @generated from field: optional string address = 21;
   */
  address?: string;

  /**
   * Latitude and longitude of the address
   *
   * @generated from field: optional string address_lat_long = 22;
   */
  addressLatLong?: string;

  /**
   * City of the address
   *
   * @generated from field: optional string address_city = 23;
   */
  addressCity?: string;

  /**
   * State of the address
   *
   * @generated from field: optional string address_state = 24;
   */
  addressState?: string;

  /**
   * Country of the address
   *
   * @generated from field: optional string address_country = 25;
   */
  addressCountry?: string;

  /**
   * Zip code of the address
   *
   * @generated from field: optional string address_zip_code = 26;
   */
  addressZipCode?: string;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 27;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 28;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 29;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 30;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional int32 roll_number = 33;
   */
  rollNumber?: number;

  /**
   * @generated from field: optional bool is_sso_user = 34;
   */
  isSsoUser?: boolean;

  /**
   * @generated from field: optional string sso_user_id = 35;
   */
  ssoUserId?: string;

  /**
   * @generated from field: geneo.ums.db.ProfileVerificationStatusEnum profile_verification_status = 36;
   */
  profileVerificationStatus = ProfileVerificationStatusEnum.PROFILE_VERIFICATION_UNDEFINED;

  /**
   * @generated from field: geneo.ums.db.ProfileCreationModeEnum profile_creation_mode = 37;
   */
  profileCreationMode = ProfileCreationModeEnum.UNDEFINED;

  /**
   * @generated from field: optional string nano_id = 38;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 39 ;
   *
   * @generated from field: optional int32 school_device_id = 39;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 40;
   */
  version?: number;

  constructor(data?: PartialMessage<StudentProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.StudentProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 31, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 32, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "profile_pics", kind: "message", T: ProfilePics, repeated: true },
    { no: 15, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 16, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 17, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 20, name: "medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 21, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 27, name: "created_on", kind: "message", T: Timestamp },
    { no: 28, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 29, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 30, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 33, name: "roll_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 34, name: "is_sso_user", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 35, name: "sso_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 36, name: "profile_verification_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum) },
    { no: 37, name: "profile_creation_mode", kind: "enum", T: proto3.getEnumType(ProfileCreationModeEnum) },
    { no: 38, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 40, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentProfile {
    return new StudentProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentProfile {
    return new StudentProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentProfile {
    return new StudentProfile().fromJsonString(jsonString, options);
  }

  static equals(a: StudentProfile | PlainMessage<StudentProfile> | undefined, b: StudentProfile | PlainMessage<StudentProfile> | undefined): boolean {
    return proto3.util.equals(StudentProfile, a, b);
  }
}

/**
 * Table definition for teacher_profile
 *
 * @generated from message geneo.ums.db.TeacherProfile
 */
export class TeacherProfile extends Message<TeacherProfile> {
  /**
   * Primary key for teacher_profile table
   *
   * @generated from field: optional int64 teacher_profile_id = 1;
   */
  teacherProfileId?: bigint;

  /**
   * Name of the account
   *
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * Email address of the account
   *
   * @generated from field: optional string email = 3;
   */
  email?: string;

  /**
   * Country of the phone number
   *
   * @generated from field: optional string phone_country = 4;
   */
  phoneCountry?: string;

  /**
   * Phone number of the account
   *
   * @generated from field: optional string phone_number = 5;
   */
  phoneNumber?: string;

  /**
   * First name of the account holder
   *
   * @generated from field: string first_name = 6;
   */
  firstName = "";

  /**
   * Last name of the account holder
   *
   * @generated from field: optional string last_name = 7;
   */
  lastName?: string;

  /**
   * Middle name of the account holder
   *
   * @generated from field: optional string middle_name = 8;
   */
  middleName?: string;

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: optional bool is_profile_verified = 29;
   */
  isProfileVerified?: boolean;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: optional bool is_password_updated = 30;
   */
  isPasswordUpdated?: boolean;

  /**
   * Flag indicating if the email is verified
   *
   * @generated from field: optional int32 email_verify = 9;
   */
  emailVerify?: number;

  /**
   * Flag indicating if the phone number is verified
   *
   * @generated from field: optional int32 phone_verify = 10;
   */
  phoneVerify?: number;

  /**
   * Flag indicating if the account is active
   *
   * @generated from field: optional int32 is_active = 11;
   */
  isActive?: number;

  /**
   * Flag indicating if the account is deleted
   *
   * @generated from field: optional int32 is_delete = 12;
   */
  isDelete?: number;

  /**
   * School ID
   *
   * @generated from field: optional int32 school_id = 13;
   */
  schoolId?: number;

  /**
   * @generated from field: repeated string qualification = 14;
   */
  qualification: string[] = [];

  /**
   * @generated from field: optional int32 experience = 15;
   */
  experience?: number;

  /**
   * @generated from field: repeated string teach_mediums = 16;
   */
  teachMediums: string[] = [];

  /**
   * @generated from field: repeated string languages = 17;
   */
  languages: string[] = [];

  /**
   * JSON object containing profile pictures
   *
   * @generated from field: repeated geneo.ums.db.ProfilePics profile_pics = 18;
   */
  profilePics: ProfilePics[] = [];

  /**
   * Address of the teacher
   *
   * @generated from field: optional string address = 19;
   */
  address?: string;

  /**
   * Latitude and longitude of the address
   *
   * @generated from field: optional string address_lat_long = 20;
   */
  addressLatLong?: string;

  /**
   * City of the address
   *
   * @generated from field: optional string address_city = 21;
   */
  addressCity?: string;

  /**
   * State of the address
   *
   * @generated from field: optional string address_state = 22;
   */
  addressState?: string;

  /**
   * Country of the address
   *
   * @generated from field: optional string address_country = 23;
   */
  addressCountry?: string;

  /**
   * Zip code of the address
   *
   * @generated from field: optional string address_zip_code = 24;
   */
  addressZipCode?: string;

  /**
   * Creation timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp created_on = 25;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 26;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 27;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 28;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional bool is_sso_user = 31;
   */
  isSsoUser?: boolean;

  /**
   * @generated from field: optional string sso_user_id = 32;
   */
  ssoUserId?: string;

  /**
   * @generated from field: geneo.ums.db.ProfileVerificationStatusEnum profile_verification_status = 33;
   */
  profileVerificationStatus = ProfileVerificationStatusEnum.PROFILE_VERIFICATION_UNDEFINED;

  /**
   * @generated from field: geneo.ums.db.ProfileCreationModeEnum profile_creation_mode = 34;
   */
  profileCreationMode = ProfileCreationModeEnum.UNDEFINED;

  /**
   * @generated from field: optional string nano_id = 35;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 11 ;
   *
   * @generated from field: optional int32 school_device_id = 36;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 37;
   */
  version?: number;

  /**
   * @generated from field: optional bool is_admin = 38;
   */
  isAdmin?: boolean;

  constructor(data?: PartialMessage<TeacherProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.TeacherProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 30, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "qualification", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "experience", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 16, name: "teach_mediums", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "profile_pics", kind: "message", T: ProfilePics, repeated: true },
    { no: 19, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 27, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 28, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 31, name: "is_sso_user", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 32, name: "sso_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "profile_verification_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum) },
    { no: 34, name: "profile_creation_mode", kind: "enum", T: proto3.getEnumType(ProfileCreationModeEnum) },
    { no: 35, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 36, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 37, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 38, name: "is_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherProfile {
    return new TeacherProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherProfile {
    return new TeacherProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherProfile {
    return new TeacherProfile().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherProfile | PlainMessage<TeacherProfile> | undefined, b: TeacherProfile | PlainMessage<TeacherProfile> | undefined): boolean {
    return proto3.util.equals(TeacherProfile, a, b);
  }
}

/**
 * @generated from message geneo.ums.db.AdminProfile
 */
export class AdminProfile extends Message<AdminProfile> {
  /**
   * @generated from field: optional int64 admin_profile_id = 1;
   */
  adminProfileId?: bigint;

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: optional string email = 3;
   */
  email?: string;

  /**
   * @generated from field: optional string phone_country = 4;
   */
  phoneCountry?: string;

  /**
   * @generated from field: optional string phone_number = 5;
   */
  phoneNumber?: string;

  /**
   * @generated from field: string first_name = 6;
   */
  firstName = "";

  /**
   * @generated from field: optional string last_name = 7;
   */
  lastName?: string;

  /**
   * @generated from field: optional string middle_name = 8;
   */
  middleName?: string;

  /**
   * @generated from field: optional int32 is_active = 9;
   */
  isActive?: number;

  /**
   * @generated from field: optional int32 is_delete = 10;
   */
  isDelete?: number;

  /**
   * @generated from field: optional int32 school_id = 11;
   */
  schoolId?: number;

  /**
   * @generated from field: optional string address = 12;
   */
  address?: string;

  /**
   * @generated from field: optional string address_lat_long = 13;
   */
  addressLatLong?: string;

  /**
   * @generated from field: optional string address_city = 14;
   */
  addressCity?: string;

  /**
   * @generated from field: optional string address_state = 15;
   */
  addressState?: string;

  /**
   * @generated from field: optional string address_country = 16;
   */
  addressCountry?: string;

  /**
   * @generated from field: optional string address_zip_code = 17;
   */
  addressZipCode?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 18;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 19;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 20;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 21;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional bool is_profile_verified = 22;
   */
  isProfileVerified?: boolean;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum profile_role = 23;
   */
  profileRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: optional string nano_id = 24;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 11 ;
   *
   * @generated from field: optional int32 school_device_id = 25;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 26;
   */
  version?: number;

  constructor(data?: PartialMessage<AdminProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.AdminProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 19, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 20, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 21, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 22, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 23, name: "profile_role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 24, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 26, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminProfile {
    return new AdminProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminProfile {
    return new AdminProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminProfile {
    return new AdminProfile().fromJsonString(jsonString, options);
  }

  static equals(a: AdminProfile | PlainMessage<AdminProfile> | undefined, b: AdminProfile | PlainMessage<AdminProfile> | undefined): boolean {
    return proto3.util.equals(AdminProfile, a, b);
  }
}

/**
 * Table definition for account_otp
 *
 * @generated from message geneo.ums.db.Otp
 */
export class Otp extends Message<Otp> {
  /**
   * Primary key for account_otp table
   *
   * @generated from field: optional int64 otp_id = 1;
   */
  otpId?: bigint;

  /**
   * Foreign key referencing account.account_id
   *
   * @generated from field: optional int64 profile_id = 2;
   */
  profileId?: bigint;

  /**
   * Foreign key referencing device.device_id
   *
   * @generated from field: optional int64 device_id = 3;
   */
  deviceId?: bigint;

  /**
   * OTP (One-Time Password)
   *
   * @generated from field: string otp = 4;
   */
  otp = "";

  /**
   * Type of OTP
   *
   * @generated from field: geneo.ums.db.OTPEnum otp_type = 5;
   */
  otpType = OTPEnum.OTP_TYPE_UNDEFINED;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * Expiration timestamp for the OTP
   *
   * @generated from field: google.protobuf.Timestamp expires_on = 7;
   */
  expiresOn?: Timestamp;

  /**
   * Verification key to check if the OTP is already used or not
   *
   * @generated from field: bool verified = 8;
   */
  verified = false;

  /**
   * @generated from field: optional string nano_id = 9;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 10;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 11;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 12;
   */
  version?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  constructor(data?: PartialMessage<Otp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.Otp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "otp_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "device_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 4, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "otp_type", kind: "enum", T: proto3.getEnumType(OTPEnum) },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "expires_on", kind: "message", T: Timestamp },
    { no: 8, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Otp {
    return new Otp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Otp {
    return new Otp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Otp {
    return new Otp().fromJsonString(jsonString, options);
  }

  static equals(a: Otp | PlainMessage<Otp> | undefined, b: Otp | PlainMessage<Otp> | undefined): boolean {
    return proto3.util.equals(Otp, a, b);
  }
}

/**
 * Table definition for account_passwords
 *
 * @generated from message geneo.ums.db.ProfilePasswords
 */
export class ProfilePasswords extends Message<ProfilePasswords> {
  /**
   * Primary key for account_passwords table
   *
   * @generated from field: optional int64 profile_password_id = 1;
   */
  profilePasswordId?: bigint;

  /**
   * Foreign key referencing account.account_id
   *
   * @generated from field: optional int64 profile_id = 2;
   */
  profileId?: bigint;

  /**
   * Password
   *
   * @generated from field: string password = 3;
   */
  password = "";

  /**
   * Salt used for password hashing
   *
   * @generated from field: string salt = 4;
   */
  salt = "";

  /**
   * Password hash to support offline login
   *
   * @generated from field: optional string password_hash = 5;
   */
  passwordHash?: string;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  /**
   * @generated from field: optional int32 school_id = 11;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 school_device_id = 12;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 13;
   */
  version?: number;

  constructor(data?: PartialMessage<ProfilePasswords>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.ProfilePasswords";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_password_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "salt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "password_hash", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfilePasswords {
    return new ProfilePasswords().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfilePasswords {
    return new ProfilePasswords().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfilePasswords {
    return new ProfilePasswords().fromJsonString(jsonString, options);
  }

  static equals(a: ProfilePasswords | PlainMessage<ProfilePasswords> | undefined, b: ProfilePasswords | PlainMessage<ProfilePasswords> | undefined): boolean {
    return proto3.util.equals(ProfilePasswords, a, b);
  }
}

/**
 * Table definition for account_role
 *
 * @generated from message geneo.ums.db.ProfileRole
 */
export class ProfileRole extends Message<ProfileRole> {
  /**
   * Primary key for account_role table
   *
   * @generated from field: optional int64 profile_role_id = 1;
   */
  profileRoleId?: bigint;

  /**
   * Foreign key referencing account.account_id
   *
   * @generated from field: optional int64 profile_id = 2;
   */
  profileId?: bigint;

  /**
   * Role of the account
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<ProfileRole>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.ProfileRole";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_role_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileRole {
    return new ProfileRole().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileRole {
    return new ProfileRole().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileRole {
    return new ProfileRole().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileRole | PlainMessage<ProfileRole> | undefined, b: ProfileRole | PlainMessage<ProfileRole> | undefined): boolean {
    return proto3.util.equals(ProfileRole, a, b);
  }
}

/**
 * @generated from message geneo.ums.db.EmailTemplates
 */
export class EmailTemplates extends Message<EmailTemplates> {
  /**
   * @generated from field: optional int32 email_template_id = 1;
   */
  emailTemplateId?: number;

  /**
   * @generated from field: string template_name = 2;
   */
  templateName = "";

  /**
   * @generated from field: string template_content = 3;
   */
  templateContent = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 4;
   */
  createdOn?: Timestamp;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 5;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 6;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<EmailTemplates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.EmailTemplates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email_template_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "template_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_on", kind: "message", T: Timestamp },
    { no: 5, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmailTemplates {
    return new EmailTemplates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmailTemplates {
    return new EmailTemplates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmailTemplates {
    return new EmailTemplates().fromJsonString(jsonString, options);
  }

  static equals(a: EmailTemplates | PlainMessage<EmailTemplates> | undefined, b: EmailTemplates | PlainMessage<EmailTemplates> | undefined): boolean {
    return proto3.util.equals(EmailTemplates, a, b);
  }
}

/**
 * for self-registration db
 *
 * @generated from message geneo.ums.db.Remark
 */
export class Remark extends Message<Remark> {
  /**
   * @generated from field: geneo.ums.db.ProfileRejectionEnum reason = 1;
   */
  reason = ProfileRejectionEnum.PROFILE_REJECTION_UNDEFINED;

  /**
   * @generated from field: string comments = 2;
   */
  comments = "";

  constructor(data?: PartialMessage<Remark>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.Remark";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reason", kind: "enum", T: proto3.getEnumType(ProfileRejectionEnum) },
    { no: 2, name: "comments", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Remark {
    return new Remark().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Remark {
    return new Remark().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Remark {
    return new Remark().fromJsonString(jsonString, options);
  }

  static equals(a: Remark | PlainMessage<Remark> | undefined, b: Remark | PlainMessage<Remark> | undefined): boolean {
    return proto3.util.equals(Remark, a, b);
  }
}

/**
 * @generated from message geneo.ums.db.SelfRegistrationRequests
 */
export class SelfRegistrationRequests extends Message<SelfRegistrationRequests> {
  /**
   * @generated from field: optional int32 self_registration_request_id = 1;
   */
  selfRegistrationRequestId?: number;

  /**
   * @generated from field: optional int64 profile_id = 2;
   */
  profileId?: bigint;

  /**
   * @generated from field: geneo.ums.db.ProfileVerificationStatusEnum approval_status = 3;
   */
  approvalStatus = ProfileVerificationStatusEnum.PROFILE_VERIFICATION_UNDEFINED;

  /**
   * @generated from field: optional geneo.ums.db.Remark remark = 4;
   */
  remark?: Remark;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional int32 school_id = 9;
   */
  schoolId?: number;

  /**
   * @generated from field: optional google.protobuf.Timestamp last_reminder_time = 10;
   */
  lastReminderTime?: Timestamp;

  /**
   * @generated from field: optional string nano_id = 11;
   */
  nanoId?: string;

  /**
   * optional int32 school_id = 11 ;
   *
   * @generated from field: optional int32 school_device_id = 12;
   */
  schoolDeviceId?: number;

  /**
   * @generated from field: optional int32 version = 13;
   */
  version?: number;

  constructor(data?: PartialMessage<SelfRegistrationRequests>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.db.SelfRegistrationRequests";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "self_registration_request_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "approval_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum) },
    { no: 4, name: "remark", kind: "message", T: Remark, opt: true },
    { no: 5, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "last_reminder_time", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "school_device_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SelfRegistrationRequests {
    return new SelfRegistrationRequests().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelfRegistrationRequests {
    return new SelfRegistrationRequests().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SelfRegistrationRequests {
    return new SelfRegistrationRequests().fromJsonString(jsonString, options);
  }

  static equals(a: SelfRegistrationRequests | PlainMessage<SelfRegistrationRequests> | undefined, b: SelfRegistrationRequests | PlainMessage<SelfRegistrationRequests> | undefined): boolean {
    return proto3.util.equals(SelfRegistrationRequests, a, b);
  }
}

