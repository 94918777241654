// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.school.apis.proto (package geneo.lms.school.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Any, ListValue, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { AcademicYear_Enum, APIResponse_CodeEnum, APIResponse_StatusEnum, Board_BoardEnum, Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { FeatureInfo, School, SchoolType, SchoolZone } from "@protos/school_management/school.db_pb";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";

/**
 * @generated from enum geneo.lms.school.apis.SheetType
 */
export enum SheetType {
  /**
   * @generated from enum value: SHEET_TYPE_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: SHEET_TYPE_STUDENT_LIST = 1;
   */
  STUDENT_LIST = 1,

  /**
   * @generated from enum value: SHEET_TYPE_TEACHER_LIST = 2;
   */
  TEACHER_LIST = 2,

  /**
   * @generated from enum value: SHEET_TYPE_SCHOOL_COURSE_INFO = 3;
   */
  SCHOOL_COURSE_INFO = 3,

  /**
   * @generated from enum value: SHEET_TYPE_TEACHER_CLASS_MAP = 4;
   */
  TEACHER_CLASS_MAP = 4,

  /**
   * @generated from enum value: SHEET_TYPE_CONNECTED_CLASSROOM_MAPPING = 5;
   */
  CONNECTED_CLASSROOM_MAPPING = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(SheetType)
proto3.util.setEnumType(SheetType, "geneo.lms.school.apis.SheetType", [
  { no: 0, name: "SHEET_TYPE_UNDEFINED" },
  { no: 1, name: "SHEET_TYPE_STUDENT_LIST" },
  { no: 2, name: "SHEET_TYPE_TEACHER_LIST" },
  { no: 3, name: "SHEET_TYPE_SCHOOL_COURSE_INFO" },
  { no: 4, name: "SHEET_TYPE_TEACHER_CLASS_MAP" },
  { no: 5, name: "SHEET_TYPE_CONNECTED_CLASSROOM_MAPPING" },
]);

/**
 * @generated from message geneo.lms.school.apis.SaveSchoolDeviceInfoRequest
 */
export class SaveSchoolDeviceInfoRequest extends Message<SaveSchoolDeviceInfoRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<SaveSchoolDeviceInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SaveSchoolDeviceInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveSchoolDeviceInfoRequest {
    return new SaveSchoolDeviceInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveSchoolDeviceInfoRequest {
    return new SaveSchoolDeviceInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveSchoolDeviceInfoRequest {
    return new SaveSchoolDeviceInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveSchoolDeviceInfoRequest | PlainMessage<SaveSchoolDeviceInfoRequest> | undefined, b: SaveSchoolDeviceInfoRequest | PlainMessage<SaveSchoolDeviceInfoRequest> | undefined): boolean {
    return proto3.util.equals(SaveSchoolDeviceInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SaveSchoolDeviceInfoResponse
 */
export class SaveSchoolDeviceInfoResponse extends Message<SaveSchoolDeviceInfoResponse> {
  /**
   * @generated from field: int32 schoolDeviceId = 1;
   */
  schoolDeviceId = 0;

  constructor(data?: PartialMessage<SaveSchoolDeviceInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SaveSchoolDeviceInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schoolDeviceId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveSchoolDeviceInfoResponse {
    return new SaveSchoolDeviceInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveSchoolDeviceInfoResponse {
    return new SaveSchoolDeviceInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveSchoolDeviceInfoResponse {
    return new SaveSchoolDeviceInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SaveSchoolDeviceInfoResponse | PlainMessage<SaveSchoolDeviceInfoResponse> | undefined, b: SaveSchoolDeviceInfoResponse | PlainMessage<SaveSchoolDeviceInfoResponse> | undefined): boolean {
    return proto3.util.equals(SaveSchoolDeviceInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ConnectedClassroomData
 */
export class ConnectedClassroomData extends Message<ConnectedClassroomData> {
  /**
   * Base station ID
   *
   * @generated from field: string base_station_id = 1;
   */
  baseStationId = "";

  /**
   * Grade
   *
   * @generated from field: geneo.common.db.Class.ClassEnum grade = 12;
   */
  grade = Class_ClassEnum.UNDEFINED;

  /**
   * Section
   *
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * Clicker ID
   *
   * @generated from field: string clicker_id = 4;
   */
  clickerId = "";

  /**
   * Student ID
   *
   * @generated from field: int64 student_id = 5;
   */
  studentId = protoInt64.zero;

  /**
   * Is active
   *
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  constructor(data?: PartialMessage<ConnectedClassroomData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ConnectedClassroomData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_station_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "grade", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassroomData {
    return new ConnectedClassroomData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassroomData {
    return new ConnectedClassroomData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassroomData {
    return new ConnectedClassroomData().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassroomData | PlainMessage<ConnectedClassroomData> | undefined, b: ConnectedClassroomData | PlainMessage<ConnectedClassroomData> | undefined): boolean {
    return proto3.util.equals(ConnectedClassroomData, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ConnectedClassroomRequest
 */
export class ConnectedClassroomRequest extends Message<ConnectedClassroomRequest> {
  /**
   * Array of ConnectedClassroomData
   *
   * @generated from field: repeated geneo.lms.school.apis.ConnectedClassroomData data = 1;
   */
  data: ConnectedClassroomData[] = [];

  constructor(data?: PartialMessage<ConnectedClassroomRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ConnectedClassroomRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "message", T: ConnectedClassroomData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassroomRequest {
    return new ConnectedClassroomRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassroomRequest {
    return new ConnectedClassroomRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassroomRequest {
    return new ConnectedClassroomRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassroomRequest | PlainMessage<ConnectedClassroomRequest> | undefined, b: ConnectedClassroomRequest | PlainMessage<ConnectedClassroomRequest> | undefined): boolean {
    return proto3.util.equals(ConnectedClassroomRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ConnectedClassroomResponse
 */
export class ConnectedClassroomResponse extends Message<ConnectedClassroomResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<ConnectedClassroomResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ConnectedClassroomResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassroomResponse {
    return new ConnectedClassroomResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassroomResponse {
    return new ConnectedClassroomResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassroomResponse {
    return new ConnectedClassroomResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassroomResponse | PlainMessage<ConnectedClassroomResponse> | undefined, b: ConnectedClassroomResponse | PlainMessage<ConnectedClassroomResponse> | undefined): boolean {
    return proto3.util.equals(ConnectedClassroomResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolRequest
 */
export class SyncSchoolRequest extends Message<SyncSchoolRequest> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolSheetModel schools = 1;
   */
  schools: SchoolSheetModel[] = [];

  constructor(data?: PartialMessage<SyncSchoolRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schools", kind: "message", T: SchoolSheetModel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolRequest {
    return new SyncSchoolRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolRequest {
    return new SyncSchoolRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolRequest {
    return new SyncSchoolRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolRequest | PlainMessage<SyncSchoolRequest> | undefined, b: SyncSchoolRequest | PlainMessage<SyncSchoolRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolResponse
 */
export class SyncSchoolResponse extends Message<SyncSchoolResponse> {
  /**
   * @generated from field: repeated int32 schoolIds = 1;
   */
  schoolIds: number[] = [];

  constructor(data?: PartialMessage<SyncSchoolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schoolIds", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolResponse {
    return new SyncSchoolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolResponse {
    return new SyncSchoolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolResponse {
    return new SyncSchoolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolResponse | PlainMessage<SyncSchoolResponse> | undefined, b: SyncSchoolResponse | PlainMessage<SyncSchoolResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolCourseSheetModel
 */
export class SchoolCourseSheetModel extends Message<SchoolCourseSheetModel> {
  /**
   * @generated from field: geneo.common.db.Board.BoardEnum board = 1;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * @generated from field: string teach_medium = 2;
   */
  teachMedium = "";

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 3;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 5;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: int32 book_id = 6;
   */
  bookId = 0;

  constructor(data?: PartialMessage<SchoolCourseSheetModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolCourseSheetModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 2, name: "teach_medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 6, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolCourseSheetModel {
    return new SchoolCourseSheetModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolCourseSheetModel {
    return new SchoolCourseSheetModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolCourseSheetModel {
    return new SchoolCourseSheetModel().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolCourseSheetModel | PlainMessage<SchoolCourseSheetModel> | undefined, b: SchoolCourseSheetModel | PlainMessage<SchoolCourseSheetModel> | undefined): boolean {
    return proto3.util.equals(SchoolCourseSheetModel, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolCoursesRequest
 */
export class SyncSchoolCoursesRequest extends Message<SyncSchoolCoursesRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 2;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolCourseSheetModel courses = 3;
   */
  courses: SchoolCourseSheetModel[] = [];

  constructor(data?: PartialMessage<SyncSchoolCoursesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolCoursesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 3, name: "courses", kind: "message", T: SchoolCourseSheetModel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolCoursesRequest {
    return new SyncSchoolCoursesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolCoursesRequest {
    return new SyncSchoolCoursesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolCoursesRequest {
    return new SyncSchoolCoursesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolCoursesRequest | PlainMessage<SyncSchoolCoursesRequest> | undefined, b: SyncSchoolCoursesRequest | PlainMessage<SyncSchoolCoursesRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolCoursesRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SPOCInfo
 */
export class SPOCInfo extends Message<SPOCInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  constructor(data?: PartialMessage<SPOCInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SPOCInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SPOCInfo {
    return new SPOCInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SPOCInfo {
    return new SPOCInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SPOCInfo {
    return new SPOCInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SPOCInfo | PlainMessage<SPOCInfo> | undefined, b: SPOCInfo | PlainMessage<SPOCInfo> | undefined): boolean {
    return proto3.util.equals(SPOCInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolKyanInfo
 */
export class SchoolKyanInfo extends Message<SchoolKyanInfo> {
  /**
   * @generated from field: string school_id = 1;
   */
  schoolId = "";

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: repeated string kYan_serial_nos = 3;
   */
  kYanSerialNos: string[] = [];

  constructor(data?: PartialMessage<SchoolKyanInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolKyanInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "kYan_serial_nos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolKyanInfo {
    return new SchoolKyanInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolKyanInfo {
    return new SchoolKyanInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolKyanInfo {
    return new SchoolKyanInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolKyanInfo | PlainMessage<SchoolKyanInfo> | undefined, b: SchoolKyanInfo | PlainMessage<SchoolKyanInfo> | undefined): boolean {
    return proto3.util.equals(SchoolKyanInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolSheetModel
 */
export class SchoolSheetModel extends Message<SchoolSheetModel> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 school_group_id = 2;
   */
  schoolGroupId = 0;

  /**
   * @generated from field: string school_name = 3;
   */
  schoolName = "";

  /**
   * @generated from field: int32 region_id = 4;
   */
  regionId = 0;

  /**
   * @generated from field: string address = 5;
   */
  address = "";

  /**
   * @generated from field: string address_lat_long = 6;
   */
  addressLatLong = "";

  /**
   * @generated from field: string address_city = 7;
   */
  addressCity = "";

  /**
   * @generated from field: string address_state = 8;
   */
  addressState = "";

  /**
   * @generated from field: string address_country = 9;
   */
  addressCountry = "";

  /**
   * @generated from field: string address_zip_code = 10;
   */
  addressZipCode = "";

  /**
   * @generated from field: string branch_name = 11;
   */
  branchName = "";

  /**
   * @generated from field: string school_logo_image_url = 12;
   */
  schoolLogoImageUrl = "";

  /**
   * @generated from field: geneo.lms.school.apis.SPOCInfo school_spoc_info = 13;
   */
  schoolSpocInfo?: SPOCInfo;

  /**
   * @generated from field: geneo.lms.school.apis.SPOCInfo geneo_spoc_info = 14;
   */
  geneoSpocInfo?: SPOCInfo;

  /**
   * @generated from field: string udise_id = 15;
   */
  udiseId = "";

  /**
   * @generated from field: bool isActive = 16;
   */
  isActive = false;

  /**
   * @generated from field: geneo.lms.school.apis.SchoolKyanInfo school_kyan_info = 17;
   */
  schoolKyanInfo?: SchoolKyanInfo;

  /**
   * @generated from field: optional bool isInternal = 18;
   */
  isInternal?: boolean;

  /**
   * @generated from field: repeated geneo.school.db.FeatureInfo feature_info = 19;
   */
  featureInfo: FeatureInfo[] = [];

  /**
   * @generated from field: optional geneo.school.db.SchoolType school_type = 20;
   */
  schoolType?: SchoolType;

  /**
   * @generated from field: optional geneo.school.db.SchoolZone school_zone = 21;
   */
  schoolZone?: SchoolZone;

  /**
   * repeated SPOCInfo geneo_spocs_info = 23;
   *
   * @generated from field: repeated geneo.lms.school.apis.SPOCInfo school_spocs_info = 22;
   */
  schoolSpocsInfo: SPOCInfo[] = [];

  constructor(data?: PartialMessage<SchoolSheetModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolSheetModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "branch_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "school_logo_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "school_spoc_info", kind: "message", T: SPOCInfo },
    { no: 14, name: "geneo_spoc_info", kind: "message", T: SPOCInfo },
    { no: 15, name: "udise_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "school_kyan_info", kind: "message", T: SchoolKyanInfo },
    { no: 18, name: "isInternal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 19, name: "feature_info", kind: "message", T: FeatureInfo, repeated: true },
    { no: 20, name: "school_type", kind: "enum", T: proto3.getEnumType(SchoolType), opt: true },
    { no: 21, name: "school_zone", kind: "enum", T: proto3.getEnumType(SchoolZone), opt: true },
    { no: 22, name: "school_spocs_info", kind: "message", T: SPOCInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolSheetModel {
    return new SchoolSheetModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolSheetModel {
    return new SchoolSheetModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolSheetModel {
    return new SchoolSheetModel().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolSheetModel | PlainMessage<SchoolSheetModel> | undefined, b: SchoolSheetModel | PlainMessage<SchoolSheetModel> | undefined): boolean {
    return proto3.util.equals(SchoolSheetModel, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolCoursesResponse
 */
export class SyncSchoolCoursesResponse extends Message<SyncSchoolCoursesResponse> {
  constructor(data?: PartialMessage<SyncSchoolCoursesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolCoursesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolCoursesResponse {
    return new SyncSchoolCoursesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolCoursesResponse {
    return new SyncSchoolCoursesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolCoursesResponse {
    return new SyncSchoolCoursesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolCoursesResponse | PlainMessage<SyncSchoolCoursesResponse> | undefined, b: SyncSchoolCoursesResponse | PlainMessage<SyncSchoolCoursesResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolCoursesResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolClassSectionRequest
 */
export class SyncSchoolClassSectionRequest extends Message<SyncSchoolClassSectionRequest> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolClassSection section = 1;
   */
  section: SchoolClassSection[] = [];

  constructor(data?: PartialMessage<SyncSchoolClassSectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolClassSectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "section", kind: "message", T: SchoolClassSection, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolClassSectionRequest {
    return new SyncSchoolClassSectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolClassSectionRequest {
    return new SyncSchoolClassSectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolClassSectionRequest {
    return new SyncSchoolClassSectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolClassSectionRequest | PlainMessage<SyncSchoolClassSectionRequest> | undefined, b: SyncSchoolClassSectionRequest | PlainMessage<SyncSchoolClassSectionRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolClassSectionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolClassSection
 */
export class SchoolClassSection extends Message<SchoolClassSection> {
  /**
   * @generated from field: string section_name = 1;
   */
  sectionName = "";

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * @generated from field: int32 academic_year_id = 4;
   */
  academicYearId = 0;

  constructor(data?: PartialMessage<SchoolClassSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolClassSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolClassSection | PlainMessage<SchoolClassSection> | undefined, b: SchoolClassSection | PlainMessage<SchoolClassSection> | undefined): boolean {
    return proto3.util.equals(SchoolClassSection, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolClassSectionResponse
 */
export class SyncSchoolClassSectionResponse extends Message<SyncSchoolClassSectionResponse> {
  /**
   * @generated from field: repeated int32 school_class_section_id = 1;
   */
  schoolClassSectionId: number[] = [];

  constructor(data?: PartialMessage<SyncSchoolClassSectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolClassSectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolClassSectionResponse {
    return new SyncSchoolClassSectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolClassSectionResponse {
    return new SyncSchoolClassSectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolClassSectionResponse {
    return new SyncSchoolClassSectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolClassSectionResponse | PlainMessage<SyncSchoolClassSectionResponse> | undefined, b: SyncSchoolClassSectionResponse | PlainMessage<SyncSchoolClassSectionResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolClassSectionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolTeacherListRequest
 */
export class SyncSchoolTeacherListRequest extends Message<SyncSchoolTeacherListRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolTeacherList teachers = 2;
   */
  teachers: SchoolTeacherList[] = [];

  constructor(data?: PartialMessage<SyncSchoolTeacherListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolTeacherListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teachers", kind: "message", T: SchoolTeacherList, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolTeacherListRequest {
    return new SyncSchoolTeacherListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolTeacherListRequest {
    return new SyncSchoolTeacherListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolTeacherListRequest {
    return new SyncSchoolTeacherListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolTeacherListRequest | PlainMessage<SyncSchoolTeacherListRequest> | undefined, b: SyncSchoolTeacherListRequest | PlainMessage<SyncSchoolTeacherListRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolTeacherListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolTeacherList
 */
export class SchoolTeacherList extends Message<SchoolTeacherList> {
  /**
   * @generated from field: optional int64 teacherId = 8;
   */
  teacherId?: bigint;

  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: optional string phone = 2;
   */
  phone?: string;

  /**
   * @generated from field: optional string email = 3;
   */
  email?: string;

  /**
   * @generated from field: string username = 4;
   */
  username = "";

  /**
   * @generated from field: string password = 5;
   */
  password = "";

  /**
   * @generated from field: bool is_admin = 6;
   */
  isAdmin = false;

  /**
   * @generated from field: bool isActive = 7;
   */
  isActive = false;

  /**
   * @generated from field: optional bool is_disabled = 9;
   */
  isDisabled?: boolean;

  constructor(data?: PartialMessage<SchoolTeacherList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolTeacherList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 8, name: "teacherId", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolTeacherList {
    return new SchoolTeacherList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolTeacherList {
    return new SchoolTeacherList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolTeacherList {
    return new SchoolTeacherList().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolTeacherList | PlainMessage<SchoolTeacherList> | undefined, b: SchoolTeacherList | PlainMessage<SchoolTeacherList> | undefined): boolean {
    return proto3.util.equals(SchoolTeacherList, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolTeacherListResponse
 */
export class SyncSchoolTeacherListResponse extends Message<SyncSchoolTeacherListResponse> {
  /**
   * @generated from field: repeated int64 ids = 1;
   */
  ids: bigint[] = [];

  constructor(data?: PartialMessage<SyncSchoolTeacherListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolTeacherListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolTeacherListResponse {
    return new SyncSchoolTeacherListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolTeacherListResponse {
    return new SyncSchoolTeacherListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolTeacherListResponse {
    return new SyncSchoolTeacherListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolTeacherListResponse | PlainMessage<SyncSchoolTeacherListResponse> | undefined, b: SyncSchoolTeacherListResponse | PlainMessage<SyncSchoolTeacherListResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolTeacherListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolStudentListRequest
 */
export class SyncSchoolStudentListRequest extends Message<SyncSchoolStudentListRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 2;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolStudentList students = 3;
   */
  students: SchoolStudentList[] = [];

  constructor(data?: PartialMessage<SyncSchoolStudentListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolStudentListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 3, name: "students", kind: "message", T: SchoolStudentList, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolStudentListRequest {
    return new SyncSchoolStudentListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolStudentListRequest {
    return new SyncSchoolStudentListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolStudentListRequest {
    return new SyncSchoolStudentListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolStudentListRequest | PlainMessage<SyncSchoolStudentListRequest> | undefined, b: SyncSchoolStudentListRequest | PlainMessage<SyncSchoolStudentListRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolStudentListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolStudentList
 */
export class SchoolStudentList extends Message<SchoolStudentList> {
  /**
   * @generated from field: int32 school_id = 13;
   */
  schoolId = 0;

  /**
   * @generated from field: optional int64 student_id = 12;
   */
  studentId?: bigint;

  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int32 roll_no = 2;
   */
  rollNo = 0;

  /**
   * @generated from field: geneo.common.db.Board.BoardEnum board = 3;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * @generated from field: string teach_medium = 4;
   */
  teachMedium = "";

  /**
   * @generated from field: string section = 5;
   */
  section = "";

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 6;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: string username = 7;
   */
  username = "";

  /**
   * @generated from field: string password = 8;
   */
  password = "";

  /**
   * @generated from field: bool isActive = 9;
   */
  isActive = false;

  /**
   * @generated from field: string email = 10;
   */
  email = "";

  /**
   * @generated from field: string phone = 11;
   */
  phone = "";

  constructor(data?: PartialMessage<SchoolStudentList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolStudentList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 13, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "roll_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 4, name: "teach_medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 7, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolStudentList {
    return new SchoolStudentList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolStudentList {
    return new SchoolStudentList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolStudentList {
    return new SchoolStudentList().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolStudentList | PlainMessage<SchoolStudentList> | undefined, b: SchoolStudentList | PlainMessage<SchoolStudentList> | undefined): boolean {
    return proto3.util.equals(SchoolStudentList, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolStudentListResponse
 */
export class SyncSchoolStudentListResponse extends Message<SyncSchoolStudentListResponse> {
  /**
   * @generated from field: repeated int64 student_ids = 1;
   */
  studentIds: bigint[] = [];

  constructor(data?: PartialMessage<SyncSchoolStudentListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolStudentListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolStudentListResponse {
    return new SyncSchoolStudentListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolStudentListResponse {
    return new SyncSchoolStudentListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolStudentListResponse {
    return new SyncSchoolStudentListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolStudentListResponse | PlainMessage<SyncSchoolStudentListResponse> | undefined, b: SyncSchoolStudentListResponse | PlainMessage<SyncSchoolStudentListResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolStudentListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolTeacherCourseMapRequest
 */
export class SyncSchoolTeacherCourseMapRequest extends Message<SyncSchoolTeacherCourseMapRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academicYear = 2;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolTeacherCourse teacher_courses = 3;
   */
  teacherCourses: SchoolTeacherCourse[] = [];

  constructor(data?: PartialMessage<SyncSchoolTeacherCourseMapRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolTeacherCourseMapRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "academicYear", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 3, name: "teacher_courses", kind: "message", T: SchoolTeacherCourse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolTeacherCourseMapRequest {
    return new SyncSchoolTeacherCourseMapRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolTeacherCourseMapRequest {
    return new SyncSchoolTeacherCourseMapRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolTeacherCourseMapRequest {
    return new SyncSchoolTeacherCourseMapRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolTeacherCourseMapRequest | PlainMessage<SyncSchoolTeacherCourseMapRequest> | undefined, b: SyncSchoolTeacherCourseMapRequest | PlainMessage<SyncSchoolTeacherCourseMapRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolTeacherCourseMapRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolTeacherCourse
 */
export class SchoolTeacherCourse extends Message<SchoolTeacherCourse> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.TeacherCourse class_subject_map = 3;
   */
  classSubjectMap: TeacherCourse[] = [];

  constructor(data?: PartialMessage<SchoolTeacherCourse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolTeacherCourse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_subject_map", kind: "message", T: TeacherCourse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolTeacherCourse {
    return new SchoolTeacherCourse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolTeacherCourse {
    return new SchoolTeacherCourse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolTeacherCourse {
    return new SchoolTeacherCourse().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolTeacherCourse | PlainMessage<SchoolTeacherCourse> | undefined, b: SchoolTeacherCourse | PlainMessage<SchoolTeacherCourse> | undefined): boolean {
    return proto3.util.equals(SchoolTeacherCourse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.TeacherCourse
 */
export class TeacherCourse extends Message<TeacherCourse> {
  /**
   * @generated from field: geneo.common.db.Board.BoardEnum board = 1;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * @generated from field: string teacher_medium = 2;
   */
  teacherMedium = "";

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 3;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: string section = 4;
   */
  section = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 5;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  constructor(data?: PartialMessage<TeacherCourse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.TeacherCourse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 2, name: "teacher_medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 4, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherCourse {
    return new TeacherCourse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherCourse {
    return new TeacherCourse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherCourse {
    return new TeacherCourse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherCourse | PlainMessage<TeacherCourse> | undefined, b: TeacherCourse | PlainMessage<TeacherCourse> | undefined): boolean {
    return proto3.util.equals(TeacherCourse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SyncSchoolTeacherCourseMapResponse
 */
export class SyncSchoolTeacherCourseMapResponse extends Message<SyncSchoolTeacherCourseMapResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  constructor(data?: PartialMessage<SyncSchoolTeacherCourseMapResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolTeacherCourseMapResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolTeacherCourseMapResponse {
    return new SyncSchoolTeacherCourseMapResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolTeacherCourseMapResponse {
    return new SyncSchoolTeacherCourseMapResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolTeacherCourseMapResponse {
    return new SyncSchoolTeacherCourseMapResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolTeacherCourseMapResponse | PlainMessage<SyncSchoolTeacherCourseMapResponse> | undefined, b: SyncSchoolTeacherCourseMapResponse | PlainMessage<SyncSchoolTeacherCourseMapResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolTeacherCourseMapResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ValidateFileRequest
 */
export class ValidateFileRequest extends Message<ValidateFileRequest> {
  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 1;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 2;
   */
  profileId = protoInt64.zero;

  constructor(data?: PartialMessage<ValidateFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ValidateFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateFileRequest {
    return new ValidateFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateFileRequest {
    return new ValidateFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateFileRequest {
    return new ValidateFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateFileRequest | PlainMessage<ValidateFileRequest> | undefined, b: ValidateFileRequest | PlainMessage<ValidateFileRequest> | undefined): boolean {
    return proto3.util.equals(ValidateFileRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ValidateFileResponse
 */
export class ValidateFileResponse extends Message<ValidateFileResponse> {
  /**
   * Status of the validation
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * List of validation errors, if any
   *
   * @generated from field: repeated geneo.lms.school.apis.ValidationError errors = 4;
   */
  errors: ValidationError[] = [];

  constructor(data?: PartialMessage<ValidateFileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ValidateFileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateFileResponse {
    return new ValidateFileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateFileResponse {
    return new ValidateFileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateFileResponse {
    return new ValidateFileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateFileResponse | PlainMessage<ValidateFileResponse> | undefined, b: ValidateFileResponse | PlainMessage<ValidateFileResponse> | undefined): boolean {
    return proto3.util.equals(ValidateFileResponse, a, b);
  }
}

/**
 * Request and response messages for saving validated data
 *
 * @generated from message geneo.lms.school.apis.SaveValidatedDataRequest
 */
export class SaveValidatedDataRequest extends Message<SaveValidatedDataRequest> {
  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 1;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 2;
   */
  profileId = protoInt64.zero;

  constructor(data?: PartialMessage<SaveValidatedDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SaveValidatedDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveValidatedDataRequest {
    return new SaveValidatedDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveValidatedDataRequest {
    return new SaveValidatedDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveValidatedDataRequest {
    return new SaveValidatedDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveValidatedDataRequest | PlainMessage<SaveValidatedDataRequest> | undefined, b: SaveValidatedDataRequest | PlainMessage<SaveValidatedDataRequest> | undefined): boolean {
    return proto3.util.equals(SaveValidatedDataRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SaveValidatedDataResponse
 */
export class SaveValidatedDataResponse extends Message<SaveValidatedDataResponse> {
  /**
   * Status of the request
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Response code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Response message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Number of records successfully saved
   *
   * @generated from field: int32 saved_records_count = 4;
   */
  savedRecordsCount = 0;

  constructor(data?: PartialMessage<SaveValidatedDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SaveValidatedDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "saved_records_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveValidatedDataResponse {
    return new SaveValidatedDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveValidatedDataResponse {
    return new SaveValidatedDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveValidatedDataResponse {
    return new SaveValidatedDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SaveValidatedDataResponse | PlainMessage<SaveValidatedDataResponse> | undefined, b: SaveValidatedDataResponse | PlainMessage<SaveValidatedDataResponse> | undefined): boolean {
    return proto3.util.equals(SaveValidatedDataResponse, a, b);
  }
}

/**
 * Request and response messages for activateSchool
 *
 * @generated from message geneo.lms.school.apis.ActivateSchoolRequest
 */
export class ActivateSchoolRequest extends Message<ActivateSchoolRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<ActivateSchoolRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ActivateSchoolRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateSchoolRequest {
    return new ActivateSchoolRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateSchoolRequest {
    return new ActivateSchoolRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivateSchoolRequest {
    return new ActivateSchoolRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ActivateSchoolRequest | PlainMessage<ActivateSchoolRequest> | undefined, b: ActivateSchoolRequest | PlainMessage<ActivateSchoolRequest> | undefined): boolean {
    return proto3.util.equals(ActivateSchoolRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ActivateSchoolResponse
 */
export class ActivateSchoolResponse extends Message<ActivateSchoolResponse> {
  /**
   * @generated from field: int32 updated_student_count = 1;
   */
  updatedStudentCount = 0;

  /**
   * @generated from field: int32 updated_teacher_count = 2;
   */
  updatedTeacherCount = 0;

  constructor(data?: PartialMessage<ActivateSchoolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ActivateSchoolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_student_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "updated_teacher_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateSchoolResponse {
    return new ActivateSchoolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateSchoolResponse {
    return new ActivateSchoolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivateSchoolResponse {
    return new ActivateSchoolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ActivateSchoolResponse | PlainMessage<ActivateSchoolResponse> | undefined, b: ActivateSchoolResponse | PlainMessage<ActivateSchoolResponse> | undefined): boolean {
    return proto3.util.equals(ActivateSchoolResponse, a, b);
  }
}

/**
 * Request and response messages for deactivateSchool
 *
 * @generated from message geneo.lms.school.apis.DeactivateSchoolRequest
 */
export class DeactivateSchoolRequest extends Message<DeactivateSchoolRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  constructor(data?: PartialMessage<DeactivateSchoolRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DeactivateSchoolRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivateSchoolRequest {
    return new DeactivateSchoolRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivateSchoolRequest {
    return new DeactivateSchoolRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivateSchoolRequest {
    return new DeactivateSchoolRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivateSchoolRequest | PlainMessage<DeactivateSchoolRequest> | undefined, b: DeactivateSchoolRequest | PlainMessage<DeactivateSchoolRequest> | undefined): boolean {
    return proto3.util.equals(DeactivateSchoolRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.DeactivateSchoolResponse
 */
export class DeactivateSchoolResponse extends Message<DeactivateSchoolResponse> {
  /**
   * @generated from field: int32 updated_student_count = 1;
   */
  updatedStudentCount = 0;

  /**
   * @generated from field: int32 updated_teacher_count = 2;
   */
  updatedTeacherCount = 0;

  constructor(data?: PartialMessage<DeactivateSchoolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DeactivateSchoolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_student_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "updated_teacher_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivateSchoolResponse {
    return new DeactivateSchoolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivateSchoolResponse {
    return new DeactivateSchoolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivateSchoolResponse {
    return new DeactivateSchoolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivateSchoolResponse | PlainMessage<DeactivateSchoolResponse> | undefined, b: DeactivateSchoolResponse | PlainMessage<DeactivateSchoolResponse> | undefined): boolean {
    return proto3.util.equals(DeactivateSchoolResponse, a, b);
  }
}

/**
 * Request and response messages for deleteStudentUsers
 *
 * @generated from message geneo.lms.school.apis.DeleteStudentUsersRequest
 */
export class DeleteStudentUsersRequest extends Message<DeleteStudentUsersRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated string usernames = 2;
   */
  usernames: string[] = [];

  constructor(data?: PartialMessage<DeleteStudentUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DeleteStudentUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteStudentUsersRequest {
    return new DeleteStudentUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteStudentUsersRequest {
    return new DeleteStudentUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteStudentUsersRequest {
    return new DeleteStudentUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteStudentUsersRequest | PlainMessage<DeleteStudentUsersRequest> | undefined, b: DeleteStudentUsersRequest | PlainMessage<DeleteStudentUsersRequest> | undefined): boolean {
    return proto3.util.equals(DeleteStudentUsersRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.DeleteStudentUsersResponse
 */
export class DeleteStudentUsersResponse extends Message<DeleteStudentUsersResponse> {
  /**
   * @generated from field: int32 deleted_student_count = 1;
   */
  deletedStudentCount = 0;

  constructor(data?: PartialMessage<DeleteStudentUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DeleteStudentUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deleted_student_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteStudentUsersResponse {
    return new DeleteStudentUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteStudentUsersResponse {
    return new DeleteStudentUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteStudentUsersResponse {
    return new DeleteStudentUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteStudentUsersResponse | PlainMessage<DeleteStudentUsersResponse> | undefined, b: DeleteStudentUsersResponse | PlainMessage<DeleteStudentUsersResponse> | undefined): boolean {
    return proto3.util.equals(DeleteStudentUsersResponse, a, b);
  }
}

/**
 * Request and response messages for deleteTeacherUsers
 *
 * @generated from message geneo.lms.school.apis.DeleteTeacherUsersRequest
 */
export class DeleteTeacherUsersRequest extends Message<DeleteTeacherUsersRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated string usernames = 2;
   */
  usernames: string[] = [];

  constructor(data?: PartialMessage<DeleteTeacherUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DeleteTeacherUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTeacherUsersRequest {
    return new DeleteTeacherUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTeacherUsersRequest {
    return new DeleteTeacherUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTeacherUsersRequest {
    return new DeleteTeacherUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTeacherUsersRequest | PlainMessage<DeleteTeacherUsersRequest> | undefined, b: DeleteTeacherUsersRequest | PlainMessage<DeleteTeacherUsersRequest> | undefined): boolean {
    return proto3.util.equals(DeleteTeacherUsersRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.DeleteTeacherUsersResponse
 */
export class DeleteTeacherUsersResponse extends Message<DeleteTeacherUsersResponse> {
  /**
   * @generated from field: int32 deleted_teacher_count = 1;
   */
  deletedTeacherCount = 0;

  constructor(data?: PartialMessage<DeleteTeacherUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DeleteTeacherUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deleted_teacher_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTeacherUsersResponse {
    return new DeleteTeacherUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTeacherUsersResponse {
    return new DeleteTeacherUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTeacherUsersResponse {
    return new DeleteTeacherUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTeacherUsersResponse | PlainMessage<DeleteTeacherUsersResponse> | undefined, b: DeleteTeacherUsersResponse | PlainMessage<DeleteTeacherUsersResponse> | undefined): boolean {
    return proto3.util.equals(DeleteTeacherUsersResponse, a, b);
  }
}

/**
 * Request and response messages for resetStudentPasswords
 *
 * @generated from message geneo.lms.school.apis.ResetStudentPasswordsRequest
 */
export class ResetStudentPasswordsRequest extends Message<ResetStudentPasswordsRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated string usernames = 2;
   */
  usernames: string[] = [];

  constructor(data?: PartialMessage<ResetStudentPasswordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ResetStudentPasswordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetStudentPasswordsRequest {
    return new ResetStudentPasswordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetStudentPasswordsRequest {
    return new ResetStudentPasswordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetStudentPasswordsRequest {
    return new ResetStudentPasswordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetStudentPasswordsRequest | PlainMessage<ResetStudentPasswordsRequest> | undefined, b: ResetStudentPasswordsRequest | PlainMessage<ResetStudentPasswordsRequest> | undefined): boolean {
    return proto3.util.equals(ResetStudentPasswordsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ResetStudentPasswordsResponse
 */
export class ResetStudentPasswordsResponse extends Message<ResetStudentPasswordsResponse> {
  /**
   * @generated from field: int32 reset_student_count = 1;
   */
  resetStudentCount = 0;

  constructor(data?: PartialMessage<ResetStudentPasswordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ResetStudentPasswordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reset_student_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetStudentPasswordsResponse {
    return new ResetStudentPasswordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetStudentPasswordsResponse {
    return new ResetStudentPasswordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetStudentPasswordsResponse {
    return new ResetStudentPasswordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetStudentPasswordsResponse | PlainMessage<ResetStudentPasswordsResponse> | undefined, b: ResetStudentPasswordsResponse | PlainMessage<ResetStudentPasswordsResponse> | undefined): boolean {
    return proto3.util.equals(ResetStudentPasswordsResponse, a, b);
  }
}

/**
 * Request and response messages for resetTeacherPasswords
 *
 * @generated from message geneo.lms.school.apis.ResetTeacherPasswordsRequest
 */
export class ResetTeacherPasswordsRequest extends Message<ResetTeacherPasswordsRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated string usernames = 2;
   */
  usernames: string[] = [];

  constructor(data?: PartialMessage<ResetTeacherPasswordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ResetTeacherPasswordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetTeacherPasswordsRequest {
    return new ResetTeacherPasswordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetTeacherPasswordsRequest {
    return new ResetTeacherPasswordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetTeacherPasswordsRequest {
    return new ResetTeacherPasswordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetTeacherPasswordsRequest | PlainMessage<ResetTeacherPasswordsRequest> | undefined, b: ResetTeacherPasswordsRequest | PlainMessage<ResetTeacherPasswordsRequest> | undefined): boolean {
    return proto3.util.equals(ResetTeacherPasswordsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ResetTeacherPasswordsResponse
 */
export class ResetTeacherPasswordsResponse extends Message<ResetTeacherPasswordsResponse> {
  /**
   * @generated from field: int32 reset_teacher_count = 1;
   */
  resetTeacherCount = 0;

  constructor(data?: PartialMessage<ResetTeacherPasswordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ResetTeacherPasswordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reset_teacher_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetTeacherPasswordsResponse {
    return new ResetTeacherPasswordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetTeacherPasswordsResponse {
    return new ResetTeacherPasswordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetTeacherPasswordsResponse {
    return new ResetTeacherPasswordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetTeacherPasswordsResponse | PlainMessage<ResetTeacherPasswordsResponse> | undefined, b: ResetTeacherPasswordsResponse | PlainMessage<ResetTeacherPasswordsResponse> | undefined): boolean {
    return proto3.util.equals(ResetTeacherPasswordsResponse, a, b);
  }
}

/**
 * Added the School Admin proto changes here
 *
 * @generated from message geneo.lms.school.apis.GetSchoolInfoRequest
 */
export class GetSchoolInfoRequest extends Message<GetSchoolInfoRequest> {
  /**
   * string file_name = 1; // The name of the file to be uploaded or processed
   *
   * MIME type of the file (e.g., "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
   *
   * @generated from field: string mime_type = 1;
   */
  mimeType = "";

  /**
   * The size of the file in bytes (e.g., 5242880 for 5MB)
   *
   * @generated from field: int64 file_size = 2;
   */
  fileSize = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 4;
   */
  profileId = protoInt64.zero;

  /**
   * string file_upload_type = 7; // the type will be either template or filled
   * string school_id = 8;
   *
   * @generated from field: string file_path = 5;
   */
  filePath = "";

  constructor(data?: PartialMessage<GetSchoolInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.GetSchoolInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 4, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSchoolInfoRequest {
    return new GetSchoolInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSchoolInfoRequest {
    return new GetSchoolInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSchoolInfoRequest {
    return new GetSchoolInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSchoolInfoRequest | PlainMessage<GetSchoolInfoRequest> | undefined, b: GetSchoolInfoRequest | PlainMessage<GetSchoolInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetSchoolInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ProcessingSummary
 */
export class ProcessingSummary extends Message<ProcessingSummary> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: geneo.lms.school.apis.Steps steps = 2;
   */
  steps?: Steps;

  /**
   * @generated from field: geneo.lms.school.apis.Summary summary = 3;
   */
  summary?: Summary;

  /**
   * @generated from field: string storagePath = 4;
   */
  storagePath = "";

  constructor(data?: PartialMessage<ProcessingSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ProcessingSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "steps", kind: "message", T: Steps },
    { no: 3, name: "summary", kind: "message", T: Summary },
    { no: 4, name: "storagePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProcessingSummary {
    return new ProcessingSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProcessingSummary {
    return new ProcessingSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProcessingSummary {
    return new ProcessingSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ProcessingSummary | PlainMessage<ProcessingSummary> | undefined, b: ProcessingSummary | PlainMessage<ProcessingSummary> | undefined): boolean {
    return proto3.util.equals(ProcessingSummary, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Steps
 */
export class Steps extends Message<Steps> {
  /**
   * @generated from field: geneo.lms.school.apis.Step schoolInfo = 1;
   */
  schoolInfo?: Step;

  /**
   * @generated from field: geneo.lms.school.apis.Step schoolCourses = 2;
   */
  schoolCourses?: Step;

  /**
   * @generated from field: geneo.lms.school.apis.Step teachers = 3;
   */
  teachers?: Step;

  /**
   * @generated from field: geneo.lms.school.apis.Step students = 4;
   */
  students?: Step;

  /**
   * @generated from field: geneo.lms.school.apis.Step excelUpdate = 5;
   */
  excelUpdate?: Step;

  /**
   * @generated from field: geneo.lms.school.apis.Step teacherCourseMap = 6;
   */
  teacherCourseMap?: Step;

  constructor(data?: PartialMessage<Steps>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Steps";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schoolInfo", kind: "message", T: Step },
    { no: 2, name: "schoolCourses", kind: "message", T: Step },
    { no: 3, name: "teachers", kind: "message", T: Step },
    { no: 4, name: "students", kind: "message", T: Step },
    { no: 5, name: "excelUpdate", kind: "message", T: Step },
    { no: 6, name: "teacherCourseMap", kind: "message", T: Step },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Steps {
    return new Steps().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Steps {
    return new Steps().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Steps {
    return new Steps().fromJsonString(jsonString, options);
  }

  static equals(a: Steps | PlainMessage<Steps> | undefined, b: Steps | PlainMessage<Steps> | undefined): boolean {
    return proto3.util.equals(Steps, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Step
 */
export class Step extends Message<Step> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  /**
   * @generated from field: optional google.protobuf.Any data = 3;
   */
  data?: Any;

  constructor(data?: PartialMessage<Step>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Step";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: Any, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Step {
    return new Step().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Step {
    return new Step().fromJsonString(jsonString, options);
  }

  static equals(a: Step | PlainMessage<Step> | undefined, b: Step | PlainMessage<Step> | undefined): boolean {
    return proto3.util.equals(Step, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Summary
 */
export class Summary extends Message<Summary> {
  /**
   * @generated from field: int32 totalSteps = 1;
   */
  totalSteps = 0;

  /**
   * @generated from field: int32 successfulSteps = 2;
   */
  successfulSteps = 0;

  /**
   * @generated from field: int32 failedSteps = 3;
   */
  failedSteps = 0;

  /**
   * @generated from field: bool criticalErrors = 4;
   */
  criticalErrors = false;

  constructor(data?: PartialMessage<Summary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Summary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "totalSteps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "successfulSteps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "failedSteps", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "criticalErrors", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Summary {
    return new Summary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Summary {
    return new Summary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Summary {
    return new Summary().fromJsonString(jsonString, options);
  }

  static equals(a: Summary | PlainMessage<Summary> | undefined, b: Summary | PlainMessage<Summary> | undefined): boolean {
    return proto3.util.equals(Summary, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolInfoResponse
 */
export class SchoolInfoResponse extends Message<SchoolInfoResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string storagePath = 4;
   */
  storagePath = "";

  /**
   * @generated from field: geneo.lms.school.apis.ProcessingSummary processingSummary = 5;
   */
  processingSummary?: ProcessingSummary;

  /**
   * @generated from field: string error = 6;
   */
  error = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.ValidationError errors = 7;
   */
  errors: ValidationError[] = [];

  constructor(data?: PartialMessage<SchoolInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "storagePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "processingSummary", kind: "message", T: ProcessingSummary },
    { no: 6, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolInfoResponse {
    return new SchoolInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolInfoResponse {
    return new SchoolInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolInfoResponse {
    return new SchoolInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolInfoResponse | PlainMessage<SchoolInfoResponse> | undefined, b: SchoolInfoResponse | PlainMessage<SchoolInfoResponse> | undefined): boolean {
    return proto3.util.equals(SchoolInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FileRequest
 */
export class FileRequest extends Message<FileRequest> {
  /**
   * string bucketName = 1; // Name of the GCS bucket
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 1;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 2;
   */
  profileId = protoInt64.zero;

  /**
   * Name of the file to download
   *
   * @generated from field: string fileName = 3;
   */
  fileName = "";

  /**
   * @generated from field: string storagePath = 4;
   */
  storagePath = "";

  constructor(data?: PartialMessage<FileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 2, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "fileName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "storagePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileRequest {
    return new FileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileRequest {
    return new FileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileRequest {
    return new FileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FileRequest | PlainMessage<FileRequest> | undefined, b: FileRequest | PlainMessage<FileRequest> | undefined): boolean {
    return proto3.util.equals(FileRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FileResponse
 */
export class FileResponse extends Message<FileResponse> {
  /**
   * @generated from field: geneo.common.db.APIResponse.StatusEnum status = 1;
   */
  status = APIResponse_StatusEnum.STATUS_UNDEFINED;

  /**
   * @generated from field: geneo.common.db.APIResponse.CodeEnum code = 2;
   */
  code = APIResponse_CodeEnum.UNDEFINED;

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Content of the file
   *
   * @generated from field: bytes fileContent = 4;
   */
  fileContent = new Uint8Array(0);

  /**
   * Name of the file
   *
   * @generated from field: string fileName = 5;
   */
  fileName = "";

  constructor(data?: PartialMessage<FileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(APIResponse_StatusEnum) },
    { no: 2, name: "code", kind: "enum", T: proto3.getEnumType(APIResponse_CodeEnum) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fileContent", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "fileName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileResponse {
    return new FileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileResponse {
    return new FileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileResponse {
    return new FileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FileResponse | PlainMessage<FileResponse> | undefined, b: FileResponse | PlainMessage<FileResponse> | undefined): boolean {
    return proto3.util.equals(FileResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UploadTSheetRequest
 */
export class UploadTSheetRequest extends Message<UploadTSheetRequest> {
  /**
   * MIME type of the file (e.g., "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
   *
   * @generated from field: string mime_type = 1;
   */
  mimeType = "";

  /**
   * The size of the file in bytes (e.g., 5242880 for 5MB)
   *
   * @generated from field: int64 file_size = 2;
   */
  fileSize = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 4;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: string file_path = 5;
   */
  filePath = "";

  /**
   * @generated from field: string school_id = 6;
   */
  schoolId = "";

  /**
   * @generated from field: string sheet_name = 7;
   */
  sheetName = "";

  constructor(data?: PartialMessage<UploadTSheetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UploadTSheetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 4, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "school_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sheet_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadTSheetRequest {
    return new UploadTSheetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadTSheetRequest {
    return new UploadTSheetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadTSheetRequest {
    return new UploadTSheetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadTSheetRequest | PlainMessage<UploadTSheetRequest> | undefined, b: UploadTSheetRequest | PlainMessage<UploadTSheetRequest> | undefined): boolean {
    return proto3.util.equals(UploadTSheetRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UploadSheetResponse
 */
export class UploadSheetResponse extends Message<UploadSheetResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string storagePath = 4;
   */
  storagePath = "";

  /**
   * @generated from field: geneo.lms.school.apis.ProcessingSummary processingSummary = 5;
   */
  processingSummary?: ProcessingSummary;

  /**
   * @generated from field: string error = 6;
   */
  error = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.ValidationError errors = 7;
   */
  errors: ValidationError[] = [];

  constructor(data?: PartialMessage<UploadSheetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UploadSheetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "storagePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "processingSummary", kind: "message", T: ProcessingSummary },
    { no: 6, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadSheetResponse {
    return new UploadSheetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadSheetResponse {
    return new UploadSheetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadSheetResponse {
    return new UploadSheetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadSheetResponse | PlainMessage<UploadSheetResponse> | undefined, b: UploadSheetResponse | PlainMessage<UploadSheetResponse> | undefined): boolean {
    return proto3.util.equals(UploadSheetResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ValidationError
 */
export class ValidationError extends Message<ValidationError> {
  /**
   * Name of the field with an error
   *
   * @generated from field: string field_name = 1;
   */
  fieldName = "";

  /**
   * Description of the error
   *
   * @generated from field: string error_message = 2;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<ValidationError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ValidationError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidationError {
    return new ValidationError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidationError {
    return new ValidationError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidationError {
    return new ValidationError().fromJsonString(jsonString, options);
  }

  static equals(a: ValidationError | PlainMessage<ValidationError> | undefined, b: ValidationError | PlainMessage<ValidationError> | undefined): boolean {
    return proto3.util.equals(ValidationError, a, b);
  }
}

/**
 * Request message for all sync operations
 *
 * @generated from message geneo.lms.school.apis.SyncSchoolDataRequest
 */
export class SyncSchoolDataRequest extends Message<SyncSchoolDataRequest> {
  /**
   * @generated from field: int64 school_id = 1;
   */
  schoolId = protoInt64.zero;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 2;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * SheetType sheet_type = 3;
   *
   * @generated from oneof geneo.lms.school.apis.SyncSchoolDataRequest.data
   */
  data: {
    /**
     * @generated from field: geneo.lms.school.apis.StudentSyncData students = 4;
     */
    value: StudentSyncData;
    case: "students";
  } | {
    /**
     * @generated from field: geneo.lms.school.apis.TeacherSyncData teachers = 5;
     */
    value: TeacherSyncData;
    case: "teachers";
  } | {
    /**
     * @generated from field: geneo.lms.school.apis.TeacherCourseSyncData teacher_courses = 6;
     */
    value: TeacherCourseSyncData;
    case: "teacherCourses";
  } | {
    /**
     * @generated from field: geneo.lms.school.apis.ConnectedClassSyncData connected_class_map = 7;
     */
    value: ConnectedClassSyncData;
    case: "connectedClassMap";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: optional geneo.lms.school.apis.SheetType sheet_name = 8;
   */
  sheetName?: SheetType;

  /**
   * @generated from field: optional geneo.lms.school.apis.FileInfo file = 9;
   */
  file?: FileInfo;

  constructor(data?: PartialMessage<SyncSchoolDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 4, name: "students", kind: "message", T: StudentSyncData, oneof: "data" },
    { no: 5, name: "teachers", kind: "message", T: TeacherSyncData, oneof: "data" },
    { no: 6, name: "teacher_courses", kind: "message", T: TeacherCourseSyncData, oneof: "data" },
    { no: 7, name: "connected_class_map", kind: "message", T: ConnectedClassSyncData, oneof: "data" },
    { no: 8, name: "sheet_name", kind: "enum", T: proto3.getEnumType(SheetType), opt: true },
    { no: 9, name: "file", kind: "message", T: FileInfo, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolDataRequest {
    return new SyncSchoolDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolDataRequest {
    return new SyncSchoolDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolDataRequest {
    return new SyncSchoolDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolDataRequest | PlainMessage<SyncSchoolDataRequest> | undefined, b: SyncSchoolDataRequest | PlainMessage<SyncSchoolDataRequest> | undefined): boolean {
    return proto3.util.equals(SyncSchoolDataRequest, a, b);
  }
}

/**
 * File information for Excel uploads
 *
 * @generated from message geneo.lms.school.apis.FileInfo
 */
export class FileInfo extends Message<FileInfo> {
  /**
   * @generated from field: string path = 1;
   */
  path = "";

  /**
   * @generated from field: string original_name = 2;
   */
  originalName = "";

  /**
   * @generated from field: string mime_type = 3;
   */
  mimeType = "";

  /**
   * @generated from field: int64 size = 4;
   */
  size = protoInt64.zero;

  constructor(data?: PartialMessage<FileInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FileInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "original_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileInfo {
    return new FileInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileInfo {
    return new FileInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileInfo {
    return new FileInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FileInfo | PlainMessage<FileInfo> | undefined, b: FileInfo | PlainMessage<FileInfo> | undefined): boolean {
    return proto3.util.equals(FileInfo, a, b);
  }
}

/**
 * Student sync data
 *
 * @generated from message geneo.lms.school.apis.StudentSyncData
 */
export class StudentSyncData extends Message<StudentSyncData> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.RawStudentRecord students = 1;
   */
  students: RawStudentRecord[] = [];

  constructor(data?: PartialMessage<StudentSyncData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.StudentSyncData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "students", kind: "message", T: RawStudentRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentSyncData {
    return new StudentSyncData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentSyncData {
    return new StudentSyncData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentSyncData {
    return new StudentSyncData().fromJsonString(jsonString, options);
  }

  static equals(a: StudentSyncData | PlainMessage<StudentSyncData> | undefined, b: StudentSyncData | PlainMessage<StudentSyncData> | undefined): boolean {
    return proto3.util.equals(StudentSyncData, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.StudentRecord
 */
export class StudentRecord extends Message<StudentRecord> {
  /**
   * @generated from field: optional int32 rowNumber = 13;
   */
  rowNumber?: number;

  /**
   * @generated from field: optional int64 student_id = 1;
   */
  studentId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: optional string phone = 5;
   */
  phone?: string;

  /**
   * @generated from field: string password = 6;
   */
  password = "";

  /**
   * @generated from field: int32 board = 7;
   */
  board = 0;

  /**
   * @generated from field: int32 class = 8;
   */
  class = 0;

  /**
   * @generated from field: string section = 9;
   */
  section = "";

  /**
   * @generated from field: string teach_medium = 10;
   */
  teachMedium = "";

  /**
   * @generated from field: optional string roll_no = 11;
   */
  rollNo?: string;

  /**
   * @generated from field: optional bool is_disabled = 12;
   */
  isDisabled?: boolean;

  constructor(data?: PartialMessage<StudentRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.StudentRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 13, name: "rowNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "board", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "class", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "teach_medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "roll_no", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "is_disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentRecord {
    return new StudentRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentRecord {
    return new StudentRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentRecord {
    return new StudentRecord().fromJsonString(jsonString, options);
  }

  static equals(a: StudentRecord | PlainMessage<StudentRecord> | undefined, b: StudentRecord | PlainMessage<StudentRecord> | undefined): boolean {
    return proto3.util.equals(StudentRecord, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.RawStudentRecord
 */
export class RawStudentRecord extends Message<RawStudentRecord> {
  /**
   * @generated from field: optional int32 rowNumber = 13;
   */
  rowNumber?: number;

  /**
   * @generated from field: optional int64 student_id = 1;
   */
  studentId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: optional string phone = 5;
   */
  phone?: string;

  /**
   * @generated from field: string password = 6;
   */
  password = "";

  /**
   * @generated from field: string board = 7;
   */
  board = "";

  /**
   * @generated from field: string class = 8;
   */
  class = "";

  /**
   * @generated from field: string section = 9;
   */
  section = "";

  /**
   * @generated from field: string teach_medium = 10;
   */
  teachMedium = "";

  /**
   * @generated from field: optional int32 roll_no = 11;
   */
  rollNo?: number;

  /**
   * @generated from field: optional bool is_disabled = 12;
   */
  isDisabled?: boolean;

  constructor(data?: PartialMessage<RawStudentRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.RawStudentRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 13, name: "rowNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "board", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "teach_medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "roll_no", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "is_disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RawStudentRecord {
    return new RawStudentRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RawStudentRecord {
    return new RawStudentRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RawStudentRecord {
    return new RawStudentRecord().fromJsonString(jsonString, options);
  }

  static equals(a: RawStudentRecord | PlainMessage<RawStudentRecord> | undefined, b: RawStudentRecord | PlainMessage<RawStudentRecord> | undefined): boolean {
    return proto3.util.equals(RawStudentRecord, a, b);
  }
}

/**
 * Teacher sync data
 *
 * @generated from message geneo.lms.school.apis.TeacherSyncData
 */
export class TeacherSyncData extends Message<TeacherSyncData> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.TeacherRecord teachers = 1;
   */
  teachers: TeacherRecord[] = [];

  constructor(data?: PartialMessage<TeacherSyncData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.TeacherSyncData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teachers", kind: "message", T: TeacherRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherSyncData {
    return new TeacherSyncData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherSyncData {
    return new TeacherSyncData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherSyncData {
    return new TeacherSyncData().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherSyncData | PlainMessage<TeacherSyncData> | undefined, b: TeacherSyncData | PlainMessage<TeacherSyncData> | undefined): boolean {
    return proto3.util.equals(TeacherSyncData, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.TeacherRecord
 */
export class TeacherRecord extends Message<TeacherRecord> {
  /**
   * @generated from field: optional int32 rowNumber = 13;
   */
  rowNumber?: number;

  /**
   * @generated from field: optional int64 teacher_id = 1;
   */
  teacherId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: optional string phone = 5;
   */
  phone?: string;

  /**
   * @generated from field: string password = 6;
   */
  password = "";

  /**
   * @generated from field: repeated string teach_mediums = 7;
   */
  teachMediums: string[] = [];

  /**
   * @generated from field: optional bool is_admin = 8;
   */
  isAdmin?: boolean;

  /**
   * @generated from field: optional bool is_disabled = 9;
   */
  isDisabled?: boolean;

  constructor(data?: PartialMessage<TeacherRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.TeacherRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 13, name: "rowNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "teach_mediums", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "is_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "is_disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherRecord {
    return new TeacherRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherRecord {
    return new TeacherRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherRecord {
    return new TeacherRecord().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherRecord | PlainMessage<TeacherRecord> | undefined, b: TeacherRecord | PlainMessage<TeacherRecord> | undefined): boolean {
    return proto3.util.equals(TeacherRecord, a, b);
  }
}

/**
 * Teacher course mapping data
 *
 * @generated from message geneo.lms.school.apis.TeacherCourseSyncData
 */
export class TeacherCourseSyncData extends Message<TeacherCourseSyncData> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.TeacherCourseMappingForJSON teacher_courses = 1;
   */
  teacherCourses: TeacherCourseMappingForJSON[] = [];

  constructor(data?: PartialMessage<TeacherCourseSyncData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.TeacherCourseSyncData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_courses", kind: "message", T: TeacherCourseMappingForJSON, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherCourseSyncData {
    return new TeacherCourseSyncData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherCourseSyncData {
    return new TeacherCourseSyncData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherCourseSyncData {
    return new TeacherCourseSyncData().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherCourseSyncData | PlainMessage<TeacherCourseSyncData> | undefined, b: TeacherCourseSyncData | PlainMessage<TeacherCourseSyncData> | undefined): boolean {
    return proto3.util.equals(TeacherCourseSyncData, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.TeacherCourseMapping
 */
export class TeacherCourseMapping extends Message<TeacherCourseMapping> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.ClassSubjectMap class_subject_map = 3;
   */
  classSubjectMap: ClassSubjectMap[] = [];

  constructor(data?: PartialMessage<TeacherCourseMapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.TeacherCourseMapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_subject_map", kind: "message", T: ClassSubjectMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherCourseMapping {
    return new TeacherCourseMapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherCourseMapping {
    return new TeacherCourseMapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherCourseMapping {
    return new TeacherCourseMapping().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherCourseMapping | PlainMessage<TeacherCourseMapping> | undefined, b: TeacherCourseMapping | PlainMessage<TeacherCourseMapping> | undefined): boolean {
    return proto3.util.equals(TeacherCourseMapping, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.TeacherCourseMappingForJSON
 */
export class TeacherCourseMappingForJSON extends Message<TeacherCourseMappingForJSON> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.ClassSubjectMapForJSON class_subject_map = 3;
   */
  classSubjectMap: ClassSubjectMapForJSON[] = [];

  constructor(data?: PartialMessage<TeacherCourseMappingForJSON>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.TeacherCourseMappingForJSON";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_subject_map", kind: "message", T: ClassSubjectMapForJSON, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherCourseMappingForJSON {
    return new TeacherCourseMappingForJSON().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherCourseMappingForJSON {
    return new TeacherCourseMappingForJSON().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherCourseMappingForJSON {
    return new TeacherCourseMappingForJSON().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherCourseMappingForJSON | PlainMessage<TeacherCourseMappingForJSON> | undefined, b: TeacherCourseMappingForJSON | PlainMessage<TeacherCourseMappingForJSON> | undefined): boolean {
    return proto3.util.equals(TeacherCourseMappingForJSON, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClassSubjectMap
 */
export class ClassSubjectMap extends Message<ClassSubjectMap> {
  /**
   * @generated from field: optional int32 rowNumber = 5;
   */
  rowNumber?: number;

  /**
   * @generated from field: geneo.common.db.Board.BoardEnum board = 1;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 2;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * @generated from field: string subject = 4;
   */
  subject = "";

  constructor(data?: PartialMessage<ClassSubjectMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClassSubjectMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 5, name: "rowNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 1, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 2, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSubjectMap {
    return new ClassSubjectMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSubjectMap {
    return new ClassSubjectMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSubjectMap {
    return new ClassSubjectMap().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSubjectMap | PlainMessage<ClassSubjectMap> | undefined, b: ClassSubjectMap | PlainMessage<ClassSubjectMap> | undefined): boolean {
    return proto3.util.equals(ClassSubjectMap, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClassSubjectMapForJSON
 */
export class ClassSubjectMapForJSON extends Message<ClassSubjectMapForJSON> {
  /**
   * optional int32 rowNumber = 5;
   *
   * @generated from field: geneo.common.db.Board.BoardEnum board = 1;
   */
  board = Board_BoardEnum.UNDEFINED;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 2;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: string medium = 5;
   */
  medium = "";

  /**
   * @generated from field: int32 sectionId = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 subjectId = 4;
   */
  subjectId = 0;

  constructor(data?: PartialMessage<ClassSubjectMapForJSON>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClassSubjectMapForJSON";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
    { no: 2, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 5, name: "medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subjectId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSubjectMapForJSON {
    return new ClassSubjectMapForJSON().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSubjectMapForJSON {
    return new ClassSubjectMapForJSON().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSubjectMapForJSON {
    return new ClassSubjectMapForJSON().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSubjectMapForJSON | PlainMessage<ClassSubjectMapForJSON> | undefined, b: ClassSubjectMapForJSON | PlainMessage<ClassSubjectMapForJSON> | undefined): boolean {
    return proto3.util.equals(ClassSubjectMapForJSON, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ConnectedClassSyncData
 */
export class ConnectedClassSyncData extends Message<ConnectedClassSyncData> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.ConnectedClassMap connected_class_map = 1;
   */
  connectedClassMap: ConnectedClassMap[] = [];

  constructor(data?: PartialMessage<ConnectedClassSyncData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ConnectedClassSyncData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_map", kind: "message", T: ConnectedClassMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassSyncData {
    return new ConnectedClassSyncData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassSyncData {
    return new ConnectedClassSyncData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassSyncData {
    return new ConnectedClassSyncData().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassSyncData | PlainMessage<ConnectedClassSyncData> | undefined, b: ConnectedClassSyncData | PlainMessage<ConnectedClassSyncData> | undefined): boolean {
    return proto3.util.equals(ConnectedClassSyncData, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ConnectedClassMap
 */
export class ConnectedClassMap extends Message<ConnectedClassMap> {
  /**
   * @generated from field: optional int32 rowNumber = 6;
   */
  rowNumber?: number;

  /**
   * Base station ID
   *
   * @generated from field: string base_station_id = 1;
   */
  baseStationId = "";

  /**
   * Grade
   *
   * @generated from field: geneo.common.db.Class.ClassEnum grade = 2;
   */
  grade = Class_ClassEnum.UNDEFINED;

  /**
   * Section
   *
   * @generated from field: string section = 3;
   */
  section = "";

  /**
   * Clicker ID
   *
   * @generated from field: string clicker_id = 4;
   */
  clickerId = "";

  /**
   * Student ID
   *
   * @generated from field: int64 student_id = 5;
   */
  studentId = protoInt64.zero;

  constructor(data?: PartialMessage<ConnectedClassMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ConnectedClassMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 6, name: "rowNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 1, name: "base_station_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "grade", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassMap {
    return new ConnectedClassMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassMap {
    return new ConnectedClassMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassMap {
    return new ConnectedClassMap().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassMap | PlainMessage<ConnectedClassMap> | undefined, b: ConnectedClassMap | PlainMessage<ConnectedClassMap> | undefined): boolean {
    return proto3.util.equals(ConnectedClassMap, a, b);
  }
}

/**
 * Response messages
 *
 * @generated from message geneo.lms.school.apis.SyncSchoolDataResponse
 */
export class SyncSchoolDataResponse extends Message<SyncSchoolDataResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: optional string message = 3;
   */
  message?: string;

  /**
   * @generated from field: geneo.lms.school.apis.ProcessingResult result = 4;
   */
  result?: ProcessingResult;

  constructor(data?: PartialMessage<SyncSchoolDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SyncSchoolDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "result", kind: "message", T: ProcessingResult },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncSchoolDataResponse {
    return new SyncSchoolDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncSchoolDataResponse {
    return new SyncSchoolDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncSchoolDataResponse {
    return new SyncSchoolDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncSchoolDataResponse | PlainMessage<SyncSchoolDataResponse> | undefined, b: SyncSchoolDataResponse | PlainMessage<SyncSchoolDataResponse> | undefined): boolean {
    return proto3.util.equals(SyncSchoolDataResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ProcessingResult
 */
export class ProcessingResult extends Message<ProcessingResult> {
  /**
   * @generated from field: repeated geneo.lms.school.apis.ProcessedRecord successful = 1;
   */
  successful: ProcessedRecord[] = [];

  /**
   * @generated from field: repeated geneo.lms.school.apis.FailedRecord failed = 2;
   */
  failed: FailedRecord[] = [];

  /**
   * @generated from field: repeated geneo.lms.school.apis.ProcessedRecord deactivated = 3;
   */
  deactivated: ProcessedRecord[] = [];

  /**
   * @generated from field: geneo.lms.school.apis.SheetSummary summary = 4;
   */
  summary?: SheetSummary;

  constructor(data?: PartialMessage<ProcessingResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ProcessingResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "successful", kind: "message", T: ProcessedRecord, repeated: true },
    { no: 2, name: "failed", kind: "message", T: FailedRecord, repeated: true },
    { no: 3, name: "deactivated", kind: "message", T: ProcessedRecord, repeated: true },
    { no: 4, name: "summary", kind: "message", T: SheetSummary },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProcessingResult {
    return new ProcessingResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProcessingResult {
    return new ProcessingResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProcessingResult {
    return new ProcessingResult().fromJsonString(jsonString, options);
  }

  static equals(a: ProcessingResult | PlainMessage<ProcessingResult> | undefined, b: ProcessingResult | PlainMessage<ProcessingResult> | undefined): boolean {
    return proto3.util.equals(ProcessingResult, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ProcessedRecord
 */
export class ProcessedRecord extends Message<ProcessedRecord> {
  /**
   * @generated from field: int32 user_id = 1;
   */
  userId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: optional string class = 3;
   */
  class?: string;

  /**
   * @generated from field: optional string user_name = 4;
   */
  userName?: string;

  constructor(data?: PartialMessage<ProcessedRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ProcessedRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProcessedRecord {
    return new ProcessedRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProcessedRecord {
    return new ProcessedRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProcessedRecord {
    return new ProcessedRecord().fromJsonString(jsonString, options);
  }

  static equals(a: ProcessedRecord | PlainMessage<ProcessedRecord> | undefined, b: ProcessedRecord | PlainMessage<ProcessedRecord> | undefined): boolean {
    return proto3.util.equals(ProcessedRecord, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FailedRecord
 */
export class FailedRecord extends Message<FailedRecord> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated string errors = 2;
   */
  errors: string[] = [];

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: optional bytes data = 4;
   */
  data?: Uint8Array;

  /**
   * @generated from field: optional int32 rowNumber = 5;
   */
  rowNumber?: number;

  constructor(data?: PartialMessage<FailedRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FailedRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 5, name: "rowNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FailedRecord {
    return new FailedRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FailedRecord {
    return new FailedRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FailedRecord {
    return new FailedRecord().fromJsonString(jsonString, options);
  }

  static equals(a: FailedRecord | PlainMessage<FailedRecord> | undefined, b: FailedRecord | PlainMessage<FailedRecord> | undefined): boolean {
    return proto3.util.equals(FailedRecord, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SheetSummary
 */
export class SheetSummary extends Message<SheetSummary> {
  /**
   * @generated from field: int32 total_processed = 1;
   */
  totalProcessed = 0;

  /**
   * @generated from field: int32 success_count = 2;
   */
  successCount = 0;

  /**
   * @generated from field: int32 failure_count = 3;
   */
  failureCount = 0;

  /**
   * @generated from field: int32 deactivated_count = 4;
   */
  deactivatedCount = 0;

  constructor(data?: PartialMessage<SheetSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SheetSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_processed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "success_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "failure_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "deactivated_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SheetSummary {
    return new SheetSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SheetSummary {
    return new SheetSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SheetSummary {
    return new SheetSummary().fromJsonString(jsonString, options);
  }

  static equals(a: SheetSummary | PlainMessage<SheetSummary> | undefined, b: SheetSummary | PlainMessage<SheetSummary> | undefined): boolean {
    return proto3.util.equals(SheetSummary, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.GenerateExcelRequest
 */
export class GenerateExcelRequest extends Message<GenerateExcelRequest> {
  /**
   * @generated from field: int64 school_id = 1;
   */
  schoolId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.school.apis.SheetType sheet_name = 2;
   */
  sheetName = SheetType.UNDEFINED;

  /**
   * @generated from field: string academic_year = 3;
   */
  academicYear = "";

  /**
   * @generated from field: bool for_upload = 4;
   */
  forUpload = false;

  constructor(data?: PartialMessage<GenerateExcelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.GenerateExcelRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "sheet_name", kind: "enum", T: proto3.getEnumType(SheetType) },
    { no: 3, name: "academic_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "for_upload", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateExcelRequest {
    return new GenerateExcelRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateExcelRequest {
    return new GenerateExcelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateExcelRequest {
    return new GenerateExcelRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateExcelRequest | PlainMessage<GenerateExcelRequest> | undefined, b: GenerateExcelRequest | PlainMessage<GenerateExcelRequest> | undefined): boolean {
    return proto3.util.equals(GenerateExcelRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.GenerateExcelResponse
 */
export class GenerateExcelResponse extends Message<GenerateExcelResponse> {
  /**
   * @generated from field: string download_url = 1;
   */
  downloadUrl = "";

  /**
   * @generated from field: bool success = 4;
   */
  success = false;

  /**
   * @generated from field: optional string message = 5;
   */
  message?: string;

  constructor(data?: PartialMessage<GenerateExcelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.GenerateExcelResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateExcelResponse {
    return new GenerateExcelResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateExcelResponse {
    return new GenerateExcelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateExcelResponse {
    return new GenerateExcelResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateExcelResponse | PlainMessage<GenerateExcelResponse> | undefined, b: GenerateExcelResponse | PlainMessage<GenerateExcelResponse> | undefined): boolean {
    return proto3.util.equals(GenerateExcelResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FetchPaginatedSchoolDataRequest
 */
export class FetchPaginatedSchoolDataRequest extends Message<FetchPaginatedSchoolDataRequest> {
  /**
   * @generated from field: geneo.lms.school.apis.SheetType sheet_type = 1;
   */
  sheetType = SheetType.UNDEFINED;

  /**
   * @generated from field: int64 school_id = 2;
   */
  schoolId = protoInt64.zero;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 3;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * @generated from field: int32 page = 4;
   */
  page = 0;

  /**
   * string sort_field = 6;
   * string sort_order = 7;
   *
   * @generated from field: int32 page_size = 5;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<FetchPaginatedSchoolDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FetchPaginatedSchoolDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sheet_type", kind: "enum", T: proto3.getEnumType(SheetType) },
    { no: 2, name: "school_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 4, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPaginatedSchoolDataRequest {
    return new FetchPaginatedSchoolDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPaginatedSchoolDataRequest {
    return new FetchPaginatedSchoolDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPaginatedSchoolDataRequest {
    return new FetchPaginatedSchoolDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPaginatedSchoolDataRequest | PlainMessage<FetchPaginatedSchoolDataRequest> | undefined, b: FetchPaginatedSchoolDataRequest | PlainMessage<FetchPaginatedSchoolDataRequest> | undefined): boolean {
    return proto3.util.equals(FetchPaginatedSchoolDataRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.PaginationInfo
 */
export class PaginationInfo extends Message<PaginationInfo> {
  /**
   * @generated from field: int32 current_page = 1;
   */
  currentPage = 0;

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 total_count = 3;
   */
  totalCount = 0;

  /**
   * @generated from field: int32 total_pages = 4;
   */
  totalPages = 0;

  constructor(data?: PartialMessage<PaginationInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.PaginationInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "current_page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_pages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationInfo {
    return new PaginationInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationInfo {
    return new PaginationInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaginationInfo {
    return new PaginationInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PaginationInfo | PlainMessage<PaginationInfo> | undefined, b: PaginationInfo | PlainMessage<PaginationInfo> | undefined): boolean {
    return proto3.util.equals(PaginationInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Status
 */
export class Status extends Message<Status> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Status";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Status {
    return new Status().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Status {
    return new Status().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Status {
    return new Status().fromJsonString(jsonString, options);
  }

  static equals(a: Status | PlainMessage<Status> | undefined, b: Status | PlainMessage<Status> | undefined): boolean {
    return proto3.util.equals(Status, a, b);
  }
}

/**
 * message DataItem {
 *   string json_data = 1;  // Serialized JSON string
 * }
 *
 * @generated from message geneo.lms.school.apis.FetchPaginatedSchoolDataResponse
 */
export class FetchPaginatedSchoolDataResponse extends Message<FetchPaginatedSchoolDataResponse> {
  /**
   * @generated from field: geneo.lms.school.apis.Status status = 1;
   */
  status?: Status;

  /**
   * @generated from field: google.protobuf.ListValue data = 2;
   */
  data?: ListValue;

  /**
   * @generated from field: geneo.lms.school.apis.PaginationInfo pagination = 3;
   */
  pagination?: PaginationInfo;

  constructor(data?: PartialMessage<FetchPaginatedSchoolDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FetchPaginatedSchoolDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "message", T: Status },
    { no: 2, name: "data", kind: "message", T: ListValue },
    { no: 3, name: "pagination", kind: "message", T: PaginationInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPaginatedSchoolDataResponse {
    return new FetchPaginatedSchoolDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPaginatedSchoolDataResponse {
    return new FetchPaginatedSchoolDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPaginatedSchoolDataResponse {
    return new FetchPaginatedSchoolDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPaginatedSchoolDataResponse | PlainMessage<FetchPaginatedSchoolDataResponse> | undefined, b: FetchPaginatedSchoolDataResponse | PlainMessage<FetchPaginatedSchoolDataResponse> | undefined): boolean {
    return proto3.util.equals(FetchPaginatedSchoolDataResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ResetUserPasswordRequest
 */
export class ResetUserPasswordRequest extends Message<ResetUserPasswordRequest> {
  /**
   * @generated from field: int32 userId = 1;
   */
  userId = 0;

  /**
   * @generated from field: string userType = 2;
   */
  userType = "";

  /**
   * @generated from field: string newPassword = 3;
   */
  newPassword = "";

  constructor(data?: PartialMessage<ResetUserPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ResetUserPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "userId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "userType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "newPassword", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetUserPasswordRequest {
    return new ResetUserPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetUserPasswordRequest {
    return new ResetUserPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetUserPasswordRequest {
    return new ResetUserPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetUserPasswordRequest | PlainMessage<ResetUserPasswordRequest> | undefined, b: ResetUserPasswordRequest | PlainMessage<ResetUserPasswordRequest> | undefined): boolean {
    return proto3.util.equals(ResetUserPasswordRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ResetUserPasswordResponse
 */
export class ResetUserPasswordResponse extends Message<ResetUserPasswordResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<ResetUserPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ResetUserPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetUserPasswordResponse {
    return new ResetUserPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetUserPasswordResponse {
    return new ResetUserPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetUserPasswordResponse {
    return new ResetUserPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetUserPasswordResponse | PlainMessage<ResetUserPasswordResponse> | undefined, b: ResetUserPasswordResponse | PlainMessage<ResetUserPasswordResponse> | undefined): boolean {
    return proto3.util.equals(ResetUserPasswordResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FetchSchoolInfoRequest
 */
export class FetchSchoolInfoRequest extends Message<FetchSchoolInfoRequest> {
  /**
   * @generated from field: int32 schoolId = 1;
   */
  schoolId = 0;

  /**
   * UserRole userRole = 2;
   *
   * int64 profile_id = 4;
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum userRole = 3;
   */
  userRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<FetchSchoolInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FetchSchoolInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schoolId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "userRole", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolInfoRequest {
    return new FetchSchoolInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolInfoRequest {
    return new FetchSchoolInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolInfoRequest {
    return new FetchSchoolInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolInfoRequest | PlainMessage<FetchSchoolInfoRequest> | undefined, b: FetchSchoolInfoRequest | PlainMessage<FetchSchoolInfoRequest> | undefined): boolean {
    return proto3.util.equals(FetchSchoolInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FetchSchoolInfoResponse
 */
export class FetchSchoolInfoResponse extends Message<FetchSchoolInfoResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: geneo.school.db.School school = 3;
   */
  school?: School;

  /**
   * @generated from field: geneo.lms.school.apis.SchoolFieldPermissions permissions = 4;
   */
  permissions?: SchoolFieldPermissions;

  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolClassSectionSubjects school_class_section_subjects = 5;
   */
  schoolClassSectionSubjects: SchoolClassSectionSubjects[] = [];

  /**
   * @generated from field: repeated geneo.lms.school.apis.Board boards = 6;
   */
  boards: Board[] = [];

  /**
   * @generated from field: repeated geneo.lms.school.apis.Medium mediums = 7;
   */
  mediums: Medium[] = [];

  /**
   * @generated from field: repeated geneo.lms.school.apis.AcademicYear academic_years = 8;
   */
  academicYears: AcademicYear[] = [];

  constructor(data?: PartialMessage<FetchSchoolInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FetchSchoolInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school", kind: "message", T: School },
    { no: 4, name: "permissions", kind: "message", T: SchoolFieldPermissions },
    { no: 5, name: "school_class_section_subjects", kind: "message", T: SchoolClassSectionSubjects, repeated: true },
    { no: 6, name: "boards", kind: "message", T: Board, repeated: true },
    { no: 7, name: "mediums", kind: "message", T: Medium, repeated: true },
    { no: 8, name: "academic_years", kind: "message", T: AcademicYear, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchSchoolInfoResponse {
    return new FetchSchoolInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchSchoolInfoResponse {
    return new FetchSchoolInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchSchoolInfoResponse {
    return new FetchSchoolInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchSchoolInfoResponse | PlainMessage<FetchSchoolInfoResponse> | undefined, b: FetchSchoolInfoResponse | PlainMessage<FetchSchoolInfoResponse> | undefined): boolean {
    return proto3.util.equals(FetchSchoolInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.AcademicYear
 */
export class AcademicYear extends Message<AcademicYear> {
  /**
   * @generated from field: int32 academicYearId = 1;
   */
  academicYearId = 0;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academicYear = 3;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  constructor(data?: PartialMessage<AcademicYear>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.AcademicYear";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "academicYearId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "academicYear", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcademicYear {
    return new AcademicYear().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcademicYear {
    return new AcademicYear().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcademicYear {
    return new AcademicYear().fromJsonString(jsonString, options);
  }

  static equals(a: AcademicYear | PlainMessage<AcademicYear> | undefined, b: AcademicYear | PlainMessage<AcademicYear> | undefined): boolean {
    return proto3.util.equals(AcademicYear, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolClassSectionSubjects
 */
export class SchoolClassSectionSubjects extends Message<SchoolClassSectionSubjects> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: string class_name = 2;
   */
  className = "";

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 4;
   */
  sectionName = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.ClassSubjects subjects = 5;
   */
  subjects: ClassSubjects[] = [];

  constructor(data?: PartialMessage<SchoolClassSectionSubjects>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolClassSectionSubjects";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subjects", kind: "message", T: ClassSubjects, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolClassSectionSubjects {
    return new SchoolClassSectionSubjects().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolClassSectionSubjects {
    return new SchoolClassSectionSubjects().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolClassSectionSubjects {
    return new SchoolClassSectionSubjects().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolClassSectionSubjects | PlainMessage<SchoolClassSectionSubjects> | undefined, b: SchoolClassSectionSubjects | PlainMessage<SchoolClassSectionSubjects> | undefined): boolean {
    return proto3.util.equals(SchoolClassSectionSubjects, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClassSubjects
 */
export class ClassSubjects extends Message<ClassSubjects> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 2;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: string subjectName = 3;
   */
  subjectName = "";

  constructor(data?: PartialMessage<ClassSubjects>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClassSubjects";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 3, name: "subjectName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSubjects {
    return new ClassSubjects().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSubjects {
    return new ClassSubjects().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSubjects {
    return new ClassSubjects().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSubjects | PlainMessage<ClassSubjects> | undefined, b: ClassSubjects | PlainMessage<ClassSubjects> | undefined): boolean {
    return proto3.util.equals(ClassSubjects, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Board
 */
export class Board extends Message<Board> {
  /**
   * @generated from field: int32 board_id = 1;
   */
  boardId = 0;

  /**
   * @generated from field: string board_name = 2;
   */
  boardName = "";

  /**
   * @generated from field: geneo.common.db.Board.BoardEnum board = 3;
   */
  board = Board_BoardEnum.UNDEFINED;

  constructor(data?: PartialMessage<Board>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Board";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "board_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "board", kind: "enum", T: proto3.getEnumType(Board_BoardEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Board {
    return new Board().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Board {
    return new Board().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Board {
    return new Board().fromJsonString(jsonString, options);
  }

  static equals(a: Board | PlainMessage<Board> | undefined, b: Board | PlainMessage<Board> | undefined): boolean {
    return proto3.util.equals(Board, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Medium
 */
export class Medium extends Message<Medium> {
  /**
   * @generated from field: int32 medium_id = 1;
   */
  mediumId = 0;

  /**
   * @generated from field: string medium_name = 2;
   */
  mediumName = "";

  constructor(data?: PartialMessage<Medium>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Medium";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "medium_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Medium {
    return new Medium().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Medium {
    return new Medium().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Medium {
    return new Medium().fromJsonString(jsonString, options);
  }

  static equals(a: Medium | PlainMessage<Medium> | undefined, b: Medium | PlainMessage<Medium> | undefined): boolean {
    return proto3.util.equals(Medium, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FieldPermission
 */
export class FieldPermission extends Message<FieldPermission> {
  /**
   * @generated from field: bool editable = 1;
   */
  editable = false;

  /**
   * @generated from field: bool visible = 2;
   */
  visible = false;

  constructor(data?: PartialMessage<FieldPermission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FieldPermission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "editable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldPermission {
    return new FieldPermission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldPermission {
    return new FieldPermission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldPermission {
    return new FieldPermission().fromJsonString(jsonString, options);
  }

  static equals(a: FieldPermission | PlainMessage<FieldPermission> | undefined, b: FieldPermission | PlainMessage<FieldPermission> | undefined): boolean {
    return proto3.util.equals(FieldPermission, a, b);
  }
}

/**
 * Represents permissions for all school fields
 *
 * @generated from message geneo.lms.school.apis.SchoolFieldPermissions
 */
export class SchoolFieldPermissions extends Message<SchoolFieldPermissions> {
  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission school_id = 1;
   */
  schoolId?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission school_name = 2;
   */
  schoolName?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission udise_id = 3;
   */
  udiseId?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission address = 4;
   */
  address?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission address_city = 5;
   */
  addressCity?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission address_state = 6;
   */
  addressState?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission address_zip_code = 7;
   */
  addressZipCode?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission branch_name = 8;
   */
  branchName?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission school_spocs_info = 9;
   */
  schoolSpocsInfo?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission geneo_spoc_info = 10;
   */
  geneoSpocInfo?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission is_active = 11;
   */
  isActive?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission school_type = 12;
   */
  schoolType?: FieldPermission;

  /**
   * @generated from field: geneo.lms.school.apis.FieldPermission school_zone = 13;
   */
  schoolZone?: FieldPermission;

  constructor(data?: PartialMessage<SchoolFieldPermissions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolFieldPermissions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "message", T: FieldPermission },
    { no: 2, name: "school_name", kind: "message", T: FieldPermission },
    { no: 3, name: "udise_id", kind: "message", T: FieldPermission },
    { no: 4, name: "address", kind: "message", T: FieldPermission },
    { no: 5, name: "address_city", kind: "message", T: FieldPermission },
    { no: 6, name: "address_state", kind: "message", T: FieldPermission },
    { no: 7, name: "address_zip_code", kind: "message", T: FieldPermission },
    { no: 8, name: "branch_name", kind: "message", T: FieldPermission },
    { no: 9, name: "school_spocs_info", kind: "message", T: FieldPermission },
    { no: 10, name: "geneo_spoc_info", kind: "message", T: FieldPermission },
    { no: 11, name: "is_active", kind: "message", T: FieldPermission },
    { no: 12, name: "school_type", kind: "message", T: FieldPermission },
    { no: 13, name: "school_zone", kind: "message", T: FieldPermission },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolFieldPermissions {
    return new SchoolFieldPermissions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolFieldPermissions {
    return new SchoolFieldPermissions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolFieldPermissions {
    return new SchoolFieldPermissions().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolFieldPermissions | PlainMessage<SchoolFieldPermissions> | undefined, b: SchoolFieldPermissions | PlainMessage<SchoolFieldPermissions> | undefined): boolean {
    return proto3.util.equals(SchoolFieldPermissions, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UpdateSchoolInfoRequest
 */
export class UpdateSchoolInfoRequest extends Message<UpdateSchoolInfoRequest> {
  /**
   * @generated from field: int64 school_id = 1;
   */
  schoolId = protoInt64.zero;

  /**
   * UserRole role = 2;
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum userRole = 2;
   */
  userRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 userId = 3;
   */
  userId = protoInt64.zero;

  /**
   * Optional fields that can be updated based on role permissions
   *
   * @generated from field: optional string school_name = 4;
   */
  schoolName?: string;

  /**
   * @generated from field: optional string udise_id = 5;
   */
  udiseId?: string;

  /**
   * @generated from field: optional string address = 6;
   */
  address?: string;

  /**
   * @generated from field: optional string address_city = 7;
   */
  addressCity?: string;

  /**
   * @generated from field: optional string address_state = 8;
   */
  addressState?: string;

  /**
   * @generated from field: optional string address_zip_code = 9;
   */
  addressZipCode?: string;

  /**
   * @generated from field: optional string branch_name = 10;
   */
  branchName?: string;

  /**
   * optional SchoolSpocInfo school_spoc_info = 11;
   *
   * @generated from field: optional geneo.lms.school.apis.GeneoSpocInfo geneo_spoc_info = 12;
   */
  geneoSpocInfo?: GeneoSpocInfo;

  /**
   * @generated from field: repeated geneo.lms.school.apis.SchoolSpocInfo school_spocs_info = 11;
   */
  schoolSpocsInfo: SchoolSpocInfo[] = [];

  /**
   * repeated GeneoSpocInfo geneo_spocs_info = 12;
   *
   * @generated from field: optional bool is_active = 13;
   */
  isActive?: boolean;

  /**
   * @generated from field: optional string school_type = 14;
   */
  schoolType?: string;

  /**
   * @generated from field: optional string school_zone = 15;
   */
  schoolZone?: string;

  constructor(data?: PartialMessage<UpdateSchoolInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UpdateSchoolInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "userRole", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "userId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "udise_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "branch_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "geneo_spoc_info", kind: "message", T: GeneoSpocInfo, opt: true },
    { no: 11, name: "school_spocs_info", kind: "message", T: SchoolSpocInfo, repeated: true },
    { no: 13, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "school_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "school_zone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSchoolInfoRequest {
    return new UpdateSchoolInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSchoolInfoRequest {
    return new UpdateSchoolInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSchoolInfoRequest {
    return new UpdateSchoolInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSchoolInfoRequest | PlainMessage<UpdateSchoolInfoRequest> | undefined, b: UpdateSchoolInfoRequest | PlainMessage<UpdateSchoolInfoRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSchoolInfoRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.SchoolSpocInfo
 */
export class SchoolSpocInfo extends Message<SchoolSpocInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  constructor(data?: PartialMessage<SchoolSpocInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.SchoolSpocInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolSpocInfo {
    return new SchoolSpocInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolSpocInfo {
    return new SchoolSpocInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolSpocInfo {
    return new SchoolSpocInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolSpocInfo | PlainMessage<SchoolSpocInfo> | undefined, b: SchoolSpocInfo | PlainMessage<SchoolSpocInfo> | undefined): boolean {
    return proto3.util.equals(SchoolSpocInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.GeneoSpocInfo
 */
export class GeneoSpocInfo extends Message<GeneoSpocInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  constructor(data?: PartialMessage<GeneoSpocInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.GeneoSpocInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeneoSpocInfo {
    return new GeneoSpocInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeneoSpocInfo {
    return new GeneoSpocInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeneoSpocInfo {
    return new GeneoSpocInfo().fromJsonString(jsonString, options);
  }

  static equals(a: GeneoSpocInfo | PlainMessage<GeneoSpocInfo> | undefined, b: GeneoSpocInfo | PlainMessage<GeneoSpocInfo> | undefined): boolean {
    return proto3.util.equals(GeneoSpocInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UpdateSchoolInfoResponse
 */
export class UpdateSchoolInfoResponse extends Message<UpdateSchoolInfoResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: repeated string validation_errors = 3;
   */
  validationErrors: string[] = [];

  /**
   * @generated from field: geneo.school.db.School updated_school = 4;
   */
  updatedSchool?: School;

  constructor(data?: PartialMessage<UpdateSchoolInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UpdateSchoolInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "validation_errors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "updated_school", kind: "message", T: School },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSchoolInfoResponse {
    return new UpdateSchoolInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSchoolInfoResponse {
    return new UpdateSchoolInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSchoolInfoResponse {
    return new UpdateSchoolInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSchoolInfoResponse | PlainMessage<UpdateSchoolInfoResponse> | undefined, b: UpdateSchoolInfoResponse | PlainMessage<UpdateSchoolInfoResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSchoolInfoResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.DataImportTrack
 */
export class DataImportTrack extends Message<DataImportTrack> {
  /**
   * @generated from field: int64 import_id = 1;
   */
  importId = protoInt64.zero;

  /**
   * @generated from field: string import_key = 2;
   */
  importKey = "";

  /**
   * @generated from field: int64 school_id = 3;
   */
  schoolId = protoInt64.zero;

  /**
   * @generated from field: string sheet_name = 4;
   */
  sheetName = "";

  /**
   * @generated from field: string academic_year = 5;
   */
  academicYear = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * @generated from field: google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string nano_id = 10;
   */
  nanoId?: string;

  constructor(data?: PartialMessage<DataImportTrack>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.DataImportTrack";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "import_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "import_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "sheet_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "academic_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "nano_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataImportTrack {
    return new DataImportTrack().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataImportTrack {
    return new DataImportTrack().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataImportTrack {
    return new DataImportTrack().fromJsonString(jsonString, options);
  }

  static equals(a: DataImportTrack | PlainMessage<DataImportTrack> | undefined, b: DataImportTrack | PlainMessage<DataImportTrack> | undefined): boolean {
    return proto3.util.equals(DataImportTrack, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UpsertClassSectionMappingRequest
 */
export class UpsertClassSectionMappingRequest extends Message<UpsertClassSectionMappingRequest> {
  /**
   * @generated from field: repeated string sections = 1;
   */
  sections: string[] = [];

  /**
   * @generated from field: int32 class_id = 2;
   */
  classId = 0;

  /**
   * @generated from field: geneo.common.db.AcademicYear.Enum academic_year = 3;
   */
  academicYear = AcademicYear_Enum.UNDEFINED;

  /**
   * @generated from field: int32 school_id = 4;
   */
  schoolId = 0;

  /**
   * int64 user_id = 5;
   *
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 5;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 6;
   */
  profileId = protoInt64.zero;

  constructor(data?: PartialMessage<UpsertClassSectionMappingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UpsertClassSectionMappingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sections", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "academic_year", kind: "enum", T: proto3.getEnumType(AcademicYear_Enum) },
    { no: 4, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 6, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertClassSectionMappingRequest {
    return new UpsertClassSectionMappingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertClassSectionMappingRequest {
    return new UpsertClassSectionMappingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertClassSectionMappingRequest {
    return new UpsertClassSectionMappingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertClassSectionMappingRequest | PlainMessage<UpsertClassSectionMappingRequest> | undefined, b: UpsertClassSectionMappingRequest | PlainMessage<UpsertClassSectionMappingRequest> | undefined): boolean {
    return proto3.util.equals(UpsertClassSectionMappingRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UpsertClassSectionMappingResponse
 */
export class UpsertClassSectionMappingResponse extends Message<UpsertClassSectionMappingResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: repeated string addedSections = 3;
   */
  addedSections: string[] = [];

  /**
   * repeated string updatedSubjects = 5;
   *
   * @generated from field: repeated string removedSections = 4;
   */
  removedSections: string[] = [];

  constructor(data?: PartialMessage<UpsertClassSectionMappingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UpsertClassSectionMappingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "addedSections", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "removedSections", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertClassSectionMappingResponse {
    return new UpsertClassSectionMappingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertClassSectionMappingResponse {
    return new UpsertClassSectionMappingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertClassSectionMappingResponse {
    return new UpsertClassSectionMappingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertClassSectionMappingResponse | PlainMessage<UpsertClassSectionMappingResponse> | undefined, b: UpsertClassSectionMappingResponse | PlainMessage<UpsertClassSectionMappingResponse> | undefined): boolean {
    return proto3.util.equals(UpsertClassSectionMappingResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FetchClickerSetListRequest
 */
export class FetchClickerSetListRequest extends Message<FetchClickerSetListRequest> {
  /**
   * @generated from field: int32 schoolId = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum userRole = 3;
   */
  userRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<FetchClickerSetListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FetchClickerSetListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schoolId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "userRole", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchClickerSetListRequest {
    return new FetchClickerSetListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchClickerSetListRequest {
    return new FetchClickerSetListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchClickerSetListRequest {
    return new FetchClickerSetListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchClickerSetListRequest | PlainMessage<FetchClickerSetListRequest> | undefined, b: FetchClickerSetListRequest | PlainMessage<FetchClickerSetListRequest> | undefined): boolean {
    return proto3.util.equals(FetchClickerSetListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.FetchClickerSetListResponse
 */
export class FetchClickerSetListResponse extends Message<FetchClickerSetListResponse> {
  /**
   * @generated from field: int32 total_count = 1;
   */
  totalCount = 0;

  /**
   * @generated from field: repeated geneo.lms.school.apis.ClickerSet data = 2;
   */
  data: ClickerSet[] = [];

  constructor(data?: PartialMessage<FetchClickerSetListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.FetchClickerSetListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "data", kind: "message", T: ClickerSet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchClickerSetListResponse {
    return new FetchClickerSetListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchClickerSetListResponse {
    return new FetchClickerSetListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchClickerSetListResponse {
    return new FetchClickerSetListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchClickerSetListResponse | PlainMessage<FetchClickerSetListResponse> | undefined, b: FetchClickerSetListResponse | PlainMessage<FetchClickerSetListResponse> | undefined): boolean {
    return proto3.util.equals(FetchClickerSetListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClickerSet
 */
export class ClickerSet extends Message<ClickerSet> {
  /**
   * @generated from field: string setId = 1;
   */
  setId = "";

  /**
   * @generated from field: geneo.lms.school.apis.BaseReceiver baseReceiver = 2;
   */
  baseReceiver?: BaseReceiver;

  /**
   * @generated from field: repeated geneo.lms.school.apis.ClassConfig classConfigs = 3;
   */
  classConfigs: ClassConfig[] = [];

  /**
   * repeated ClassSection classSections = 5;
   *
   * @generated from field: repeated geneo.lms.school.apis.Clicker clickers = 4;
   */
  clickers: Clicker[] = [];

  constructor(data?: PartialMessage<ClickerSet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClickerSet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "setId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "baseReceiver", kind: "message", T: BaseReceiver },
    { no: 3, name: "classConfigs", kind: "message", T: ClassConfig, repeated: true },
    { no: 4, name: "clickers", kind: "message", T: Clicker, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClickerSet {
    return new ClickerSet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClickerSet {
    return new ClickerSet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClickerSet {
    return new ClickerSet().fromJsonString(jsonString, options);
  }

  static equals(a: ClickerSet | PlainMessage<ClickerSet> | undefined, b: ClickerSet | PlainMessage<ClickerSet> | undefined): boolean {
    return proto3.util.equals(ClickerSet, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.BaseReceiver
 */
export class BaseReceiver extends Message<BaseReceiver> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string model = 2;
   */
  model = "";

  /**
   * @generated from field: string serialNumber = 3;
   */
  serialNumber = "";

  constructor(data?: PartialMessage<BaseReceiver>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.BaseReceiver";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "serialNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BaseReceiver {
    return new BaseReceiver().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BaseReceiver {
    return new BaseReceiver().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BaseReceiver {
    return new BaseReceiver().fromJsonString(jsonString, options);
  }

  static equals(a: BaseReceiver | PlainMessage<BaseReceiver> | undefined, b: BaseReceiver | PlainMessage<BaseReceiver> | undefined): boolean {
    return proto3.util.equals(BaseReceiver, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClassConfig
 */
export class ClassConfig extends Message<ClassConfig> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 2;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: int32 sectionId = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string sectionName = 4;
   */
  sectionName = "";

  constructor(data?: PartialMessage<ClassConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClassConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sectionName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassConfig {
    return new ClassConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassConfig {
    return new ClassConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassConfig {
    return new ClassConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ClassConfig | PlainMessage<ClassConfig> | undefined, b: ClassConfig | PlainMessage<ClassConfig> | undefined): boolean {
    return proto3.util.equals(ClassConfig, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.Clicker
 */
export class Clicker extends Message<Clicker> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string serialNumber = 2;
   */
  serialNumber = "";

  /**
   * @generated from field: int32 baseReceiverId = 3;
   */
  baseReceiverId = 0;

  constructor(data?: PartialMessage<Clicker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.Clicker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "serialNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "baseReceiverId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Clicker {
    return new Clicker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Clicker {
    return new Clicker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Clicker {
    return new Clicker().fromJsonString(jsonString, options);
  }

  static equals(a: Clicker | PlainMessage<Clicker> | undefined, b: Clicker | PlainMessage<Clicker> | undefined): boolean {
    return proto3.util.equals(Clicker, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClickerSetAssignmentRequest
 */
export class ClickerSetAssignmentRequest extends Message<ClickerSetAssignmentRequest> {
  /**
   * @generated from field: int32 sectionId = 1;
   */
  sectionId = 0;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum className = 2;
   */
  className = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: int64 schoolId = 3;
   */
  schoolId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 4;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 5;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: int32 oldBaseReceiverId = 6;
   */
  oldBaseReceiverId = 0;

  /**
   * @generated from field: int32 newBaseReceiverId = 7;
   */
  newBaseReceiverId = 0;

  constructor(data?: PartialMessage<ClickerSetAssignmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClickerSetAssignmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "className", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "schoolId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 5, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "oldBaseReceiverId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "newBaseReceiverId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClickerSetAssignmentRequest {
    return new ClickerSetAssignmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClickerSetAssignmentRequest {
    return new ClickerSetAssignmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClickerSetAssignmentRequest {
    return new ClickerSetAssignmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ClickerSetAssignmentRequest | PlainMessage<ClickerSetAssignmentRequest> | undefined, b: ClickerSetAssignmentRequest | PlainMessage<ClickerSetAssignmentRequest> | undefined): boolean {
    return proto3.util.equals(ClickerSetAssignmentRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.ClickerSetAssignmentResponse
 */
export class ClickerSetAssignmentResponse extends Message<ClickerSetAssignmentResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<ClickerSetAssignmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.ClickerSetAssignmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClickerSetAssignmentResponse {
    return new ClickerSetAssignmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClickerSetAssignmentResponse {
    return new ClickerSetAssignmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClickerSetAssignmentResponse {
    return new ClickerSetAssignmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ClickerSetAssignmentResponse | PlainMessage<ClickerSetAssignmentResponse> | undefined, b: ClickerSetAssignmentResponse | PlainMessage<ClickerSetAssignmentResponse> | undefined): boolean {
    return proto3.util.equals(ClickerSetAssignmentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UpdateStudentClickerMapRequest
 */
export class UpdateStudentClickerMapRequest extends Message<UpdateStudentClickerMapRequest> {
  /**
   * @generated from field: int32 studentId = 1;
   */
  studentId = 0;

  /**
   * int64 newClickerId=2;
   *
   * @generated from field: string newClickerSerialNo = 2;
   */
  newClickerSerialNo = "";

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: int64 profile_id = 4;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: int32 baseReceiverId = 5;
   */
  baseReceiverId = 0;

  constructor(data?: PartialMessage<UpdateStudentClickerMapRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UpdateStudentClickerMapRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "studentId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "newClickerSerialNo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 4, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "baseReceiverId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentClickerMapRequest {
    return new UpdateStudentClickerMapRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentClickerMapRequest {
    return new UpdateStudentClickerMapRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentClickerMapRequest {
    return new UpdateStudentClickerMapRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentClickerMapRequest | PlainMessage<UpdateStudentClickerMapRequest> | undefined, b: UpdateStudentClickerMapRequest | PlainMessage<UpdateStudentClickerMapRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStudentClickerMapRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.UpdateStudentClickerMapResponse
 */
export class UpdateStudentClickerMapResponse extends Message<UpdateStudentClickerMapResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.school.apis.StudentClickerMap data = 3;
   */
  data: StudentClickerMap[] = [];

  constructor(data?: PartialMessage<UpdateStudentClickerMapResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.UpdateStudentClickerMapResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: StudentClickerMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentClickerMapResponse {
    return new UpdateStudentClickerMapResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentClickerMapResponse {
    return new UpdateStudentClickerMapResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentClickerMapResponse {
    return new UpdateStudentClickerMapResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentClickerMapResponse | PlainMessage<UpdateStudentClickerMapResponse> | undefined, b: UpdateStudentClickerMapResponse | PlainMessage<UpdateStudentClickerMapResponse> | undefined): boolean {
    return proto3.util.equals(UpdateStudentClickerMapResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.school.apis.StudentClickerMap
 */
export class StudentClickerMap extends Message<StudentClickerMap> {
  /**
   * @generated from field: int64 studentId = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string clickerId = 3;
   */
  clickerId = "";

  /**
   * @generated from field: string rollNo = 4;
   */
  rollNo = "";

  /**
   * @generated from field: string clickerNo = 5;
   */
  clickerNo = "";

  constructor(data?: PartialMessage<StudentClickerMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.school.apis.StudentClickerMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "studentId", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "clickerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rollNo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "clickerNo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentClickerMap {
    return new StudentClickerMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentClickerMap {
    return new StudentClickerMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentClickerMap {
    return new StudentClickerMap().fromJsonString(jsonString, options);
  }

  static equals(a: StudentClickerMap | PlainMessage<StudentClickerMap> | undefined, b: StudentClickerMap | PlainMessage<StudentClickerMap> | undefined): boolean {
    return proto3.util.equals(StudentClickerMap, a, b);
  }
}

